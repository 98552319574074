
/**
 * After adding the type here, f12 to the TourGroup page and add a new Tour to the matching TourGroup
 * @see TourGroup
 * import { Tours } from '../components/Tours/Tours';
 * 
 * Finally, add the reference to your new tour in the Tours component
 * @see Tours
 * import { TourGroup } from '../hooks/useAtlantisTours';
 */
export enum TourType {
    // HOME
    Welcome,

    // DOCUMENTS
    RestoreDeletedFile,
    CreateACabinet,
    ApplyAFolderTemplate,

    // PEOPLE
    CreateNewUser,

    // WORK
    HowToUseESignature,

    // ACCOUNT
}
