import { Api } from './';
import { AxiosResponse } from 'axios';
import { INodePermission } from '../types/NodePermissionTypes';

export const useNodePermissionsApi = () => {
    const getPermissionByNodeIdAsync = async (nodeId: string): Promise<INodePermission[]> => {
        const resp: AxiosResponse = await Api.get(`api/NodePermissions/NodeId/${nodeId}`);
        return resp.data;
    };

    return {
        getPermissionByNodeIdAsync,
    };
};
