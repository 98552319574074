import { useUtilities } from './useUtilities';
import { MetadataTKeys, TranslationFiles, useSafeTranslation } from './useSafeTranslation';
import { INodeProfile, IProfileItem, ProfileItemTypeEnum } from '../types/ProfilesAndProfileItemsTypes';

export const useProfileUtilities = () => {

    const STRING_TRUNCATE_LENGTH = 30;

    const { t } = useSafeTranslation(TranslationFiles.Metadata);
    const { isEmailValid } = useUtilities();

    function comparePositionFn(a: IProfileItem, b: IProfileItem) {
        if ((a.position ?? 0) < (b.position ?? 0)) {
            return -1;
          } else if ((a.position ?? 0) > (b.position ?? 0)) {
            return 1;
          } else {
            return 0;
          }
    }

    function createBlankNodeProfile(nodeId: string, accountId: number): INodeProfile {
        return {
            nodeID: parseInt(nodeId),
            accountID: accountId,
            profileID: undefined,
            profileName: '',
            profileItems: [],
            inherited: false,
            profileNodeId: undefined,
        };
    }

    function isNewValueInvalid(value: string, item: IProfileItem): string {
        if (item.required && !value) {
            return t(MetadataTKeys.RequiredValidationError);
        }

        switch (item.type) {
            case ProfileItemTypeEnum.Email:
                return getEmailValidationMessage(value);
            case ProfileItemTypeEnum.PhoneNumber:
                return getPhoneValidationMessage(value);
            case ProfileItemTypeEnum.Date:
                return getDateValidationMessage(value);
            case ProfileItemTypeEnum.CheckBox:
            case ProfileItemTypeEnum.Percentage:
            case ProfileItemTypeEnum.Currency:
            case ProfileItemTypeEnum.Number:
            case ProfileItemTypeEnum.Memo:
            case ProfileItemTypeEnum.PresetValues:
            case ProfileItemTypeEnum.HiddenText:
            case ProfileItemTypeEnum.Text:
            default:
                return '';
        }
    }

    function hasAdditionalProfileItems(nodeProfile: INodeProfile): boolean {
        return !!nodeProfile.profileItems.filter(x => !x.profileID).length;
    }

    function hasAppliedProfile(nodeProfile: INodeProfile): boolean {
        return !!nodeProfile?.profileID;
    }

    function hasProfileData(nodeProfile: INodeProfile): boolean {
        return hasAppliedProfile(nodeProfile) || hasAdditionalProfileItems(nodeProfile);
    }

    function truncateString(value: string) {
        if (!!value && value.length > STRING_TRUNCATE_LENGTH) {
            return value.substring(0, STRING_TRUNCATE_LENGTH - 3) + '...';
        }

        return value;
    }

    function getEmailValidationMessage(value: string): string {
        if (!!value && !isEmailValid(value)) {
            return t(MetadataTKeys.EmailValidationError);
        }
        else {
            return '';
        }
    }

    function getDateValidationMessage(value: string) {
        if (!!value && value.length < 8) { // 8 is the minimum length for a valid date
            return t(MetadataTKeys.DateValidationError);
        }
        return '';
    }

    function getPhoneValidationMessage(value: string): string {
        if (!!value && value.length < 10) { // 10 is the minimum length for a valid phone number
            return t(MetadataTKeys.PhoneValidationError);
        }
        else { return ''; }
    }

    return {
        comparePositionFn,
        createBlankNodeProfile,
        isNewValueInvalid,
        truncateString,
        hasAdditionalProfileItems,
        hasAppliedProfile,
        hasProfileData,
    };
};