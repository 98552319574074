import { useNavigate } from 'react-router';
import { RoutePath, UserRouteParams } from '../../types/RoutePaths';

export const useUsersRouting = () => {

    const navigate = useNavigate();

    // User Index

    const getLinkToUserIndex = () => {
        return RoutePath.UserIndex;
    };

    const routeToUserIndex = () => {
        navigate(getLinkToUserIndex());
    };
    
    // User Tab

    const getLinkToUsers = () => {
        return RoutePath.Users;
    };

    const routeToUsers = () => {
        navigate(getLinkToUsers());
    };

    const getLinkToUser = (userId: string) => {
        return RoutePath.UserEdit.replace(UserRouteParams.UserId, userId);
    };

    const routeToUser = (userId: string) => {
        navigate(getLinkToUser(userId));
    };

    const getLinkToUserNew = () => {
        return RoutePath.UserNew;
    };

    const routeToUserNew = () => {
        navigate(getLinkToUserNew());
    };

    // Group Tab

    const getLinkToGroups = () => {
        return RoutePath.Groups;
    };

    const routeToGroups = () => {
        navigate(getLinkToGroups());
    };

    const getLinkToGroup = (groupId: string) => {
        return RoutePath.GroupEdit.replace(UserRouteParams.GroupId, groupId);
    };

    const routeToGroup = (groupId: string) => {
        navigate(getLinkToGroup(groupId));
    };

    const getLinkToGroupNew = () => {
        return RoutePath.GroupNew;
    };

    const routeToGroupNew = () => {
        navigate(getLinkToGroupNew());
    };

    // User Sessions Tab

    const getLinkToUserSessions = () => {
        return RoutePath.UserSessions;
    };

    const routeToUserSessions = () => {
        navigate(getLinkToUserSessions());
    };

    // Access Links Tab

    const getLinkToAccessLinks = () => {
        return RoutePath.AccessLinks;
    };

    const routeToAccessLinks = () => {
        navigate(getLinkToAccessLinks());
    };

    const getLinkToAccessLink = (accessLinkId: string) => {
        return RoutePath.AccessLinkEdit.replace(UserRouteParams.AccessLinkId, accessLinkId);
    };

    const routeToAccessLink = (accessLinkId: string) => {
        navigate(getLinkToAccessLink(accessLinkId));
    };

    // Directories Tab

    const getLinkToUserDirectories = () => {
        return RoutePath.UserDirectories;
    };

    const routeToUserDirectories = () => {
        navigate(getLinkToUserDirectories());
    };

    return {
        getLinkToUserIndex,
        getLinkToUsers,
        getLinkToUser,
        getLinkToUserNew,
        getLinkToGroups,
        getLinkToGroup,
        getLinkToGroupNew,
        getLinkToUserSessions,
        getLinkToAccessLinks,
        getLinkToAccessLink,
        getLinkToUserDirectories,
        routeToUserIndex,
        routeToUsers,
        routeToUser,
        routeToUserNew,
        routeToGroups,
        routeToGroup,
        routeToGroupNew,
        routeToUserSessions,
        routeToAccessLinks,
        routeToAccessLink,
        routeToUserDirectories,
    };
};
