import React from 'react';
import { useBootstrapBreakpoints } from '../../../../hooks/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../types/BootstrapTypes';
import { FileUploadNavMenuItem } from '../FileUpload';
import { HelpNavMenuItem } from '../Help';
import { NotificationMenuItem } from '../Notifications';
import { RecentsNavMenuItem } from '../Recents';
import { AccountsNavMenuItem } from '../Accounts';
import '../NavMenu.css';

export const GuestExperienceNavItems = () => {
    const { screenSize } = useBootstrapBreakpoints();

    return (
        <>
            {screenSize > ScreenSize.m && (
                <>
                    <FileUploadNavMenuItem />
                    <HelpNavMenuItem />
                </>
            )}
            <NotificationMenuItem /> 
            {screenSize > ScreenSize.m && (
                <RecentsNavMenuItem />
            )}
            <AccountsNavMenuItem className='ms-2' />
        </>
    );
};
