import { Api } from './';
import { AxiosResponse } from 'axios';
import { IFileVersion } from '../types/CommonTypes';

export const useFileVersionApi = () => {
    const getFileVersions = async (nodeId: string) => {
        const resp: AxiosResponse = await Api.get(`api/FileVersion/${nodeId}`);
        return resp.data as IFileVersion[];
    };

    const deleteFileVersion = async (versionId: string) => {
        await Api.delete(`api/FileVersion/${versionId}`);
    };

    const lockVersion = async (fileVersion: IFileVersion) => {
        await Api.put(`api/FileVersion/LockVersion/${fileVersion.nodeId}`, fileVersion);
    };

    const makePrimary = async (fileVersion: IFileVersion) => {
        await Api.put(`api/FileVersion/${fileVersion.nodeId}`, fileVersion);
    };

    return {
        getFileVersions,
        deleteFileVersion,
        lockVersion,
        makePrimary,
    };
};
