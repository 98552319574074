import { Role } from './CommonTypes';

export enum NodePermissionInheritanceEnum {
    Default = 0,
    AppliesToSelfOnly = 20,
}

export enum NodePermissionLevelEnum {
    Default,
    View,
    Download,
    Create,
    OwnDelete,
    UploadFiles,
    CreateDirectories,
    Write,
    Delete,
    Admin,
    Override,
    RestrictAll,
}

export interface INodePermission {
    admin: boolean;
    childPermission:  {
        view?: boolean;
        download?: boolean;
        uploadFiles?: boolean;
        createDirectories?: boolean;
        write?: boolean;
        delete?: boolean;
        inheritanceBehavior?: NodePermissionInheritanceEnum;
    } | null;
    createDirectories: boolean;
    createdBy: string;
    createdByUserID?: number;
    createdByUsername: string;
    createdOn: Date;
    delete: boolean;
    download: boolean;
    enforce: boolean;
    id: number;
    inheritanceBehavior: NodePermissionInheritanceEnum;
    name: string;
    nodeID?: number;
    preview: boolean;
    read: boolean;
    remove: boolean;
    restrict: boolean;
    role: string;
    roleData: Role;
    roleID?: number;
    systemType: number;
    uploadFiles: boolean;
    view: boolean;
    write: boolean;
}

export const initialPermissionState: INodePermission = {
    admin: false,
    childPermission: {
        view: false,
        download: false,
        uploadFiles: false,
        createDirectories: false,
        write: false,
        delete: false,
        inheritanceBehavior: NodePermissionInheritanceEnum.Default,
    } as INodePermission,
    createDirectories: false,
    createdBy: '',
    createdByUsername: '',
    createdOn: new Date(),
    delete: false,
    download: false,
    enforce: false,
    id: 0,
    inheritanceBehavior: NodePermissionInheritanceEnum.Default,
    name: '',
    preview: false,
    read: false,
    remove: false,
    restrict: false,
    role: '',
    roleData: {} as Role,
    systemType: 0,
    uploadFiles: false,
    view: false,
    write: false,
};
