import { useTranslation } from 'react-i18next';

export const useSafeTranslation = (translationFileName: TranslationFiles) => {
    return useTranslation(translationFileName);
};

export enum TranslationFiles {
    AccountFeatureUsage = 'AccountFeatureUsage',
    AccountModal = 'AccountModal',
    AccountsNavMenuItem = 'AccountsNavMenuItem',
    AccountSwitchRequiredModal = 'AccountSwitchRequiredModal',
    AccountTile = 'AccountTile',
    Activities = 'Activities',
    AddressMDFieldModal = 'AddressMDFieldModal',
    AddTaskModal = 'AddTaskModal',
    AddTemplateModal = 'AddTemplateModal',
    AtlantisTour = 'AtlantisTour',
    Automation = 'Automation',
    Branding = 'Branding',
    BrandingImageModal = 'BrandingImageModal',
    BrandingSaveConfirmModal = 'BrandingSaveConfirmModal',
    CheckboxMDFieldModal = 'CheckboxMDFieldModal',
    CheckedOut = 'CheckedOut',
    Collections = 'Collections',
    DateMDFieldModal = 'DateMDFieldModal',
    DocumentRequests = 'DocumentRequests',
    DocumentRequestTemplateTable = 'DocumentRequestTemplateTable',
    DocumentLayer = 'DocumentLayer',
    MDFieldDropdownListModal = 'MDFieldDropdownListModal',
    Errors = 'Errors',
    ESignature = 'ESignature',
    ESignatureModal = 'ESignatureModal',
    Favorites = 'Favorites',
    FeedbackModal = 'FeedbackModal',
    FilePassword = 'FilePassword',
    FileProcessingQueue = 'FileProcessingQueue',
    FileVersions = 'FileVersions',
    FileUploadNavMenuItem = 'FileUploadNavMenuItem',
    FolderTemplates = 'FolderTemplates',
    Forms = 'Forms',
    Governance = 'Governance',
    GovernanceActionFormControl = 'GovernanceActionFormControl',
    GovernanceActionSelector = 'GovernanceActionSelector',
    GovernanceDeleteActionForm = 'GovernanceDeleteActionForm',
    GovernanceEmailReminderControl = 'GovernanceEmailReminderControl',
    GovernanceInheritanceSelector = 'GovernanceInheritanceSelector',
    GovernanceLockControl = 'GovernanceLockControl',
    GovernanceScheduleActionModal = 'GovernanceScheduleActionModal',
    GovernanceTimeSpanSelector = 'GovernanceTimeSpanSelector',
    HelpNavMenuItem = 'HelpNavMenuItem',
    History = 'History',
    Home = 'Home',
    InheritanceSelector = 'InheritanceSelector',
    LoginLoadingStates = 'LoginLoadingStates',
    Metadata = 'Metadata',
    MDFields = 'MDFields',
    MDFieldModal = 'MDFieldModal',
    MDSideSheetInformation = 'MDSideSheetInformation',
    MDGroups = 'MDGroups',
    MDGroupModal = 'MDGroupModal',
    MDZeroState = 'MDZeroState',
    NestedChecklist = 'NestedChecklist',
    NodeDetails = 'NodeDetails',
    NodeSelectorModal = 'NodeSelectorModal',
    Notes = 'Notes',
    NotificationMenuItem = 'NotificationMenuItem',
    NumberMDFieldModal = 'NumberMDFieldModal',
    OptOutToggleNavItem = 'OptOutToggleNavItem',
    OptOutModal = 'OptOutModal',
    Permissions = 'Permissions',
    PermissionsInheritanceSelector = 'PermissionsInheritanceSelector',
    Preview = 'Preview',
    ProductUpdates = 'ProductUpdates',
    ProfileItemsTable = 'ProfileItemsTable',
    ProfilesAndProfileItems = 'ProfilesAndProfileItems',
    ProfileSelector = 'ProfileSelector',
    ProfilesTable = 'ProfilesTable',
    QuickLinks = 'QuickLinks',
    Recents = 'Recents',
    RecycleBin = 'RecycleBin',
    Reports = 'Reports',
    RoleSelector = 'RoleSelector',
    Salesforce = 'Salesforce',
    SalesforceTable = 'SalesforceTable',
    Search = 'Search',
    SecurityPolicies = 'SecurityPolicies',
    SecurityPoliciesTable = 'SecurityPoliciesTable',
    Settings = 'Settings',
    ShareableLink = 'ShareableLink',
    SharedWithYou = 'SharedWithYou',
    SurveyModal = 'SurveyModal',
    SurveyReprompt = 'SurveyReprompt',
    SystemLogs = 'SystemLogs',
    TextMDFieldModal = 'TextMDFieldModal',
    ToDoList = 'ToDoList',
    UploadLinkModal = 'UploadLinkModal',
    Users = 'Users',
    UserSessions = 'UserSessions',
    UserSettings = 'UserSettings',
    Utilities = 'Utilities',
    UtopiaAlerts = 'UtopiaAlerts',
    UtopiaNotificationsConverter = 'UtopiaNotificationsConverter',
    VerticalNav = 'VerticalNav',
    WelcomeModal = 'WelcomeModal',
    WelcomeTour = 'WelcomeTour',
    Workflow = 'Workflow',
    WorkYouCareAbout = 'WorkYouCareAbout',
}

export enum AccountFeatureUsageModalTKeys {
    Full = 'full',
    Essential = 'essential',
    FolderTemplates = 'folderTemplates',
    Workflows = 'workflows',
    Signatures = 'signatures',
    KBA = 'kba',
    OneTimePasscode = 'oneTimePasscode',
    FormFillTemplates = 'formFillTemplates',
    OpenDocumentRequests = 'openDocumentRequests',
    Compliance = 'compliance',
    FullDetail = 'fullDetail',
    EssentialDetail = 'essentialDetail',
    FolderTemplatesDetail = 'folderTemplatesDetail',
    WorkflowsDetail = 'workflowsDetail',
    SignaturesDetail = 'signaturesDetail',
    KBADetail = 'kbaDetail',
    OneTimePasscodeDetail = 'oneTimePasscodeDetail',
    FormFillTemplatesDetail = 'formFillTemplatesDetail',
    OpenDocumentRequestsDetail = 'openDocumentRequestsDetail',
    ComplianceDetail = 'complianceDetail',
    DisplayHeader = 'displayHeader',
    SingleTooltip = 'singleTooltip',
    MultiTooltipHeader = 'multiTooltipHeader',
    MutliTooltip = 'mutliTooltip',
    MultiTooltipFooter = 'multiTooltipFooter',
}

export enum AccountModalTKeys {
    AccountModalTitle = 'accountModalTitle',
    ActiveAccount = 'activeAccount',
    AdditionalAccounts = 'additionalAccounts',
}

export enum AccountsNavMenuItemTKeys {
    English = 'english',
    Spanish = 'spanish',
    Logout = 'logout',
    ActiveAccount = 'activeAccount',
    AdditionalAccounts = 'additionalAccounts',
    MySettings = 'mySettings',
    Language = 'language',
    Accounts = 'accounts',
}

export enum AccountSwitchRequiredModalTKeys {
    CtaText = 'ctaText',
    CtaTitle = 'ctaTitle',
    Text = 'text',
    ActiveAccount = 'activeAccount',
    AdditionalAccounts = 'additionalAccounts',
    NoAccessTitle = 'noAccessTitle',
    NoAccessText = 'noAccessText',
}

export enum AccountTileTKeys {
    AccountSettings = 'accountSettings',
    SwitchToThisAccount = 'switchToThisAccount',
}

export enum ActivitiesTKeys {
    Title = 'title',
}

export enum AddTaskModalTKeys {
    Cancel = 'cancel',
    Add = 'add',
    Title = 'title',
    Label = 'label',
    PlaceHolder = 'placeHolder',
}

export enum AddTemplateModalTKeys {
    CancelCta = 'cancelCta',
    AddCta = 'addCta',
    AddLibraryCta = 'addLibraryCta',
    Title = 'title',
    TitleLibrary = 'titleLibrary',
    NameLabel = 'nameLabel',
    PlaceHolder = 'placeHolder',
    PlaceHolderLibrary = 'placeHolderLibrary',
}

export enum AtlantisTourTKeys {
    NextButton = 'nextButton',
    CloseButton = 'closeButton',
    StepCounter = 'stepCounter',
}

export enum AutomationTKeys {
    Title = 'title',
}

export enum BrandingTKeys {
    AccountSetUp = 'accountSetUp',
    AccountName = 'accountName',
    AccountNameAlternateHelpText = 'accountNameAlternateHelpText',
    AccountNameCharacterLimit = 'accountNameCharacterLimit',
    AccountNameHelpText = 'accountNameHelpText',
    AccountNameInvalidCharacters = 'accountNameInvalidCharacters',
    AccountNameRequired = 'accountNameRequired',
    AdjustSize = 'adjustSize',
    Cancel = 'cancel',
    CompanyLogo = 'companyLogo',
    CompanyLogoHelpText = 'companyLogoHelpText',
    CompanyWebsite = 'companyWebsite',
    CompanyWebsiteHelpText = 'companyWebsiteHelpText',
    CustomizeGuestMessage = 'customizeGuestMessage',
    CustomizeGuestMessageHelpText = 'customizeGuestMessageHelpText',
    CustomizeSignInPage = 'customizeSignInPage',
    CustomizeSignInTooltip = 'customizeSignInTooltip',
    DefaultAccountMessage = 'defaultAccountMessage',
    DomainCharacterLimit = 'domainCharacterLimit',
    DomainHyphenConsecutive = 'domainHyphenConsecutive',
    DomainHyphenFirstLast = 'domainHyphenFirstLast',
    DomainName = 'domainName',
    DomainRequired = 'domainRequired',
    DomainValidCharacters = 'domainValidCharacters',
    FetchBrandingFailure = 'fetchBrandingFailure',
    GuestWelcomeMessage = 'guestWelcomeMessage',
    GuestWelcomePreview = 'guestWelcomePreview',
    IncludeLogo = 'includeLogo',
    IncludeLogoWithMessage = 'includeLogoWithMessage',
    ManualLogoUpload = 'manualLogoUpload',
    Optional = 'optional',
    SaveBrandingFailure = 'saveBrandingFailure',
    SaveChanges = 'saveChanges',
    SearchCompanyLogo = 'searchCompanyLogo',
    SearchCompanyLogoPlaceholder = 'searchCompanyLogoPlaceholder',
    SignInPage = 'signInPage',
    SignInPreview = 'signInPreview',
    SignInPreviewMessage = 'signInPreviewMessage',
    SignInPreviewEmailLabel = 'signInPreviewEmailLabel',
    SignInPreviewRememberMe = 'signInPreviewRememberMe',
    SignInPreviewNext = 'signInPreviewNext',
    SuccessfullySaved = 'successfullySaved',
    UploadCompanyLogo = 'uploadCompanyLogo',
    ValidDomainMessage = 'validDomainMessage',
}

export enum BrandingImageModalTKeys {
    Cancel = 'cancel',
    ClickToUpload = 'clickToUpload',
    FileSizeExceedsLimit = 'fileSizeExceedsLimit',
    MaxFileSize = 'maxFileSize',
    OnlyImageFiles = 'onlyImageFiles',
    OrDragAndDrop = 'orDragAndDrop',
    SaveImage = 'saveImage',
    SavingImage = 'savingImage',
    SizeTips = 'sizeTips',
    SizeYourLogo = 'sizeYourLogo',
    SizeYourLogoMessage = 'sizeYourLogoMessage',
    UploadYourLogo = 'uploadYourLogo',
    UploadYourLogoMessage = 'uploadYourLogoMessage',
}

export enum BrandingSaveConfirmModalTKeys {
    Cancel = 'cancel',
    Confirm = 'confirm',
    ModalTitle = 'modalTitle',
    ModalBody = 'modalBody',
    Emails = 'emails',
    TopOfApp = 'topOfApp',
    DocumentView = 'documentView',
    GuestMessage = 'guestMessage',
    ModalBody2 = 'modalBody2',
}

export enum CheckedOutTKeys {
    CheckedOutTitle = 'checkedOutTitle',
    NameColumn = 'nameColumn',
    CheckedOutOnColumn = 'checkedOutOnColumn',
    CheckedOutByColumn = 'checkedOutByColumn',
    PathColumn = 'pathColumn',
    ForceCheckInButton = 'forceCheckInButton',
    CancelButton = 'cancelButton',
    MyCheckedOutFilesTitle = 'myCheckedOutFilesTitle',
    AccountCheckedOutFilesTitle = 'accountCheckedOutFilesTitle',
    EmptyCheckedOutMessage = 'emptyCheckedOutMessage',
    ForceCheckInConfirmationMessage = 'forceCheckInConfirmationMessage',
    ForceCheckInConfirmationTitle = 'forceCheckInConfirmationTitle',
}

export enum CollectionsTKeys {
    CollectionsTitle = 'collectionsTitle',
    SeeAllText = 'seeAllText',
}

export enum DocumentsTKeys {
    DismissCta = 'dismissCta',
    CloseCta = 'closeCta',
    InvalidNodeTitle = 'invalidNodeTitle',
    InvalidNodeMessage = 'invalidNodeMessage',
    MissingNodePermissionMessage = 'missingNodePermissionMessage',
}

export enum DocumentRequestsTKeys {
    NewRequestButton = 'newRequestButton',
    NewRequestTemplateButton = 'newRequestTemplateButton',
    DocumentRequestsTitle = 'documentRequestsTitle',
    InboxTab = 'inboxTab',
    SentTab = 'sentTab',
    AccountRequestsTab = 'accountRequestsTab',
    TemplatesTab = 'templatesTab',
}

export enum DocumentRequestTemplateTableTKeys {
    CancelButton = 'cancelButton',
    DeleteTemplateButton = 'deleteButton',
    SubjectColumn = 'subjectColumn',
    FromColumn = 'fromColumn',
    ToColumn = 'toColumn',
    CreatedOnColumn = 'createdOnColumn',
    ExpiresColumn = 'expiresColumn',
    EmptyTableMessage = 'emptyTableMessage',
    DeleteConfirmationTitle = 'deleteConfirmationTitle',
    DeleteConfirmationMessage = 'deleteConfirmationMessage',
}

export enum ErrorTKeys {
    HomeCtaText = 'homeLinkCta',
    SignInCtaText = 'signInLinkCta',
    BadLinkTitle = 'badLinkTitle',
    BadLinkDescription = 'badLinkDescription',
    PageNotFoundTitle = 'pageNotFoundLinkTitle',
    PageNotFoundDescription = 'pageNotFoundLinkDescription',
}

export enum ESignatureTKeys {
    ESignatureTitle = 'eSignatureTitle',
    SentTransactionsTab = 'sentTransactionsTab',
    AccountTransactionsTab = 'accountTransactionsTab',
    TemplatesTab = 'templatesTab',
}

export enum ESignatureModalTKeys {
    CtaText = 'ctaText',
    ResendCtaText = 'resendCtaText',
    NameDocuSign = 'nameDocusign',
    NameSignix = 'nameSignix',
    TitleDocuSign = 'titleDocusign',
    TitleSignix = 'titleSignix',
    TitleEmailResent = 'titleEmailResent',
    SenderNameDocuSign = 'senderNameDocusign',
    SenderNameSignix = 'senderNameSignix',
    EmailCopyDocuSign = 'emailCopyDocusign',
    EmailCopySignix = 'emailCopySignix',
    ModalTextLine1 = 'modalTextLine1',
    ModalTextLine2 = 'modalTextLine2',
    ModalTextLine3 = 'modalTextLine3',
}

export enum FavoritesTKeys {
    SeeAllText = 'seeAllText',
    FavoritesTitle = 'favoritesTitle',
    ErrorLoading = 'errorLoading',
    NoCollections = 'noCollections',
    NoFavoritesMainText = 'noFavoritesMainText',
    NoFavoritesSubText = 'noFavoritesSubText',
    ZeroStateImgAltText = 'zeroStateImgAltText',
}

export enum FeedbackModalTKeys {
    AtlantisOnlyExperiencedFeedbackMessage = 'atlantisOnlyExperiencedFeedbackMessage',
    AtlantisOnlyExperiencedFeedbackRangeLabel = 'atlantisOnlyExperiencedFeedbackRangeLabel',
    Better = 'better',
    BugOption = 'bugOption',
    CallUsAt = 'callUsAt',
    ChatWithAnAgent = 'chatWithAnAgent',
    Close = 'close',
    DescribeFeatureRequest = 'describeFeatureRequest',
    DescribeYourIssue = 'describeYourIssue',
    Difficult = 'difficult',
    Easy = 'easy',
    Excellent = 'excellent',
    ExperiencedFeedbackLabel = 'experiencedFeedbackLabel',
    ExperiencedFeedbackMessage = 'experiencedFeedbackMessage',
    ExperiencedFeedbackRangeLabel = 'experiencedFeedbackRangeLabel',
    FeatureOption = 'featureOption',
    FeedbackOption = 'feedbackOption',
    HowIsProductWorking = 'howIsProductWorking',
    ImmediateSupport = 'immediateSupport',
    LearnMore = 'learnMore',
    MuchBetter = 'muchBetter',
    MuchWorse = 'muchWorse',
    Neutral = 'neutral',
    NotFoundOption = 'notFoundOption',
    Poor = 'poor',
    PreviewerXExperienceFeedbackSuccessMessage = 'previewerXExperienceFeedbackSuccessMessage',
    PreviewerXExperienceFeedbackSuccessTitle = 'previewerXExperienceFeedbackSuccessTitle',
    PreviewerXExperienceFeedbackTitle = 'previewerXExperienceFeedbackTitle',
    Required = 'required',
    Same = 'same',
    SeeUpdates = 'seeUpdates',
    Submit = 'submit',
    SuccessTitle = 'successTitle',
    SupportNumber = 'supportNumber',
    Title = 'title',
    TypeOfFeedback = 'typeOfFeedback',
    UnspecifiedOption = 'unspecifiedOption',
    VeryDifficult = 'veryDifficult',
    VeryEasy = 'veryEasy',
    Worse = 'worse',
}

export enum FilePasswordTKeys {
    HasFilePasswordMessage = 'hasFilePasswordMessage',
    FilePasswordModalTitle = 'filePasswordModalTitle',
    FilePasswordInputLabel = 'filePasswordInputLabel',
    Cancel = 'cancel',
    RemovePassword = 'removePassword',
    Submit = 'submit',
    Confirm = 'confirm',
    IncorrectPasswordError = 'incorrectPasswordError',
    RemovePasswordError = 'removePasswordError',
    RemovePasswordSuccess = 'removePasswordSuccess',
    SubmitPasswordError = 'submitPasswordError',
    ConfirmPasswordRemoveTitle = 'confirmPasswordRemoveTitle',
    ConfirmPasswordRemoveText = 'confirmPasswordRemoveText'
}

export enum FileProcessingQueueTKeys {
    FileNameColumnHeader = 'fileNameColumnHeader',
    CurrentStatusColumnHeader = 'currentStatusColumnHeader',
    TryCountRemainingColumnHeader = 'tryCountRemainingColumnHeader',
    QueueDateColumnHeader = 'queueDateColumnHeader',
    ErrorMessageColumnHeader = 'errorMessageColumnHeader',
    EmptyStateMessage = 'emptyStateMessage',
    Retry = 'retry',
    Delete = 'delete',
    PageTitle = 'pageTitle',
    Reload = 'reload',
}

export enum FileVersionsTKeys {
    CancelAction = 'cancelAction',
    Close = 'close',
    ConfirmDeleteAction = 'confirmDeleteAction',
    ConfirmDeleteHeader = 'confirmDeleteHeader',
    ConfirmDeleteBody = 'confirmDeleteBody',
    ConfirmPrimaryAction = 'confirmPrimaryAction',
    ConfirmPrimaryHeader = 'confirmPrimaryHeader',
    ConfirmPrimaryBody = 'confirmPrimaryBody',
    CreatedByColumn = 'createdByColumn',
    DeleteVersion = 'deleteVersion',
    DeleteSuccess = 'deleteSuccess',
    FileVersions = 'fileVersions',
    LockPreview = 'lockPreview',
    LockedTooltip = 'lockedTooltip',
    MakePrimary = 'makePrimary',
    ModalTitle = 'modalTitle',
    PreviewTooltip = 'previewTooltip',
    PrimaryTooltip = 'primaryTooltip',
    SeeAll = 'seeAll',
    SizeColumn = 'sizeColumn',
    UnlockPreview = 'unlockPreview',
    VersionActions = 'versionActions',
    VersionDateColumn = 'versionDateColumn',
    NotesColumn = 'notesColumn',
    VersionNoteFullPopupTitle = 'versionNoteFullPopupTitle',
    SeeVersionNoteTooltip = 'seeVersionNoteTooltip',
}

export enum FileUploadNavMenuItemTKeys {
    Prompt = 'prompt',
    Uploads = 'uploads',
    ClearCta = 'clearCta',
    LoadingItems = 'loadingItems',
    FailedUpload = 'failedUpload',
    ZeroStateImageAlt = 'zeroStateImageAlt',
    ZeroStateMessage = 'zeroStateMessage',
}

export enum FolderTemplatesTKeys {
    PageTitle = 'pageTitle',
    NewTemplateLibrary = 'newTemplateLibrary',
    NewTemplate = 'newTemplate',
    NameColumn = 'nameColumn',
    InstancesColumn = 'instancesColumn',
    CreatedByColumn = 'createdByColumn',
    CreatedOnColumn = 'createdOnColumn',
    Delete = 'delete',
}

export enum FormsTKeys {
    NameColumn = 'nameColumn',
    CreatedOnColumn = 'createdOnColumn',
    CreatedByColumn = 'createdByColumn',
    DeleteButton = 'deleteButton',
    CancelButton = 'cancelButton',
    FormsTitle = 'formsTitle',
    EmptyTemplatesMessage = 'emptyTemplatesMessage',
    DeleteConfirmationTitle = 'deleteConfirmationTitle',
    DeleteConfirmationMessage = 'deleteConfirmationMessage',
}

export enum GovernanceTKeys {
    GovernanceTitle = 'governanceTitle',
    ZeroStateCopy = 'zeroStateCopy',
    ZeroStateImageAlt = 'zeroStateImageAlt',
    ZeroStateTip = 'zeroStateTip',
    ZeroStateTipLink = 'zeroStateTipLink',
}

export enum GovernanceActionFormControlTKeys {

}

export enum GovernanceActionSelectorTKeys {
    SelectorTitle = 'selectorTitle',
    None = 'none',
    Delete = 'delete',
    Move = 'move',
    Copy = 'copy',
    Notify = 'notify',
    RemoveAccess = 'removeAccess',
    Purge = 'purge',
    StartWorkflow = 'startWorkflow',
    ApiCallouts = 'apiCallouts',
    ExportSearchResults = 'exportSearchResults',
    TooltipMessage = 'tooltipMessage',
}

export enum GovernanceDeleteActionFormTKeys {

}

export enum GovernanceInheritanceSelectorTKeys {
    InheritanceTitle = 'inheritanceTitle',
    InheritanceToThisItemText = 'inheritanceToThisItemText',
    InheritanceAllItemsText = 'inheritanceAllItemsText',
    InheritanceToFilesOnlyText = 'inheritanceToFilesOnlyText',
    InheritanceFirstLevelItemsText = 'inheritanceFirstLevelItemsText',
}

export enum GovernanceLockControlTKeys {

}

export enum GovernanceScheduleActionModalTKeys {
    ModalTitle = 'title',
    SubmitButtonText = 'submitButtonText',
    CancelButtonText = 'cancelButtonText',
}

export enum GovernanceTimeSpanSelectorTKeys {

}

export enum GovernanceEmailReminderControlTKeys {

}

export enum HelpNavMenuItemTKeys {
    AppVersion = 'appVersion',
    Help = 'help',
    NeedHelp = 'needHelp',
    GetInAppGuidance = 'getInAppGuidance',
    ChatNow = 'chatNow',
    KnowledgeBase = 'knowledgeBase',
    ShareFeedback = 'shareFeedback',
    DownloadDesktopApp = 'downloadDesktopApp',
    New = 'new',
    ProductUpdates = 'productUpdates',
    SystemLogs = 'systemLogs',
    Version = 'version',
    ViewNewExperienceGuide = 'viewNewExperienceGuide',
}

export enum HistoryTKeys {
    Title = 'title',
}

export enum HomeTKeys {
    DefaultWelcomeMessage = 'defaultWelcomeMessage',
}

export enum InheritanceSelectorTKeys {
    InheritanceTitle = 'inheritanceTitle',
    InheritanceToThisItemText = 'inheritanceToThisItemText',
    InheritanceAllItemsText = 'inheritanceAllItemsText',
    InheritanceToFilesOnlyText = 'inheritanceToFilesOnlyText',
    InheritanceFirstLevelItemsText = 'inheritanceFirstLevelItemsText',
}

export enum LoginLoadingStatesTKeys {
    GettingEverythingReady = 'gettingEverythingReady',
    LeavingTheNewExperience = 'leavingNewExperience',
    ComeBackAnytime = 'comeBackAnytime',
    SigningIn = 'signingIn',
    PuttingDocumentsToWork = 'puttingDocumentsToWork',
    Loading = 'loading',
}

export enum MDFieldModalTKeys {
    ModalTitle = 'modalTitle',
    TitleAddOptions = 'titleAddOptions',
    FieldName = 'fieldName',
    FieldType = 'fieldType',
    SaveCta = 'saveCta',
    NextCta = 'nextCta',
    BackCta = 'backCta',
    SaveListCta = 'saveListCta',
    Default = 'Select',
    Text = 'text',
    Email = 'email',
    Address = 'address',
    Date = 'date',
    PhoneNumber = 'phoneNumber',
    Checkbox = 'checkbox',
    Number = 'number',
    DropdownList = 'dropdownList',
    AdvancedFormatting = 'advancedFormatting',
    DuplicateNameError = 'duplicateNameError',
    BlankNameError = 'blankNameError',
    AlphanumericNameError = 'alphaNumericNameError',
    AddInBulk = 'addInBulk',
    AddIndividually = 'addIndividually',
    Subtitle = 'subtitle',
    OptionInputTitle = 'optionInputTitle',
    EmptyOptionsContainer = 'emptyOptionsContainer',
    OptionInputPlaceholder = 'optionInputPlaceholder',
    MakeDefault = 'makeDefault',
    EnterToSubmit = 'enterToSubmit',
    ClearAll = 'clearAll',
    ConfirmClearAll = 'confirmClearAll',
    SortBy = 'sortBy',
    AlphabeticallyAsc = 'alphabeticallyAsc',
    AlphabeticallyDesc = 'alphabeticallyDesc',
    BulkAddInstructions = 'bulkAddInstructions',
    BulkPlaceholder = 'bulkPlaceholder',
    InvalidOptionError = 'invalidOptionError',
    ApplyOptions = 'applyOptions',
    NoDuplicateOptionsAddedMessage = 'noDuplicateOptionsAddedMessage',
    ConfirmCloseMessage = 'confirmCloseMessage',
    ConfirmChangeFieldTypeMessage = 'confirmChangeFieldTypeMessage',
    CancelCta = 'cancelCta',
    ConfirmCta = 'confirmCta',
    CloseCta = 'closeCta',
    StayCta = 'stayCta',
    EditDropdownMessage = 'editDropdownMessage',
    ConfirmationModalTitle = 'confirmationModalTitle'
}

export enum MDSideSheetInformationTKeys {
    Title = 'title',
    Format = 'format',
    FieldName = 'fieldName',
    FieldType = 'fieldType',
    AdvancedFormatting = 'advancedFormatting',
    NoSensitiveData = 'noSensitiveData',
    ShowFirst = 'showFirst',
    ShowLast = 'showLast',
    Characters = 'characters',
    HasPrefix = 'hasPrefix',
    NoMinMax = 'noMinMax',
    MinChars = 'minChars',
    MaxChars = 'maxChars',
    CheckboxChecked = 'checkboxChecked',
    CheckboxUnchecked = 'checkboxUnchecked',
    TextFormatSingleLine = 'textFormatSingleLine',
    TextFormatMultiLine = 'textFormatMultiLine',
    Options = 'options',
    AllowAddNewOptions = 'allowAddNewOptions',
    DropdownListFormatSingleSelect = 'dropdownListFormatSingleSelect',
    DropdownListFormatMultiSelect = 'dropdownListFormatMultiSelect',
    AddressLine1 = 'addressLine1',
    AddressLine2 = 'addressLine2',
    City = 'city',
    State = 'state',
    PostalCode = 'postalCode',
    Country = 'country',
    mmddyyyy = 'mmddyyyy',
    ddmmyyyy = 'ddmmyyyy',
    yyyymmdd = 'yyyymmdd',
    monthddyyyy = 'monthddyyyy',
    ddmonthyyyy = 'ddmonthyyyy',
}

export enum TextMDFieldModalTKeys {
    SelectFormat = 'selectFormat',
    AdvancedFormatting = 'advancedFormatting',
    SingleLine = 'singleLine',
    MultiLine = 'multiLine',
    SingleLineHelpText = 'singleLineHelpText',
    MultiLineHelpText = 'multiLineHelpText',
    Alphanumeric = 'alphanumeric',
    Alpha = 'alpha',
    AlphanumericHelpText = 'alphanumericHelpText',
    AlphaHelpText = 'alphaHelpText',
    MinMaxCharacters = 'minMaxCharacters',
    MinimumCharacters = 'minimumCharacters',
    MaximumCharacters = 'maximumCharacters',
    Prefix = 'prefix',
    PrefixPlaceholder = 'prefixPlaceholder',
    SensitiveData = 'sensitiveData',
    Hide = 'Hide ',
    Show = 'Show ',
    CharactersUpper = 'charactersUpper',
    CharactersLower = 'charactersLower',
    First = 'first',
    Last = 'last',
    NAPlaceholder = 'naPlaceholder',
    NegativeValueError = 'negativeValueError',
    MaxValueError = 'maxValueError',
    MinMaxCharsNeededError = 'minMaxCharsNeededError',
    PrefixNeededError = 'prefixNeededError',
    SensitiveDataNeededError = 'sensitiveDataNeededError',
}

export enum DateMDFieldModalTKeys {
    SelectFormat = 'selectFormat',
    AdvancedFormatting = 'advancedFormatting',
    mmddyyyy = 'mmddyyyy',
    ddmmyyyy = 'ddmmyyyy',
    yyyymmdd = 'yyyymmdd',
    monthddyyyy = 'monthddyyyy',
    ddmonthyyyy = 'ddmonthyyyy',
}

export enum AddressMDFieldModalTKeys {
    SelectFormat = 'selectFormat',
    AdvancedFormatting = 'advancedFormatting',
    AddressLineOne = 'addressLineOne',
    AddressLineTwo = 'addressLineTwo',
    City = 'city',
    State = 'state',
    Postal = 'postal',
    Country = 'country',
}

export enum CheckboxMDFieldModalTKeys {
    CheckedTitle = 'checkedTitle',
    CheckedLabel = 'checkedLabel',
    UncheckedLabel = 'uncheckedLabel',
}

export enum NumberMDFieldModalTKeys {
    SelectFormat = 'selectFormat',
    AdvancedFormatting = 'advancedFormatting',
    Number = 'number',
    NumberHelpText = 'numberHelpText',
    Percentage = 'percentage',
    PercentageHelpText = 'percentageHelpText',
    Decimal = 'decimal',
    DecimalHelpText = 'decimalHelpText',
    Currency = 'currency',
    CurrencyHelpText = 'currencyHelpText',
    MinMaxValue = 'minMaxValue',
    MinimumValue = 'minimumValue',
    MaximumValue = 'maximumValue',
    SensitiveData = 'sensitiveData',
    Hide = 'hide',
    Show = 'show',
    DigitsUpper = 'digitsUpper',
    DigitsLower = 'digitsLower',
    First = 'first',
    Last = 'last',
    NegativeValueError = 'negativeValueError',
    MaxValueError = 'maxValueError',
    MinMaxValueNeededError = 'minMaxValueNeededError',
    SensitiveDataNeededError = 'sensitiveDataNeededError',
}

export enum MDFieldDropdownListModalTKeys {
    SelectFormat = 'selectFormat',
    AdvancedFormatting = 'advancedFormatting',
    SingleSelect = 'singleSelect',
    MultiSelect = 'multiSelect',
    AllowNewOptions = 'allowNewOptions',
    AllowNewOptionsTooltip = 'allowNewOptionsTooltip',
}

export enum MetadataTKeys {
    MetadataTitle = 'metadataTitle',
    FieldsTab = 'fieldsTab',
    GroupsTab = 'groupsTab',
    CreateField = 'createField',
    CreateGroup = 'createGroup',
    AddFromLibrary = 'addFromLibrary',
    AddNewField = 'addNewField',
    AddNewGroup = 'addNewGroup',
    Field = 'field',
    Group = 'group',
    // sidesheet
    ProfileTitle = 'profileTitle',
    ZeroStateCopy = 'zeroStateCopy',
    ZeroStateImageAlt = 'zeroStateImageAlt',
    AddProfile = 'addProfile',
    EditProfile = 'editProfile',
    AddProfileText = 'addProfileText',
    AddProfileItemText = 'addProfileItemText',
    Delete = 'delete',
    AdditionalProfileItems = 'additionalProfileItems',
    CancelButton = 'cancelButton',
    SaveButton = 'saveButton',
    Confirm = 'confirm',
    ConfirmDeleteBtnText = 'confirmDeleteBtnText',
    ConfirmDeleteText = 'confirmDeleteText',
    ConfirmDeleteProfileItemTitle = 'confirmDeleteProfileItemTitle',
    ConfirmDeleteProfileTitle = 'confirmDeleteProfileTitle',
    DeleteProfileNotItems = 'deleteProfileNotItems',
    DeleteAll = 'deleteAll',
    TypeToSearch = 'typeToSearch',
    EmailValidationError = 'emailValidationError',
    RequiredValidationError = 'requiredValidationError',
    DateValidationError = 'dateValidationError',
    PhoneValidationError = 'phoneValidationError',
}

export enum MDFieldsTKeys {
    NameColumn = 'nameColumn',
    TypeColumn = 'typeColumn',
    CreatedByColumn = 'createdByColumn',
    InstanceColumn = 'instanceColumn',
    EditContextButton = 'editContextButton',
    DeleteContextButton = 'deleteContextButton',
}

export enum MDGroupModalTKeys {
    ModalTitle = 'modalTitle',
    SaveCta = 'saveCta',
    DuplicateNameError = 'duplicateNameError',
    BlankNameError = 'blankNameError',
    AlphanumericNameError = 'alphaNumericNameError',
    SelectFields = 'selectFields',
    AddedFields = 'addedFields',
    RevverLibrary = 'revverLibrary',
    AddedFieldsZeroStateImgAltText = 'addedFieldsZeroStateImgAltText',
    AddedFieldsZeroStateBody = 'addedFieldsZeroStateBody',
    NameColumn = 'nameColumn',
    TypeColumn = 'typeColumn',
    AddFieldCta = 'addFieldCta',
    CancelCta = 'cancelCta',
    AddMetadata = 'addMetadata',
}

export enum MDGroupsTKeys {
    GroupNameColumn = 'groupNameColumn',
    InstanceColumn = 'instanceColumn',
    CreatedByColumn = 'createdByColumn',
    EditContextButton = 'editContextButton',
    DeleteContextButton = 'deleteContextButton',
}

export enum MDZeroStateTKeys {
    AddFromLibrary = 'addFromLibrary',
    ZeroStateImgAltText = 'zeroStateImgAltText',
    ZeroStateHeader = 'zeroStateHeader',
    ZeroStateBody = 'zeroStateBody',
}

export enum NestedChecklistTKeys {
    Optional = 'optional',
}

export enum NodeDetailsTKeys {
    AccountContainer = 'accountContainer',
    CabinetContainer = 'cabinetContainer',
    CreatedOn = 'createdOn',
    CreatedBy = 'createdBy',
    Details = 'details',
    Files = 'files',
    FoldersContainer = 'foldersContainer',
    NodeId = 'nodeId',
    NoTemplate = 'noTemplate',
    Templates = 'templates',
    UploadedBy = 'uploadedBy',
    UploadedOn = 'uploadedOn',
    Size = 'size',
    Calculate = 'calculate',
}

export enum NodeSelectorModalTKeys {
    SelectBothButtonText = 'selectBothButtonText',
    SelectFileButtonText = 'selectFileButtonText',
    SelectLocationButtonText = 'selectLocationButtonText',
    SelectFileTitle = 'selectFileTitle',
    SelectLocationTitle = 'selectLocationTitle',
    SelectBothTitle = 'selectBothTitle',
}

export enum NotesTKeys {
    Title = 'title',
    EmptyMailboxImgAltText = 'emptyMailboxImgAltText',
    ErrorSendingNote = 'errorSendingNote',
    ErrorLoadingNotes = 'errorLoadingNotes',
    ErrorEditingNote = 'errorEditingNote',
    ErrorDeletingNote = 'errorDeletingNote',
    NoNotesYet = 'noNotesYet',
    WriteFirstNoteInvitation = 'writeFirstNoteInvitation',
    ProTip = 'proTip',
    Use = 'use',
    Shift = 'shift',
    Plus = 'plus',
    Enter = 'enter',
    ToMakeALineBreak = 'toMakeALineBreak',
    InputBoxPlaceholderText = 'inputBoxPlaceholderText',
    Save = 'save',
    Cancel = 'cancel',
    OverAMonthAgo = 'overAMonthAgo',
    LastMonth = 'lastMonth',
    LastWeek = 'lastWeek',
    Yesterday = 'yesterday',
    Today = 'today',
    Delete = 'delete',
    ConfirmationModalTitle = 'confirmationModalTitle',
    ConfirmationModalText = 'confirmationModalText',
}

export enum NotificationMenuItemTKeys {
    NotificationTitle = 'notificationTitle',
    ErrorLoading = 'errorLoading',
    NoItems1 = 'noItems1',
    NoItems2 = 'noItems2',
    NoItems3 = 'noItems3',
}

export enum OptOutToggleNavItemTKeys {
    NewExperience = 'newExperience',
}

export enum OptOutModalTKeys {
    BeforeYouGo = 'beforeYouGo',
    Cancel = 'cancel',
    CloseAndLeave = 'closeAndLeave',
    FeedbackHelpsUs = 'feedbackHelpsUs',
    LearnMore = 'learnMore',
    SeeUpdates = 'seeUpdates',
    WhyAreYouLeaving = 'whyAreYouLeaving',
}

export enum PermissionsInheritanceSelectorTKeys {
    Account = 'account',
    AllItems = 'allItems',
    Cabinet = 'cabinet',
    Drawer = 'drawer',
    Folder = 'folder',
    InheritanceInstructions = 'inheritanceInstructions',
    ItemOnly = 'itemOnly',
}

export enum PermissionsTKeys {
    AccessLinksHeader = 'accessLinksHeader',
    AdminColumnHeader = 'adminColumnHeader',
    AdminCheckboxLabel = 'adminCheckboxLabel',
    AdminInfoText = 'adminInfoText',
    AdminInfoTextNoCreate = 'adminInfoTextNoCreate',
    AdvancedPermissions = 'advancedPermissions',
    AllOtherAccessHeader = 'allOtherAccessHeader',
    CancelButton = 'cancelButton',
    CreateCheckboxLabel = 'createCheckboxLabel',
    CreateColumnHeader = 'createColumnHeader',
    CreateDirectories = 'createDirectories',
    CreateDirectoriesTooltip = 'createDirectoriesTooltip',
    CreateInfoText = 'createInfoText',
    CreateInfoTextAddl = 'createInfoTextAddl',
    DeleteCheckboxLabel = 'deleteCheckboxLabel',
    DeleteColumnHeader = 'deleteColumnHeader',
    DeleteInfoText = 'deleteInfoText',
    DeleteInfoTextNoCreate = 'deleteInfoTextNoCreate',
    DeleteTooltip = 'deleteTooltip',
    DownloadCheckboxLabel = 'downloadCheckboxLabel',
    DownloadColumnHeader = 'downloadColumnHeader',
    DownloadInfoText = 'downloadInfoText',
    EditButton = 'editButton',
    EditDetailsModalHeader = 'editDetailsModalHeader',
    ErrorLoadingPermissions = 'errorLoadingPermissions',
    GrantedToolTip = 'grantedToolTip',
    GrantAccessButton = 'grantAccessButton',
    GrantPermissionsButton = 'grantPermissionsButton',
    GroupsHeader = 'groupsHeader',
    GuestUsersHeader = 'guestUsersHeader',
    InheritedFromTooltip = 'inheritedFromTooltip',
    InheritanceSelectorText = 'inheritanceSelectorText',
    OverrideCheckboxLabel = 'overrideCheckboxLabel',
    OverrideColumnHeader = 'overrideColumnHeader',
    OverrideInfoText = 'overrideInfoText',
    PermissionsAndSharingHeader = 'permissionsAndSharingHeader',
    PermissionsYouGrantedHeader = 'permissionsYouGrantedHeader',
    RestrictAllCheckboxLabel = 'restrictAllCheckboxLabel',
    RestrictAllColumnHeader = 'restrictAllColumnHeader',
    RestrictAllInfoText = 'restrictAllInfoText',
    RoleColumnHeader = 'roleColumnHeader',
    SaveEditsButton = 'saveEditsButton',
    Title = 'title',
    UploadFiles = 'uploadFiles',
    UploadFilesTooltip = 'uploadFilesTooltip',
    UsersHeader = 'usersHeader',
    ViewAllButton = 'viewAllButton',
    ViewCheckboxLabel = 'viewCheckboxLabel',
    ViewColumnHeader = 'viewColumnHeader',
    ViewDetailsModalHeader = 'viewDetailsModalHeader',
    ViewInfoText = 'viewInfoText',
    WriteCheckboxLabel = 'writeCheckboxLabel',
    WriteInfoText = 'writeInfoText',
    WriteInfoTextNoCreate = 'writeInfoTextNoCreate',
    WriteUploadColumnHeader = 'writeUploadColumnHeader',
    WriteUploadInfoText = 'writeUploadInfoText',
    YourPermissionsHeader = 'yourPermissionsHeader',
}

export enum PreviewTKeys {
    Title = 'title',
    FullScreenVersionLabel = 'fullScreenVersionLabel',
    FullScreenNodeSelectorTooltip = 'fullScreenNodeSelectorTooltip',
    GoFullScreen = 'goFullScreen',
    Close = 'close',
    SaveHeader = 'saveHeader',
    SaveBody = 'saveBody',
    SaveDiscard = 'saveDiscard',
    Save = 'save',
    PreviewerXSaveSuccessAlert = 'previewerXSaveSuccessAlert',
    AccusoftSaveSuccessAlertBeforeBtn = 'accusoftSaveSuccessAlertBeforeBtn',
    AccusoftSaveSuccessAlertBtn = 'accusoftSaveSuccessAlertBtn',
    AccusoftSaveSuccessAlertAfterBtn = 'accusoftSaveSuccessAlertAfterBtn',
}

export enum ProductUpdatesTKeys {
    Title = 'title',
}

export enum ProfilesAndProfileItemsTKeys {
    ProfilesTitle = 'profilesTitle',
    ProfileItemsTitle = 'profileItemsTitle',
    CreateProfile = 'createProfile',
    CreateProfileItem = 'createProfileItem',
    RequiredText = 'requiredText',
    AddProfile = 'addProfile',
    EditProfile = 'editProfile',
    AddProfileItem = 'addProfileItem',
    EditProfileItem = 'editProfileItem',
    CancelButton = 'cancelButton',
    SaveButton = 'saveButton',
    ShowHiddenText = 'showHiddenText',
    HideText = 'hideText',
    DeleteText = 'deleteText',
}

export enum ProfileSelectorTKeys {
    AddProfile = 'addProfile',
    CancelButton = 'cancelButton',
    SaveButton = 'saveButton',
    TypeToSearch = 'typeToSearch',
}

export enum ProfilesTableTKeys {
    NameColumn = 'nameColumn',
    ProfileItemCountColumn = 'profileItemCountColumn',
    DeleteButton = 'deleteButton',
    CancelButton = 'cancelButton',
    DeleteConfirmationTitle = 'deleteConfirmationTitle',
    DeleteConfirmationBody = 'deleteConfirmationBody',
    NoProfilesMessage = 'noProfilesMessage',
}

export enum ProfileItemsTableTKeys {
    NameColumn = 'nameColumn',
    TypeColumn = 'typeColumn',
    DefaultValueColumn = 'defaultValueColumn',
    CancelButton = 'cancelButton',
    DeleteButton = 'deleteButton',
    NoProfileItemsMessage = 'noProfileItemsMessage',
    DeleteConfirmationTitle = 'deleteConfirmationTitle',
    DeleteConfirmationMessage = 'deleteConfirmationMessage',
}

export enum QuickLinksTKeys {
    QuickLinks = 'quickLinks',
    Documents = 'documents',
    Workflow = 'workflow',
    DocumentRequests = 'documentRequests',
    Groups = 'groups',
    FormFillTemplates = 'formFillTemplates',
    People = 'people',
    ESignature = 'eSignature',
    FolderTemplates = 'folderTemplates',
    RevverReports = 'revverReports',
}

export enum RecentsTKeys {
    NameColumn = 'nameColumn',
    TypeColumn = 'typeColumn',
    LastViewedColumn = 'lastViewedColumn',
    CreatedOnColumn = 'createdOnColumn',
    CreatedByColumn = 'createdByColumn',
    RecentsTitle = 'recentsTitle',
    ErrorLoading = 'errorLoading',
    NoItemsMainText = 'noItemsMainText',
    NoItemsSubText = 'noItemsSubText',
    ZeroStateImgAltText = 'zeroStateImgAltText',
}

export enum RecycleBinTKeys {
    Cancel = 'cancel',
    Confirm = 'confirm',
    DeleteError = 'deleteError',
    DeleteModalBody = 'deleteModalBody',
    DeleteModalTitle = 'deleteModalTitle',
    DeleteSuccess = 'deleteSuccess',
    PageTitle = 'pageTitle',
}

export enum ReportsTKeys {
    ReportsTitle = 'reportsTitle',
}

export enum RoleSelectorTKeys {
    DefaultText = 'defaultText',
    RoleTypeDefaultText = 'roleTypeDefaultText',
    RoleTypeUserText = 'roleTypeUserText',
    RoleTypeGuestText = 'roleTypeGuestText',
    RoleTypeGroupText = 'roleTypeGroupText',
    NoChipsErrorMessage = 'noChipsErrorMessage',
    AlreadySelectedMessage = 'alreadySelectedMessage',
}

export enum SalesforceTKeys {
    NewMappingButton = 'newMappingButton',
    SalesforceTitle = 'salesforceTitle',
}

export enum SalesforceTableTKeys {
    DeleteAction = 'deleteAction',
    DeleteConfirmationCancel = 'deleteConfirmationCancel',
    DeleteConfirmationMessage = 'deleteConfirmationMessage',
    DeleteConfirmationTitle = 'deleteConfirmationTitle',
    DestinationColumn = 'destinationColumn',
    EmptyTableMessage = 'emptyTableMessage',
    MappingTypeColumn = 'mappingTypeColumn',
    SalesforceObjectTypeColumn = 'salesforceObjectTypeColumn',
}

export enum SecurityPoliciesTKeys {
    CreateSecurityPolicyButton = 'createSecurityPolicyButton',
    SecurityPoliciesTitle = 'securityPoliciesTitle',
}

export enum SecurityPoliciesTableTKeys {
    NameColumn = 'nameColumn',
    DeleteButton = 'deleteButton',
    CancelButton = 'cancelButton',
    NoSecurityPoliciesMessage = 'noSecurityPoliciesMessage',
    DeleteConfirmationTitle = 'deleteConfirmationTitle',
    DeleteConfirmationMessage = 'deleteConfirmationMessage',
}

export enum SearchTKeys {
    SearchTitle = 'searchTitle',
}

export enum SettingsTKeys {
    GoToSAMLAssertionValidatorCta = 'goToSAMLAssertionValidatorCta',
    CreateSAMLConfigurationCta = 'createSAMLConfigurationCta',
    AddNewMappingCta = 'addNewMappingCta',
    AccountSettingsTitle = 'accountSettingsTitle',
    SettingsTitle = 'settingsTitle',
    SingleSignOnTitle = 'singleSignOnTitle',
    BrandingTitle = 'brandingTitle',
    EmailImportsTitle = 'emailImportsTitle',
}

export enum ShareableLinkTKeys {
    CopyLinkText = 'copyLinkText',
    CopiedText = 'copiedText',
}

export enum SharedWithYouTKeys {
    SharedWithYouTitle = 'sharedWithYouTitle',
    SeeAll = 'seeAll',
    ErrorLoading = 'errorLoading',
    NoItemsMainText = 'noItemsMainText',
    NoItemsSubText = 'noItemsSubText',
    ZeroStateImgAltText = 'zeroStateImgAltText',
}

export enum SurveyModalTKeys {
    Title = 'title',
    Body = 'body',
    NoThanks = 'noThanks',
    TakeSurvey = 'takeSurvey',
}

export enum SurveyRepromptTKeys {
    Title = 'title',
    BodyTop = 'bodyTop',
    BodyBottom = 'bodyBottom',
    ButtonText = 'buttonText',
}

export enum SystemLogsTKeys {
    SystemLogsTitle = 'systemLogsTitle',
    ClearLogsButton = 'clearLogsButton',
    DownloadLogsButton = 'downloadLogsButton',
    ServerTab = 'serverTab',
    ClientTab = 'clientTab',
    AreYouSure = 'areYouSure',
    ClearLogsConfirmMsg = 'clearLogsConfirmMsg',
    ClearLogsCancelButton = 'clearLogsCancel',
    ClearLogsConfirmButton = 'clearLogsConfirm'
}

export enum ToDoListTKeys {
    ToDoTitle = 'toDoTitle',
    NameColumn = 'nameColumn',
    FromColumn = 'fromColumn',
    DateColumn = 'dateColumn',
    DateCreatedColumn = 'dateCreatedColumn',
    DateCompleted = 'dateCompleted',
    DateDismissed = 'dateDismissed',
    ErrorLoading = 'errorLoading',
    NoItemsLine1 = 'noItemsLine1',
    NoItemsLine2Guest = 'noItemsLine2Guest',
    NoItemsLine2User = 'noItemsLine2User',
    ZeroStateImgAltText = 'zeroStateImgAltText',
    SeeAllText = 'seeAllText',
    DismissContextText = 'dismissContextText',
    RestoreContextText = 'restoreContextText',
    AddTaskCtaText = 'addTaskCtaText',
    OpenToDosTab = 'openToDosTab',
    CompletedTab = 'completedTab',
    DismissedTab = 'dismissedTab',
}

export enum UploadLinkTKeys {
    Cancel = 'cancel',
    CopyURL = 'copyURL',
    Copied = 'copied',
    CreatedDescription = 'createdDescription',
    CreateDescription = 'createDescription',
    CreateHelpText = 'createHelpText',
    CreateLink = 'createLink',
    CreateListItemOne = 'createListItemOne',
    CreateListItemTwo = 'createListItemTwo',
    CreatePageTitle = 'createPageTitle',
    CreateTitle = 'createTitle',
    ErrorCreatingLink = 'errorCreatingLink',
    LearnMore = 'learnMore',
    LinkLabel = 'linkLabel',
    List = 'list',
    MaximumCharacterError = 'maximumCharacterError',
    MinimumCharacterError = 'minimumCharacterError',
    Required = 'required',
    SentDocumentRequests = 'sentDocumentRequests',
    ViewDescription = 'viewDescription',
    ViewTitle = 'viewTitle',
}

export enum UsersTKeys {
    AddUserButton = 'addUserButton',
    AddGroupButton = 'addGroupButton',
    UsersTab = 'usersTab',
    GroupsTab = 'groupsTab',
    UserSessionsTab = 'userSessionsTab',
    AccessLinksTab = 'accessLinksTab',
    DirectoriesTab = 'directoriesTab',
    UsersTitle = 'usersTitle',
    DeleteModalTitle = 'deleteModalTitle',
    DeleteModalBody = 'deleteModalBody',
    DeleteAdvancedOption = 'deleteAdvancedOption',
    DeleteAdvancedOptionLabel = 'deleteAdvancedOptionLabel',
    Cancel = 'cancel',
    Confirm = 'confirm',
    DeleteWarning = 'deleteWarning',
    DeleteSuccess = 'deleteSuccess',
    DeleteRevokeSuccess = 'deleteRevokeSuccess',
    DeleteError = 'deleteError',
}

export enum UserSessionsTKeys {
    UserColumn = 'userColumn',
    IpAddressColumn = 'ipAddressColumn',
    ApplicationColumn = 'applicationColumn',
    EndUserSessionButton = 'endUserSessionButton',
    CancelButton = 'cancelButton',
    EndSessionConfirmationMessage = 'endSessionConfirmationMessage',
    EndSessionConfirmationTitle = 'endSessionConfirmationTitle',
}

export enum UserSettingsTKeys {
    AddNewMappingButton = 'addNewMappingButton',
    UserSettingsTab = 'userSettingsTab',
    MyAccountsTab = 'myAccountsTab',
    IntegratedProductsTab = 'integratedProductsTab',
    EmailImportsTab = 'emailImportsTab',
    UserSettingsTitle = 'userSettingsTitle',
}

export enum UtilitiesTKeys {
    CSVImportTab = 'csvImportTab',
    CSVExportTab = 'csvExportTab',
    AccountTemplateImportTab = 'accountTemplateImportTab',
    AccountTemplateExportTab = 'accountTemplateExportTab',
    UtilitiesTitle = 'utilitiesTitle',
}

export enum UtopiaAlertsTKeys {
    PreviewerXSaveSuccessAlert = 'previewerXSaveSuccessAlert',
    PreviewerXSaveFailureAlert = 'previewerXSaveFailureAlert',
    AccusoftSaveSuccessAlertBeforeBtn = 'accusoftSaveSuccessAlertBeforeBtn',
    AccusoftSaveSuccessAlertBtn = 'accusoftSaveSuccessAlertBtn',
    AccusoftSaveSuccessAlertAfterBtn = 'accusoftSaveSuccessAlertAfterBtn',
}

export enum UtopiaNotificationsConverterTKeys {
    DocRequestTextUpload = 'docRequestTextUpload',
    DocRequestTextUploadAndFormFill = 'docRequestTextUploadAndFormFill',
    DocRequestTextFormFill = 'docRequestTextFormFill',
    DocRequestCtaText = 'docRequestCtaText',
    ESignatureCtaText = 'eSignatureCtaText',
    WorkflowAssignmentText = 'workflowAssignmentText',
    WorkflowAssignmentCtaText = 'workflowAssignmentCtaText',
    WorkflowApprovalText = 'workflowApprovalText',
    WorkflowApprovalCtaText = 'workflowApprovalCtaText',
    WorkflowFrom = 'workflowFrom',
    DefaultFrom = 'defaultFrom',
    DefaultCtaText = 'defaultCtaText',
}

export enum VerticalNavTKeys {
    Home = 'home',
    Documents = 'documents',
    DocRequests = 'docRequests',
    ESignature = 'eSignature',
    Forms = 'forms',
    Workflow = 'workflow',
    Reports = 'reports',
    AllFeatures = 'allFeatures',
    MyFavorites = 'myFavorites',
    CheckedOut = 'checkedOut',
    RecycleBin = 'recycleBin',
    FolderTemplates = 'folderTemplates',
    EmailImports = 'emailImports',
    Profiles = 'profiles',
    Governance = 'governance',
    Users = 'users',
    Groups = 'groups',
    PublicAccessLinks = 'pubAccessLinks',
    AuditLogs = 'auditLogs',
    AccountSettings = 'accountSettings',
    MySettings = 'mySettings',
    Metadata = 'metadata',
    SecurityPolicies = 'securityPolicies',
    SalesforceIntegration = 'salesforce',
    Utilities = 'utilities',
    Work = 'work',
    People = 'people',
    Account = 'account',
    Search = 'search',
    FileProcessingQueue = 'fileProcessingQueue',
    NoAccessToFeaturesHeaderTitle = 'noAccessToFeaturesHeaderTitle',
    NoAccessToFeaturesHeaderLarge = 'noAccessToFeaturesHeaderLarge',
    NoAccessToFeaturesHeaderSmall = 'noAccessToFeaturesHeaderSmall',
}

export enum WelcomeModalTKeys {
    SkipTourCta = 'skipTourCta',
    GetStartedCta = 'getStartedCta',
    WelcomeTitle = 'welcomeTitle',
    JumpInAndExplore = 'jumpInAndExplore',
    GuideToNewExperienceLinkText = 'guideToNewExperienceLinkText',
    DownloadGuide = 'downloadGuide',
    DontShowLabel = 'dontShowLabel',
    GuestWelcomeSubText = 'guestWelcomeSubText',
    ExistingUserAccountOnboardingTitle = 'existingUserAccountOnboardingTitle',
    AccountOnboardingNameTitle = 'accountOnboardingNameTitle',
    AccountOnboardingNameTitleFirstTimeUser = 'accountOnboardingNameTitleFirstTimeUser',
    AccountOnboardingNameError = 'accountOnboardingNameError',
    AccountOnboardingNameLabel = 'accountOnboardingNameLabel',
    AdminFirstTimeUserAccountOnboardingTitle = 'adminFirstTimeUserAccountOnboardingTitle',
    AccountOnboardingAdminIntroText = 'accountOnboardingAdminIntroText',
    AccountOnboardingGuidPdfText = 'accountOnboardingGuidPdfText',
    AccountOnboardingNameEntered = 'accountOnboardingNameEntered',
    AccountOnboardingBrandingTitle = 'accountOnboardingBrandingTitle',
    AccountOnboardingCompanyLogoTitle = 'accountOnboardingCompanyLogoTitle',
    AccountOnboardingLogoIssuesText = 'accountOnboardingLogoIssuesText',
    AccountOnboardingDomainTitle = 'accountOnboardingDomainTitle',
    AccountOnboardingDomainSpecialCharError = 'accountOnboardingDomainSpecialCharError',
    AccountOnboardingAccountTitle = 'accountOnboardingAccountTitle',
    AccountOnboardingAccountNameEntered = 'accountOnboardingAccountNameEntered',
    AccountOnboardingAccountDefaultName = 'accountOnboardingAccountDefaultName',
    AccountOnboardingAccountNameInfo = 'accountOnboardingAccountNameInfo',
    AccountOnboardingAccountNameEmptyError = 'accountOnboardingAccountNameEmptyError',
    AccountOnboardingAccountNameInvalidCharactersError = 'accountOnboardingAccountNameInvalidCharactersError',
    AccountOnboardingAccountNameMaxSizeError = 'accountOnboardingAccountNameMaxSizeError',
    AccountOnboardingBrandingSubTitle = 'accountOnboardingBrandingSubTitle',
    AccountOnboardingBrandingSubTitleSingular = 'accountOnboardingBrandingSubTitleSingular',
    AccountOnboardingBrandingAdjustSize = 'accountOnboardingBrandingAdjustSize',
    AccountOnboardingBrandingUploadImage = 'accountOnboardingBrandingUploadImage',
    AccountOnboardingUpNextTitle = 'accountOnboardingUpNextTitle',
    AccountOnboardingUpNextSubTitle = 'accountOnboardingUpNextSubTitle',
    AccountOnboardingOptional = 'accountOnboardingOptional',
    AccountOnboardingRequired = 'accountOnboardingRequired',
}

export enum WelcomeTourTKeys {
    NavigateHome = 'navigateHome',
    Step1Text = 'step1Text',
    Step2Text = 'step2Text',
    Step3Text = 'step3Text',
    Step4Text = 'step4Text',
    Step5Text = 'step5Text',
}

export enum WorkflowTKeys {
    WorkflowTitle = 'workflowTitle',
    WorkflowsTab = 'workflowsTab',
    InstancesTab = 'instancesTab',
    DashboardTab = 'dashboardTab',
    HistoryTab = 'historyTab',
}

export enum WorkYouCareAboutTKeys {
    WorkYouCareAboutTitle = 'workYouCareAboutTitle',
    ErrorLoading = 'errorLoading',
    NoItemsMainText = 'noItemsMainText',
    NoItemsSubText = 'noItemsSubText',
    NoItemsFull = 'noItemsFull',
    ZeroStateImgAltText = 'zeroStateImgAltText',
}
