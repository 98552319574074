import React, { useState } from 'react';
import { Button, Card } from '@efilecabinet/efc-atlantis-components';
import { ProfilesAndProfileItemsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../hooks/useSafeTranslation';
import { ProfileItemSelector } from '../../ProfileItemSelector/ProfileItemSelector';
import { IProfileItem } from '../../../../../../types/ProfilesAndProfileItemsTypes';
import { NodeProfileItemInput } from '../../NodeProfileItemInput/NodeProfileItemInput';
import { NodeProfileItemField } from '../../Fields/NodeProfileItemField/NodeProfileItemField';
import './NodeProfileItemForm.css';

interface NodeProfileItemFormProps {
    accountId: number;
    disabledProfileItems?: IProfileItem[];
    nodeId: string;
    onCanceled: () => void;
    onSaved: (profileItem: IProfileItem) => void;
    originalNodeProfileItem?: IProfileItem;
}

export const NodeProfileItemForm = ({ accountId, disabledProfileItems, nodeId, onCanceled, onSaved, originalNodeProfileItem }: NodeProfileItemFormProps) => {

    const { t } = useSafeTranslation(TranslationFiles.ProfilesAndProfileItems);

    const [nodeProfileItem, setNodeProfileItem] = useState<IProfileItem | undefined>(originalNodeProfileItem);
    const [error, setError] = useState<string>('');

    const title = !!originalNodeProfileItem ? t(ProfilesAndProfileItemsTKeys.EditProfileItem) : t(ProfilesAndProfileItemsTKeys.AddProfileItem);

    return (
        <Card className='mb-3 node-profile-item-form' color=''>

            <Card.Title className='pt-3 ps-3' size='lg' >{title}</Card.Title>

            <Card.Body className='pb-0 pt-0'>
                {!nodeProfileItem
                    ? <ProfileItemSelector accountId={accountId} disabledProfileItems={disabledProfileItems ?? []} onProfileItemSelect={setNodeProfileItem} />
                    : (
                        <NodeProfileItemField nodeProfileItem={nodeProfileItem}>
                            <NodeProfileItemInput errorState={[error, setError]} nodeId={nodeId} nodeProfileItemState={[nodeProfileItem, (newNodeProfileItemState) => setNodeProfileItem(newNodeProfileItemState)]} />
                        </NodeProfileItemField>
                    )}
            </Card.Body>

            <Card.Footer className='d-flex justify-content-end'>
                <Button dataId='nodeProfileItemCancelBtn' onClick={onCanceled} color='secondary' emphasis='med' className='me-2'>{t(ProfilesAndProfileItemsTKeys.CancelButton)}</Button>
                <Button dataId='nodeProfileItemSaveBtn' onClick={() => !!nodeProfileItem && onSaved(nodeProfileItem)} color='primary' disabled={!!error}>{t(ProfilesAndProfileItemsTKeys.SaveButton)}</Button>
            </Card.Footer>

        </Card>
    );
};
