import { Api } from './';
import { AxiosResponse } from 'axios';
import { IAccount } from '../types/CommonTypes';

export const useAccountApi = () => {
    const updateAccountNameInUtopia = async (accountId: number, name: string): Promise<IAccount> => {
        const resp: AxiosResponse = await Api.get(`api/Account/UpdateName/${accountId}?name=${name}`);
        return resp.data;
    };

    return {
        updateAccountNameInUtopia,
    };
};
