import React, { ReactNode, useState } from 'react';
import { useNodeApi } from '../../api';
import { NodeDetailsContext } from './NodeDetailsContext';
import { useFileVersionApi } from '../../api/useFileVersionApi';
import { useFilePasswordApi } from '../../api/useFilePasswordApi';
import { useFileUtilities } from '../../hooks/useFileUtilities';
import { IFileVersion, INode } from '../../types/CommonTypes';

export interface INodeDetailsContext {
    activeNode: INode | undefined;
    fileVersions: IFileVersion[];
    activeFileVersion: IFileVersion | undefined;
    isLoadingFileVersion: boolean;
    isLoadingNode: boolean;
    fetchFileVersions: (nodeId: string) => Promise<void>;
    fetchNode: (nodeId: string) => Promise<void>;
    nodeTypeIsNotAFile: boolean;
    isFileVersionModalOpen: boolean;
    setIsFileVersionModalOpen: (isFileVersionModalOpen: boolean) => void;
    hasFilePassword: boolean;
    onFilePasswordDelete: () => void;
}

interface NodeDetailsProviderProps {
    children: ReactNode;
}

export const NodeDetailsProvider = ({ children }: NodeDetailsProviderProps) => {
    const { getNodeByIdAsync } = useNodeApi();
    const { getFileVersions } = useFileVersionApi();
    const { nodeTypeIsNotFile } = useFileUtilities();
    const { nodeHasFilePasswordAsync } = useFilePasswordApi();

    const [activeNode, setActiveNode] = useState<INode>();
    const [isLoadingNode, setIsLoadingNode] = useState<boolean>(false);
    const [hasFilePassword, setHasFilePassword] = useState<boolean>(false);
    const [isLoadingFileVersion, setIsLoadingFileVersion] = useState<boolean>(false);
    const [activeFileVersion, setActiveFileVersion] = useState<IFileVersion | undefined>();
    const [fileVersions, setFileVersions] = useState<IFileVersion[]>([]);
    const [nodeTypeIsNotAFile, setNodeTypeIsNotAFile] = useState<boolean>(true);
    const [isFileVersionModalOpen, setIsFileVersionModalOpen] = useState<boolean>(false);

    // This is needed to prevent a race condition that happens when a user clicks between different nodes quickly
    function verifyNodeId(nodeId: string) {
        return location.pathname.includes(nodeId);
    }

    const fetchFileVersions = async (nodeId: string) => {
        setIsLoadingFileVersion(true);
        try {
            const fileVersionsData = await getFileVersions(nodeId);
            setFileVersions(fileVersionsData);
            setActiveFileVersion(fileVersionsData.find((v: IFileVersion) => v.isActive));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingFileVersion(false);
        }
    };

    const fetchNode = async (nodeId: string) => {
        setIsLoadingNode(true);
        try {
            const nodeData = await getNodeByIdAsync(nodeId);

            if (verifyNodeId(nodeId)) {
                setActiveNode(nodeData);

                const isNotFile = nodeTypeIsNotFile(nodeData);
                setNodeTypeIsNotAFile(isNotFile);

                if (!isNotFile) {
                    setHasFilePassword(await nodeHasFilePasswordAsync(nodeId));
                }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingNode(false);
        }
    };

    const onFilePasswordDelete = async () => {
        if (!!activeNode) {
            setHasFilePassword(await nodeHasFilePasswordAsync(activeNode.id));
        }
    };

    const contextObject: INodeDetailsContext = {
        fetchFileVersions,
        fetchNode,
        activeNode,
        fileVersions,
        activeFileVersion,
        nodeTypeIsNotAFile,
        isLoadingFileVersion,
        isLoadingNode,
        isFileVersionModalOpen,
        setIsFileVersionModalOpen,
        hasFilePassword,
        onFilePasswordDelete,
    };

    return <NodeDetailsContext.Provider value={contextObject}>{children}</NodeDetailsContext.Provider>;
};
