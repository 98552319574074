import React from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { MDGroupModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { ZeroState } from '../../../../../components/ZeroState';

import zeroStateImg from './addedFieldsEmptyState.svg';

export const AddedFieldsZeroState: React.FC = () => {
    const { t } = useSafeTranslation(TranslationFiles.MDGroupModal);

    return (
        <>
            <ZeroState maxImgWidth={450} style={{ padding: '5em' }} maxTextWidth={450}
                imageColContent={<img src={zeroStateImg} alt={t(MDGroupModalTKeys.AddedFieldsZeroStateImgAltText)} />}
                textColContent={
                    <Text>{t(MDGroupModalTKeys.AddedFieldsZeroStateBody)}</Text>
                }
                stackColumns={true} />
        </>
    );
};