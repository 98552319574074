import React, { useState } from 'react';
import { Dropdown, Text, useDropdown } from '@efilecabinet/efc-atlantis-components';
import { useBootstrapBreakpoints } from '../../../../hooks/useBootstrapBreakpoints';
import { useColors } from '../../../../hooks/useColors';
import { useAuthContext } from '../../../../auth';
import { ScreenSize } from '../../../../types/BootstrapTypes';
import { AccountsCard } from './AccountsCard/AccountsCard';
import { AccountsModal } from '../../../AccountsModal';
import { FeedbackModal } from '../Help/Feedback/FeedbackModal';
import { DuoLetterIcon } from '../../../Icons/DuoLetterIcon';
import './AccountsNavMenuItem.css';

interface IAccountsNavMenuItemProps {
    className?: string;
}

export const AccountsNavMenuItem = (props: IAccountsNavMenuItemProps) => {
    const { className } = props;

    const { authUser, hasAuthUser, userAccounts } = useAuthContext();
    const { screenSize } = useBootstrapBreakpoints();
    const { toggleDropdown, dropdownIsOpen } = useDropdown();
    const colors = useColors();
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const duotoneCircleOpacity = 1;

    const getCurrentAccountName = () => {
        if (!!userAccounts && !!hasAuthUser) {
            return (userAccounts as any).get(authUser?.accountID).accountName;
        } else return '';
    };

    const getCurrentAccountLetter = (): string => {
        return getCurrentAccountName().charAt(0).toLowerCase();
    };

    const [accountsModalIsOpen, setAccountsModalIsOpen] = useState(false);
    const closeAccountsModal = () => setAccountsModalIsOpen(false);
    const openAccountsModal = () => setAccountsModalIsOpen(true);

    const toggleFeedback = () => {

        if (!showFeedbackModal) {
            toggleDropdown();
        }

        setShowFeedbackModal(!showFeedbackModal);
    };

    return (
        <>
            <Dropdown className={className} toggle={toggleDropdown} isOpen={dropdownIsOpen}>
                {screenSize > ScreenSize.s ? (
                    <Dropdown.Toggle caret className='nav-link d-flex align-items-center pe-2 py-0' tag='a' style={{ border: '1px solid lightgray', borderRadius: '5px' }}>
                        <DuoLetterIcon letter={getCurrentAccountLetter()} primaryColor='white' secondaryColor={colors.atlantisBrandPurple} secondaryOpacity={duotoneCircleOpacity} size='2x' />
                        <Text className='pe-2'>{getCurrentAccountName()}</Text>
                    </Dropdown.Toggle>
                ) : (
                    <Dropdown.Toggle className='nav-link d-flex align-items-center pe-2 py-0' tag='a'>
                        {screenSize == ScreenSize.s ?
                            <DuoLetterIcon letter={getCurrentAccountLetter()} primaryColor='white' secondaryColor={colors.atlantisBrandPurple} secondaryOpacity={duotoneCircleOpacity} size='2x' />
                            :
                            <DuoLetterIcon letter={getCurrentAccountLetter()} primaryColor={colors.atlantisEnabled} secondaryColor={colors.atlantisLightHover} secondaryOpacity={duotoneCircleOpacity} size='2x' />
                        }
                    </Dropdown.Toggle>
                )}
                <Dropdown.Menu className={screenSize > ScreenSize.xs ? 'accounts-navmenu' : 'accounts-navmenu-mobile'}>
                    <AccountsCard toggleDropdown={toggleDropdown} toggleFeedback={toggleFeedback} openAccountsModal={openAccountsModal} />
                </Dropdown.Menu>
            </Dropdown>
            <AccountsModal isOpen={accountsModalIsOpen} closeModal={closeAccountsModal} />
            <FeedbackModal showFeedbackModal={showFeedbackModal} toggleFeedback={toggleFeedback} />
        </>
    );
};

