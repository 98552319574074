import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ToDoListRoutePath } from '../../../types/RoutePaths';
import { EfcCallToActionInfo, EfcLink, EfcTableColumnInfo, FullTable, Page, Tab, Tabs, Text } from '@efilecabinet/efc-atlantis-components';
import { IToDo, IAtlantisTask, SourceTypeEnum, AtlantisTaskStatus } from '../../../types/ToDoTypes';
import { NotificationStatusEnum, NotificationOrderEnum, IUtopiaTaskNotification } from '../../../types/UtopiaNotificationTypes';
import { AddTaskModal } from '../../Home/ToDoList/AddTaskModal';
import { useTaskApi } from '../../../api';
import { useAuthContext } from '../../../auth';
import { useAtlantisTaskConverter } from '../../../hooks/useAtlantisTaskConverter';
import { useUtopiaNotificationsConverter } from '../../../hooks/useUtopiaNotificationsConverter';
import { useModal } from '../../../hooks/useModal';
import { TranslationFiles, useSafeTranslation, ToDoListTKeys } from '../../../hooks/useSafeTranslation';
import { ScreenSize } from '../../../types/BootstrapTypes';
import { useBootstrapBreakpoints } from '../../../hooks/useBootstrapBreakpoints';
import { ZeroState } from '../../../components/ZeroState';
import { ExperienceTypeEnum } from '../../../auth/AuthenticationTypes';
import zeroStateImg from '../../Home/ToDoList/ToDoListZeroState.svg';
import './ToDoListPage.css';

export const ToDoListPage = () => {
    const { authUser, hasAuthUser, experienceType } = useAuthContext();
    const navigate = useNavigate();

    const [atlantisTasks, setAtlantisTasks] = useState<IAtlantisTask[]>([]);
    const [utopiaTasks, setUtopiaTasks] = useState<IUtopiaTaskNotification[]>([]);

    const { t } = useSafeTranslation(TranslationFiles.ToDoList);
    const { addAtlantisTask, getUserAtlantisTasks, getUserUtopiaTasks, dismissAtlantisTask, dismissUtopiaTask, restoreAtlantisTask, restoreUtopiaTask } = useTaskApi();
    const { toToDo, eSignatureModal } = useUtopiaNotificationsConverter();
    const { toAtlantisToDo } = useAtlantisTaskConverter({ atlantisTasks, setAtlantisTasks });
    const { screenSize } = useBootstrapBreakpoints();

    const MAX_TO_DOS = 1000;
    const ZERO_STATE_MAX_IMG_WIDTH = 200;
    const currentRoute = `${location.pathname}` as ToDoListRoutePath;

    const addTaskModal = useModal();

    const onSubmitAtlantisTask = (task: IAtlantisTask) => {
        addAtlantisTask(authUser?.userID as number, task).then((newTask) => {
            if (currentRoute == ToDoListRoutePath.OpenToDos) {
                setAtlantisTasks((currentAtlantisTasks) => [{ ...newTask }].concat(currentAtlantisTasks));
            }
        });
    };

    const openAddTaskModal = () => {
        addTaskModal.open();
    };

    const onDismissToDo = (toDo: IToDo) => {
        if (toDo.sourceType == SourceTypeEnum.Atlantis) {
            const updatedAtlantisTasks = atlantisTasks
                .filter((task) => task.taskId !== toDo.id)
                .map((e) => {
                    return { ...e };
                });
            setAtlantisTasks(updatedAtlantisTasks);
            dismissAtlantisTask(authUser?.userID as number, toDo.id);
        } else {
            const updatedUtopiaTasks = utopiaTasks
                .filter((task) => task.id.toString() !== toDo.id)
                .map((e) => {
                    return { ...e };
                });
            setUtopiaTasks(updatedUtopiaTasks);
            dismissUtopiaTask(toDo.id);
        }
    };

    const onRestoreToDo = (toDo: IToDo) => {
        if (toDo.sourceType == SourceTypeEnum.Atlantis) {
            const updatedAtlantisTasks = atlantisTasks
                .filter((task) => task.taskId !== toDo.id)
                .map((e) => {
                    return { ...e };
                });
            setAtlantisTasks(updatedAtlantisTasks);
            restoreAtlantisTask(authUser?.userID as number, toDo.id);
        } else {
            const updatedUtopiaTasks = utopiaTasks
                .filter((task) => task.id.toString() !== toDo.id)
                .map((e) => {
                    return { ...e };
                });
            setUtopiaTasks(updatedUtopiaTasks);
            restoreUtopiaTask(toDo.id);
        }
    };

    const getCombinedToDos = () => {
        return atlantisTasks
            .map((task) => toAtlantisToDo(task))
            .concat(utopiaTasks.map((task) => toToDo(task)));
    };

    const getAllTasks = (route: ToDoListRoutePath) => {
        const [utopiaTaskStatuses, utopiaTasksOrderBy] = getQueryUtopiaTasksParams(route);
        const getUtopiaTasksPromise = getUserUtopiaTasks(utopiaTaskStatuses, utopiaTasksOrderBy, 0, 4000);

        const getAtlantisTasksPromise = getUserAtlantisTasks(authUser?.userID as number, getQueryAtlantisTasksStatus(route), 0, MAX_TO_DOS);

        getUtopiaTasksPromise.then((utopiaTasks) =>
            getAtlantisTasksPromise.then((atlantisTasks: IAtlantisTask[]) => {
                if (route == location.pathname) {
                    setUtopiaTasks(utopiaTasks);
                    setAtlantisTasks(atlantisTasks);
                }
            })
        );
    };

    const getQueryAtlantisTasksStatus = (route: ToDoListRoutePath) => {
        switch (route) {
            case ToDoListRoutePath.OpenToDos:
                return AtlantisTaskStatus[AtlantisTaskStatus.Assigned];
            case ToDoListRoutePath.CompletedToDos:
                return AtlantisTaskStatus[AtlantisTaskStatus.Completed];
            case ToDoListRoutePath.DismissedToDos:
                return AtlantisTaskStatus[AtlantisTaskStatus.Dismissed];
        }
    };

    const getQueryUtopiaTasksParams = (route: ToDoListRoutePath): [status: NotificationStatusEnum[], orderBy: NotificationOrderEnum] => {
        switch (route) {
            case ToDoListRoutePath.OpenToDos:
            default:
                return [[NotificationStatusEnum.Active], NotificationOrderEnum.QueuedOnDesc];
            case ToDoListRoutePath.CompletedToDos:
                return [[NotificationStatusEnum.Completed], NotificationOrderEnum.CompletedOnDesc];
            case ToDoListRoutePath.DismissedToDos:
                return [[NotificationStatusEnum.Dismissed], NotificationOrderEnum.DismissedOnDesc];
        }
    };

    const changeTab = (toRoute: ToDoListRoutePath) => {
        if (currentRoute != toRoute) {
            setAtlantisTasks([]);
            setUtopiaTasks([]);
            getAllTasks(toRoute);
            navigate(toRoute);                       
        }
    };

    const createColumns = () => {
        const columns: (EfcTableColumnInfo)[] = [
            { name: 'text', displayName: t(ToDoListTKeys.NameColumn), useIcon: (item) => item.icon, widthPct: (currentRoute == ToDoListRoutePath.OpenToDos ? 50 : 35) }
        ];

        if (screenSize > ScreenSize.m) {
            columns.push(
                { name: 'from', displayName: t(ToDoListTKeys.FromColumn), widthPct: 15 },
                { name: 'createdDate', displayName: currentRoute == ToDoListRoutePath.OpenToDos ? t(ToDoListTKeys.DateColumn) : t(ToDoListTKeys.DateCreatedColumn), isDate: true, widthPct: 15 }
            );

            if (currentRoute == ToDoListRoutePath.CompletedToDos || currentRoute == ToDoListRoutePath.DismissedToDos) {
                columns.push({ name: currentRoute == ToDoListRoutePath.CompletedToDos ? 'completedDate' : 'dismissedDate', displayName: currentRoute == ToDoListRoutePath.CompletedToDos ? t(ToDoListTKeys.DateCompleted) : t(ToDoListTKeys.DateDismissed), isDate: true, widthPct: 15 });
            }
        }

        if (currentRoute == ToDoListRoutePath.OpenToDos || currentRoute == ToDoListRoutePath.DismissedToDos) {
            columns.push({ name: 'cta', displayName: '', useCta: (item) => item.cta, widthPct: 15 });
        }

        return columns;
    };

    const openTabContextActions: EfcCallToActionInfo[] = [{ text: t(ToDoListTKeys.DismissContextText), icon: { icon: ['far', 'circle-xmark'] }, onClick: onDismissToDo }];
    const dismissTabContextActions: EfcCallToActionInfo[] = [{ text: t(ToDoListTKeys.RestoreContextText), icon: { icon: ['far', 'arrow-left'] }, onClick: onRestoreToDo }];

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(ToDoListTKeys.AddTaskCtaText),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            onClick: openAddTaskModal,            
        },        
    ];

    useEffect(() => {
        if (hasAuthUser) {
            getAllTasks(currentRoute);
        }
    }, [hasAuthUser]);

    return (
        <>
            <Page title='Your To-Do Items' icon={{ icon: ['far', 'clipboard-check'] }} callsToAction={ctas}>
                <div className='my-1'>
                    <Tabs>
                        <Tab active={currentRoute === ToDoListRoutePath.OpenToDos} onClick={() => changeTab(ToDoListRoutePath.OpenToDos)}>
                            {t(ToDoListTKeys.OpenToDosTab)}
                        </Tab>
                        <Tab active={currentRoute === ToDoListRoutePath.CompletedToDos} onClick={() => changeTab(ToDoListRoutePath.CompletedToDos)}>
                            {t(ToDoListTKeys.CompletedTab)}
                        </Tab>
                        <Tab active={currentRoute === ToDoListRoutePath.DismissedToDos} onClick={() => changeTab(ToDoListRoutePath.DismissedToDos)}>
                            {t(ToDoListTKeys.DismissedTab)}
                        </Tab>
                    </Tabs>
                    <Tab.Content className='my-3'>
                        {currentRoute === ToDoListRoutePath.OpenToDos &&
                            <FullTable data={getCombinedToDos()} columns={createColumns()} selectable={false} contextActions={openTabContextActions} sortable={false} />
                        }
                        {currentRoute === ToDoListRoutePath.CompletedToDos &&
                            <FullTable data={getCombinedToDos()} columns={createColumns()} selectable={false} sortable={false} />
                        }
                        {currentRoute === ToDoListRoutePath.DismissedToDos &&
                            <FullTable data={getCombinedToDos()} columns={createColumns()} selectable={false} contextActions={dismissTabContextActions} sortable={false} />
                        }

                        {utopiaTasks.length == 0 && atlantisTasks.length == 0 && (
                            <ZeroState
                                imageColContent={<img src={zeroStateImg} alt={t(ToDoListTKeys.ZeroStateImgAltText)} />}
                                textColContent={
                                    <>
                                        <Text bold>
                                            {t(ToDoListTKeys.NoItemsLine1)}
                                            {experienceType == ExperienceTypeEnum.Guest && 
                                                t(ToDoListTKeys.NoItemsLine2Guest)
                                            }
                                        </Text>

                                        {experienceType == ExperienceTypeEnum.Standard &&
                                            <EfcLink className='todo-zero-state-link-bold' color='primary' onClick={addTaskModal.open}>
                                                {t(ToDoListTKeys.NoItemsLine2User)}
                                            </EfcLink>
                                        }
                                    </>
                                }
                                stackColumns={screenSize < ScreenSize.xl}
                                maxImgWidth={ZERO_STATE_MAX_IMG_WIDTH} />
                        )}
                    </Tab.Content>
                </div>
            </Page>
            {eSignatureModal}
            {addTaskModal.render(<AddTaskModal submitTask={onSubmitAtlantisTask} />)}
        </>
    );
};
