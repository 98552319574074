import React from 'react';
import { Col, Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { MDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { AnimatedCollapseIcon } from '../../../../components/AnimatedCollapseIcon';

interface AdvancedFormattingCollapseProps {
    isOpen: boolean;
    toggleCollapse: () => void;
    className?: string;
}

export const AdvancedFormattingCollapse = (props: AdvancedFormattingCollapseProps) => {
    const { isOpen, toggleCollapse, className } = props;

    const { t } = useSafeTranslation(TranslationFiles.MDFieldModal);

    return (
        <>
            <Row className={`${className || ''}`}>
                <Col xs='auto' role='button' className='d-flex' onClick={toggleCollapse}>
                    <Text>{t(MDFieldModalTKeys.AdvancedFormatting)}</Text>
                    <AnimatedCollapseIcon isOpen={isOpen} />
                </Col>
            </Row>
        </>
    );
};