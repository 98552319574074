import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button, Modal, FormGroup, FormFeedback, Label, Input } from '@efilecabinet/efc-atlantis-components';
import { FilePasswordTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { useNodeDetailsContext } from '../../../../context/nodeDetails/NodeDetailsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { FilePasswordRemoveConfirmModal } from '../FilePasswordRemoveConfirmModal/FilePasswordRemoveConfirmModal';
import { useFilePasswordApi } from '../../../../api/useFilePasswordApi';
import { IFilePasswordOperation, FilePasswordOperationEnum, FilePasswordOperationResultEnum } from '../../../../types/CommonTypes';
import { ThemeEnum } from '../../../../hooks/useColors';
import { useIcons } from '../../../../hooks/useIcons';
import './FilePasswordModal.css';

export enum FilePasswordModalUseEnum {
    Download,
    Preview,
    Checkout,
}

interface FilePasswordModalProps {
    show: boolean;
    toggle: () => void;
    nodeId: string | undefined;
    setHasEnteredCorrectPassword: React.Dispatch<React.SetStateAction<boolean>>;
    modalUse: FilePasswordModalUseEnum;
}

export const FilePasswordModal = ({ show, toggle, nodeId, setHasEnteredCorrectPassword, modalUse }: FilePasswordModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.FilePassword);

    const { openModal, showToastMessage } = useLayerContext();
    const { onFilePasswordDelete } = useNodeDetailsContext();
    const { spinnerIconProps } = useIcons();

    const { performFilePasswordOperationAsync } = useFilePasswordApi();

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string | undefined>(undefined);

    const onAccountNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordError(undefined);
        setPassword(event.target.value);
    };

    const removePasswordError = () => {
        showToastMessage({
            color: ThemeEnum.Danger,
            timeout: 5000,
            identifier: 'remove-file-password-error',
            message: t(FilePasswordTKeys.RemovePasswordError)
        });
    };

    const isValidPassword = async() => {
        const operation : IFilePasswordOperation = {
            nodeID: parseInt(nodeId as string),
            currentPassword: password,
            operation: FilePasswordOperationEnum.CheckPassword
        };
        const result = await performFilePasswordOperationAsync(operation);

        return result.result == FilePasswordOperationResultEnum.ValidPassword;
    };

    const onDeleteConfirm = async() => {
        setHasEnteredCorrectPassword(true);

        const operation : IFilePasswordOperation = {
            nodeID: parseInt(nodeId as string),
            currentPassword: password,
            operation: FilePasswordOperationEnum.DeletePassword
        };

        const result = await performFilePasswordOperationAsync(operation);

        if (result.result == FilePasswordOperationResultEnum.Completed) {
            showToastMessage({
                color: ThemeEnum.Success,
                timeout: 4000,
                identifier: 'remove-file-password-success',
                message: t(FilePasswordTKeys.RemovePasswordSuccess)
            });
            onFilePasswordDelete();
        }
        else {
            removePasswordError();
        }
    };

    const removePassword = async() => {
        try {
            setShowSpinner(true);
            if (await isValidPassword()) {
                openModal((closeModalFn) =>
                    <FilePasswordRemoveConfirmModal onClose={closeModalFn} onCancel={toggle} onDeleteConfirm={onDeleteConfirm} />
                );
                toggle();
            }
            else  {
                setPasswordError(t(FilePasswordTKeys.IncorrectPasswordError));
            }
        }
        catch {
            removePasswordError();
        }
        finally {
            setShowSpinner(false);
        }
    };

    const submit = async() => {
        try {
            setShowSpinner(true);
            if (await isValidPassword()) {
                setHasEnteredCorrectPassword(true);
                toggle();
            }
            else  {
                setPasswordError(t(FilePasswordTKeys.IncorrectPasswordError));
            }
        }
        catch {
            showToastMessage({
                color: ThemeEnum.Danger,
                timeout: 5000,
                identifier: 'file-password-submit-error',
                message: t(FilePasswordTKeys.SubmitPasswordError)
            });
        }
        finally {
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        if(!!nodeId) {
            setPassword('');
            setPasswordError(undefined);
        }
    }, [nodeId]);

    return (
        <Modal isOpen={show} toggle={toggle} title={t(FilePasswordTKeys.FilePasswordModalTitle) + FilePasswordModalUseEnum[modalUse].toLowerCase()}>
            <Modal.Body>
                <FormGroup>
                    <Label for='accountName'>{t(FilePasswordTKeys.FilePasswordInputLabel)}</Label>
                    <Input className='input-name' required id='filePasswordInput' dataId='filePasswordInput' name='password' type='password' value={password} onChange={onAccountNameChange} invalid={!!passwordError} />
                    <FormFeedback className='file-password-error'>
                        <FontAwesomeIcon icon='triangle-exclamation' size='sm' className='icon-warning' /> {passwordError}
                    </FormFeedback>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Row className='g-0'>
                    <Col><Button dataId='revversAmazingFilePasswordModalCancelButtonDataIdAttributeTag' color='primary' onClick={toggle} emphasis='low'>{t(FilePasswordTKeys.Cancel)}</Button></Col>
                    <Col className='px-2'><Button dataId='revversAmazingFilePasswordModalRemoveButtonDataIdAttributeTag' color='primary' onClick={removePassword} emphasis='med' disabled={!!passwordError || !password} className='file-password-remove-button'>{showSpinner ? <FontAwesomeIcon {...spinnerIconProps} /> : t(FilePasswordTKeys.RemovePassword)}</Button></Col>
                    <Col><Button dataId='revversAmazingFilePasswordModalSubmitButtonDataIdAttributeTag' color='primary' onClick={submit} disabled={!!passwordError || !password} className='file-password-submit-button'>{showSpinner ? <FontAwesomeIcon {...spinnerIconProps} /> : t(FilePasswordTKeys.Submit)}</Button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
