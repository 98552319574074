import React from 'react';
import { Col, Row } from 'reactstrap';
import { Nav, Text } from '@efilecabinet/efc-atlantis-components';
import { useAllFeaturesStyle } from '../../../../hooks/useAllFeaturesStyle';
import { useRouteValidator } from '../../../../hooks/useRouteValidator';
import { TranslationFiles, VerticalNavTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useBootstrapBreakpoints } from '../../../../hooks/useBootstrapBreakpoints';
import { useAllFeaturesFlyoutHook } from '../../../../hooks/useAllFeaturesFlyoutHook';
import { useAuthContext } from '../../../../auth';
import { ScreenSize } from '../../../../types/BootstrapTypes';
import { ExperienceTypeEnum } from '../../../../auth/AuthenticationTypes';
import './AllFeaturesPanel.css';
import './NoAccessToFeatures.css';

export function NoAccessToFeatures() {
    const columnCount = 4;

    const { allFeaturesWidthStyle } = useAllFeaturesStyle();
    const { canGoToRoute } = useRouteValidator();
    const { AllFeatureGroups } = useAllFeaturesFlyoutHook();
    const { t } = useSafeTranslation(TranslationFiles.VerticalNav);
    const { screenSize } = useBootstrapBreakpoints();
    const { experienceType } = useAuthContext();

    const noAccessFeatures = AllFeatureGroups.flatMap((i) => i.Features).filter((i) => !canGoToRoute(i.routePath));
    const noAccessFeaturesGroupedByColumn = groupNoAccessFeatures();

    function groupNoAccessFeatures() {
        const itemsPerColumn = Math.ceil(noAccessFeatures.length / columnCount);
        const groupedFeatures = [];
        for (let i = 0; i < columnCount; i++) {
            groupedFeatures.push(noAccessFeatures.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn));
        }
        return groupedFeatures;
    }

    return screenSize > ScreenSize.l && noAccessFeatures.length > 0 ? (
        <Row className='mt-2 bg-white no-access-to-features-spacing' style={allFeaturesWidthStyle}>
            <Row className='pt-2'>
                <Text bold={true} className='mb-1'>
                    {t(VerticalNavTKeys.NoAccessToFeaturesHeaderTitle)}
                </Text>
                <Text>{t(VerticalNavTKeys.NoAccessToFeaturesHeaderLarge)}</Text>
            </Row>
            <Row>
                {noAccessFeaturesGroupedByColumn.map((column, index) => (
                    <Col key={index} className='pb-2 has-access-to-features-column no-access-to-features-spacing'>
                        {column.map((feature) => (
                            <Nav.Link disabled key={feature.routePath} icon={feature.icon} className='my-3 cursor-auto'>
                                {t(feature.translationKey)}
                            </Nav.Link>
                        ))}
                    </Col>
                ))}
            </Row>
        </Row>
    ) : screenSize <= ScreenSize.l && screenSize > ScreenSize.xs && noAccessFeatures.length > 0 ? (
        <Row className='mt-2 bg-white no-access-to-features-spacing' style={allFeaturesWidthStyle}>
            <Text bold={true} className='mb-1'>
                {t(VerticalNavTKeys.NoAccessToFeaturesHeaderTitle)}
            </Text>
            <Text>{noAccessFeatures.length + ' ' + t(VerticalNavTKeys.NoAccessToFeaturesHeaderSmall)}</Text>
        </Row>
    ) : noAccessFeatures.length > 0 && experienceType == ExperienceTypeEnum.Standard ? (
        <Row className='bg-white no-access-to-features-spacing' style={{ width: 'auto' }}>
            <Text bold={true} className='mb-1'>
                {t(VerticalNavTKeys.NoAccessToFeaturesHeaderTitle)}
            </Text>
            <Text>{noAccessFeatures.length + ' ' + t(VerticalNavTKeys.NoAccessToFeaturesHeaderSmall)}</Text>
        </Row>
    ) : null;
}
