import React, { useEffect, useState } from 'react';
import { EfcTableColumnInfo, FullTable, EfcCallToActionInfo, Modal } from '@efilecabinet/efc-atlantis-components';
import { IActiveSession, ActiveSessionQueryRequest } from '../../../../types/SessionTypes';
import { useUserSessionsApi } from '../../../../api';
import { useAuthContext } from '../../../../auth';
import { TranslationFiles, useSafeTranslation, UserSessionsTKeys } from '../../../../hooks/useSafeTranslation';
import './UserSessions.css';

export const UserSessions = () => {
    const { t } = useSafeTranslation(TranslationFiles.UserSessions);

    const { getUserSessions, endUserSessions } = useUserSessionsApi();
    const { authUser } = useAuthContext();

    const [userSessions, setUserSessions] = useState<IActiveSession[]>([]);
    const [selectedSessions, setSelectedSessions] = useState<IActiveSession[]>([]);
    const [displayEndSessionConfirmation, setDisplayEndSessionConfirmation] = useState(false);

    const initQueryProps: ActiveSessionQueryRequest = {
        start: 0,
        count: 100,
        excludeCurrentUser: false,
    };

    const hideEndSessionConfirmation = () => setDisplayEndSessionConfirmation(false);
    const showEndSessionConfirmation = () => setDisplayEndSessionConfirmation(true);

    const handleEndUserSession = (rowItem?: IActiveSession) => {
        if (!!rowItem) {
            setSelectedSessions([rowItem]);
            showEndSessionConfirmation();
        }
    };

    const confirmEndUserSessions = async (confirm: boolean) => {
        hideEndSessionConfirmation();

        if (confirm && selectedSessions.length > 0) {
            const roleIDs = selectedSessions.map((item) => item.roleID);
            await endUserSessions(roleIDs);
            await getActiveSessions();
            setSelectedSessions([]);
        }
    };

    const getActiveSessions = async () => {
        const activeSessions = await getUserSessions(authUser?.accountID as number, initQueryProps);
        setUserSessions(activeSessions);
    };

    useEffect(() => {
        getActiveSessions();
    }, []);

    const columns: (string | EfcTableColumnInfo)[] = [
        { name: 'roleName', displayName: t(UserSessionsTKeys.UserColumn) },
        { name: 'ipAddress', displayName: t(UserSessionsTKeys.IpAddressColumn) },
        { name: 'clientApplication.name', displayName: t(UserSessionsTKeys.ApplicationColumn) },
    ];

    const bulkActions: EfcCallToActionInfo[] = [
        { text: t(UserSessionsTKeys.EndUserSessionButton), color: 'primary', onClick: showEndSessionConfirmation }
    ];

    const contextActions: EfcCallToActionInfo[] = [
        { text: t(UserSessionsTKeys.EndUserSessionButton), color: 'primary', onClick: handleEndUserSession }
    ];

    const confirmationCTAs: EfcCallToActionInfo[] = [
        { text: t(UserSessionsTKeys.CancelButton), color: 'primary', emphasis: 'med', onClick: () => confirmEndUserSessions(false) },
        { text: t(UserSessionsTKeys.EndUserSessionButton), color: 'primary', emphasis: 'high', onClick: () => confirmEndUserSessions(true) }
    ];

    return (
        <>
            <div className='my-2'>
                <FullTable data={userSessions} columns={columns} bulkActions={bulkActions} contextActions={contextActions} onSelect={setSelectedSessions} />
            </div>

            <Modal toggle={hideEndSessionConfirmation} title={t(UserSessionsTKeys.EndSessionConfirmationTitle)} isOpen={displayEndSessionConfirmation} ctas={confirmationCTAs}>
                <Modal.Body>
                    {t(UserSessionsTKeys.EndSessionConfirmationMessage, { count: selectedSessions.length })}
                </Modal.Body>
            </Modal>
        </>
    );
};
