import { Api } from './';
import { AxiosResponse } from 'axios';
import { INodeNote } from '../types/NodeTypes';

export const useNodeCommentApi = () => {

    const getNodeNotesAsync = async (nodeId: string) => {
        const resp: AxiosResponse = await Api.get(`api/NodeComment/NodeNote?id=${nodeId}`);
        return resp.data;
    };

    const addNodeNoteAsync = async (nodeNote: INodeNote) => {
        const resp: AxiosResponse = await Api.post('api/NodeComment/NodeNote', nodeNote);
        return resp.data;
    };

    const updateNodeNoteAsync = async (updatedNodeNote: INodeNote) => {
        const resp: AxiosResponse = await Api.put(`api/NodeComment/NodeNote?id=${updatedNodeNote.id}`, updatedNodeNote);
        return resp.data;
    };

    const deleteNodeNoteAsync = async (nodeId: number) => {
        const resp: AxiosResponse = await Api.delete(`api/NodeComment/NodeNote?id=${nodeId}`);
        return resp.data;
    };

    return {
        getNodeNotesAsync,
        addNodeNoteAsync,
        updateNodeNoteAsync,
        deleteNodeNoteAsync
    };
};
