import { Api } from '.';
import { AxiosResponse } from 'axios';
import { IMDGetFieldsRequest, IMDField, IMDCheckFieldNameRequest } from '../types/MDFieldTypes';

export const useMDFieldApi = () => {

    const getMDField = async (accountId: number, mdFieldId: string): Promise<IMDField> => {
        const resp: AxiosResponse = await Api.get(`api/metadataField/${accountId}/${mdFieldId}`);
        return resp.data as IMDField;
    };

    const getAccountMDFields = async (accountId: number, start: number, count: number): Promise<IMDField[]> => {
        const resp: AxiosResponse = await Api.get(`api/metadataField/account/${accountId}/${start}/${count}`);
        return resp.data as IMDField[];
    };

    const getMDFields = async (mdFieldRequest: IMDGetFieldsRequest): Promise<IMDField[]> => {
        const resp: AxiosResponse = await Api.post('api/metadataField/query', mdFieldRequest);
        return resp.data as IMDField[];
    };

    const getMDFieldByName = async (mdFieldRequest: IMDCheckFieldNameRequest): Promise<IMDField> => {
        const resp: AxiosResponse = await Api.post(`api/metadataField/${mdFieldRequest.accountId}/name`, mdFieldRequest);
        return resp.data as IMDField;
    };

    const createMDField = async (mdField: IMDField): Promise<IMDField> => {
        const resp: AxiosResponse = await Api.post('api/metadataField', mdField);
        return resp.data as IMDField;
    };

    const updateMDField = async (mdField: IMDField): Promise<IMDField> => {
        const resp: AxiosResponse = await Api.put('api/metadataField', mdField);
        return resp.data as IMDField;
    };

    const deleteMDField = async (accountId: number, mdFieldId: string): Promise<IMDField> => {
        const resp: AxiosResponse = await Api.delete(`api/metadataField/${accountId}/${mdFieldId}`);
        return resp.data as IMDField;
    };

    return {
        getMDField,
        getAccountMDFields,
        getMDFields,
        getMDFieldByName,
        createMDField,
        updateMDField,
        deleteMDField
    };
};