import { Api } from '.';
import { AxiosResponse } from 'axios';
import { IProfile } from '../types/ProfilesAndProfileItemsTypes';

export const useProfileApi = () => {
    const getAccountProfiles = async (accountId: number): Promise<IProfile[]> => {
        const resp: AxiosResponse = await Api.get(`api/profile/account/${accountId}`);
        return resp.data as IProfile[];
    };

    const deleteProfile = async (id: number, accountId: number) => {
        await Api.delete(`api/profile/delete?id=${id}&accountId=${accountId}`);
    };

    return {
        getAccountProfiles,
        deleteProfile,
    };
};