import { Api } from './';
import { AxiosResponse } from 'axios';
import { UserSearch } from '../types/SearchTypes';

export const useSearchApi = () => {

    const createUserSearchAsync = async (userSearch: UserSearch) : Promise<UserSearch> => {
        const resp: AxiosResponse = await Api.post('api/userSearch', userSearch);
        return resp.data;
    };

    return {
        createUserSearchAsync,
    };
};
