import { Api } from '.';
import { AxiosResponse } from 'axios';
import { IProfileItem } from '../types/ProfilesAndProfileItemsTypes';

export const useProfileItemApi = () => {
    const getAccountProfileItems = async (accountId: number): Promise<IProfileItem[]> => {
        const resp: AxiosResponse = await Api.get(`api/profileitem/account/${accountId}`);
        return resp.data as IProfileItem[];
    };

    const deleteProfileItems = async (ids: number[]) => {
        await Api.post('api/profileitem/delete', ids);
    };

    return {
        getAccountProfileItems,
        deleteProfileItems,
    };
};