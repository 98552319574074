import { Api } from './';
import { AxiosResponse } from 'axios';
import { UtopiaUploadFileResponse } from '../types/FileUploadTypes';

export const useFileUploadApi = () => {

    const deleteAllFailedFileUploadsAsync = async () => {
        const resp: AxiosResponse = await Api.delete('api/FileUpload/Failed/All');
        return resp.data as UtopiaUploadFileResponse[];
    };

    const deleteFailedFileUploadAsync = async (nodeId: string, uploadIdentifier: string) => {
        const resp: AxiosResponse = await Api.delete(`api/FileUpload/Failed?nodeId=${nodeId}&uploadIdentifier=${uploadIdentifier}`);
        return resp.data as UtopiaUploadFileResponse[];
    };

    const queryFailedFileUploadsAsync = async (start: number, count: number) => {
        const resp: AxiosResponse = await Api.get(`api/FileUpload/Failed?start=${start}&count=${count}`);
        return resp.data as UtopiaUploadFileResponse[];
    };

    return {
        deleteAllFailedFileUploadsAsync,
        deleteFailedFileUploadAsync,
        queryFailedFileUploadsAsync
    };
};