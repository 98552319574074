import { Api } from './';
import { AxiosResponse } from 'axios';
import { IFileProcessingQuery } from '../types/FileProcessingQueueTypes';

export const useFileProcessingQueueApi = () => {
    const getFileProcessingQueue = async (query: IFileProcessingQuery) => {
        const resp: AxiosResponse = await Api.get(`api/FileProcessingQueue?accountId=${query.accountId}&start=${query.start}&count=${query.count}`);
        return resp.data;
    };

    const deleteFileQueueItems = async (ids: number[]) => {
        await Api.post('api/FileProcessingQueue/Delete', ids);
        return true;
    };

    const retryFileQueueItems = async (ids: number[]) => {
        await Api.post('api/FileProcessingQueue/Retry', ids);
        return true;
    };

    return {
        getFileProcessingQueue,
        deleteFileQueueItems,
        retryFileQueueItems
    };
};