import React, { ReactNode, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { PreviewerContext } from './PreviewerContext';
import { useSettingsAndFeatures } from '../settingsAndFeatures/SettingsFeaturesContext';

export interface LoadDocumentOptions {
    fileVersion?: number;
    password?: string;
    annotationId?: string;
    formToolsCallback?: () => void;
    nonPdfRedirectCallback?: (targetFileNodeId: string) => void;
    searchTerm?: string;
}

export interface IPreviewerContext {
    isProviderReady: boolean;
    utopiaUrl: string;
    previewerWebServerUrl: string;
    baseAccusoftPreviewUrl: string;
    searchTerm: string;
    setSearchTerm:  Dispatch<SetStateAction<string>>;
}

interface PreviewerProviderProps {
    children: ReactNode;
}

export const PreviewerProvider = ({ children }: PreviewerProviderProps) => {
    const { hasUtopiaConfig, utopiaConfig } = useSettingsAndFeatures();

    const [isProviderReady, setIsProviderReady] = useState<boolean>(false);
    const [utopiaUrl, setUtopiaUrl] = useState<string>('');
    const [previewerWebServerUrl, setPreviewerWebServerUrl] = useState<string>('');
    const [baseAccusoftPreviewUrl, setBaseAccusoftPreviewUrl] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');

    const contextObject: IPreviewerContext = {
        isProviderReady,
        utopiaUrl,
        previewerWebServerUrl,
        baseAccusoftPreviewUrl,
        searchTerm,
        setSearchTerm,
    };

    useEffect(() => {
        if (!!hasUtopiaConfig) {
            setUtopiaUrl(utopiaConfig?.DefaultUrl ?? '');
            setPreviewerWebServerUrl(utopiaConfig?.PreviewerXWebserverUrl ?? '');
            setBaseAccusoftPreviewUrl(utopiaConfig?.DefaultPreviewUrl ?? '');
            setIsProviderReady(true);
        }

    }, [hasUtopiaConfig, utopiaConfig]);

    return (
        <PreviewerContext.Provider value={contextObject}>
            {children}
        </PreviewerContext.Provider>
    );
};