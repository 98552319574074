import { Api } from './';
import { AxiosResponse } from 'axios';
import { ICheckedOutFile } from '../types/CommonTypes';

export const useCheckedOutApi = () => {

    const getAccountCheckedOut = async (accountId: number): Promise<ICheckedOutFile[]> => {
        const resp: AxiosResponse = await Api.get(`api/checkedOut/account/${accountId}`);
        return resp.data as ICheckedOutFile[];
    };

    const getMyCheckedOut = async (): Promise<ICheckedOutFile[]> => {
        const resp: AxiosResponse = await Api.get('api/checkedOut');
        return resp.data as ICheckedOutFile[];
    };

    const forceCheckIn = async (nodeIds: string[]) => {        
        await Api.post('api/checkedOut/forceCheckIn', nodeIds);
    };

    return {
        getAccountCheckedOut,
        getMyCheckedOut,
        forceCheckIn,
    };
};
