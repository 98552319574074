import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Card, Text, EfcCallToActionInfo, Button, EfcLink, FullTable, EfcTableColumnInfo } from '@efilecabinet/efc-atlantis-components';
import { AtlantisTaskStatus, IAtlantisTask, IToDo, IUtopiaTaskCount } from '../../../types/ToDoTypes';
import { NotificationStatusEnum, NotificationOrderEnum, IUtopiaTaskNotification } from '../../../types/UtopiaNotificationTypes';
import { ScreenSize } from '../../../types/BootstrapTypes';
import { AddTaskModal } from './AddTaskModal';
import { useAuthContext } from '../../../auth';
import { useModal } from '../../../hooks/useModal';
import { useSafeTranslation, ToDoListTKeys, TranslationFiles } from '../../../hooks/useSafeTranslation';
import { useTaskApi } from '../../../api';
import { useAtlantisTaskConverter } from '../../../hooks/useAtlantisTaskConverter';
import { useUtopiaNotificationsConverter } from '../../../hooks/useUtopiaNotificationsConverter';
import { useBootstrapBreakpoints } from '../../../hooks/useBootstrapBreakpoints';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { ZeroState } from '../../../components/ZeroState';
import { ExperienceTypeEnum } from '../../../auth/AuthenticationTypes';
import zeroStateImg from './ToDoListZeroState.svg';
import './ToDoList.css';

export interface IToDoListProps {
    className?: string;
}

export const ToDoList = (props: IToDoListProps) => {
    const { className } = props;

    const [atlantisTasks, setAtlantisTasks] = useState<IAtlantisTask[]>([]);
    const [utopiaTasks, setUtopiaTasks] = useState<IUtopiaTaskNotification[]>([]);
    const [utopiaTaskCount, setUtopiaTaskCount] = useState<number>(0);
    const [isLoadingAtlantisTasks, setIsLoadingAtlantisTasks] = useState<boolean>(true);
    const [isLoadingUtopiaTasks, setIsLoadingUtopiaTasks] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const { t } = useSafeTranslation(TranslationFiles.ToDoList);

    const { authUser, hasAuthUser, experienceType } = useAuthContext();
    const { addAtlantisTask, getUserAtlantisTasks, getUserUtopiaTasks, getUserUtopiaTasksCount } = useTaskApi();
    const { toAtlantisToDo } = useAtlantisTaskConverter({ atlantisTasks, setAtlantisTasks });
    const { toToDo, eSignatureModal } = useUtopiaNotificationsConverter();
    const { screenSize } = useBootstrapBreakpoints();
    const { getLinkToOpenToDos } = useRouting();

    const onSubmitAtlantisTask = (task: IAtlantisTask) => {
        addAtlantisTask(authUser?.userID as number, task).then((newTask) => {
            setAtlantisTasks((currentTasks) => [newTask, ...currentTasks]);
        });
    };

    const NUM_SKELETON_ITEMS = 5;
    const MAX_TASKS_COUNT = 10;

    const addTaskModal = useModal();

    const getAtlantisTasks = () => {
        getUserAtlantisTasks(authUser?.userID as number, AtlantisTaskStatus[AtlantisTaskStatus.Assigned], 0, MAX_TASKS_COUNT)
            .then((atlantisTasks: IAtlantisTask[]) => {
                setAtlantisTasks(atlantisTasks);
            })
            .catch(() => {
                setErrorLoading(true);
            })
            .finally(() => {
                setIsLoadingAtlantisTasks(false);
            });
    };

    const getUtopiaTasks = () => {
        getUserUtopiaTasks([NotificationStatusEnum.Active], NotificationOrderEnum.QueuedOnDesc, 0, MAX_TASKS_COUNT)
            .then((utopiaTasks: IUtopiaTaskNotification[]) => {
                setUtopiaTasks(utopiaTasks);
            })
            .catch(() => {
                setErrorLoading(true);
            })
            .finally(() => {
                setIsLoadingUtopiaTasks(false);
            });
    };

    const getUtopiaTaskCount = () => {
        getUserUtopiaTasksCount([NotificationStatusEnum.Active]).then((utopiaTaskCount: IUtopiaTaskCount) => {
            setUtopiaTaskCount(utopiaTaskCount.count);
        });
    };

    const addTaskCta: EfcCallToActionInfo = {
        text: t(ToDoListTKeys.AddTaskCtaText),
        icon: { icon: ['far', 'circle-plus'] },
        onClick: addTaskModal.open,
    };

    useEffect(() => {
        if (!!hasAuthUser) {
            getAtlantisTasks();
            getUtopiaTasks();
            getUtopiaTaskCount();
        }
    }, [hasAuthUser]);

    const createColumns = () => {
        const columns: EfcTableColumnInfo[] = [{ name: 'text', displayName: t(ToDoListTKeys.NameColumn), useIcon: (elem: IToDo) => elem.icon, widthPct: 60 }];
        if (screenSize > ScreenSize.m) {
            columns.push({ name: 'from', displayName: t(ToDoListTKeys.FromColumn) });
        }
        columns.push({ name: 'theCta', displayName: '', useCta: (elem: IToDo) => elem.cta, widthPct: 10 });
        return columns;
    };

    return (
        <>
            <Card fluid borderless className={className}>
                <Card.Body dataId='toDoListBody'>
                    <Card.Title tag={Link} size='xl' bold icon={{ icon: ['far', 'clipboard-check'] }} cta={addTaskCta} to={getLinkToOpenToDos()}>
                        {t(ToDoListTKeys.ToDoTitle)}
                    </Card.Title>

                    {!!isLoadingAtlantisTasks || !!isLoadingUtopiaTasks || !!errorLoading || !authUser ? (
                        <>
                            {(!!isLoadingAtlantisTasks || !!isLoadingUtopiaTasks || !authUser) && (
                                <SkeletonTheme enableAnimation height='24px' width='100%'>
                                    <Skeleton count={NUM_SKELETON_ITEMS} className='mb-3 mx-auto' />
                                </SkeletonTheme>
                            )}

                            {!!errorLoading && <Card.Text>{t(ToDoListTKeys.ErrorLoading)}</Card.Text>}
                        </>
                    ) : (
                        <>
                            {(!atlantisTasks && !utopiaTasks) || atlantisTasks.length + utopiaTasks.length == 0 ? (
                                <ZeroState
                                    imageColContent={<img src={zeroStateImg} alt={t(ToDoListTKeys.ZeroStateImgAltText)} />}
                                    textColContent={
                                        <>
                                            <Text bold>
                                                {t(ToDoListTKeys.NoItemsLine1)}{experienceType == ExperienceTypeEnum.Guest && t(ToDoListTKeys.NoItemsLine2Guest)}
                                            </Text>

                                            {experienceType == ExperienceTypeEnum.Standard &&
                                                <EfcLink className='todo-zero-state-link-bold' color='primary' dataId='lnkAddTask' onClick={addTaskModal.open}>
                                                    {t(ToDoListTKeys.NoItemsLine2User)}
                                                </EfcLink>
                                            }
                                        </>
                                    }
                                    stackColumns={screenSize < ScreenSize.xl} />
                            ) : (
                                <>
                                    <FullTable
                                        small
                                        columns={createColumns()}
                                        data={atlantisTasks
                                            .map((task) => toAtlantisToDo(task))
                                            .concat(utopiaTasks.map((task) => toToDo(task)))
                                            .slice(0, MAX_TASKS_COUNT)}
                                        useAnimatedList={true}
                                        doAnimatedFadeOut={utopiaTaskCount + atlantisTasks.length <= MAX_TASKS_COUNT}
                                    />

                                    {utopiaTaskCount + atlantisTasks.length > MAX_TASKS_COUNT && (
                                        <div className='d-flex justify-content-center'>
                                            <Button color='primary' emphasis='low' tag={Link} to={getLinkToOpenToDos()}>
                                                {utopiaTaskCount + atlantisTasks.length - MAX_TASKS_COUNT}
                                                {t(ToDoListTKeys.SeeAllText)}
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
            {eSignatureModal}
            {addTaskModal.render(<AddTaskModal submitTask={onSubmitAtlantisTask} />)}
        </>
    );
};
