import { Api } from './';
import { AxiosResponse } from 'axios';
import { INode, INodeDetails } from '../types/CommonTypes';
import { HasSomeNodeAccessResponse } from '../types/DocumentsTypes';
import { INodeNote } from '../types/NodeTypes';
import { useUrlUtilities } from '../hooks/useUrlUtilities';

export const useNodeApi = () => {

    const { getQueryParamDelimiter } = useUrlUtilities();

    const createTemplateLibrary = async (libraryName: string) => {
        const resp: AxiosResponse = await Api.post(`api/Node/TemplateLibrary/${libraryName}`);
        return resp.data;
    };

    const createTemplate = async (parentId: string, templateName: string) => {
        const resp: AxiosResponse = await Api.post(`api/Node/TemplateLibrary/${parentId}/Template/${templateName}`);
        return resp.data;
    };

    const updateAccountWorkspaceName = async (accountId: number, name: string): Promise<INode> => {
        const resp: AxiosResponse = await Api.post(`api/Node/UpdateWorkspaceNode/${accountId}/${name}`);
        return resp.data;
    };

    const getRootNodesAsync = async(): Promise<INode[]> => {
        const resp: AxiosResponse = await Api.get(`api/Node/Root`);
        return resp.data;
    };

    const getAncestorsAsync = async(nodeId: string, count: number): Promise<INode[]> => {
        const resp: AxiosResponse = await Api.get(`api/Node/Ancestors/${nodeId}?count=${count}`);
        return resp.data;
    };

    const getAncestorsOnlyAsync = async(nodeId: string): Promise<INode[]> => {
        const resp: AxiosResponse = await Api.get(`api/Node/AncestorsOnly/${nodeId}`);
        return resp.data;
    };

    const getNodeBatchAsync = async (nodeIDs: string[]) => {
        const resp: AxiosResponse = await Api.post('api/Node/Query', nodeIDs);
        return resp.data;
    };

    const getNodeByIdAsync = async (nodeId: string): Promise<INode> => {
        const resp: AxiosResponse = await Api.get(`api/Node/${nodeId}`);
        return resp.data;
    };

    const getNodeDetailsByIdAsync = async (nodeId: string): Promise<INodeDetails> => {
        const resp: AxiosResponse = await Api.get(`api/Node/Details/${nodeId}`);
        return resp.data;
    };

    const getNodeDetailTemplateNamesByIdAsync = async (nodeId: string): Promise<INodeDetails> => {
        const resp: AxiosResponse = await Api.get(`api/Node/Details/TemplateNames/${nodeId}`);
        return resp.data;
    };

    const getRootNodeByAccountIdAsync = async (accountId: number) => {
        const resp: AxiosResponse = await Api.get(`api/Node/Root/${accountId}`);
        return resp.data;
    };

    const getTemplateLibraries = async (accountId: number) => {
        const resp: AxiosResponse = await Api.get(`api/Node/Templates/${accountId}`);
        return resp.data;
    };

    const getNodeChildrenAsync = async (nodeId: number, start?: number, count?: number, searchCriteria?: string): Promise<INode[]> => {

        let url = `api/Node/Children/${nodeId}`;
        if (!!start) {
            url = `${url}${getQueryParamDelimiter(url)}start=${start}`;
        }
        if (!!count) {
            url = `${url}${getQueryParamDelimiter(url)}count=${count}`;
        }
        if (!!searchCriteria) {
            url = `${url}${getQueryParamDelimiter(url)}searchCriteria=${searchCriteria}`;
        }

        const resp: AxiosResponse = await Api.get(url);
        return resp.data;
    };

    const getHasSomeNodeAccess = async (accountId: number): Promise<HasSomeNodeAccessResponse> => {
        const resp: AxiosResponse = await Api.get(`api/Node/HasSomeNodeAccess/${accountId}`);
        return resp.data as HasSomeNodeAccessResponse;
    };

    const getSharedWithYou = async (accountId: number, start: number, count: number) => {
        const resp: AxiosResponse = await Api.get(`api/Node/SharedWithYou/${accountId}?start=${start}&count=${count}`);
        return resp.data;
    };

    const getSharedWithAnonymous = async (start: number, count: number) => {
        const resp: AxiosResponse = await Api.get(`api/Node/SharedWithAnonymous?start=${start}&count=${count}`);
        return resp.data;
    };

    const deleteNodeById = async (nodeId: string) => {
        await Api.delete(`api/Node/${nodeId}`);
    };

    return {
        createTemplateLibrary,
        createTemplate,
        getRootNodesAsync,
        getAncestorsAsync,
        getAncestorsOnlyAsync,
        getNodeBatchAsync,
        getNodeByIdAsync,
        getNodeDetailsByIdAsync,
        getNodeDetailTemplateNamesByIdAsync,
        getRootNodeByAccountIdAsync,
        getTemplateLibraries,
        getNodeChildrenAsync,
        getHasSomeNodeAccess,
        getSharedWithYou,
        getSharedWithAnonymous,
        deleteNodeById,
        updateAccountWorkspaceName
    };
};
