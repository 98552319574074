import { Api } from '.';
import { AxiosResponse } from 'axios';

export const useCollectionsApi = () => {

    const getCollections = async () => {
        const resp: AxiosResponse = await Api.get('api/collections');
        return resp.data;
    };

    const getCollectionById = async (id: number) => {
        const resp: AxiosResponse = await Api.get(`api/collections/${id}`);
        return resp.data;
    };

    return {
        getCollections,
        getCollectionById,
    };
};