export enum DocumentRequestType {
    NotFound = 0,
    Regular = 1,
    UploadLink = 2
}

export interface UploadLinkResponse {
    uploadLink: UploadLink;
    errorMessage: string;
}

export interface DocumentRequestTypeCheckResponse {
    documentRequestType: number;
    errorMessage: string;
}

export interface UploadLink {
    id: number;
    nodeID: number;
    accountID: number;
    documentRequestID: string;
    uploadLinkURL: string;
}