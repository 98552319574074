import React, { useEffect } from 'react';
import { Page } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { RecycleBinTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useLayerContext } from '../../../context/layer/LayerContext';
import { RecycleBinDeleteConfirmationModal } from './RecycleBinDeleteConfirmationModal/RecycleBinDeleteConfirmationModal';

export const RecycleBin = () => {
    const { t } = useSafeTranslation(TranslationFiles.RecycleBin);
    const { openModal } = useLayerContext();

    const recycleBinUrl = '/admin/recyclebin';
    const iframe = useUtopiaIFrame(recycleBinUrl);

    const confirmDelete = (e: MessageEvent) => {
        if (e.data?.type === 'SHOW_CONFIRM_PURGE_MODAL') {
            openModal((closeModalFn) => <RecycleBinDeleteConfirmationModal onClose={closeModalFn} nodesToDelete={e.data.nodesToDelete} />);
        }
    };

    useEffect(() => {
        window.addEventListener('message', confirmDelete);

        return () => {
            window.removeEventListener('message', confirmDelete);
        };
    }, []);

    return (
        <Page title={t(RecycleBinTKeys.PageTitle)} icon={{ icon: 'trash-can' }}>
            <IFrame className='iframe' src={iframe.url} name='recycleBinIFrame' />
        </Page>
    );
};
