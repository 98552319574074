import { OrderEnum, RoleEnum } from '../types/CommonTypes';
import { UserLicenseEnum } from '../auth/AuthenticationTypes';

export enum FilterTypeEnum {
    Name = 0,
    ProfileValue = 1,
    CreatedByName = 2,
    Path = 3,
    DateCreated = 4,
    Type = 5,
    FullText = 6,
    DeletedOn = 7,
    Username = 8,
    Comment = 9,
    Container = 10,
    DateModified = 11,
    Profile = 12,
    ProfileItem = 13,
    CreatedByUserID = 14,
    ParentID = 15,
}

export enum FilterComparatorEnum {
    Contains = 0,
    Equals = 1,
    GreaterThan = 2,
    LessThan = 3,
    NotEqual = 4,
    NotContain = 5,
    GreaterThanOrEqualTo = 6,
    LessThanOrEqualTo = 7,
    EndsWith = 8,
    StartsWith = 9,
    WithInPast = 10,
    Matches = 11,
    Has = 12,
    ContainsWords = 13,
    MatchPhrase = 14,
    Like = 15,
}

export class FilterValue {
    type: FilterTypeEnum;
    value: string;
    comparator: FilterComparatorEnum;

    constructor(type: FilterTypeEnum, value: string, comparator: FilterComparatorEnum) {
        this.type = type;
        this.value = value;
        this.comparator = comparator;
    }
}

export class FilterItem {
    filterCriteria: FilterValue | null;
    andCriteria: FilterItem[];
    orCriteria: FilterItem[];

    constructor(type: FilterTypeEnum, value: string, comparator: FilterComparatorEnum) {
        this.filterCriteria = new FilterValue(type, value, comparator);
        this.andCriteria = [];
        this.orCriteria = [];
    }
}

export class UserSearch {
    id: number;
    searchedOn: Date;
    filterItem: FilterItem;
    saved: boolean;
    name: string;
    smartSearchNodeID: string;

    constructor(id?: number, searchedOn?: Date, filterItem?: FilterItem, saved?: boolean, name?: string, smartSearchNodeID?: string) {
        this.id = id ?? 0;
        this.searchedOn = searchedOn ?? new Date();
        this.filterItem = filterItem ?? {
            filterCriteria: null,
            andCriteria: [],
            orCriteria: []
        };
        this.saved = saved ?? false;
        this.name = name ?? '';
        this.smartSearchNodeID = smartSearchNodeID ?? '';
    }
}

export class RoleQuery {
    accountId: number;
    roleTypes: RoleEnum[];
    userLicenseTypes: UserLicenseEnum[];
    order: OrderEnum | null;
    searchCriteria: string | null;
    start: number | null;
    count: number | null;

    constructor(accountId: number, roleTypes: RoleEnum[], userLicenseTypes?: UserLicenseEnum[], order?: OrderEnum, searchCriteria?: string, start?: number, count?: number) {
        this.accountId = accountId;
        this.roleTypes = roleTypes;
        this.userLicenseTypes = userLicenseTypes ?? [];
        this.order = order ?? null;
        this.searchCriteria = searchCriteria ?? null;
        this.start = start ?? null;
        this.count = count ?? null;
    }
}
