import { MDFieldTypes } from '../types/MDFieldTypes';

export const useMetadataUtilities = () => {

    function createInitialMDField() {
        return { id: '', name: '', type: MDFieldTypes.Text };
    }

    function createInitialMDGroup() {
        return { id: '', name: '', metadataFields: [] };
    }

    return {
        createInitialMDField,
        createInitialMDGroup
    };
};