export enum FileQueueStatusEnum {
    NeedsIndexing = 0,
    ProcessingIndexing = 1,
    FailedToIndex = 2,
    // File OCR'ing Statuses
    NeedsOCR = 10,
    ProcessingOCR = 11,
    FailedToOCR = 13,
}

export interface IFileQueueItem {
    id: number;
    nodeID: number;
    accountID: number;
    fileInfoID: number;
    queuedOn: Date;
    currentStatus: FileQueueStatusEnum;
    previousStatus?: FileQueueStatusEnum;
    fileName: string;
    tryCountRemaining: number;
    errorMessage: string;
}

export interface IFileProcessingQuery {
    accountId: number,
    start: number,
    count: number
}
