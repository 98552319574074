import { Api } from './';
import { AxiosResponse } from 'axios';
import { AccountFeatureUsage } from '../types/AccountFeatureUsageTypes';

export const useAccountFeatureUsageApi = () => {

    const getAccountFeatureUsageByAccountId = async (accountId:number): Promise<AccountFeatureUsage[]> => {
        const resp: AxiosResponse = await Api.get(`api/accountFeatureUsage/${accountId}`);
        return resp.data;
    };

    return {
        getAccountFeatureUsageByAccountId,
    };
};
