import React from 'react';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { TourType } from '../../../../types/TourTypes';
import { RoutePath } from '../../../../types/RoutePaths';
import { AtlantisTour, AtlantisTourStep } from '../../AtlantisTour/AtlantisTour';

export const CreateNewUser = () => {

    //const { t } = useSafeTranslation(TranslationFiles.WelcomeTour);

    const { isTourOpen } = useLayerContext();

    const tourType = TourType.CreateNewUser;

    const steps: AtlantisTourStep[] = [
        {
            selector: '[data-id=sideNavAllFeatures] > li > span',
            content: 'Open all features panel',
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.Users && window.location.pathname !== RoutePath.UserNew && !document.querySelector('[data-id="allFeatures/user"]')
        },
        {
            selector: '[data-id="allFeatures/user"]',
            content: 'Select Users',
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.Users && window.location.pathname !== RoutePath.UserNew,
            mutationObservables: ['[data-id="allFeatures/user"]']
        },
        {
            selector: '[data-id=btnAddUser]',
            content: 'Select \'Add User\'',
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.UserNew,
            mutationObservables: ['[data-id=btnAddUser]']
        },
        {
            selector: '[data-id=userIframe]',
            content: 'Enter the user\'s email, and assign any profile information you want to associate with them. Finish by selecting a user type, and granting any system permissions then clicking \'Create\''
        }
    ];

    return (
        <AtlantisTour steps={steps} isOpen={isTourOpen(tourType)} tourType={tourType} />
    );
};
