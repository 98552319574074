import { Api } from './';
import { AxiosResponse } from 'axios';
import { IUtopiaTaskCount } from '../types/ToDoTypes';
import { IUtopiaNotification, NotificationStatusEnum, IUtopiaNotificationsQueryParams } from '../types/UtopiaNotificationTypes';

export const useNotificationsApi = () => {

    const getUserNotifications = async (notificationParams: IUtopiaNotificationsQueryParams): Promise<IUtopiaNotification[]> => {
        const resp: AxiosResponse = await Api.get(`api/Notification?${notificationParams.notificationStatuses.map((notificationStatus) => `notificationStatuses=${notificationStatus}&`).join('')}orderBy=${notificationParams.orderBy}&start=${notificationParams.start}&count=${notificationParams.count}`);
        return resp.data;
    };

    const getUserNotificationsCount = async (notificationStatuses: NotificationStatusEnum[]) => {
        const resp: AxiosResponse = await Api.get(`api/Notification/Count?${notificationStatuses.map((notificationStatus) => `notificationStatuses=${notificationStatus}&`).join('')}`);
        return resp.data as IUtopiaTaskCount;
    };

    return {
        getUserNotifications,
        getUserNotificationsCount,
    };
};