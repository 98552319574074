import React, { useEffect, useState } from 'react';
import { useWindowWidth } from './useWindowWidth';
import { useAuthContext } from '../auth';

export const useResizableColUtilities = () => {
    const MIN_EXPANDED_WIDTH = 375;
    const PX_RESERVED_FOR_UTOPIA_IFRAME = 890;
    const DEFAULT_EXPANDED_WIDTH = 425;
    const MIN_COLLAPSED_WIDTH = 40;

    const { windowWidth } = useWindowWidth();
    const { atlantisSideSheetEnabled } = useAuthContext();

    const [sideSheetEnabled, setSideSheetEnabled] = useState(false);
    const [sideSheetWidth, setSidesheetWidth] = useState(MIN_COLLAPSED_WIDTH);
    const [canResize, setCanResize] = useState(false);
    const [maxSideSheetExpandedWidth, setMaxSideSheetExpandedWidth] = useState(windowWidth - PX_RESERVED_FOR_UTOPIA_IFRAME);
    const [showOverlay, setShowOverlay] = useState(false);

    const onSidesheetCollapse = () => {
        setSidesheetWidth(MIN_COLLAPSED_WIDTH);
        setCanResize(false);
    };

    const onSidesheetExpand = () => {
        if (!canResize) {
            if (DEFAULT_EXPANDED_WIDTH > maxSideSheetExpandedWidth) {
                setSidesheetWidth(MIN_EXPANDED_WIDTH);
            } else {
                setSidesheetWidth(DEFAULT_EXPANDED_WIDTH);
            }
            setCanResize(true);
        }
    };

    const handleSideSheetResize = (deltaX: number) => {
        const newWidth = sideSheetWidth + deltaX;
        if (newWidth >= MIN_EXPANDED_WIDTH && newWidth <= maxSideSheetExpandedWidth) {
            setSidesheetWidth(newWidth);
        }
    };

    const getRowSubClasses = (name: string) => {
        let styling = `${name}-row`;
        if (!!sideSheetEnabled) {
            styling += ` ${name}-row-width-with-sidesheet`;
            styling += (sideSheetWidth > MIN_COLLAPSED_WIDTH ? ` ${name}-row-width-with-sidesheet-open` : ` ${name}-row-width-with-sidesheet-closed`);
        }
        return styling;
    };

    const getSideSheetEnabled = () => { return sideSheetEnabled; };
    const getSideSheetWidth = () => { return sideSheetWidth; };
    const getCanResize = () => { return canResize; };
    const getShowOverlay = () => { return showOverlay; };

    useEffect(() => {
        const updateMaxExpandedWidth = () => {
            const newMax = windowWidth - PX_RESERVED_FOR_UTOPIA_IFRAME;
            setMaxSideSheetExpandedWidth(newMax);
            if (sideSheetWidth > newMax) {
                setSidesheetWidth((newMax > MIN_EXPANDED_WIDTH ? newMax : MIN_EXPANDED_WIDTH));
            }
        };

        updateMaxExpandedWidth();
    }, [windowWidth]);

    useEffect(() => {
        !!atlantisSideSheetEnabled ? setSideSheetEnabled(true) : setSideSheetEnabled(false);
    }, [atlantisSideSheetEnabled]);

    return {
        MIN_EXPANDED_WIDTH,
        MIN_COLLAPSED_WIDTH,
        onSidesheetCollapse,
        onSidesheetExpand,
        handleSideSheetResize,
        getRowSubClasses,
        getSideSheetEnabled,
        getSideSheetWidth,
        getCanResize,
        getShowOverlay,
        setShowOverlay,
    };
};