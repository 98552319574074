import { RoutePath } from '../../types/RoutePaths';
import { useDocumentsRouting } from './useDocumentsRouting';
import { useDocRequestsRouting } from './useDocRequestsRouting';
import { useESignatureRouting } from './useESignatureRouting';
import { useWorkflowRouting } from './useWorkflowRouting';
import { useUsersRouting } from './useUsersRouting';
import { useCheckedOutRouting } from './useCheckedOutRouting';
import { useFolderTemplatesRouting } from './useFolderTemplatesRouting';
import { useUserSettingsRouting } from './useUserSettingsRouting';
import { useMetadataRouting } from './useMetadataRouting';
import { useProfilesRouting } from './useProfilesRouting';
import { useAccountSettingsRouting } from './useAccountSettingsRouting';
import { useSecurityPoliciesRouting } from './useSecurityPoliciesRouting';
import { useSalesforceRouting } from './useSalesforceRouting';
import { useUtilitiesRouting } from './useUtilitiesRouting';
import { useToDoListRouting } from './useToDoListRouting';
import { useSearchRouting } from './useSearchRouting';
import { useHomeRouting } from './useHomeRouting';
import { useReportsRouting } from './useReportsRouting';
import { useRecycleBinRouting } from './useRecycleBinRouting';
import { useFileQueueRouting } from './useFileQueueRouting';
import { useFormsRouting } from './useFormsRouting';
import { useGovernanceRouting } from './useGovernanceRouting';
import { useAuditLogsRouting } from './useAuditLogsRouting';
import { useRecentsRouting } from './useRecentsRouting';
import { useWorkYouCareRouting } from './useWorkYouCareRouting';
import { useSystemLogsRouting } from './useSystemLogsRouting';

export const useRouting = () => {

    function doNotRedirectToRoute(route: string) {
        const doNotGoToRoutes = [RoutePath.Login, RoutePath.PageNotFound];
        return doNotGoToRoutes.some(i => !!route?.startsWith(i));
    }

    return {
        doNotRedirectToRoute,
        ...useHomeRouting(),
        ...useDocumentsRouting(),
        ...useDocRequestsRouting(),
        ...useESignatureRouting(),
        ...useWorkflowRouting(),
        ...useReportsRouting(),
        ...useCheckedOutRouting(),
        ...useRecycleBinRouting(),
        ...useFileQueueRouting(),
        ...useFolderTemplatesRouting(),
        ...useUserSettingsRouting(),
        ...useMetadataRouting(),
        ...useProfilesRouting(),
        ...useFormsRouting(),
        ...useGovernanceRouting(),
        ...useUsersRouting(),
        ...useAuditLogsRouting(),
        ...useAccountSettingsRouting(),
        ...useSecurityPoliciesRouting(),
        ...useSalesforceRouting(),
        ...useUtilitiesRouting(),
        ...useSearchRouting(),
        ...useRecentsRouting(),
        ...useWorkYouCareRouting(),
        ...useToDoListRouting(),
        ...useSystemLogsRouting(),
    };
};
