import { UserLicenseEnum, SystemPermissions } from '../auth/AuthenticationTypes';
import { INodePermission } from './NodePermissionTypes';

// Enums
export enum DaysOfWeekEnum {
    Sunday = 1,
    Monday = 2,
    Tuesday = 4,
    Wednesday = 8,
    Thursday = 16,
    Friday = 32,
    Saturday = 64,
}

export enum NodeType {
    Account = 1,
    Shared = 2,
    Cabinet = 4,
    Drawer = 5,
    Folder = 6,
    File = 7,
    TemplateLibrary = 11,
    Template = 12,
    TemplateFolder = 13,
    TemplateFile = 14,
    PredefinedDocument = 15,
    GDWorkspace = 16,
    ODWorkspace = 17,
    Record = 18,
}

export enum OrderEnum {
    DefaultAscending = 0,
    DefaultDescending = 1,
    NameAscending = 2,
    NameDescending = 3,
    CreatedByAscending = 4,
    CreatedByDescending = 5,
    CreatedOnAscending = 6,
    CreatedOnDescending = 7,
    TypeAscending = 8,
    TypeDescending = 9,
    DeletedOnAscending = 10,
    DeletedOnDescending = 11,
    UserNameAscending = 12,
    UserNameDescending = 13,
    SizeAscending = 14,
    SizeDescending = 15,
    ModifiedOnAscending = 16,
    ModifiedOnDescending = 17,
}

export enum SystemActionEnum {
    Create = 1,
    Read = 2,
    Update = 3,
    Delete = 4,

    ApplyTemplate = 5,
    Restore = 6,
    Purged = 7,

    Copy = 8,

    Append = 9,

    Expired = 10,
    Failed = 11,
    PreNotify = 12,
    Move = 13,
    Watermark = 14,
    PermissionPushdown = 15,

    FileUpload = 100,
    FileDownload = 101,
    FileCheckOut = 102,
    FileCheckIn = 103,
    FileView = 104,

    NodeLegacyMappingCreated = 204,

    EmailQueued = 300,
    EmailSent = 301,

    Share = 400,

    AddedToAccount = 500,

    PasswordSet = 600,
    PasswordUpdated = 601,
    PasswordRemoved = 602,

    Login = 700,
    FailedLogin = 701,

    Completed = 800,

    WorkflowCurrentStageChanged = 900,
    OnApproval = 901,
    Approved = 902,
    Rejected = 903,
    BeforeTimeframe = 904,
    AfterPastDue = 905,

    SecurityPolicyChanged = 1000,
    ApproveRequest = 1001,
    Assignment = 1002,

    Migrate = 1100,

    Suspended = 1200,
    InProgress = 1201,
}

export enum SystemTypeEnum {
    Node = 0,
    NodeShare = 1,
    Role = 2,
    FileInfo = 3,
    NodeComment = 5,
    Email = 6,
    DocumentRequest = 7,
    TimeTrigger = 8,
    EventTrigger = 9,
    Notification = 10,
    Permission = 11,
    Collection = 12,
    ProgressNotification = 13,
    WorkflowInstance = 14,
    WorkflowInstanceStage = 15,
    WorkflowInstanceStep = 16,
    WorkflowInstanceAsset = 17,
    NodeAnnotation = 18,
    NodeProfile = 19,
    EmailFilesRequest = 20,
    ESignatureTransaction = 21,
    AccessLink = 22,
    FormFill = 23,
    ApiCallout = 24,
    UpdateIsAvailable = 25,
    Account = 26,
}

export interface IClientApplication {
    id: string;
    name: string;
    website: string;
    description: string;
    organization: string;
}

export interface ICheckedOutFile {
    node: INode;
    checkedOutBy: string;
    checkedOutOn: Date;
    path: string;
    o365ClientLock: string;
    o365ClientLockedOn: Date | null;
}

export interface ICheckOutInfo {
    nodeId: number;
    checkedOutByRoleID: number;
    checkedOutOn: Date;
    o365ClientLock: string;
    o365ClientLockedOn: Date;
}

//Was previously IFileExtension, but we needed full object from Utopia
export interface IFileInfo {
    accountId: number;
    id: number;
    fileExtension: string;
    sizeInBytes: number;
    createdOn: Date;
    uploadSuccessful: boolean;
    uploadIdentifier: string;
    checkedOutByRoleID?: number;
    formFillDefinitionId?: number;
    generatedFromFileInfoID?: number;
    needsFlattening: boolean;
}
export interface IFileVersion {
    id: number;
    nodeId: number;
    node: INode;
    isActive: boolean;
    createdByUserId: number;
    createdByUser: string;
    createdOn: string;
    size: number;
    formFillDefinitionId: number;
    allowPreview: boolean;
    needsFlattening: boolean;
    nodeComment?: { text: string; };
}

export interface INode {
    id: string;
    accountID: number;
    parentID: string;
    name: string;
    size: number;
    systemType: NodeType;
    fileInfo: IFileInfo;
    createdOn: string;
    createdBy: string;
    createdByUserId: number;
    modifiedOn: string;
    hasChildren: boolean;
    checkoutInfo: ICheckOutInfo;
    instances?: number;
    permission: INodePermission;
}

export interface INodeDetails {
    templateNames: string[];
    size: number;
    fileCount: number;
    containerCount: number;
}

export enum RoleEnum {
    Guest = 1, // OBSOLETE
    User = 2,
    Group = 3,
}

export interface Role {
    id: number;
    accountID: number;
    userID?: number;
    profileID?: number;
    roleType: RoleEnum;
    userName: string;
    name: string;
    license?: UserLicenseEnum;
    systemPermissions: SystemPermissions;
    memberOfRoles: Role[];
    roleMembers: Role[];
    securityPolicyID?: number;
    roleManagerID?: number;
    anonymous: boolean;
    includeInSalesforceMapping: boolean;
    ldapID: number;
}

export interface AccountRoleCounts {
    userCount: number;
    guestCount: number;
    accountGroupData: AccountGroupData[];
}

export interface AccountGroupData {
    groupId: number;
    memberCount: number;
}

export interface IAccessLink {
    id: number;
    accountID: number;
    role: Role;
    createdByUserID?: number;
    createdByUsername: string;
    systemType: SystemTypeEnum;
    createdOn: Date;
    modifiedOn: Date;
    nodePermissions: INodePermission[];
}

export interface IUserFeedbackInfo {
    previewerFeedbackAcknowledged: boolean;
    hasSeenSurveyModal: boolean;
    hasSeenSurveyReprompt: boolean;
}

export interface IBatchItem {
    batchObject: any;
    clientIdentifier: string;
}

export interface IAccount {
    id: number;
    identifier: string;
    instanceId: string;
    name: string;
}

export interface IFilePasswordOperation {
    nodeID: number;
    currentPassword: string;
    newPassword?: string;
    operation: FilePasswordOperationEnum;
}

export enum FilePasswordOperationEnum {
    CheckPassword = 0,
    ApplyPassword = 1,
    UpdatePassword = 2,
    DeletePassword = 3,
}

export interface IFilePasswordOperationResponse {
    result: FilePasswordOperationResultEnum;
    errorMessage: string;
}

export enum FilePasswordOperationResultEnum
{
    Completed = 0,
    Failed = 1,
    ValidPassword = 2,
    InvalidPassword = 3,
}