import React, { useEffect, useState } from 'react';
import { Alert, Button, FormGroup, Input, Label, Modal, Text } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, UsersTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { Role } from '../../../../types/CommonTypes';
import { ThemeEnum } from '../../../../hooks/useColors';
import { NestedCollapse } from '../../../../components/NestedCollapse';
import { useIcons } from '../../../../hooks/useIcons';
import { ToastMessage } from '../../../../hooks/useToastMessages';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import './DeleteUserConfirmationModal.css';

interface DeleteUserConfirmationModalProps {
    onClose: () => void;
    roles: Role[];
}

interface DeleteMessageToUtopia {
    type: string;
    roles: Role[];
    revoke: boolean;
}

export const DeleteUserConfirmationModal = ({ onClose, roles }: DeleteUserConfirmationModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Users);
    const { spinnerIconProps } = useIcons();
    const { showToastMessage } = useLayerContext();

    const [hasError, setHasError] = useState(false);
    const [revoke, setRevoke] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => setRevoke(e.target.checked);

    const handleConfirm = () => {
        setShowSpinner(true);
        const userIframe = document.querySelector('[data-id="userIframe"]') as HTMLIFrameElement;
        userIframe?.contentWindow?.postMessage({ type: 'CONFIRM_DELETE_USER', roles, revoke } as DeleteMessageToUtopia, '*');
    };

    const handleResponse = (e: MessageEvent) => {
        const { type, success, revoke, roles } = e.data;
        if (type === 'DELETE_USER_RESULT') {
            setShowSpinner(false);

            if (!success) {
                setHasError(true);
                return;
            }

            const toastData = {
                color: ThemeEnum.Success,
                identifier: 'delete-user-result-toast',
                message: revoke
                    ? t(UsersTKeys.DeleteRevokeSuccess, {
                          count: roles?.length,
                          displayName: roles[0].name,
                      })
                    : t(UsersTKeys.DeleteSuccess, {
                          count: roles?.length,
                          displayName: roles[0].name,
                      }),
                timeout: 5000,
            } as ToastMessage;

            showToastMessage(toastData);
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleResponse);

        return () => {
            window.removeEventListener('message', handleResponse);
        };
    }, []);

    return (
        <Modal isOpen={true} toggle={onClose} title={t(UsersTKeys.DeleteModalTitle)}>
            <Modal.Body>
                {hasError && (
                    <Alert color={ThemeEnum.Danger} className='mb-3'>
                        {t(UsersTKeys.DeleteError, { count: roles?.length })}
                    </Alert>
                )}
                <Text className='d-block mt-2 mb-4'>{t(UsersTKeys.DeleteModalBody, { count: roles?.length })}</Text>
                <ul className='modal-body-scrollable'>
                    {roles?.map((role, index) => (
                        <li key={index}>{role.name}</li>
                    ))}
                </ul>
                <NestedCollapse title={t(UsersTKeys.DeleteAdvancedOptionLabel)} innerClassName=''>
                    <FormGroup check className='pointer'>
                        <Label check>
                            <Input type='checkbox' checked={revoke} onChange={(e) => handleCheckboxChange(e)} />
                            {t(UsersTKeys.DeleteAdvancedOption, { count: roles?.length })}
                        </Label>
                    </FormGroup>
                    <Alert color={ThemeEnum.Warning} className='mt-2 warning'>
                        {t(UsersTKeys.DeleteWarning)}
                    </Alert>
                </NestedCollapse>
            </Modal.Body>
            <Modal.Footer>
                <Button emphasis='med' onClick={onClose} disabled={showSpinner}>
                    {t(UsersTKeys.Cancel)}
                </Button>
                <Button color={ThemeEnum.Danger} icon={showSpinner ? spinnerIconProps : undefined} onClick={handleConfirm} disabled={showSpinner}>
                    {t(UsersTKeys.Confirm)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
