import { Api } from './';
import { AxiosResponse } from 'axios';
import { IAccessLink } from '../types/CommonTypes';

export const useAccessLinksApi = () => {

    const getAccessLinksByNodeIdAsync = async (accountId: string, nodeId: string): Promise<IAccessLink[]> => {
        const resp: AxiosResponse = await Api.get(`api/AccessLinks/${accountId}/${nodeId}`);
        return resp.data;
    };

    return {
        getAccessLinksByNodeIdAsync,
    };
};
