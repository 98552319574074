import React from 'react';
import { Card } from '@efilecabinet/efc-atlantis-components';
import { Col, Row } from 'reactstrap';

import './AccountGuestWelcome.css';

export interface IAccountGuestWelcomeProps {
    className?: string;
    isModal: boolean;
    accountLogoImgString?: string;
    accountMessage?: string;
}

export const AccountGuestWelcome = (props: IAccountGuestWelcomeProps) => {
    const { className, isModal, accountLogoImgString, accountMessage} = props;

    const includeBorder = () => {
        return !!accountMessage && isModal;
    };

    return (
        <>
            <Card fluid borderless className={`${className} ${isModal ? 'account-guest-welcome-card-spacing' : ''} ${includeBorder() ? 'account-guest-welcome-custom-border' : ''}`}>
                <Card.Body className={`${isModal ? 'reduced-card-padding' : ''}`}>
                    <Row>
                        {!!accountLogoImgString &&
                            <Col className='account-guest-welcome-image-spacing'>
                                <img src={accountLogoImgString} className='account-guest-welcome-image' alt='Welcome' />
                            </Col>
                        }
                        {(!!accountMessage) ?
                            <Col className='account-guest-welcome-message'>
                                <Card.Title size='xxl' className='text-weight'>
                                    {accountMessage}
                                </Card.Title>
                            </Col> 
                            : 
                            <></>
                        }
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
