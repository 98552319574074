import React from 'react';
import { Modal, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { ScreenSize } from '../../../../types/BootstrapTypes';
import { useBootstrapBreakpoints } from '../../../../hooks/useBootstrapBreakpoints';

interface ConfirmationModalProps {
    closeModalFn: () => void;
    onClose: (isConfirmed: boolean) => void;
    titleText: string;
    bodyText: string;
    confirmCtaText: string;
    confirmCtaColor?: 'primary' | 'danger';
    denyCtaText: string;
}

export const ConfirmationModal = ({ closeModalFn, onClose, titleText, bodyText, confirmCtaText, confirmCtaColor = 'primary', denyCtaText }: ConfirmationModalProps) => {

    const { screenSize } = useBootstrapBreakpoints();

    const handleClose = () => {
        // click x or off canvas to close modal
        onClose(false);
        closeModalFn();
    };

    const handleDeny = () => {
        // click cta that is med emphasis
        onClose(false);
        closeModalFn();
    };

    const handleConfirm = () => {
        // click cta that is high emphasis
        onClose(true);
        closeModalFn();
    };

    const getCtas = (): EfcCallToActionInfo[] => {
        return [
            {
                text: denyCtaText,
                icon: undefined,
                emphasis: 'med',
                color: 'primary',
                disabled: false,
                onClick: handleDeny,
                dataId: 'btnNegativeConfirmation',
            },
            {
                text: confirmCtaText,
                icon: undefined,
                emphasis: 'high',
                color: confirmCtaColor,
                disabled: false,
                onClick: handleConfirm,
                dataId: 'btnAffirmativeConfirmation',
            }
        ];
    };

    return (
        <Modal
            size='md'
            isOpen={true}
            toggle={handleClose}
            ctas={getCtas()}
            unmountOnClose={true}
            title={titleText}
            className={`${screenSize < ScreenSize.s ? 'md-field-mobile-modal-spacing' : ''}`}>
            <Modal.Body>
                {bodyText}
            </Modal.Body>
        </Modal>
    );
};