export enum ProfileItemTypeEnum {
    Text = 0,
    Email = 1,
    Number = 2,
    Date = 3,
    PhoneNumber = 4,
    CheckBox = 5,
    Currency = 6,
    Memo = 7,
    PresetValues = 8,
    Percentage = 9,
    HiddenText = 10,
}

export interface IProfile {
    id: number;
    accountID: number;
    name: string;
    isDeleted: boolean;
    profileItems: IProfileItem[];
}

export interface INodeProfile {
    profileID?: number;
    nodeID?: number;
    accountID: number;
    profileName?: string;
    profileItems: IProfileItem[];
    inherited: boolean;
    profileNodeId?: number;
}

export interface IProfileItemPresetValue {
    id: number;
    value: string;
    order: number;
}

export interface IProfileItemHiddenCharacterRange {
    id: number;
    startingPosition: number;
    endingPosition: number;
}

export interface IProfileItemHiddenCharacterConfiguration {
    id: number;
    hideSpecifiedCharacters: boolean;
    fromBeginning?: number;
    fromEnd?: number;
    characterRanges: IProfileItemHiddenCharacterRange[];
}

export interface IProfileItem {
    id: number;
    accountID: number;
    name: string;
    type: ProfileItemTypeEnum;
    value: any;
    required: boolean;
    joinID: number;
    valueID: number;
    include: boolean;
    position?: number;
    presetValueID?: number;
    presetValues: IProfileItemPresetValue[];
    hiddenCharacterID?: number;
    hiddenCharacters: IProfileItemHiddenCharacterConfiguration;
    isVisible: boolean;
    isDeleted: boolean;
    profileID?: number;
    visibleDecimalPlaces?: number;
}