import React, { ReactNode, useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { VertNavState, useVertNavState } from '../../hooks/useVertNavState';
import { useAuthContext } from '../../auth';
import { useFeedbackApi } from '../../api/useFeedbackApi';
import { RoutePath } from '../../types/RoutePaths';
import { ExperienceTypeEnum } from '../../auth/AuthenticationTypes';
import { NavMenu } from '../Nav/NavMenu/NavMenu';
import { VerticalNav } from '../Nav/VerticalNav/VerticalNav';
import { BottomNav } from '../Nav/BottomNav/BottomNav';
import { AccountGuestWelcomeModal } from './AccountGuestWelcomeModal/AccountGuestWelcomeModal';
import { AdminNewExperienceWelcomeModal } from './AccountOnboardingWelcomeModal/AdminNewExperienceWelcomeModal/AdminNewExperienceWelcomeModal';
import { NewExperienceWelcomeModal } from './AccountOnboardingWelcomeModal/NewExperienceWelcomeModal/NewExperienceWelcomeModal';
import { SurveyModal } from './SurveyModal/SurveyModal';
import { SurveyReprompt } from './SurveyModal/SurveyReprompt/SurveyReprompt';
import { NodeSideSheet } from '../NodeSideSheet/NodeSideSheet';
import { useResizableColUtilities } from '../../hooks/useResizableColUtilities';
import { useNavStateStyling } from '../../hooks/useNavStateStyling';
import { NodeDetailsProvider } from '../../context/nodeDetails/NodeDetailsProvider';
import './Layout.css';

interface LayoutProps {
    children: ReactNode;
}

export const Layout = (props: LayoutProps) => {

    const MIN_LOGIN_SESSION_REMINDER_COUNT = 3;

    const { children } = props;

    const { updateSurveyModal, updateSurveyReprompt } = useFeedbackApi();

    const { vertNavState, toggleAndSaveVertNavState, closeAndSaveVertNavState } = useVertNavState();
    const { experienceType, optInInfo, showAdminAccountOnboarding, authUser, userFeedbackInfo } = useAuthContext();
    const { getRowSubClasses, getSideSheetEnabled } = useResizableColUtilities();
    const { getPagesSubClasses, getPageContainerSubClasses } = useNavStateStyling();

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showGuestWelcomeModal, setShowGuestWelcomeModal] = useState(false);
    const [showSurveyModal, setShowSurveyModal] = useState(false);
    const [showSurveyReprompt, setShowSurveyReprompt] = useState(false);

    const USER_LOGIN_COUNT = localStorage.getItem(`login-count-${authUser?.userID}`) ?? '0';

    function toggleWelcomeModal() {
        setShowWelcomeModal((prevState) => !prevState);
    }

    function toggleGuestWelcomeModal() {
        setShowGuestWelcomeModal((prevState) => !prevState);
    }

    const toggleSurveyModal = () => setShowSurveyModal((prevState) => !prevState);
    const toggleSurveyReprompt = () => setShowSurveyReprompt((prevState) => !prevState);

    const isOutsideReminderCount = () => {
        const reminderCount = localStorage.getItem(`admin-welcome-remind-me-${authUser?.userID}`) ?? '-1';

        if (reminderCount === '-1') {
            return true;
        }

        const total = +USER_LOGIN_COUNT - +reminderCount;

        return total >= MIN_LOGIN_SESSION_REMINDER_COUNT;
    };

    const shouldShowWelcomeModal = () => {
        const userAccessToken = localStorage.getItem('guest-welcome-modal') ?? '';
        return userAccessToken != authUser?.accessToken;
    };

    const shouldShowSurveyModal = async () => {
        if (location.pathname == RoutePath.Home &&
            +USER_LOGIN_COUNT % MIN_LOGIN_SESSION_REMINDER_COUNT === 0 &&
            +USER_LOGIN_COUNT > 0 &&
            !showWelcomeModal &&
            !userFeedbackInfo?.hasSeenSurveyModal
        ) {
            setShowSurveyModal(true);
            await updateSurveyModal(authUser?.userID as number);
        }
    };

    const shouldShowSurveyReprompt = async () => {
        if (location.pathname == RoutePath.Home &&
            +USER_LOGIN_COUNT % MIN_LOGIN_SESSION_REMINDER_COUNT === 0 &&
            +USER_LOGIN_COUNT > 0 &&
            !showWelcomeModal &&
            !showSurveyModal &&
            !userFeedbackInfo?.hasSeenSurveyReprompt
        ) {
            setShowSurveyReprompt(true);
            await updateSurveyReprompt(authUser?.userID as number);
        }
    };

    const showGreyBackground = () => {
        return (location.pathname == RoutePath.Home
                || location.pathname.includes(RoutePath.Documents)
                || location.pathname.includes(RoutePath.MetadataIndex)
                || location.pathname == RoutePath.Search)
            ||
            (getSideSheetEnabled()
                && (location.pathname.includes(RoutePath.FolderTemplatesIndex)));
    };

    useEffect(() => {
        if (!!optInInfo?.showWelcomeModal && isOutsideReminderCount()) {
            setShowWelcomeModal(true);
        }
    }, [optInInfo?.showWelcomeModal]);

    useEffect(() => {
        if (experienceType == ExperienceTypeEnum.Standard) {
            setShowGuestWelcomeModal(false);
            if (!!userFeedbackInfo) shouldShowSurveyModal();
            if (!!userFeedbackInfo) shouldShowSurveyReprompt();
        } else if (location.pathname != RoutePath.Home && shouldShowWelcomeModal()) {
            setShowGuestWelcomeModal(true);
            localStorage.setItem('guest-welcome-modal', authUser?.accessToken as string);
        } else if (location.pathname == RoutePath.Home) {
            localStorage.setItem('guest-welcome-modal', authUser?.accessToken as string);
        }
    }, [experienceType, userFeedbackInfo]);

    return (
        <div className='layout'>
            {vertNavState != VertNavState.Hidden && <VerticalNav className='vertical-nav' toggleAndSaveVertNavState={toggleAndSaveVertNavState} isCollapsed={vertNavState == VertNavState.Collapsed} closeAndSaveVertNavState={closeAndSaveVertNavState} />}

            <NavMenu vertNavState={vertNavState} />

            <div className={`pages ${getPagesSubClasses(vertNavState)} ${showGreyBackground() ? 'grey-background' : '' }`}>
                <Container fluid className={`page-container ${getPageContainerSubClasses(vertNavState)}`}>
                    <Row className={getRowSubClasses('layout')} >
                        <Col className='h-100'>
                            {children}
                        </Col>

                        <NodeDetailsProvider>
                            <NodeSideSheet showFullScreenButton={true} enablePreviewer={true} />
                        </NodeDetailsProvider>
                    </Row>
                </Container>
            </div>

            {vertNavState == VertNavState.Hidden && experienceType != ExperienceTypeEnum.Anonymous && <BottomNav />}

            {showAdminAccountOnboarding ? <AdminNewExperienceWelcomeModal showWelcomeModal={showWelcomeModal} toggleWelcome={toggleWelcomeModal} /> : <NewExperienceWelcomeModal showWelcomeModal={showWelcomeModal} toggleWelcome={toggleWelcomeModal} />}

            <AccountGuestWelcomeModal showWelcomeModal={showGuestWelcomeModal} toggleWelcome={toggleGuestWelcomeModal} />

            {showSurveyModal && <SurveyModal isOpen={showSurveyModal} toggle={toggleSurveyModal} />}
            {showSurveyReprompt && <SurveyReprompt isOpen={showSurveyReprompt} toggle={toggleSurveyReprompt} />}
        </div>
    );
};