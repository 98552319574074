import { Api } from './';
import { AxiosResponse } from 'axios';
import { IFilePasswordOperation, IFilePasswordOperationResponse } from '../types/CommonTypes';

export const useFilePasswordApi = () => {

    const nodeHasFilePasswordAsync = async (nodeId: string): Promise<boolean> => {
        const resp: AxiosResponse = await Api.get(`api/FilePassword/${nodeId}`);
        return resp.data;
    };

    const performFilePasswordOperationAsync = async (filePasswordOperation: IFilePasswordOperation): Promise<IFilePasswordOperationResponse> => {
        const resp: AxiosResponse = await Api.post('api/FilePassword', filePasswordOperation);
        return resp.data;
    };

    return {
        nodeHasFilePasswordAsync,
        performFilePasswordOperationAsync,
    };
};
