import React from 'react';
import { useBootstrapBreakpoints } from '../../../../hooks/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../types/BootstrapTypes';
import { useAuthContext } from '../../../../auth';
import { FileUploadNavMenuItem } from '../FileUpload';
import { HelpNavMenuItem } from '../Help';
import { RecentsNavMenuItem } from '../Recents';
import '../NavMenu.css';

export const AnonymousExperienceNavItems = () => {

    const { userCanViewDocuments } = useAuthContext();
    const { screenSize } = useBootstrapBreakpoints();

    return (
        <>
            {screenSize > ScreenSize.xs && (
                <>
                    <FileUploadNavMenuItem />
                    <HelpNavMenuItem />
                    {userCanViewDocuments &&
                        <RecentsNavMenuItem />}
                </>
            )}
        </>
    );
};
