import { ScreenSize } from '../types/BootstrapTypes';
import { useBootstrapBreakpoints } from './useBootstrapBreakpoints';

export const useCardSizes = () => {
    const { screenSize } = useBootstrapBreakpoints();

    const getCardTitleSize = () => {
        return screenSize > ScreenSize.m ? 'lg' : 'xl';
    };

    const getCardIconSize = () => {
        return screenSize > ScreenSize.m ? 'lg' : 'sm';
    };

    return {
        getCardTitleSize,
        getCardIconSize,
    };
};