import { useNavigate } from 'react-router';
import { RoutePath, SearchRouteParams } from '../../types/RoutePaths';

export const useSearchRouting = () => {

    const navigate = useNavigate();

    // Search

    const getLinkToSearch = () => {
        return RoutePath.Search;
    };

    const routeToSearch = () => {
        navigate(getLinkToSearch());
    };

    const routeToSearchFilterItemResults = (searchRequestFilterItem?: string) => {
        navigate(getLinkToSearch(), { state: { searchRequestFilterItem: searchRequestFilterItem } });
    };

    const routeToSearchInputResults = (searchInput: string) => {
        navigate(getLinkToSearch(), { state: { searchInputValue: searchInput } });
    };

    const routeToAdvancedSearch = () => {
        navigate(getLinkToSearch(), { state: { toggleAdvancedSearch: true } });
    };

    const routeToAdvancedSearchResults = (searchInput: string) => {
        navigate(getLinkToSearch(), { state: { searchInputValue: searchInput, toggleAdvancedSearch: true } });
    };

    // User Search

    const getLinkToUserSearch = (userSearchId: string) => {
        return RoutePath.UserSearch.replace(SearchRouteParams.UserSearchId, userSearchId);
    };

    const routeToUserSearchResults = (userSearchId: string) => {
        navigate(getLinkToUserSearch(userSearchId));
    };

    // Shared Search

    const getLinkToSharedSearch = (sharedSearchId: string) => {
        return RoutePath.SharedSearch.replace(SearchRouteParams.SharedSearchId, sharedSearchId);
    };

    const routeToSharedSearchResults = (sharedSearchId: string) => {
        navigate(getLinkToSharedSearch(sharedSearchId));
    };

    return {
        getLinkToSearch,
        getLinkToUserSearch,
        getLinkToSharedSearch,
        routeToSearch,
        routeToSearchFilterItemResults,
        routeToSearchInputResults,
        routeToAdvancedSearch,
        routeToAdvancedSearchResults,
        routeToUserSearchResults,
        routeToSharedSearchResults,
    };
};