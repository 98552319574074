import { Api } from './';

export const useESignatureTransactionApi = () => {

    const resendEmailAsync = async (transactionId: number): Promise<void> => {
        await Api.post('api/ESignatureTransaction/ResendEmail', { transactionId: transactionId });
    };

    return {
        resendEmailAsync,
    };
};
