import React from 'react';
import { EfcCallToActionInfo, Modal, Text, Input, EfcLink } from '@efilecabinet/efc-atlantis-components';
import { OptOutModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useGoToProductBoard } from '../../../../../hooks/useGoToProductBoard';
import { FeedbackFormProps, FeedbackType } from './FeedbackModal';
import { useForm } from '../../../../../hooks/useForm';
import { useAuthContext } from '../../../../../auth';
import { useSettingsAndFeatures } from '../../../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { useFeedbackApi } from '../../../../../api/useFeedbackApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../../../../hooks/useColors';
import './FeedbackModal.css';

export interface FeedbackModalProps {
    showOptOutModal: boolean;
    toggleModal: () => void;
    onOptOutSubmission: (optedOut: boolean) => void;
}

export const OptOutModal = ({ showOptOutModal, toggleModal, onOptOutSubmission }: FeedbackModalProps) => {

    const { t } = useSafeTranslation(TranslationFiles.OptOutModal);

    const { openProductBoardUpdatesInNewTab } = useGoToProductBoard();
    const { desktopApplicationInfo } = useSettingsAndFeatures();
    const { user, authUser, optInInfo } = useAuthContext();
    const { submitFeedback } = useFeedbackApi();
    const { atlantisPrimary } = useColors();

    const initFeedbackForm: FeedbackFormProps = {
        FeedbackType: FeedbackType.OptingOut,
        Message: '',
        Email: '',
        AccountId: 0,
        FeedbackRange: 0,
        Location: '',
        AtlantisOnly: false,
    };

    const feedbackForm = useForm<FeedbackFormProps>(initFeedbackForm);

    const handleSubmitFeedback = async () => {
        try {
            const feedback: FeedbackFormProps = {
                FeedbackType: feedbackForm.model.FeedbackType,
                Message: feedbackForm.model.Message,
                Email: user?.userName ?? '',
                AccountId: authUser?.accountID ?? 0,
                FeedbackRange: feedbackForm.model.FeedbackRange,
                Location: location.pathname,
                DesktopVersion: desktopApplicationInfo?.version ?? '',
                AtlantisOnly: !optInInfo?.optInAtlantisDateTime,
            };

            await submitFeedback(feedback);
            onOptOutSubmission(true);
        } catch (error) {
            console.log(error);
            onOptOutSubmission(false);
        }
    };

    const ctas: EfcCallToActionInfo[] = [
        { text: t(OptOutModalTKeys.Cancel), emphasis: 'med', onClick: toggleModal },
        { text: t(OptOutModalTKeys.CloseAndLeave), emphasis: 'high', onClick: handleSubmitFeedback },
    ];

    return (
        <Modal className='px-3 py-2 optOutModal' isOpen={showOptOutModal} ctas={ctas} title={t(OptOutModalTKeys.BeforeYouGo)} toggle={toggleModal}>
            <Modal.Body className='mb-5'>
                <Text semibold className='d-block mb-2'>
                    {t(OptOutModalTKeys.WhyAreYouLeaving)}
                </Text>
                <Text semibold>{t(OptOutModalTKeys.FeedbackHelpsUs)}</Text>
                <Input name='Message' type='textarea' className='mt-3 mb-4 optOutFeedback' value={feedbackForm.model.Message} onChange={feedbackForm.onPropChanged} />
                <Text semibold className='my-5'>
                    {t(OptOutModalTKeys.LearnMore)}
                    <EfcLink color='primary' onClick={openProductBoardUpdatesInNewTab}>
                        {t(OptOutModalTKeys.SeeUpdates)}
                        <FontAwesomeIcon icon='external-link' className='my-auto' color={atlantisPrimary} />
                    </EfcLink>
                </Text>
            </Modal.Body>
        </Modal>
    );
};
