import { useNavigate } from 'react-router';
import { RoutePath } from '../../types/RoutePaths';

export const useMetadataRouting = () => {

    const navigate = useNavigate();

    // Metadata Index

    const getLinkToMetadataIndex = () => {
        return RoutePath.MetadataIndex;
    };

    const routeToMetadataIndex = () => {
        navigate(getLinkToMetadataIndex());
    };

    // Metadata Fields

    const getLinkToMDFields = () => {
        return RoutePath.MDFields;
    };

    const routeToMDFields = () => {
        navigate(getLinkToMDFields());
    };

    // Metadata Groups

    const getLinkToMDGroups = () => {
        return RoutePath.MDGroups;
    };

    const routeToMDGroups = () => {
        navigate(getLinkToMDGroups());
    };

    return {
        getLinkToMetadataIndex,
        getLinkToMDFields,
        getLinkToMDGroups,
        routeToMetadataIndex,
        routeToMDFields,
        routeToMDGroups,
    };
};
