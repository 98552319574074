import React, { useEffect, useState, useRef } from 'react';
import { useAuthContext } from '../../auth';
import { useSystemLogs } from '../../hooks/useSystemLogs';
import './IFrame.css';

export interface TabInfoProps {
    path: string;
    altPaths?: string[];
    url?: string;
}

export interface IFrameProps {
    innerRef?: any;
    className?: string;
    hidden?: boolean;
    src?: string;
    name?: string;
    dataId?: string;
}

export const IFrame = (props: IFrameProps) => {
    const { name, className, innerRef, hidden, src, dataId, ...otherProps } = props;

    const [iframeWebAppHasLoaded, setIframeWebAppHasLoaded] = useState<boolean>(false);
    const [initialPendingFilesAdded, setInitialPendingFilesAdded] = useState<boolean>(false);

    const iframeRef = innerRef ?? useRef(null);
    const defaultIFrameName = 'atlantisIFrame';

    const { handleLogout, apiOperationCache, setApiOperationCache } = useAuthContext();
    const { addSystemLog } = useSystemLogs();

    const isClientAppLoadedEventForThisIFrame = (event: any) : boolean => {
        return event?.data == 'clientOnAppLoaded' && iframeRef?.current.contentWindow == event.source;
    };

    const shouldForwardApiOperationToIFrame = (apiOperation: any) : boolean => {
        return !apiOperation?.triggedByOnAppLoaded
            || apiOperation.operationName != 'addPendingFiles'
            || !initialPendingFilesAdded;
    };

    const isInitialPendingFilesOperation = (apiOperation: any): boolean =>  {
        return !initialPendingFilesAdded
            && apiOperation.operationName == 'addPendingFiles'
            && !!apiOperation?.triggedByOnAppLoaded
            && !!iframeWebAppHasLoaded;
    };

    useEffect(() => {
        const windowMessageHandler = (event: { data: any; origin: string; }) => {
            if (!iframeWebAppHasLoaded && isClientAppLoadedEventForThisIFrame(event)) {
                setIframeWebAppHasLoaded(true);
            } else if (event.data == 'logout') {
                handleLogout();
            } else if (event.data?.operationName == 'utopia-serverSystemLogs' || event.data?.operationName == 'utopia-clientSystemLogs') {
                addSystemLog(event.data);
            }
        };

        window.addEventListener('message', windowMessageHandler);

        return () => window.removeEventListener('message', windowMessageHandler);
    }, []);

    useEffect(() => {
        apiOperationCache.forEach((apiOperation) => {
            if (shouldForwardApiOperationToIFrame(apiOperation)) {
                iframeRef?.current?.contentWindow?.postMessage(apiOperation, '*');
            }
            if (isInitialPendingFilesOperation(apiOperation)) {
                setInitialPendingFilesAdded(true);
            }
            setApiOperationCache((oldApiOperationCache) => {
                const newApiOperationCache = new Set(oldApiOperationCache);
                newApiOperationCache.delete(apiOperation);
                return newApiOperationCache;
            });
        });
    }, [apiOperationCache]);

    return (
        <div className={className} hidden={hidden ?? false} {...otherProps}>
            <iframe name={name ?? defaultIFrameName} data-id={dataId} ref={iframeRef} src={src} height='100%' width='100%' allow='clipboard-read; clipboard-write' allowFullScreen/>
        </div>
    );
};
