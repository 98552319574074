import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { EfcCallToActionInfo, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { DocumentRequestRouteParams, DocumentRequestRoutePath, RoutePath } from '../../../types/RoutePaths';
import { TranslationFiles, useSafeTranslation, DocumentRequestsTKeys } from '../../../hooks/useSafeTranslation';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useIFramePathChangeListener } from '../../../hooks/useIFramePathChangeListener';
import { AccountFeatureUsageHeader } from '../../../components/AccountFeatureUsageHeader/AccountFeatureUsageHeader';
import { AccountFeatureEnum } from '../../../auth/AuthenticationTypes';
import { DocumentRequestType } from '../../../types/UploadLinkTypes';
import { useUploadLinkApi } from '../../../api/useUploadLinkApi';
import './DocumentRequests.css';

const IFrameRoutes: Map<DocumentRequestRoutePath, string> = new Map([
    [RoutePath.DocumentRequestIndex, '/DocumentRequests/inbox'],
    [RoutePath.DocumentRequestInbox, '/DocumentRequests/inbox'],
    [RoutePath.DocumentRequestNew, '/DocumentRequests/inbox/add/'],
    [RoutePath.DocumentRequestInboxEdit, `/DocumentRequests/inbox/view/${DocumentRequestRouteParams.InboxRequestId}`],
    [RoutePath.DocumentRequestSent, '/DocumentRequests/sent'],
    [RoutePath.DocumentRequestSentEdit, `/DocumentRequests/sent/view/${DocumentRequestRouteParams.SentRequestId}`],
    [RoutePath.DocumentRequestAccountRequests, '/DocumentRequests/all'],
    [RoutePath.DocumentRequestAccountRequestEdit, `/DocumentRequests/all/view/${DocumentRequestRouteParams.AccountRequestId}`],
    [RoutePath.DocumentRequestTemplates, '/DocumentRequests/templates'],
    [RoutePath.DocumentRequestTemplateNew, '/DocumentRequests/templates/add/'],
    [RoutePath.DocumentRequestTemplateEdit, `/DocumentRequests/templates/view/${DocumentRequestRouteParams.TemplateRequestId}`],
]);

const InboxTabRoutes = new Set([RoutePath.DocumentRequestInbox, RoutePath.DocumentRequestInboxEdit]);
const SentTabRoutes = new Set([RoutePath.DocumentRequestSent, RoutePath.DocumentRequestSentEdit, RoutePath.DocumentRequestNew]);
const AccountTabRoutes = new Set([RoutePath.DocumentRequestAccountRequests, RoutePath.DocumentRequestAccountRequestEdit]);
const TemplatesTabRoutes = new Set([RoutePath.DocumentRequestTemplates, RoutePath.DocumentRequestTemplateNew]);

export const DocumentRequests = () => {

    const { inboxRequestId, sentRequestId, accountRequestId, templateRequestId } = useParams();
    const { routeToDocRequestInbox, routeToDocRequestNew, routeToDocRequestTemplateNew, routeToDocRequestSent,
        routeToDocRequestAccountRequests, routeToDocRequestTemplates, routeToDocRequestSentRequest, routeToDocRequestTemplate } = useRouting();
    const location = useLocation();

    const { t } = useSafeTranslation(TranslationFiles.DocumentRequests);
    const { canGoToRoute } = useRouteValidator();
    const { getDocumentRequestType } = useUploadLinkApi();
    const [isUploadLink, setIsUploadLink] = useState<boolean>(false);
    const [isPageTypeDetermined, setIsPageTypeDetermined] = useState<boolean>(!inboxRequestId);

    const currentRoute =
        !!inboxRequestId ? RoutePath.DocumentRequestInboxEdit :
            !!sentRequestId ? RoutePath.DocumentRequestSentEdit :
                !!accountRequestId ? RoutePath.DocumentRequestAccountRequestEdit :
                    !!templateRequestId ? RoutePath.DocumentRequestTemplateEdit :
                        (`${location.pathname}` as DocumentRequestRoutePath);
    const iframe = useUtopiaIFrame();

    const INBOX_REQUEST_ID_START_INDEX = (IFrameRoutes.get(RoutePath.DocumentRequestInboxEdit)?.lastIndexOf('/') as number) + 1;
    const TEMPLATE_REQUEST_ID_START_INDEX = (IFrameRoutes.get(RoutePath.DocumentRequestTemplateEdit)?.lastIndexOf('/') as number) + 1;

    const getCtas = (): EfcCallToActionInfo[] => {
        if (canGoToRoute(RoutePath.DocumentRequestNew) || canGoToRoute(RoutePath.DocumentRequestTemplateNew)) {
            return [
                {
                    text: t(DocumentRequestsTKeys.NewRequestButton),
                    emphasis: 'high',
                    color: 'primary',
                    icon: { icon: ['far', 'circle-plus'] },
                    onClick: () => routeToDocRequestNew(),
                    show: () => currentRoute !== RoutePath.DocumentRequestNew
                                && !TemplatesTabRoutes.has(currentRoute),
                },
                {
                    text: t(DocumentRequestsTKeys.NewRequestTemplateButton),
                    emphasis: 'high',
                    color: 'primary',
                    icon: { icon: ['far', 'circle-plus'] },
                    onClick: () => routeToDocRequestTemplateNew(),
                    show: () => currentRoute !== RoutePath.DocumentRequestTemplateNew 
                                && TemplatesTabRoutes.has(currentRoute),
                },
            ];
        } else {
            return [];
        }
    };

    function getIFrameUrl(route: DocumentRequestRoutePath) {
        return IFrameRoutes.get(route)
            ?.replace(DocumentRequestRouteParams.InboxRequestId, inboxRequestId ?? '')
            .replace(DocumentRequestRouteParams.SentRequestId, sentRequestId ?? '')
            .replace(DocumentRequestRouteParams.AccountRequestId, accountRequestId ?? '')
            .replace(DocumentRequestRouteParams.TemplateRequestId, templateRequestId ?? '') ?? '';
    }

    const onIFramePathChange = (newPath: string) => {
        const trimmedInboxEditPath = (IFrameRoutes.get(RoutePath.DocumentRequestInboxEdit) as string).slice(0, INBOX_REQUEST_ID_START_INDEX);
        const requestId = newPath.slice(INBOX_REQUEST_ID_START_INDEX);
        const trimmedTemplateEditPath = (IFrameRoutes.get(RoutePath.DocumentRequestTemplateEdit) as string).slice(0, TEMPLATE_REQUEST_ID_START_INDEX);
        const templateId = newPath.slice(TEMPLATE_REQUEST_ID_START_INDEX);
        
        if (currentRoute == RoutePath.DocumentRequestNew) {
            if (newPath == IFrameRoutes.get(RoutePath.DocumentRequestInbox)) {
                routeToDocRequestSent();
            } else if (newPath.startsWith(trimmedInboxEditPath)) {
                routeToDocRequestSentRequest(requestId);
            } else if (newPath.startsWith(trimmedTemplateEditPath)) {
                routeToDocRequestTemplate(templateId);
            }
        } else if (currentRoute == RoutePath.DocumentRequestTemplateNew) {
            if (newPath == IFrameRoutes.get(RoutePath.DocumentRequestInbox)) {
                routeToDocRequestTemplates();
            } else if (newPath.startsWith(trimmedTemplateEditPath)) {
                routeToDocRequestTemplate(templateId);
            }
        }
    };

    const getUploadLinkIFrameUrl = () => {
        if (!!iframe.url) {
            if (iframe.url.includes('?')) {
                return `${iframe.url}&isUploadLink=true`;
            } else {
                return `${iframe.url}?isUploadLink=true`;
            }
        }
        return iframe.url;
    };

    const isDocumentRequestAnUploadLink = async () => {
        if (!inboxRequestId) {
            return false;
        }
        try {
            const response = await getDocumentRequestType(inboxRequestId);
            if (!!response.documentRequestType) {
                return response.documentRequestType === DocumentRequestType.UploadLink;
            } else {
                console.error('Error getting document request type: ', response.errorMessage);
            }
        }
        catch (error) {
            console.error('Error getting document request type: ', error);
        }
        return false;
    };

    useIFramePathChangeListener({ onIFramePathChange: onIFramePathChange, currentRoute: currentRoute });

    useEffect(() => {
        if (currentRoute == RoutePath.DocumentRequestIndex) {
            routeToDocRequestInbox();
        } else {
            iframe.setUrl(getIFrameUrl(currentRoute));
        }
    }, [location.pathname]);

    useEffect(() => {
        const determinePageType = async () => {
            const response = await isDocumentRequestAnUploadLink();
            setIsUploadLink(response);
            setIsPageTypeDetermined(true);
        };

        determinePageType();
    }, [inboxRequestId]);

    return (
        <>
            {isPageTypeDetermined && !isUploadLink && (<Page title={t(DocumentRequestsTKeys.DocumentRequestsTitle)} icon={{ icon: 'inbox' }} callsToAction={getCtas()}>
                <AccountFeatureUsageHeader accountFeatureTypes={[AccountFeatureEnum.DocumentRequests]} />

                {canGoToRoute(RoutePath.DocumentRequestInbox) && (
                    <Tab active={InboxTabRoutes.has(currentRoute)} onClick={() => routeToDocRequestInbox()}>
                        {t(DocumentRequestsTKeys.InboxTab)}
                    </Tab>
                )}
                {canGoToRoute(RoutePath.DocumentRequestSent) && (
                    <Tab active={SentTabRoutes.has(currentRoute)} onClick={() => routeToDocRequestSent()}>
                        {t(DocumentRequestsTKeys.SentTab)}
                    </Tab>
                )}
                {canGoToRoute(RoutePath.DocumentRequestAccountRequests) && (
                    <Tab active={AccountTabRoutes.has(currentRoute)} onClick={() => routeToDocRequestAccountRequests()}>
                        {t(DocumentRequestsTKeys.AccountRequestsTab)}
                    </Tab>
                )}
                {canGoToRoute(RoutePath.DocumentRequestTemplates) && (
                    <Tab active={TemplatesTabRoutes.has(currentRoute)} onClick={() => routeToDocRequestTemplates()}>
                        {t(DocumentRequestsTKeys.TemplatesTab)}
                    </Tab>
                )}

                {/* Use the iframe until we make this page native */}

                <IFrame className='iframe' src={iframe.url} />

                {/* Use the following Tab Panes once we make this page native */}

                {/*<Tab.Content activeTab={activeTab}> */}
                {/*     <Tab.Pane tabId={DocumentRequestTab.Inbox}>Placeholder</Tab.Pane> */}
                {/*     <Tab.Pane tabId={DocumentRequestTab.Sent}>Placeholder</Tab.Pane> */}
                {/*     <Tab.Pane tabId={DocumentRequestTab.AccountRequests}>Placeholder</Tab.Pane> */}
                {/*     <Tab.Pane tabId={DocumentRequestTab.Templates}> */}
                {/*         <DocumentRequestTemplateTable active={activeTab === DocumentRequestTab.Templates} /> */}
                {/*     </Tab.Pane> */}
                {/*</Tab.Content> */}
            </Page>)}
            {isPageTypeDetermined && isUploadLink && (<IFrame className='iframe' src={getUploadLinkIFrameUrl()} />)}
        </>
    );
};
