import React from 'react';
import { EfcCallToActionInfo, Modal } from '@efilecabinet/efc-atlantis-components';
import { BrandingSaveConfirmModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';

interface BrandingSaveConfirmModalProps {
    isOpen: boolean;
    toggle: () => void;
    onConfirm: () => void;
}

export const BrandingSaveConfirmModal = (props: BrandingSaveConfirmModalProps) => {
    const { isOpen, onConfirm, toggle } = props;

    const { t } = useSafeTranslation(TranslationFiles.BrandingSaveConfirmModal);

    const ctas: EfcCallToActionInfo[] = [
        { text: t(BrandingSaveConfirmModalTKeys.Cancel), emphasis: 'med', color: 'primary', onClick: toggle },
        { text: t(BrandingSaveConfirmModalTKeys.Confirm), emphasis: 'high', onClick: onConfirm },
    ];

    return (
        <Modal title={t(BrandingSaveConfirmModalTKeys.ModalTitle)} toggle={toggle} isOpen={isOpen} ctas={ctas}>
            <Modal.Body>
                {t(BrandingSaveConfirmModalTKeys.ModalBody)}
                <ul>
                    <li>{t(BrandingSaveConfirmModalTKeys.Emails)}</li>
                    <li>{t(BrandingSaveConfirmModalTKeys.TopOfApp)}</li>
                    <li>{t(BrandingSaveConfirmModalTKeys.DocumentView)}</li>
                    <li>{t(BrandingSaveConfirmModalTKeys.GuestMessage)}</li>
                </ul>
                <p>{t(BrandingSaveConfirmModalTKeys.ModalBody2)}</p>
            </Modal.Body>
        </Modal>
    );
};
