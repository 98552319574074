import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { SecurityPoliciesTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { EfcCallToActionInfo, Page } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { RoutePath, SecurityPoliciesRouteParams, SecurityPoliciesRoutePath } from '../../../types/RoutePaths';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useIFramePathChangeListener } from '../../../hooks/useIFramePathChangeListener';
import './SecurityPolicies.css';

const IFrameRoutes: Map<SecurityPoliciesRoutePath, string> = new Map([
    [RoutePath.SecurityPolicies, '/admin/securitypolicies'],
    [RoutePath.SecurityPolicyNew, '/admin/securitypolicies/add'],
    [RoutePath.SecurityPolicyEdit, `/admin/securitypolicies/edit/${SecurityPoliciesRouteParams.PolicyId}`],
]);

export const SecurityPolicies = () => {

    const { policyId } = useParams();
    const { routeToSecurityPolicyNew, routeToSecurityPolicies } = useRouting();

    const { t } = useSafeTranslation(TranslationFiles.SecurityPolicies);

    const currentRoute = !!policyId ? RoutePath.SecurityPolicyEdit : `${location.pathname}` as SecurityPoliciesRoutePath;
    const iframe = useUtopiaIFrame(getIFrameUrl(currentRoute));

    function getIFrameUrl(route: SecurityPoliciesRoutePath) {
        return IFrameRoutes.get(route)?.replace(SecurityPoliciesRouteParams.PolicyId, policyId?.toString() ?? '') ?? '';
    }

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(SecurityPoliciesTKeys.CreateSecurityPolicyButton),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            dataId: 'btnCreateSecurityPolicy',
            onClick: () => routeToSecurityPolicyNew(),
        }
    ];

    const onIFramePathChange = (newPath: string) => {
        if (currentRoute == RoutePath.SecurityPolicyNew && newPath == IFrameRoutes.get(RoutePath.SecurityPolicies)) {
            routeToSecurityPolicies();
        }
    };

    useIFramePathChangeListener({ onIFramePathChange: onIFramePathChange, currentRoute: currentRoute });

    useEffect(() => {
        iframe.setUrl(getIFrameUrl(currentRoute));
    }, [location.pathname]);

    return (
        <Page title={t(SecurityPoliciesTKeys.SecurityPoliciesTitle)} icon={{ icon: 'shield' }} callsToAction={ctas}>
            <IFrame className='iframe' src={iframe.url} />

            {/*<Tab.Content activeTab={activeTab}>*/}
            {/*    <Tab.Pane tabId={SecurityPoliciesTab.Table}>*/}
            {/*        <SecurityPoliciesTable active={activeTab === SecurityPoliciesTab.Table} />*/}
            {/*    </Tab.Pane>*/}
            {/*    <Tab.Pane tabId={SecurityPoliciesTab.Add}>*/}
            {/*         Placeholder for Add/Edit*/}
            {/*    </Tab.Pane>*/}
            {/*</Tab.Content>*/}
        </Page>
    );
};
