import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, TooltipItem, Text } from '@efilecabinet/efc-atlantis-components';
import { AuthContext, IAuthContext } from '../../../auth';
import { useIcons } from '../../../hooks/useIcons';
import { useNodeApi } from '../../../api';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useBootstrapBreakpoints } from '../../../hooks/useBootstrapBreakpoints';
import { SharedWithYouTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { useCardSizes } from '../../../hooks/useCardSizes';
import { INode } from '../../../types/CommonTypes';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ScreenSize } from '../../../types/BootstrapTypes';
import { ZeroState } from '../../../components/ZeroState';
import zeroStateImg from './SharedWithYouZeroState.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import './SharedWithYou.css';

export const SharedWithYou = () => {
    const { authUser } = useContext(AuthContext) as IAuthContext;

    const [sharedNodes, setSharedNodes] = useState<INode[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const { getNodeIconProps } = useIcons();
    const { t } = useSafeTranslation(TranslationFiles.SharedWithYou);
    const { getCardTitleSize, getCardIconSize } = useCardSizes();
    const { getSharedWithYou } = useNodeApi();
    const { screenSize } = useBootstrapBreakpoints();
    const { routeToDocuments, getLinkToNode, getLinkToDocuments } = useRouting();

    const NUM_NODES = 10;

    useEffect(() => {
        if (!!authUser && authUser.accountID) {
            getSharedWithYou(authUser.accountID, 0, NUM_NODES + 1)
                .then((children) => {
                    setSharedNodes(children);
                })
                .catch(() => {
                    setErrorLoading(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [!!authUser]);

    return (
        <>
            <Card.Title tag={Link} to={getLinkToDocuments()} className='ms-1 no-decoration' size={getCardTitleSize()} bold icon={{ icon: ['far', 'circle-arrow-right'], pull: 'left', size: getCardIconSize() }}>
                {t(SharedWithYouTKeys.SharedWithYouTitle)}
            </Card.Title>

            {!!isLoading || !!errorLoading || !authUser ? (
                <>
                    {!!isLoading && (
                        <SkeletonTheme enableAnimation height='24px' width='100%'>
                            <Skeleton count={NUM_NODES} className='mb-3 mx-auto' />
                        </SkeletonTheme>
                    )}
                    {!!errorLoading &&
                        <Card.Text>{t(SharedWithYouTKeys.ErrorLoading)}</Card.Text>
                    }
                </>
            ) : (
                <>
                    {sharedNodes.length == 0 ? (
                        <ZeroState
                            imageColContent={ <img src={zeroStateImg} alt={t(SharedWithYouTKeys.ZeroStateImgAltText)} /> }
                            textColContent={
                                <>
                                    <Text bold> {t(SharedWithYouTKeys.NoItemsMainText)} </Text>
                                    <br />
                                    <Text> {t(SharedWithYouTKeys.NoItemsSubText)} </Text>
                                </>
                            }
                            stackColumns={ screenSize < ScreenSize.xxl } />
                    ) : (
                        <>
                            {sharedNodes
                                .slice(0, NUM_NODES)
                                .map((node) => (
                                    <Card.Link key={node.id} tag={Link} to={getLinkToNode([node.id])} className='ms-1 my-3' icon={{ ...getNodeIconProps(node.systemType, node.fileInfo?.fileExtension), size: 'lg' }}>
                                        <TooltipItem innerClassName='truncated-text' id={node.id.toString()} message={node.name}>
                                            {node.name}
                                        </TooltipItem>
                                    </Card.Link>
                                ))
                            }

                            {sharedNodes.length > NUM_NODES && (
                                <div className='d-flex justify-content-center'>
                                    <Button color='primary' emphasis='low' onClick={() => routeToDocuments()}>
                                        {t(SharedWithYouTKeys.SeeAll)}
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};