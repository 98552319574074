import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { uniqWith } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { closestCorners, DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Dropdown, EfcLink, Input, Label, Modal, useDropdown } from '@efilecabinet/efc-atlantis-components';
import { MDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { IDropdownListMDField, IMDField, IMDOptionItem, MDModalAddLink, MDModalSorting } from '../../../../../types/MDFieldTypes';
import { ClearAllButton } from './ClearAllButton/ClearAllButton';
import { OptionItem } from './OptionItem/OptionItem';
import './DropdownListAddOptions.css';

interface DropdownListAddOptionsProps {
    mdField: IMDField;
    setMDField: Dispatch<SetStateAction<IMDField>>;
}

export const DropdownListAddOptions = (props: DropdownListAddOptionsProps) => {

    const { mdField, setMDField } = props;

    const { t } = useSafeTranslation(TranslationFiles.MDFieldModal);

    const { toggleDropdown, dropdownIsOpen } = useDropdown();

    const [currentView, setCurrentView] = useState(MDModalAddLink.AddIndividually);
    const [options, setOptions] = useState<IMDOptionItem[]>(mdField?.dropdownListProps?.listValue as IMDOptionItem[] ?? []);
    const [optionName, setOptionName] = useState('' as string);
    const [bulkOptions, setBulkOptions] = useState('');
    const [isValidOption, setIsValidOption] = useState<boolean>(true);
    const [showDuplicateOptionsMessage, setShowDuplicateOptionsMessage] = useState(false);

    const MAX_INPUT_CHARS = 250;
    const DELAY = 15;

    const clearTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const toggleView = () => {
        if (currentView === MDModalAddLink.AddInBulk) { setCurrentView(MDModalAddLink.AddIndividually); }
        else { setCurrentView(MDModalAddLink.AddInBulk); }
    };

    const handleTrashClick = (id: string) => {
        setOptions([...options.filter(option => option.id !== id)]);
    };

    const handleIsDefaultClick = (id: string, onChangeDefault: boolean) => {
        const newOptions = options.map((option) => {
            if (id === option.id) {
                option.isDefault = onChangeDefault;
            } else {
                option.isDefault = false;
            }
            return option;
        });
        setOptions(newOptions);
    };

    const handleConfirmClearAll = () => {
        setOptions([]);
    };

    const handleSortBy = (sortBy: MDModalSorting) => {
        if (sortBy === MDModalSorting.Ascending) {
            setOptions([...options]
                .sort((a, b) => { if (a.optionName < b.optionName) { return -1; } if (a.optionName > b.optionName) { return 1; } return 0; }));
        } else {
            setOptions([...options]
                .sort((a, b) => { if (a.optionName < b.optionName) { return 1; } if (a.optionName > b.optionName) { return -1; } return 0; }));
        }
    };

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id === over.id) return;

        setOptions((optionItems) => {
            const originalPos = getOptionItemPos(active.id);
            const newPos = getOptionItemPos(over.id);
            return arrayMove(optionItems, originalPos, newPos);
        });
    };

    const getOptionItemPos = (id: number) => options.findIndex(optionItem => optionItem.id === id.toString());

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 100,
                tolerance: 10,
            },
        }),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const addOptionToList = () => {
        clearTimeout(clearTimerRef.current);
        setIsValidOption(true);
        if (optionName.trim() === '') return;
        if (options.findIndex(o => o.optionName.trim().toLowerCase() === optionName.trim().toLowerCase()) === -1) {
            setOptions([...options, { optionName: optionName.trim(), isDefault: false, id: crypto.randomUUID() }]);
        }
        else {
            setIsValidOption(false);
            clearTimerRef.current = setTimeout(() => { setIsValidOption(true); }, DELAY * 1000);
        }
        setOptionName('');
    };

    const compareRawToDedupedList = (rawRowsBulkAdded: string[], dedupedList: IMDOptionItem[]) => {
        // The options list is deduped before the options are added. We display the message if they attempted to add a duplicate
        if (rawRowsBulkAdded.length === dedupedList.length) {
            setShowDuplicateOptionsMessage(false);
        } else {
            setShowDuplicateOptionsMessage(true);
        }
    };

    const convertBulkOptionsToArray = () => {
        // split the bulk options string into an array of options and remove any empty strings
        const rawBulkOptions = bulkOptions.split('\n').filter((option) => option.trim().toLowerCase() !== '');

        // turn raw string into deduplicated array of options
        const dedupedBulkOptions = uniqWith(rawBulkOptions,
            (a, b) => a.trim().toLowerCase() === b.trim().toLowerCase());

        // add new options to the list that are not already in the list
        const newOptions = dedupedBulkOptions
            .filter((option) => options.findIndex(o => o.optionName.trim().toLowerCase() === option.trim().toLowerCase()) === -1)
            .map((option) => {
                return { optionName: option.trim(), isDefault: false, id: crypto.randomUUID() };
            });

        compareRawToDedupedList(
            rawBulkOptions,
            newOptions
        );

        return newOptions;
    };

    const addBulkOptionsToList = () => {
        setOptions([...options, ...convertBulkOptionsToArray()]);
        setBulkOptions('');
        setCurrentView(MDModalAddLink.AddIndividually);
    };

    useEffect(() => {
        setMDField({ ...mdField, dropdownListProps: { ...mdField.dropdownListProps as IDropdownListMDField, listValue: options } });
    }, [options]);

    return (
        <Modal.Body>
            <div className='fw-bold'>{t(MDFieldModalTKeys.Subtitle)}</div>

            {currentView === MDModalAddLink.AddIndividually ?
                <>
                    <div className='my-3'>
                        <Label for='optionInputFieldText'>{t(MDFieldModalTKeys.OptionInputTitle)}</Label>
                        <span className={'form-control p-1 d-flex justify-content-center flex-row input-row ' + (isValidOption ? 'is-valid' : 'is-invalid')}>
                            <Input
                                placeholder={t(MDFieldModalTKeys.OptionInputPlaceholder)}
                                id='option-input-field-text'
                                name='optionInputFieldText'
                                dataId='txtoptionInputText'
                                type='text'
                                maxLength={MAX_INPUT_CHARS}
                                value={optionName}
                                onChange={(e) => setOptionName(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        addOptionToList();
                                        e.preventDefault();
                                    }
                                }} />
                            <Button className='enter-to-submit fw-bold' color='primary' emphasis='low' onClick={addOptionToList}>{t(MDFieldModalTKeys.EnterToSubmit)}</Button>
                        </span>
                        <div className="invalid-feedback">
                            {t(MDFieldModalTKeys.InvalidOptionError)}
                        </div>
                    </div>

                    <EfcLink onClick={toggleView} dataId='linkAddinBulk' color='primary'>{t(MDFieldModalTKeys.AddInBulk)}</EfcLink>

                    <div className='justify-content-center mt-3 option-drop-area'>
                        {options.length === 0 && <div className='d-flex text-nowrap justify-content-center align-items-center empty-area'>{t(MDFieldModalTKeys.EmptyOptionsContainer)}</div>}
                        {options.length > 0 &&
                            <div className='d-flex flex-column'>
                                <div className='d-flex m-2 justify-content-between'>
                                    <Dropdown isOpen={dropdownIsOpen} toggle={toggleDropdown}>
                                        <Dropdown.Toggle color='primary' dataId='sortOptionItems'>
                                            <div className='d-flex justify-content-start'>
                                                <FontAwesomeIcon icon={['far', 'arrows-repeat']} />
                                                <div className='mx-1'>
                                                    {t(MDFieldModalTKeys.SortBy)}
                                                </div>
                                                <FontAwesomeIcon icon='chevron-down' />
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleSortBy(MDModalSorting.Ascending)}>{t(MDFieldModalTKeys.AlphabeticallyAsc)}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSortBy(MDModalSorting.Descending)}>{t(MDFieldModalTKeys.AlphabeticallyDesc)}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <ClearAllButton onClick={handleConfirmClearAll}></ClearAllButton>
                                </div>
                                <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                    <SortableContext items={options} strategy={verticalListSortingStrategy}>
                                        {
                                            options.map((option, index) => (
                                                <OptionItem key={index} optionItem={option} setIsDefault={handleIsDefaultClick} removeOption={handleTrashClick}></OptionItem>
                                            ))
                                        }
                                    </SortableContext>
                                </DndContext>
                            </div>
                        }
                    </div>
                    {showDuplicateOptionsMessage && <div>*{t(MDFieldModalTKeys.NoDuplicateOptionsAddedMessage)}</div>}
                </>
                :
                <>
                    <EfcLink onClick={toggleView} dataId='linkAddIndividually' color='primary'>{t(MDFieldModalTKeys.AddIndividually)}</EfcLink>
                    <div className='mt-4 fw-bold'>{t(MDFieldModalTKeys.BulkAddInstructions)}</div>
                    <div className='droparea-container'>
                        <Input
                            className='mt-3 droparea-input'
                            type='textarea'
                            placeholder={t(MDFieldModalTKeys.BulkPlaceholder)}
                            value={bulkOptions}
                            onChange={(e) => setBulkOptions(e.target.value)}
                        />

                        {bulkOptions.length > 0 &&
                            <div className='d-flex justify-content-end droparea-button'>
                                <Button className='mt-2' color='primary' emphasis='high' onClick={addBulkOptionsToList}>{t(MDFieldModalTKeys.ApplyOptions)}</Button>
                            </div>
                        }
                    </div>
                </>
            }

        </Modal.Body>
    );
};