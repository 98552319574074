import { useNavigate } from 'react-router';
import { RoutePath } from '../../types/RoutePaths';

export const useRecentsRouting = () => {

    const navigate = useNavigate();

    const getLinkToRecents = () => {
        return RoutePath.Recents;
    };

    const routeToRecents = () => {
        navigate(getLinkToRecents());
    };

    return {
        getLinkToRecents,
        routeToRecents,
    };
};
