import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useAccountFeatureValidator } from './hooks/useAccountFeatureValidator';
import { useIcons } from './hooks/useIcons';
import { useSystemPermissionsValidator } from './hooks/useSystemPermissionsValidators';
import { useSettingsAndFeatures } from './context/settingsAndFeatures/SettingsFeaturesContext';
import { RoutePath } from './types/RoutePaths';
import { AuthRoute } from './auth/AuthorizedRoute';
import { AuthenticatedRoute } from './auth/AuthenticatedRoute';
import { Login } from './auth/Login';
import { Layout } from './components/Layout/Layout';
import { Home } from './pages/Home/Home';
import { CheckedOut, Documents } from './pages/Documents';
import { DocumentRequests, ESignature, Forms, Governance, Metadata, ProfilesAndProfileItems, Reports, Workflow } from './pages/Work';
import { AuditLogs, Salesforce, SecurityPolicies, Settings, SystemLogs, Utilities } from './pages/Account';
import { Users } from './pages/People';
import { UserSettings } from './pages/Settings';
import { Search } from './pages/Search/Search';
import { ToDoListPage } from './pages/Work/ToDoList';
import { FileProcessingQueue } from './pages/Documents/FileProcessingQueue';
import { RecycleBin } from './pages/Documents/RecycleBin';
import { FolderTemplates } from './pages/Documents/FolderTemplates';
import { WorkYouCareAboutPage } from './pages/Work/WorkYouCareAbout';
import { RecentsPage } from './pages/Documents/RecentsPage/RecentsPage';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import { createBrowserHistory, BrowserHistoryOptions } from 'history';
import { IAtlantisHtmlDocument } from './IAtlantisHtmlDocument';
import './App.css';

const LayoutRoutes = () => {


    return (
        <Layout>
            {/* prettier-ignore */}
            <Routes>

                <Route path="*" element={<PageNotFound />} />

                <Route index element={<Home />} />

                <Route path={RoutePath.AccountSettingsIndex} element={<AuthRoute path={RoutePath.AccountSettingsIndex}><Settings /></AuthRoute>} />
                <Route path={RoutePath.AccountSettings} element={<AuthRoute path={RoutePath.AccountSettings}><Settings /></AuthRoute>} />
                <Route path={RoutePath.SingleSignOn} element={<AuthRoute path={RoutePath.SingleSignOn}><Settings /></AuthRoute>} />
                <Route path={RoutePath.SamlValidate} element={<AuthRoute path={RoutePath.SamlValidate}><Settings /></AuthRoute>} />
                <Route path={RoutePath.SamlNew} element={<AuthRoute path={RoutePath.SamlNew}><Settings /></AuthRoute>} />
                <Route path={RoutePath.SamlEdit} element={<AuthRoute path={RoutePath.SamlEdit}><Settings /></AuthRoute>} />
                <Route path={RoutePath.Branding} element={<AuthRoute path={RoutePath.Branding}><Settings /></AuthRoute>} />
                <Route path={RoutePath.AccountEmailImports} element={<AuthRoute path={RoutePath.AccountEmailImports}><Settings /></AuthRoute>} />
                <Route path={RoutePath.AccountEmailImportNew} element={<AuthRoute path={RoutePath.AccountEmailImportNew}><Settings /></AuthRoute>} />
                <Route path={RoutePath.AccountEmailImportEdit} element={<AuthRoute path={RoutePath.AccountEmailImportEdit}><Settings /></AuthRoute>} />

                <Route path={RoutePath.AuditLogs} element={<AuthRoute path={RoutePath.AuditLogs}><AuditLogs /></AuthRoute>} />

                <Route path={RoutePath.CheckedOutIndex} element={<AuthRoute path={RoutePath.CheckedOutIndex}><CheckedOut /></AuthRoute>} />
                <Route path={RoutePath.CheckedOutPersonal} element={<AuthRoute path={RoutePath.CheckedOutPersonal}><CheckedOut /></AuthRoute>} />
                <Route path={RoutePath.CheckedOutAccount} element={<AuthRoute path={RoutePath.CheckedOutAccount}><CheckedOut /></AuthRoute>} />

                <Route path={RoutePath.Documents} element={<AuthRoute path={RoutePath.Documents}><Documents /></AuthRoute>} />
                <Route path={RoutePath.Node} element={<AuthRoute path={RoutePath.Node}><Documents /></AuthRoute>} />
                <Route path={RoutePath.NodeDetail} element={<AuthRoute path={RoutePath.NodeDetail}><Documents /></AuthRoute>} />
                <Route path={RoutePath.NodeContainer} element={<AuthRoute path={RoutePath.NodeContainer}><Documents /></AuthRoute>} />
                <Route path={RoutePath.Collections} element={<AuthRoute path={RoutePath.Collections}><Documents /></AuthRoute>} />
                <Route path={RoutePath.Collection} element={<AuthRoute path={RoutePath.Collection}><Documents /></AuthRoute>} />
                <Route path={RoutePath.CollectionNodeDetail} element={<AuthRoute path={RoutePath.CollectionNodeDetail}><Documents /></AuthRoute>} />

                <Route path={RoutePath.DocumentRequestIndex} element={<AuthRoute path={RoutePath.DocumentRequestIndex}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestInbox} element={<AuthRoute path={RoutePath.DocumentRequestInbox}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestNew} element={<AuthRoute path={RoutePath.DocumentRequestNew}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestInboxEdit} element={<AuthRoute path={RoutePath.DocumentRequestInboxEdit}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestTemplates} element={<AuthRoute path={RoutePath.DocumentRequestTemplates}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestTemplateNew} element={<AuthRoute path={RoutePath.DocumentRequestTemplateNew}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestTemplateEdit} element={<AuthRoute path={RoutePath.DocumentRequestTemplateEdit}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestSent} element={<AuthRoute path={RoutePath.DocumentRequestSent}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestSentEdit} element={<AuthRoute path={RoutePath.DocumentRequestSentEdit}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestAccountRequests} element={<AuthRoute path={RoutePath.DocumentRequestAccountRequests}><DocumentRequests /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestAccountRequestEdit} element={<AuthRoute path={RoutePath.DocumentRequestAccountRequestEdit}><DocumentRequests /></AuthRoute>} />

                <Route path={RoutePath.ESignatureIndex} element={<AuthRoute path={RoutePath.ESignatureIndex}><ESignature /></AuthRoute>} />
                <Route path={RoutePath.ESignatureSent} element={<AuthRoute path={RoutePath.ESignatureSent}><ESignature /></AuthRoute>} />
                <Route path={RoutePath.ESignatureAll} element={<AuthRoute path={RoutePath.ESignatureAll}><ESignature /></AuthRoute>} />
                <Route path={RoutePath.ESignatureTemplates} element={<AuthRoute path={RoutePath.ESignatureTemplates}><ESignature /></AuthRoute>} />

                <Route path={RoutePath.PageNotFound} element={<AuthRoute path={RoutePath.PageNotFound}><PageNotFound /></AuthRoute>} />

                <Route path={RoutePath.FileProcessingQueue} element={<AuthRoute path={RoutePath.FileProcessingQueue}><FileProcessingQueue /></AuthRoute>} />

                <Route path={RoutePath.FolderTemplatesIndex} element={<AuthRoute path={RoutePath.FolderTemplatesIndex}><FolderTemplates /></AuthRoute>} />
                <Route path={RoutePath.FolderTemplateLibrary} element={<AuthRoute path={RoutePath.FolderTemplateLibrary}><FolderTemplates /></AuthRoute>} />
                <Route path={RoutePath.FolderTemplateEdit} element={<AuthRoute path={RoutePath.FolderTemplateEdit}><FolderTemplates /></AuthRoute>} />

                <Route path={RoutePath.Forms} element={<AuthRoute path={RoutePath.Forms}><Forms /></AuthRoute>} />

                <Route path={RoutePath.Governance} element={<AuthRoute path={RoutePath.Governance}><Governance /></AuthRoute>} />

                <Route path={RoutePath.MetadataIndex} element={<AuthRoute path={RoutePath.MetadataIndex}><Metadata /></AuthRoute>} />
                <Route path={RoutePath.MDFields} element={<AuthRoute path={RoutePath.MDFields}><Metadata /></AuthRoute>} />
                <Route path={RoutePath.MDGroups} element={<AuthRoute path={RoutePath.MDGroups}><Metadata /></AuthRoute>} />

                <Route path={RoutePath.ProfilesIndex} element={<AuthRoute path={RoutePath.ProfilesIndex}><ProfilesAndProfileItems /></AuthRoute>} />
                <Route path={RoutePath.Profiles} element={<AuthRoute path={RoutePath.Profiles}><ProfilesAndProfileItems /></AuthRoute>} />
                <Route path={RoutePath.ProfileEdit} element={<AuthRoute path={RoutePath.ProfileEdit}><ProfilesAndProfileItems /></AuthRoute>} />
                <Route path={RoutePath.ProfileNew} element={<AuthRoute path={RoutePath.ProfileNew}><ProfilesAndProfileItems /></AuthRoute>} />
                <Route path={RoutePath.ProfileItems} element={<AuthRoute path={RoutePath.ProfileItems}><ProfilesAndProfileItems /></AuthRoute>} />
                <Route path={RoutePath.ProfileItemEdit} element={<AuthRoute path={RoutePath.ProfileItemEdit}><ProfilesAndProfileItems /></AuthRoute>} />
                <Route path={RoutePath.ProfileItemNew} element={<AuthRoute path={RoutePath.ProfileItemNew}><ProfilesAndProfileItems /></AuthRoute>} />

                <Route path={RoutePath.Recents} element={<AuthRoute path={RoutePath.Recents}><RecentsPage /></AuthRoute>} />

                <Route path={RoutePath.RecycleBin} element={<AuthRoute path={RoutePath.RecycleBin}><RecycleBin /></AuthRoute>} />

                <Route path={RoutePath.Reports} element={<AuthRoute path={RoutePath.Reports}><Reports /></AuthRoute>} />

                <Route path={RoutePath.Salesforce} element={<AuthRoute path={RoutePath.Salesforce}><Salesforce /></AuthRoute>} />
                <Route path={RoutePath.SalesforceMappingEdit} element={<AuthRoute path={RoutePath.SalesforceMappingEdit}><Salesforce /></AuthRoute>} />
                <Route path={RoutePath.SalesforceMappingNew} element={<AuthRoute path={RoutePath.SalesforceMappingNew}><Salesforce /></AuthRoute>} />

                <Route path={RoutePath.Search} element={<AuthRoute path={RoutePath.Search}><Search /></AuthRoute>} />
                <Route path={RoutePath.UserSearch} element={<AuthRoute path={RoutePath.UserSearch}><Search /></AuthRoute>} />
                <Route path={RoutePath.SharedSearch} element={<AuthRoute path={RoutePath.SharedSearch}><Search /></AuthRoute>} />

                <Route path={RoutePath.SecurityPolicies} element={<AuthRoute path={RoutePath.SecurityPolicies}><SecurityPolicies /></AuthRoute>} />
                <Route path={RoutePath.SecurityPolicyEdit} element={<AuthRoute path={RoutePath.SecurityPolicyEdit}><SecurityPolicies /></AuthRoute>} />
                <Route path={RoutePath.SecurityPolicyNew} element={<AuthRoute path={RoutePath.SecurityPolicyNew}><SecurityPolicies /></AuthRoute>} />

                <Route path={RoutePath.SystemLogsServer} element={<AuthRoute path={RoutePath.SystemLogsServer}><SystemLogs /></AuthRoute>} />
                <Route path={RoutePath.SystemLogsClient} element={<AuthRoute path={RoutePath.SystemLogsClient}><SystemLogs /></AuthRoute>} />

                <Route path={RoutePath.OpenToDos} element={<AuthRoute path={RoutePath.OpenToDos}><ToDoListPage /></AuthRoute>} />
                <Route path={RoutePath.CompletedToDos} element={<AuthRoute path={RoutePath.CompletedToDos}><ToDoListPage /></AuthRoute>} />
                <Route path={RoutePath.DismissedToDos} element={<AuthRoute path={RoutePath.DismissedToDos}><ToDoListPage /></AuthRoute>} />

                <Route path={RoutePath.UserSettingsIndex} element={<AuthRoute path={RoutePath.UserSettingsIndex}><UserSettings /></AuthRoute>} />
                <Route path={RoutePath.UserSettings} element={<AuthRoute path={RoutePath.UserSettings}><UserSettings /></AuthRoute>} />
                <Route path={RoutePath.MyAccounts} element={<AuthRoute path={RoutePath.MyAccounts}><UserSettings /></AuthRoute>} />
                <Route path={RoutePath.IntegratedProducts} element={<AuthRoute path={RoutePath.IntegratedProducts}><UserSettings /></AuthRoute>} />
                <Route path={RoutePath.UserEmailImports} element={<AuthRoute path={RoutePath.UserEmailImports}><UserSettings /></AuthRoute>} />
                <Route path={RoutePath.UserEmailImportNew} element={<AuthRoute path={RoutePath.UserEmailImportNew}><UserSettings /></AuthRoute>} />
                <Route path={RoutePath.UserEmailImportEdit} element={<AuthRoute path={RoutePath.UserEmailImportEdit}><UserSettings /></AuthRoute>} />

                <Route path={RoutePath.UserIndex} element={<AuthRoute path={RoutePath.UserIndex}><Users /></AuthRoute>} />
                <Route path={RoutePath.Users} element={<AuthRoute path={RoutePath.Users}><Users /></AuthRoute>} />
                <Route path={RoutePath.UserNew} element={<AuthRoute path={RoutePath.UserNew}><Users /></AuthRoute>} />
                <Route path={RoutePath.UserEdit} element={<AuthRoute path={RoutePath.UserEdit}><Users /></AuthRoute>} />
                <Route path={RoutePath.Groups} element={<AuthRoute path={RoutePath.Groups}><Users /></AuthRoute>} />
                <Route path={RoutePath.GroupNew} element={<AuthRoute path={RoutePath.GroupNew}><Users /></AuthRoute>} />
                <Route path={RoutePath.GroupEdit} element={<AuthRoute path={RoutePath.GroupEdit}><Users /></AuthRoute>} />
                <Route path={RoutePath.UserSessions} element={<AuthRoute path={RoutePath.UserSessions}><Users /></AuthRoute>} />
                <Route path={RoutePath.AccessLinks} element={<AuthRoute path={RoutePath.AccessLinks}><Users /></AuthRoute>} />
                <Route path={RoutePath.AccessLinkEdit} element={<AuthRoute path={RoutePath.AccessLinkEdit}><Users /></AuthRoute>} />
                <Route path={RoutePath.UserDirectories} element={<AuthRoute path={RoutePath.UserDirectories}><Users /></AuthRoute>} />

                <Route path={RoutePath.UtilitiesIndex} element={<AuthRoute path={RoutePath.UtilitiesIndex}><Utilities /></AuthRoute>} />
                <Route path={RoutePath.CSVImport} element={<AuthRoute path={RoutePath.CSVImport}><Utilities /></AuthRoute>} />
                <Route path={RoutePath.CSVExport} element={<AuthRoute path={RoutePath.CSVExport}><Utilities /></AuthRoute>} />
                <Route path={RoutePath.AccountTemplateExport} element={<AuthRoute path={RoutePath.AccountTemplateExport}><Utilities /></AuthRoute>} />
                <Route path={RoutePath.AccountTemplateImport} element={<AuthRoute path={RoutePath.AccountTemplateImport}><Utilities /></AuthRoute>} />

                <Route path={RoutePath.WorkflowIndex} element={<AuthRoute path={RoutePath.WorkflowIndex}><Workflow /></AuthRoute>} />
                <Route path={RoutePath.Workflows} element={<AuthRoute path={RoutePath.Workflows}><Workflow /></AuthRoute>} />
                <Route path={RoutePath.WorkflowEdit} element={<AuthRoute path={RoutePath.WorkflowEdit}><Workflow /></AuthRoute>} />
                <Route path={RoutePath.WorkflowInstances} element={<AuthRoute path={RoutePath.WorkflowInstances}><Workflow /></AuthRoute>} />
                <Route path={RoutePath.WorkflowInstanceEdit} element={<AuthRoute path={RoutePath.WorkflowInstanceEdit}><Workflow /></AuthRoute>} />
                <Route path={RoutePath.WorkflowDashboard} element={<AuthRoute path={RoutePath.WorkflowDashboard}><Workflow /></AuthRoute>} />
                <Route path={RoutePath.WorkflowHistory} element={<AuthRoute path={RoutePath.WorkflowHistory}><Workflow /></AuthRoute>} />

                <Route path={RoutePath.WorkYouCareAbout} element={<AuthRoute path={RoutePath.WorkYouCareAbout}><WorkYouCareAboutPage /></AuthRoute>} />

            </Routes>
        </Layout>
    );
};

const App = () => {

    const { loadIcons } = useIcons();
    const { accountFeaturesHaveLoaded } = useAccountFeatureValidator();
    const { systemPermissionsHaveLoaded } = useSystemPermissionsValidator();
    const { settings, hasSettings } = useSettingsAndFeatures();
    const browserHistory = createBrowserHistory({ basename: '' } as BrowserHistoryOptions);
    const reactPlugin = new ReactPlugin();

    loadIcons();

    useEffect(() => {
        if (settings?.applicationInsightsConnectionString) {
            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: settings?.applicationInsightsConnectionString,
                    enableAutoRouteTracking: true,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory }
                    }
                }
            });
            appInsights.loadAppInsights();
            (document as IAtlantisHtmlDocument).appInsights = appInsights;
        }
    }, [hasSettings]);

    return (
        <Routes>
            {/* prettier-ignore */}
            <Route path='/*' element={
                <AuthenticatedRoute redirectTo={RoutePath.Login}>
                    {accountFeaturesHaveLoaded() && systemPermissionsHaveLoaded() &&
                        <LayoutRoutes />
                    }
                </AuthenticatedRoute>
            } />

            <Route path={RoutePath.Login} element={<Login />} />
            <Route path={RoutePath.LoginOneTimeToken} element={<Login />} />

        </Routes>
    );
};

export default App;
