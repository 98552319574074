import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, SearchInput, Table } from '@efilecabinet/efc-atlantis-components';
import { PermissionDetailsTableRow } from './PermissionDetailsTableRow';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { PermissionDetailsTableHeader } from './PermissionDetailsTableHeader';
import { INodePermission, NodePermissionLevelEnum } from '../../../../../types/NodePermissionTypes';
import { useNodePermissionsUtilities } from '../../../../../hooks/useNodePermissionsUtilities';
import { PermissionInheritanceEnum } from '@efilecabinet/previewerx/dist/Previewer/Types/UtopiaTypes';

interface PermissionDetailsTableProps {
    permissionsState: [INodePermission[], Dispatch<SetStateAction<INodePermission[]>>];
    isEditMode: boolean;
    editCallback?: () => void;
    grantAccessCallback?: () => void;
}

export const PermissionDetailsTable = ({ permissionsState, isEditMode, editCallback, grantAccessCallback }: PermissionDetailsTableProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { updateNodePermissionLevel } = useNodePermissionsUtilities();

    const [permissions, setPermissions] = permissionsState;

    function onEdit() {
        !!editCallback && editCallback();
    }

    function onGrantAccess() {
        !!grantAccessCallback && grantAccessCallback();
    }

    function onCheckboxClick(permissionId: number, permissionLevel: NodePermissionLevelEnum) {
        setPermissions(permissions.map((permission) => {
            return permission.id === permissionId ? { ...updateNodePermissionLevel(permission, permissionLevel) } : permission;
        }));
    }

    function onDeleteClick(permissionId: number) {
        // TODO: update this to pop the delete confirmation modal
        setPermissions(permissions.filter((permission) => permission.id !== permissionId));
    }

    function getInheritedFrom(permission: INodePermission) {
        // TODO: Add a call to get the inherited node name if applicable
        return (permission.inheritanceBehavior as unknown as PermissionInheritanceEnum) == PermissionInheritanceEnum.Default ? 'Node name' : undefined;
    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <SearchInput />
                </Col>
                <Col xs='8' className='d-flex justify-content-end'>
                    {!isEditMode &&
                        <>
                            <Button emphasis='med' icon={{ icon: 'edit' }} color='primary' onClick={onEdit}>{t(PermissionsTKeys.EditButton)}</Button>
                            <Button emphasis='high' color='primary' onClick={onGrantAccess} className='ms-2'>{t(PermissionsTKeys.GrantAccessButton)}</Button>
                        </>
                    }
                </Col>
            </Row>

            <Table>
                <thead>
                    <PermissionDetailsTableHeader />
                </thead>
                <tbody>
                    {permissions.map((permission: INodePermission) => 
                        <PermissionDetailsTableRow
                            key={permission.id}
                            permission={permission}
                            isEditMode={isEditMode}
                            onCheckboxClick={onCheckboxClick}
                            onDeleteClick={onDeleteClick}
                            inheritedFrom={getInheritedFrom(permission)} />
                    )}
                </tbody>
            </Table>
        </>
    );
};