import { Api } from './';
import { AxiosResponse } from 'axios';
import { AccountBranding, CnameAvailableResponse } from '../types/BrandingTypes';

export const useBrandingApi = () => {
    const getBrandingByAccountIdentifier = async (accountIdentifier: string): Promise<AccountBranding> => {
        const resp: AxiosResponse = await Api.get(`api/branding/accountIdentifier/${accountIdentifier}`);
        return resp.data as AccountBranding;
    };

    const getBrandingByCname = async (cname: string): Promise<AccountBranding> => {
        const resp: AxiosResponse = await Api.get(`api/branding/cname/${cname}`);
        return resp.data as AccountBranding;
    };

    const getCnameAvailable = async (cname: string): Promise<CnameAvailableResponse> => {
        const resp: AxiosResponse = await Api.get(`api/branding/available/${cname}`);
        return resp.data as CnameAvailableResponse;
    };

    const getCnameExists = async (cname: string): Promise<boolean> => {
        const resp: AxiosResponse = await Api.get(`api/branding/exists/${cname}`);
        return resp.data;
    };

    const saveBranding = async (branding: AccountBranding): Promise<AccountBranding> => {
        const resp: AxiosResponse = await Api.post('api/branding/saveBranding', branding);
        return resp.data as AccountBranding;
    };

    return {
        getBrandingByAccountIdentifier,
        getBrandingByCname,
        getCnameAvailable,
        getCnameExists,
        saveBranding,
    };
};
