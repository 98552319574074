import React, { ChangeEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import { IProfileItem, ProfileItemTypeEnum } from '../../../../../types/ProfilesAndProfileItemsTypes';
import { FormFeedback, FormGroup, Input } from '@efilecabinet/efc-atlantis-components';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { ListSelector } from '../../../../ListSelector/ListSelector';
import { useProfileUtilities } from '../../../../../hooks/useProfileUtilities';
import './NodeProfileItemInput.css';
import { useNodeProfileApi } from '../../../../../api/useNodeProfileApi';

interface NodeProfileItemInputProps {
    errorState: [string, (error: string) => void];
    nodeId: string;
    nodeProfileItemState: [IProfileItem, (nodeProfileItem: IProfileItem) => void];
}

export const NodeProfileItemInput = ({ errorState, nodeId, nodeProfileItemState }: NodeProfileItemInputProps) => {

    const { getUnmaskedProfileItemValueAsync } = useNodeProfileApi();
    const { isNewValueInvalid, truncateString } = useProfileUtilities();

    const [error, setError] = errorState;
    const [nodeProfileItem, setNodeProfileItem] = nodeProfileItemState;

    const DEFAULT_DECIMAL_PLACES = 2;

    function onProfileItemValueChange(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) {
        // TODO: break this function into 2 seperate functions when we know which types are JSON (appears to be preset values and checkbox at the very least)
        if (isValidJson(event.target.value)) {
            const parsedEvent = JSON.parse(event.target.value);
            setNodeProfileItem({
                ...nodeProfileItem,
                presetValueID: parsedEvent.id,
                value: parsedEvent.value
            });
        } else {
            setNodeProfileItem({
                ...nodeProfileItem,
                value: event.target.value
            });
        }
    }

    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === 'on') {
            event.target.value = 'true';
        }

        if (nodeProfileItem.value == 'true') {
            event.target.value = 'false';
        }
        else if (nodeProfileItem.value == 'false') {
            event.target.value = 'true';
        }

        onProfileItemValueChange(event);
    };

    function onPresetValueChange(selections: any[]) {
        const value = selections[0];
        if (value === undefined) return;

        const selected = nodeProfileItem.presetValues.find((selected) => selected.id === value);

        if (!!selected) {
            onProfileItemValueChange({ target: { value: JSON.stringify(selected) } } as ChangeEvent<HTMLInputElement>);
        }
    }

    function isValidJson(json: string) {
        try {
            const parsedJson = JSON.parse(json);
            return (typeof parsedJson === 'object');
        } catch (e) {
            return false;
        }
    }

    function getPresetValue() {
        const presetValue = _.find(nodeProfileItem.presetValues, { id: nodeProfileItem.presetValueID });
        const value = presetValue ? presetValue.value : nodeProfileItem.value;
        return truncateString(value);
    }

    function getPresetValueListSelectorValues() {
        return nodeProfileItem.presetValues.map((presetValue) => {
            return {
                name: presetValue.value,
                identifier: presetValue.id
            };
        });
    }

    function getProfileItemWriteElement() {
        switch (nodeProfileItem.type) {
            case ProfileItemTypeEnum.Date:
                return <PatternFormat
                    format='##/##/####'
                    mask={['_', '_', '_', '_', '_', '_', '_', '_']}
                    allowEmptyFormatting
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    customInput={Input}
                    value={nodeProfileItem.value}
                    onValueChange={(values) => {
                        onProfileItemValueChange({ target: { value: values.value } } as ChangeEvent<HTMLInputElement>);
                    }} />;
            case ProfileItemTypeEnum.CheckBox:
                return <Input
                    type='checkbox'
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    className='ms-3'
                    checked={nodeProfileItem.value === 'true'}
                    onChange={onCheckboxChange} />;
            case ProfileItemTypeEnum.PhoneNumber:
                return <PatternFormat
                    format='(###) ###-#### ext. ###'
                    allowEmptyFormatting
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    customInput={Input}
                    value={nodeProfileItem.value}
                    onValueChange={(values) => {
                        onProfileItemValueChange({ target: { value: values.value } } as ChangeEvent<HTMLInputElement>);
                    }} />;
            case ProfileItemTypeEnum.Email:
                return <Input
                    type='email'
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    value={nodeProfileItem.value}
                    onChange={onProfileItemValueChange} />;
            case ProfileItemTypeEnum.Percentage:
                return <NumericFormat
                    customInput={Input}
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    value={nodeProfileItem.value}
                    suffix=' %'
                    thousandSeparator
                    decimalScale={nodeProfileItem.visibleDecimalPlaces ?? DEFAULT_DECIMAL_PLACES}
                    onValueChange={(values) => {
                        onProfileItemValueChange({ target: { value: values.value } } as ChangeEvent<HTMLInputElement>);
                    }} />;
            case ProfileItemTypeEnum.Currency:
                return <NumericFormat
                    customInput={Input}
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    value={nodeProfileItem.value}
                    prefix='$ '
                    thousandSeparator
                    fixedDecimalScale
                    decimalScale={nodeProfileItem.visibleDecimalPlaces ?? DEFAULT_DECIMAL_PLACES}
                    onValueChange={(values) => {
                        onProfileItemValueChange({ target: { value: values.value } } as ChangeEvent<HTMLInputElement>);
                    }} />;
            case ProfileItemTypeEnum.PresetValues:
                return <ListSelector
                    showDisabledReason={false}
                    behaveAsSingleSelect={{ isSearchable: true, isSingleSelect: true }}
                    placeholderText={getPresetValue()}
                    items={getPresetValueListSelectorValues()}
                    selectionChangedCallback={onPresetValueChange} />;
            case ProfileItemTypeEnum.Memo:
                return <textarea className='form-control' rows={8} value={nodeProfileItem.value} onChange={onProfileItemValueChange} />;
            case ProfileItemTypeEnum.Number:
                return <NumericFormat
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    customInput={Input}
                    value={nodeProfileItem.value}
                    onChange={onProfileItemValueChange} />;
            case ProfileItemTypeEnum.Text:
            case ProfileItemTypeEnum.HiddenText:
            default:
                return <Input
                    type='text'
                    required={nodeProfileItem.required}
                    invalid={!!error}
                    value={nodeProfileItem.value}
                    onChange={onProfileItemValueChange} />;
        }
    }

    useEffect(() => {
        setError(isNewValueInvalid(nodeProfileItem.value, nodeProfileItem));
    }, [nodeProfileItem]);

    useEffect(() => {
        if (nodeProfileItem.type === ProfileItemTypeEnum.HiddenText) {
            setNodeProfileItem({ ...nodeProfileItem, value: '' });
            getUnmaskedProfileItemValueAsync(nodeId, nodeProfileItem.id)
                .then((response) => {
                    setNodeProfileItem({ ...nodeProfileItem, value: response.value });
                });
        }        
    }, []);

    return (
        <FormGroup className='form-group-wrapper'>
            {getProfileItemWriteElement()}
            {!!error &&
                <FormFeedback valid={!error}>
                    {error}
                </FormFeedback>
            }
        </ FormGroup>
    );
};
