import React, { useEffect, useState } from 'react';
import { IProfile } from '../../../../../types/ProfilesAndProfileItemsTypes';
import { useProfileApi } from '../../../../../api/useProfileApi';
import { ListSelector, ListSelectorItem } from '../../../../ListSelector/ListSelector';
import { ProfileSelectorTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';

interface ProfileSelectorProps {
    accountId: number;
    onProfileSelected: (profile: IProfile) => void;
}

export const ProfileSelector = ({ accountId, onProfileSelected }: ProfileSelectorProps) => {

    const { t } = useSafeTranslation(TranslationFiles.ProfileSelector);

    const { getAccountProfiles } = useProfileApi();

    const [profiles, setProfiles] = useState<IProfile[]>([]);
    const [listSelectorProfiles, setListSelectorProfiles] = useState<ListSelectorItem[]>([]);

    const onProfileSelect = (selections: any[]) => {
        const profileId = selections[0];
        if (!!profileId) {
            const profile = profiles.find((profile) => profile.id === profileId);
            if (!!profile) {
                profile.profileItems.forEach((profileItem) => {
                    if (!profileItem.value) {
                        profileItem.value = '';
                    }
                });
                onProfileSelected(profile);
            }
        }
    };

    useEffect(() => {
        getAccountProfiles(accountId).then((profiles) => {
            setProfiles(profiles.sort((a, b) => a.name.localeCompare(b.name)));
        });
    }, [accountId]);

    useEffect(() => {
        setListSelectorProfiles(profiles
            .filter(profile => !profile.isDeleted)
            .map(profile => ({ name: profile.name, identifier: profile.id })));
    }, [profiles]);

    return <ListSelector
        behaveAsSingleSelect={{ isSearchable: true, isSingleSelect: true }}
        placeholderText={t(ProfileSelectorTKeys.TypeToSearch)}
        items={listSelectorProfiles}
        selectionChangedCallback={onProfileSelect} />;
};
