import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../auth';
import { useSettingsAndFeatures } from '../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { FeedbackModal } from './Help/Feedback/FeedbackModal';

export const ExperiencedFeedback = () => {
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [hasShownFeedback, setHasShownFeedback] = useState(false);

    const { loginCount } = useAuthContext();
    const { settings } = useSettingsAndFeatures();

    const shouldShowFeedbackModal = () => {
        if (!!loginCount) {
            return loginCount === settings?.feedbackLoginCount && !hasShownFeedback;
        }

        return false;
    };

    useEffect(() => {
        if (shouldShowFeedbackModal()) {
            setShowFeedbackModal(true);
            setHasShownFeedback(true);
        } else {
            setShowFeedbackModal(false);
        }
    }, [loginCount]);

    return <FeedbackModal showFeedbackModal={showFeedbackModal} toggleFeedback={() => setShowFeedbackModal(false)} isExperiencedFeedback={true} />;
};
