import React from 'react';
import { EfcLink, Text } from '@efilecabinet/efc-atlantis-components';
import { useDocRequestsRouting } from '../../../../hooks/Routing/useDocRequestsRouting';
import { Link } from 'react-router-dom';
import { TranslationFiles, UploadLinkTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { UploadLinkProps } from './UploadLinkModal';
import ShareableLink from '../../../../components/ShareableLink/ShareableLink';
import { ThemeEnum } from '../../../../hooks/useColors';

export const ViewUploadLink = ({ destroyModal, uploadLink, node }: UploadLinkProps) => {
    const { t } = useSafeTranslation(TranslationFiles.UploadLinkModal);
    const { getLinkToDocRequestSent } = useDocRequestsRouting();

    return (
        <>
            <ShareableLink link={uploadLink?.uploadLinkURL || ''} label={t(UploadLinkTKeys.LinkLabel, { nodeName: node.name })} />
            <Text className='d-block mt-4 mb-75r'>
                {t(UploadLinkTKeys.ViewDescription, { nodeName: node.name })}
                <EfcLink color={ThemeEnum.Primary} tag={Link} to={getLinkToDocRequestSent()} onClick={destroyModal} dataId='link-to-sent-doc-requests'>
                    {t(UploadLinkTKeys.SentDocumentRequests)}
                </EfcLink>
                {t(UploadLinkTKeys.List)}
            </Text>
        </>
    );
};
