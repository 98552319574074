import React, { useEffect, useState } from 'react';
import { Button, Modal, Text } from '@efilecabinet/efc-atlantis-components';
import { RecycleBinTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { ThemeEnum } from '../../../../hooks/useColors';
import { useIcons } from '../../../../hooks/useIcons';
import './RecycleBinDeleteConfirmationModal.css';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { ToastMessage } from '../../../../hooks/useToastMessages';

interface NodesToDelete {
    id: string;
    name: string;
}

interface RecycleBinDeleteConfirmationModalProps {
    onClose: () => void;
    nodesToDelete: NodesToDelete[];
}

export const RecycleBinDeleteConfirmationModal = ({ onClose, nodesToDelete }: RecycleBinDeleteConfirmationModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.RecycleBin);
    const { spinnerIconProps } = useIcons();
    const { showToastMessage } = useLayerContext();

    const [showSpinner, setShowSpinner] = useState(false);

    const handleConfirm = () => {
        setShowSpinner(true);
        const recycleBinIFrame = document.querySelector('[name="recycleBinIFrame"]') as HTMLIFrameElement;
        recycleBinIFrame?.contentWindow?.postMessage({ type: 'CONFIRM_RECYCLE_BIN_PURGE' }, '*');
    };

    const handleResponse = (e: MessageEvent) => {
        if (e.data?.type === 'RECYCLE_BIN_PURGE_RESULT') {
            setShowSpinner(false);
            const toastData = {
                color: ThemeEnum.Success,
                identifier: 'purge-result-toast',
                message: t(RecycleBinTKeys.DeleteSuccess, { count: e?.data?.count }),
                timeout: 3500,
            } as ToastMessage;

            if (!e?.data?.success) {
                toastData.color = ThemeEnum.Danger;
                toastData.message = t(RecycleBinTKeys.DeleteError, { count: e?.data?.count });
                delete toastData.timeout;
            }

            showToastMessage(toastData);
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleResponse);

        return () => {
            window.removeEventListener('message', handleResponse);
        };
    }, []);

    return (
        <Modal isOpen={true} title={t(RecycleBinTKeys.DeleteModalTitle, { count: nodesToDelete.length })} toggle={onClose}>
            <Modal.Body>
                <Text>{t(RecycleBinTKeys.DeleteModalBody, { count: nodesToDelete.length })}</Text>
                <div className='modal-body-scrollable mt-3'>
                    <ul>
                        {nodesToDelete.map((node) => (
                            <li key={node.id}>{node.name}</li>
                        ))}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer className='mt-1'>
                <Button emphasis='med' onClick={onClose} disabled={showSpinner}>
                    {t(RecycleBinTKeys.Cancel)}
                </Button>
                <Button color={ThemeEnum.Danger} onClick={handleConfirm} icon={showSpinner ? spinnerIconProps : undefined} disabled={showSpinner}>
                    {t(RecycleBinTKeys.Confirm)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
