import React from 'react';
import dayjs from 'dayjs';
import { Modal, Text, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { NotesTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useBootstrapBreakpoints } from '../../../../../hooks/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../../types/BootstrapTypes';
import { INodeNote } from '../../../../../types/NodeTypes';


interface ConfirmationModalProps {
    onClose: (isConfirmed: boolean, noteId: number) => void;
    closeModalFn: () => void;
    note: INodeNote;
}

export const ConfirmationDeleteModal = (props: ConfirmationModalProps) => {
    const { onClose, closeModalFn, note } = props;

    const { t } = useSafeTranslation(TranslationFiles.Notes);
    const { screenSize } = useBootstrapBreakpoints();


    const getDisplayDate = (dateString: string) => {
        return dayjs(dateString).format('DD MMMM YYYY h:mm A');
    };

    const handleClose = () => {
        // click x or off canvas to close modal
        onClose(false, note.id as number);
        closeModalFn();
    };

    const handleDeny = () => {
        // click cta that is med emphasis
        onClose(false, note.id as number);
        closeModalFn();
    };

    const handleConfirm = () => {
        // click cta that is high emphasis
        onClose(true, note.id as number);
        closeModalFn();
    };

    const getCtas = (): EfcCallToActionInfo[] => {
        return [
            {
                text: t(NotesTKeys.Cancel),
                icon: undefined,
                emphasis: 'med',
                color: 'primary',
                disabled: false,
                onClick: handleDeny,
                dataId: 'btnNegativeConfirmation',
            },
            {
                text: t(NotesTKeys.Delete),
                icon: undefined,
                emphasis: 'high',
                color: 'danger',
                disabled: false,
                onClick: handleConfirm,
                dataId: 'btnAffirmativeConfirmation',
            }
        ];
    };

    return (
        <Modal
            size='md'
            isOpen={true}
            toggle={handleClose}
            ctas={getCtas()}
            unmountOnClose={true}
            title={t(NotesTKeys.ConfirmationModalTitle)}
            className={`${screenSize < ScreenSize.s ? 'md-field-mobile-modal-spacing' : ''}`}>
            <Modal.Body>
                <Text>{t(NotesTKeys.ConfirmationModalText)}</Text>

                <div className='card p-3 my-3' key={note.id}>
                    <div className='mb-2'>
                        <div className='mb-2'>
                            <Text bold={true}>{note.createdBy}</Text>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Text color='#696969'>{getDisplayDate(note?.lastModifiedOn ?? note?.createdOn as string)}</Text>
                        </div>
                    </div>
                    <div className='card p-3 border-0' style={{ backgroundColor: '#F8F3FC', maxHeight: '400px', overflow: 'auto' }}>
                        <Text>{note.text}</Text>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};