import React from 'react';
import { RevverIcon, Text } from '@efilecabinet/efc-atlantis-components';
import { BrandingTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import './SignInPagePreview.css';

interface SignInPagePreviewProps {
    disabled?: boolean;
    accountName?: string;
    logoString?: string;
    includeLogo?: boolean;
}

export const SignInPagePreview = (prop: SignInPagePreviewProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Branding);
    const { disabled, accountName, logoString, includeLogo } = prop;

    return (
        <>
            <div className='sign-in-preview-container'>
                <div className='sign-in-form shadow'>
                    <RevverIcon color='#7514BD' className='mx-2 mx-sm-5' />
                    <div className='border-top border-2 border-light my-4 w-100'></div>
                    <div className='mb-3'>
                        <div className='account-name'>{t(BrandingTKeys.SignInPreviewMessage, { accountName })}</div>
                        {!!logoString && !!includeLogo && (
                            <div className='logo-container mt-2'>
                                <img className='logo' src={logoString} alt='logo' />
                            </div>
                        )}
                    </div>
                    <div className='username'>
                        <div className='label'>
                            <Text size='micro' semibold>
                                {t(BrandingTKeys.SignInPreviewEmailLabel)}
                            </Text>
                            <div className='checkbox-container'>
                                <div className='checkbox'></div>
                                <Text size='micro' semibold>
                                    {t(BrandingTKeys.SignInPreviewRememberMe)}
                                </Text>
                            </div>
                        </div>
                        <div className='input-container'></div>
                    </div>
                    <div className='button'>{t(BrandingTKeys.SignInPreviewNext)}</div>
                </div>
                {disabled && <div className='sign-in-preview-overlay'></div>}
            </div>
        </>
    );
};
