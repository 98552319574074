import { Api } from './';
import { AxiosResponse } from 'axios';
import { IUtopiaTaskCount } from '../types/ToDoTypes';
import {  NotificationOrderEnum, NotificationStatusEnum, IUtopiaNotification } from '../types/UtopiaNotificationTypes';

export const useWorkYouCareAboutApi = () => {
    const getUserWorkYouCareAbout = async (notificationStatuses: NotificationStatusEnum[], orderBy: NotificationOrderEnum, start: number, count: number): Promise<IUtopiaNotification[]> => {
        const resp: AxiosResponse = await Api.get(`api/WorkYouCareAbout?${notificationStatuses.map((alertStatus) => `notificationStatuses=${alertStatus}&`).join('')}orderBy=${orderBy}&start=${start}&count=${count}`);
        return resp.data;
    };

    const getUserWorkYouCareAboutCount = async (taskStatuses: NotificationStatusEnum[]) => {
        const resp: AxiosResponse = await Api.get(`api/WorkYouCareAbout/Count?${taskStatuses.map((taskStatus) => `taskStatuses=${taskStatus}&`).join('')}`);
        return resp.data as IUtopiaTaskCount;
    };

    return {
        getUserWorkYouCareAbout,
        getUserWorkYouCareAboutCount,
    };
};