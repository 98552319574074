import { INode } from './CommonTypes';

export enum RecentNodeTypeEnum {
    Uploads = 0,
    Downloads = 1,
    All = 2,
}

export interface INodeRecent extends INode {
    auditLogDate: string;
}

export interface IRecentNodeQueryParams {
    recentType: RecentNodeTypeEnum;
    start: number;
    count: number;
}