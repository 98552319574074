import React, { useEffect, useState } from 'react';
import { IProfileItem } from '../../../../../types/ProfilesAndProfileItemsTypes';
import { useProfileItemApi } from '../../../../../api/useProfileItemApi';
import { ListSelector, ListSelectorItem } from '../../../../ListSelector/ListSelector';

interface ProfileItemSelectorProps {
    accountId: number;
    disabledProfileItems: IProfileItem[];
    onProfileItemSelect: (profileItem: IProfileItem) => void;
}

export const ProfileItemSelector = ({ accountId, disabledProfileItems, onProfileItemSelect: onProfileItemSelected }: ProfileItemSelectorProps) => {

    const { getAccountProfileItems } = useProfileItemApi();

    const [profileItems, setProfileItems] = useState<IProfileItem[]>([]);
    const [listSelectorProfileItems, setListSelectorProfileItems] = useState<ListSelectorItem[]>([]);

    const onSelectionChanged = (selections: any[]) => {
        const profileItemId = selections[0];
        if (!!profileItemId) {
            const profileItem = profileItems.find((profileItem) => profileItem.id === profileItemId);
            if (!!profileItem) {
                if (!profileItem.value) {
                    profileItem.value = '';
                }
                onProfileItemSelected(profileItem);
            }
        }
    };

    useEffect(() => {
        getAccountProfileItems(accountId).then((profileItems) => {
            setProfileItems(profileItems.sort((a, b) => a.name.localeCompare(b.name)));
        });
    }, [accountId]);

    useEffect(() => {
        setListSelectorProfileItems(profileItems
            .filter(profileItem => !profileItem.isDeleted)
            .map(profileItem => ({
                name: profileItem.name,
                identifier: profileItem.id,
                isDisabled: disabledProfileItems.some((item) => item.id == profileItem.id)
            }))
        );
    }, [profileItems]);

    return <ListSelector
        behaveAsSingleSelect={{ isSearchable: true, isSingleSelect: true }}
        placeholderText={'Type to Search'}
        items={listSelectorProfileItems}
        selectionChangedCallback={onSelectionChanged} />;
};
