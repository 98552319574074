import { useEffect, useState } from 'react';
import { ScreenSize } from '../types/BootstrapTypes';

export const useBootstrapBreakpoints = () => {

    const calculateScreenSize = (): ScreenSize => {
        const width = window.innerWidth;
        if (width >= ScreenSize.xxl) {
            return ScreenSize.xxl;
        } else if (width >= ScreenSize.xl) {
            return ScreenSize.xl;
        } else if (width >= ScreenSize.l) {
            return ScreenSize.l;
        } else if (width >= ScreenSize.m) {
            return ScreenSize.m;
        } else if (width >= ScreenSize.s) {
            return ScreenSize.s;
        } else {
            return ScreenSize.xs;
        }
    };

    const [screenSize, setScreenSize] = useState<ScreenSize>(calculateScreenSize());

    const onScreenResized = () => {
        setTimeout(() => { // on maximize, window.innerWidth is not updated immediately
            const newScreenSize = calculateScreenSize();
            setScreenSize(newScreenSize);
        });
    };

    useEffect(() => {
        window.addEventListener('resize', onScreenResized);
        return () => {
            return window.removeEventListener('resize', onScreenResized);           
        };
    }, []);

    return {
        screenSize,
    };
};