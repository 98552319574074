import { Api } from './';
import { AxiosResponse } from 'axios';

export const useSystemApi = () => {

    const getRubexBaseUrl = async () => {
        const resp: AxiosResponse = await Api.get('api/system/RubexBaseUrl');
        return resp.data;
    };

    const getRubexConfiguration = async () => {
        const resp: AxiosResponse = await Api.get('api/system/RubexConfiguration');
        return resp.data;
    };

    const getSettings = async () => {
        const resp: AxiosResponse = await Api.get('api/system/Settings');
        return resp.data;
    };

    const getFeatureFlags = async () => {
        const resp: AxiosResponse = await Api.get('api/system/FeatureFlags');
        return resp.data;
    };

    const getOptInInfo = async (userId: number) => {
        const resp: AxiosResponse = await Api.get(`api/system/OptInInfo/${userId}`);
        return resp.data;
    };

    return {
        getSettings,
        getRubexConfiguration,
        getRubexBaseUrl,
        getFeatureFlags,
        getOptInInfo
    };
};
