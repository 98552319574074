import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { NodeType } from '../../../types/CommonTypes';
import { useIcons } from '../../../hooks/useIcons';
import './NodeIcon.css';

interface INodeIconProps {
    fileExtension: string,
    nodeType: NodeType, 

    customIconClassName?: string,
    fontAwesomeClassName?: string,
    fontAwesomeSize?: SizeProp,
}

export const NodeIcon = ({fileExtension, nodeType, customIconClassName, fontAwesomeClassName, fontAwesomeSize}: INodeIconProps) => {

    const { getNodeIconProps } = useIcons();

    if (nodeType != NodeType.Cabinet && nodeType != NodeType.Drawer) {
        const iconProps: FontAwesomeIconProps = {
            ...getNodeIconProps(nodeType, fileExtension),
            className: fontAwesomeClassName,
            size: fontAwesomeSize,
        };
        if (nodeType != NodeType.File && nodeType != NodeType.TemplateFile) {
            iconProps.color = '#8e8e8e';
        }
        return <FontAwesomeIcon {...iconProps} />;
    } else {
        const iconClassName = nodeType == NodeType.Cabinet ? 'icon-efc-cabinet' : 'icon-efc-drawer';
        return (
            <div className='d-flex justify-content-center'>
                <i className={`${iconClassName} ${customIconClassName ?? (nodeType == NodeType.Cabinet ? 'cabinet-default-node-icon' : 'drawer-default-node-icon') }`}></i>
            </div>
        );
    }
};
