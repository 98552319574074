import React, { FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Text, Toggle } from '@efilecabinet/efc-atlantis-components';
import './MDAddedField.css';

export interface slimMDField {
    id: string;
    name: string;
    required: boolean;
}

export interface MDAddedFieldProps {
    field: slimMDField;
    onRemove: (id: string) => void;
    onToggleRequired: (id: string, isChecked: boolean) => void;
}

export const MDAddedField: FC<MDAddedFieldProps> = ({ field, onRemove, onToggleRequired }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: field.id });

    const [isChecked, setIsChecked] = useState(field.required);

    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    };

    const toggleRequired = () => {
        setIsChecked((prevState) => !prevState);
        onToggleRequired(field.id, isChecked);
    };

    return (
        <div className='field-border' ref={setNodeRef} {...attributes} {...listeners} style={style}>
            <Row>
                <Col xs='auto'>
                    <FontAwesomeIcon className='remove-field-from-group' icon='up-down-left-right' />
                </Col>
                <Col xs={6}>
                    {field.name}
                </Col>
                <Col>
                    <Row>
                        <Col xs='auto'>
                            <Text> required </Text>
                        </Col>
                        <Col xs='auto'>
                            <Toggle isChecked={isChecked} onClick={toggleRequired} className='me-3 opt-out-toggle' />
                        </Col>
                        <Col xs='auto'>
                            <FontAwesomeIcon icon='trash-can' onClick={() => onRemove(field.id)} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};