import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, Card, TooltipItem, Text } from '@efilecabinet/efc-atlantis-components';
import { AuthContext, IAuthContext } from '../../../auth';
import { ICollection, ICollectionNode } from '../../../types/CollectionTypes';
import { useCollectionsApi } from '../../../api/';
import { TranslationFiles, useSafeTranslation, FavoritesTKeys } from '../../../hooks/useSafeTranslation';
import { useIcons } from '../../../hooks/useIcons';
import { useColors } from '../../../hooks/useColors';
import { useNodeApi } from '../../../api/useNodeApi';
import { useCardSizes } from '../../../hooks/useCardSizes';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { ZeroState } from '../../../components/ZeroState';
import { ScreenSize } from '../../../types/BootstrapTypes';
import { useBootstrapBreakpoints } from '../../../hooks/useBootstrapBreakpoints';
import zeroStateImg from './FavoritesZeroState.svg';
import './Favorites.css';

export const Favorites = () => {
    const [favoritesCollection, setFavoritesCollection] = useState<ICollection | null>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);
    const [favoriteNodes, setFavoriteNodes] = useState<ICollectionNode[]>([]);

    const { user } = useContext(AuthContext) as IAuthContext;
    const { routeToCollections, getLinkToCollections, getLinkToNode } = useRouting();
    const { getNodeIconProps } = useIcons();
    const { t } = useSafeTranslation(TranslationFiles.Favorites);
    const colors = useColors();
    const { getCardTitleSize, getCardIconSize } = useCardSizes();
    const { screenSize } = useBootstrapBreakpoints();

    const { getCollectionById } = useCollectionsApi();
    const { getNodeBatchAsync } = useNodeApi();

    const NUM_NODES = 10;

    const getFavoritesNodes = (favorites: ICollection) => {
        if (favorites) {
            getNodeBatchAsync(favorites.collectionNodes.map((x) => x.nodeID))
                .then((items: ICollectionNode[]) => {
                    setFavoriteNodes(sortFavoriteNodes(favorites, items));
                    setIsLoading(false);
                })
                .catch(() => {
                    setErrorLoading(true);
                    setIsLoading(false);
                });
        }
    };

    const sortFavoriteNodes = (favorites: ICollection, nodes: ICollectionNode[]): ICollectionNode[] => {
        const nodeDictionary = new Map<string, ICollectionNode>();
        nodes.forEach((e) => nodeDictionary.set(e.id, e));
        return favorites.collectionNodes
            .sort((a, b) => b.id - a.id)
            .map((e) => nodeDictionary.get(e.nodeID))
            .filter((i) => !!i) as ICollectionNode[];
    };

    useEffect(() => {
        if (!!user) {
            if (!!user.preferences.defaultCollectionID) {
                getCollectionById(user.preferences.defaultCollectionID)
                    .then((favorites) => {
                        setFavoritesCollection(favorites);
                        getFavoritesNodes(favorites);
                    })
                    .catch(() => {
                        setErrorLoading(true);
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
        }
    }, [user]);

    return (
        <>
            <Card.Title tag={Link} to={getLinkToCollections()} className='ms-1 no-decoration' dataId="lblFavorites" size={getCardTitleSize()} bold icon={{ icon: 'heart', pull: 'left', color: colors.atlantisFavsAndCollections, size: getCardIconSize() }}>
                {!!favoritesCollection ? favoritesCollection.name : t(FavoritesTKeys.FavoritesTitle)}
            </Card.Title>

            {!!isLoading || !!errorLoading || !user ? (
                <>
                    {(!!isLoading || !user) && (
                        <SkeletonTheme enableAnimation height='24px' width='100%'>
                            <Skeleton count={NUM_NODES / 2} className='mb-3 mx-auto' />
                        </SkeletonTheme>
                    )}

                    {!!errorLoading && <Card.Text>{t(FavoritesTKeys.ErrorLoading)}</Card.Text>}
                </>
            ) : (
                <>
                    {!favoritesCollection && <Card.Text>{t(FavoritesTKeys.NoCollections)}</Card.Text>}

                    {favoriteNodes.length == 0 &&
                        <ZeroState
                            imageColContent={<img src={zeroStateImg} alt={t(FavoritesTKeys.ZeroStateImgAltText)} />}
                            textColContent={
                                <>
                                    <Text bold> {t(FavoritesTKeys.NoFavoritesMainText)} </Text>
                                    <br />
                                    <Text> {t(FavoritesTKeys.NoFavoritesSubText)} </Text>
                                </>
                            }
                            stackColumns={screenSize < ScreenSize.xxl} />
                    }

                    {favoriteNodes.slice(0, NUM_NODES).map((node) => (
                        <Card.Link dataId={`lbl-${node.name}`} key={node.id} tag={Link} to={getLinkToNode([node.id])} className='ms-1 my-3' icon={{ ...getNodeIconProps(node.systemType, node.fileInfo?.fileExtension), size: 'lg' }}>
                            <TooltipItem innerClassName='truncated-text' id={node.id} message={node.name} data-trigger="hover">
                                {node.name}
                            </TooltipItem>
                        </Card.Link>
                    ))}

                    {!!favoritesCollection && favoriteNodes.length >= NUM_NODES && (
                        <div className='d-flex justify-content-center'>
                            <Button color='primary' emphasis='low' onClick={() => routeToCollections()}>
                                {t(FavoritesTKeys.SeeAllText)}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
