import { Api } from '../api';
import { AxiosResponse } from 'axios';
import { IsAuthenticatedResponse } from '../types/AuthenticationTypes';

export const useAuthApi = () => {

    const getRubexBaseUrl = async () => {
        const resp: AxiosResponse = await Api.get('api/authentication/RubexBaseUrl');
        return resp.data;
    };

    const getAuthenticatedUser = async () => {
        const resp: AxiosResponse = await Api.get('api/authentication/SignedInUser');
        return resp.data;
    };

    const getCurrentUser = async () => {
        const resp: AxiosResponse = await Api.get('api/authentication/GetCurrentUser');
        return resp.data;
    };

    const getIsAuthenticatedAsync = async (): Promise<IsAuthenticatedResponse> => {
        const resp: AxiosResponse = await Api.get('api/authentication/IsAuthenticated');
        return resp.data as IsAuthenticatedResponse;
    };

    const getUserAccounts = async () => {
        const resp: AxiosResponse = await Api.get('api/authentication/GetUserAccounts');
        return resp.data;
    };

    const getOneTimeToken = async () => {
        const resp: AxiosResponse = await Api.get('api/authentication/RubexOneTimeToken');
        return resp.data;
    };

    const loginWithToken = async (token: string) => {
        const resp: AxiosResponse = await Api.get(`api/authentication/Login/${token}`);
        return resp.data;
    };

    const logout = async () => {
        const resp: AxiosResponse = await Api.delete('api/authentication/Logout');
        return resp.data;
    };

    const switchToAccount = async (roleId: number) => {
        const resp: AxiosResponse = await Api.post(`api/authentication/SwitchAccount/${roleId}`);
        return resp.data;
    };

    return {
        getAuthenticatedUser,
        getCurrentUser,
        getIsAuthenticatedAsync,
        loginWithToken,
        getOneTimeToken,
        getRubexBaseUrl,
        logout,
        getUserAccounts,
        switchToAccount
    };
};
