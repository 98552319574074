import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Badge, Card, Dropdown, Tile, Text } from '@efilecabinet/efc-atlantis-components';
import { HelpNavMenuItemTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useGoToProductBoard } from '../../../../../hooks/useGoToProductBoard';
import { useAuthContext } from '../../../../../auth';
import { OSName, useUtilities } from '../../../../../hooks/useUtilities';
import { useSettingsAndFeatures } from '../../../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { FeatureFlags } from '../../../../../types/FeatureFlags';
import { ExperienceTypeEnum } from '../../../../../auth/AuthenticationTypes';
import { useRouting } from '../../../../../hooks/Routing/useRouting';
import needHelpImage from './NeedHelpIllus.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './HelpCard.css';

interface HelpCardProps {
    isSimplifiedCard?: boolean;
    toggleFeedback: () => void;
}

export const HelpCard = (props: HelpCardProps) => {
    const { isSimplifiedCard, toggleFeedback } = props;

    const externalLinkIconColor = '#b281be';

    const { t } = useSafeTranslation(TranslationFiles.HelpNavMenuItem);

    const { settings, hasFeatureFlags, checkFeatureFlag, utopiaConfig, hasDesktopApplicationInfo, desktopApplicationInfo } = useSettingsAndFeatures();
    const { openProductBoardUpdatesInNewTab } = useGoToProductBoard();
    const { getOperatingSystemName } = useUtilities();
    const { experienceType } = useAuthContext();
    const { getLinkToSystemLogsServer } = useRouting();

    const clickChatNow = () => {
        window.open(settings?.chatNowUrl, '_blank');
    };

    const clickKnowledgeBase = () => {
        window.open(settings?.knowledgeBaseUrl, '_blank');
    };

    const clickDownloadApp = () => {
        const osType = getOperatingSystemName();

        let appSpecificUrl = 'Windows/RubexInstaller.exe';

        if (osType === OSName.Mac) {
            appSpecificUrl = 'Mac/RevverInstaller.dmg';
        }

        window.open(settings?.utopiaUrl + '/ClientApplicationInstallers/' + appSpecificUrl, '_blank');
    };

    const clickNewExperienceGuide = () => {
        window.open(settings?.guideToNewExperienceUrl, '_blank');
    };

    return (
        <>
            <Card fluid borderless>
                <Card.Body className='help-navmenu-card'>
                    <Card.Title className='ms-1 no-decoration' size='lg' bold icon={{ icon: ['far', 'circle-question'], pull: 'left', size: 'lg' }}>
                        {t(HelpNavMenuItemTKeys.Help)}
                    </Card.Title>

                    {!isSimplifiedCard
                        && !!settings?.guideToNewExperienceUrl
                        && !!hasFeatureFlags
                        && checkFeatureFlag(FeatureFlags.ShowInAppGuidance)
                        && experienceType == ExperienceTypeEnum.Standard &&
                            <>
                                <Tile fluid className='need-help-tile my-3 p-1'>
                                    <Row>
                                        <Col xs='auto' className='p-0'>
                                            <img src={needHelpImage} className='m-0' height='100%' width='100%' alt='home' />
                                        </Col>
                                        <Col>
                                            <Card.Link className='ms-1 my-3' onClick={clickNewExperienceGuide} color='primary'>
                                                {t(HelpNavMenuItemTKeys.ViewNewExperienceGuide)}
                                                <FontAwesomeIcon icon='arrow-up-right-from-square' color={externalLinkIconColor} size='lg' className='mx-2' />
                                            </Card.Link>
                                        </Col>
                                    </Row>
                                </Tile>

                                <Dropdown.Item divider />
                            </>
                    }

                    <Card.Link className='ms-1 my-3' onClick={clickChatNow}>
                        {t(HelpNavMenuItemTKeys.ChatNow)}
                        <FontAwesomeIcon icon='arrow-up-right-from-square' color={externalLinkIconColor} size='lg' className='mx-2' />
                    </Card.Link>
                    <Card.Link className='ms-1 my-3' onClick={clickKnowledgeBase}>
                        {t(HelpNavMenuItemTKeys.KnowledgeBase)}
                        <FontAwesomeIcon icon='arrow-up-right-from-square' color={externalLinkIconColor} size='lg' className='mx-2' />
                    </Card.Link>

                    {!isSimplifiedCard && experienceType == ExperienceTypeEnum.Standard && <Dropdown.Item divider />}

                    {!!hasFeatureFlags
                        && experienceType == ExperienceTypeEnum.Standard
                        && checkFeatureFlag(FeatureFlags.ProductUpdatesEnabled) &&
                            <Card.Link className='ms-1 my-3' onClick={openProductBoardUpdatesInNewTab}>
                                {t(HelpNavMenuItemTKeys.ProductUpdates)}
                                <FontAwesomeIcon icon='arrow-up-right-from-square' color={externalLinkIconColor} size='lg' className='mx-2' />
                                <Badge color='#1b6ce6' className='me-2'>
                                    {t(HelpNavMenuItemTKeys.New)}
                                </Badge>
                            </Card.Link>
                    }

                    {experienceType == ExperienceTypeEnum.Standard &&
                        <Card.Link className='ms-1 my-3' onClick={toggleFeedback}>
                            {t(HelpNavMenuItemTKeys.ShareFeedback)}
                            <Badge className='mx-2' color='#1b6ce6'>
                                {t(HelpNavMenuItemTKeys.New)}
                            </Badge>
                        </Card.Link>
                    }

                    {!isSimplifiedCard && experienceType == ExperienceTypeEnum.Standard && (
                        <>
                            <Dropdown.Item divider />

                            <Card.Link className='ms-1 my-3' onClick={clickDownloadApp}>
                                {t(HelpNavMenuItemTKeys.DownloadDesktopApp)}
                                <FontAwesomeIcon icon={['far', 'download']} color={externalLinkIconColor} size='lg' className='mx-2' />
                            </Card.Link>

                            <Dropdown.Item divider />

                            <Card.Link className='ms-1 my-3' tag={Link} to={getLinkToSystemLogsServer()}>
                                {t(HelpNavMenuItemTKeys.SystemLogs)}
                            </Card.Link>

                            <Dropdown.Item divider />

                            {!!utopiaConfig?.Version && (
                                <div className='d-flex justify-content-center mt-4'>
                                    <Text muted size='sm' className='my-auto'>
                                        {t(HelpNavMenuItemTKeys.Version)} {utopiaConfig?.Version}
                                    </Text>
                                </div>
                            )}

                            {!!hasDesktopApplicationInfo && (
                                <div className='d-flex justify-content-center mt-4'>
                                    <Text muted size='sm' className='my-auto'>
                                        {t(HelpNavMenuItemTKeys.AppVersion)} {desktopApplicationInfo?.version}
                                    </Text>
                                </div>
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};