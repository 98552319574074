export enum OSName {
    Windows = 'Windows',
    Mac = 'Mac',
    Unix = 'Unix',
    Linux = 'Linux',
    SunOS = 'SunOS',
}

enum SizeUnits {
    Bytes = 0,
    KB = 1,
    MB = 2,
    GB = 3,
    TB = 4,
}

export const useUtilities = () => {
    const getOperatingSystemName = (): OSName | null => {
        const platform = navigator.platform;
        if (platform.includes('Win')) {
            return OSName.Windows;
        } else if (platform.includes('Mac')) {
            return OSName.Mac;
        } else if (platform.includes('X11') || platform.includes('HP-UX')) {
            return OSName.Unix;
        } else if (platform.includes('Linux')) {
            return OSName.Linux;
        } else if (platform.includes('SunOS')) {
            return OSName.SunOS;
        } else {
            return null;
        }
    };

    const formatDateTime = (dateString: string) => {
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'numeric',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        })
            .format(new Date(dateString))
            .replace(',', ' |');
        return formattedDate;
    };

    const getSizeDisplay = (size: number, decimalPoints = 0) => {
        let count = 0;
        let actSize = size;

        while (actSize > 1000) {
            actSize /= 1024;
            count++;
        }

        return `${actSize.toFixed(decimalPoints)} ${SizeUnits[count]}`;
    };

    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email.toLowerCase());
    };

    return {
        getOperatingSystemName,
        formatDateTime,
        getSizeDisplay,
        isEmailValid
    };
};
