import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { TextRadioButton, EfcTextRadioButtonInfo, Label } from '@efilecabinet/efc-atlantis-components';
import { IMDField, NumberFormatTypes, NumberFormatTypeKeyStrings } from '../../../../../types/MDFieldTypes';
import { NumberMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { NumberMDFieldAdvancedFormatting } from './NumberMDFieldAdvancedFormatting';

export interface NumberMDFieldFormatsProps {
    mdField: IMDField;
    setMDField: Dispatch<SetStateAction<IMDField>>;
    setIsFormValid: (isFormValid: boolean) => void;
}

export const NumberMDFieldFormats = (props: NumberMDFieldFormatsProps) => {
    const { mdField, setMDField, setIsFormValid } = props;

    const { t } = useSafeTranslation(TranslationFiles.NumberMDFieldModal);

    const getButtonInfos = (): EfcTextRadioButtonInfo[] => {
        return [
            { key: NumberFormatTypes[NumberFormatTypes.Number], labelName: t(NumberMDFieldModalTKeys.Number), helperText: t(NumberMDFieldModalTKeys.NumberHelpText), checked: mdField?.numberProps?.numberFormat == NumberFormatTypes.Number ?? true },
            { key: NumberFormatTypes[NumberFormatTypes.Percentage], labelName: t(NumberMDFieldModalTKeys.Percentage), helperText: t(NumberMDFieldModalTKeys.PercentageHelpText), checked: mdField?.numberProps?.numberFormat == NumberFormatTypes.Percentage ?? false },
            { key: NumberFormatTypes[NumberFormatTypes.Decimal], labelName: t(NumberMDFieldModalTKeys.Decimal), helperText: t(NumberMDFieldModalTKeys.DecimalHelpText), checked: mdField?.numberProps?.numberFormat == NumberFormatTypes.Decimal ?? false },
            { key: NumberFormatTypes[NumberFormatTypes.Currency], labelName: t(NumberMDFieldModalTKeys.Currency), helperText: t(NumberMDFieldModalTKeys.CurrencyHelpText), checked: mdField?.numberProps?.numberFormat == NumberFormatTypes.Currency ?? false },
        ];
    };

    const onChecked = (button: EfcTextRadioButtonInfo) => {
        setMDField({ ...mdField, numberProps: { ...mdField.numberProps, numberFormat: NumberFormatTypes[button.key as NumberFormatTypeKeyStrings] } });
    };

    useEffect(() => {
        if (!mdField?.numberProps?.numberFormat) {
            setMDField({ ...mdField, numberProps: { ...mdField.numberProps, numberFormat: NumberFormatTypes.Number } });
        }
    }, []);

    return (
        <>
            <Row className='my-5'>
                <Col>
                    <Label className='mb-3'>{t(NumberMDFieldModalTKeys.SelectFormat)}</Label>
                    <TextRadioButton buttonInfos={getButtonInfos()} onChecked={onChecked} />
                </Col>
            </Row>

            {mdField.numberProps?.numberFormat == NumberFormatTypes.Number &&
                <NumberMDFieldAdvancedFormatting mdField={mdField} setMDField={setMDField} setIsFormValid={setIsFormValid} />
            }
        </>
    );
};