import React, { useEffect, useState } from 'react';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { NestedCollapse } from '../../../NestedCollapse/NestedCollapse';
import { Button, EfcCallToActionInfo, Text } from '@efilecabinet/efc-atlantis-components';
import { Row } from 'reactstrap';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { PermissionDetailsModal } from './DetailsModal/PermissionDetailsModal';
import { IAccessLink, RoleEnum } from '../../../../types/CommonTypes';
import { ThemeEnum } from '../../../../hooks/useColors';
import { useAuthContext } from '../../../../auth';
import { useNodePermissionsUtilities } from '../../../../hooks/useNodePermissionsUtilities';
import { SidesheetDisplayCard } from '../../../SideSheet/SidesheetDisplayCard';
import { INodePermission } from '../../../../types/NodePermissionTypes';
import { useAccessLinks } from '../../../../hooks/useAccessLinks';
import { UserLicenseEnum } from '../../../../auth/AuthenticationTypes';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EditAccessLinkModal } from './EditAccessLinkModal/EditAccessLinkModal';
import { ContextMenuOptions, ContextMenuProps } from '../../../ContextMenu/ContextMenu';

interface PermissionsProps {
    onClose?: () => void;
    nodeId?: string;
}

interface PermissionCollection {
    users: INodePermission[];
    groups: INodePermission[];
    guests: INodePermission[];
    accessLinks: IAccessLink[];
}

export const Permissions = ({ onClose, nodeId }: PermissionsProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);

    const { getNodePermissionsAsync, filterNodePermissionsByRoleType, filterNodePermissionsByCreatedByUserId, filterOtherNodePermissionsByCreatedByUserId, filterIndividualPermission } = useNodePermissionsUtilities();
    const { getNodeAccessLinksAsync, filterNodeAccessLinksByUserId, filterOtherNodeAccessLinksByUserId } = useAccessLinks();
    const { openModal, showToastMessage, closeToastMessage } = useLayerContext();
    const { authUser } = useAuthContext();

    const [permissions, setPermissions] = useState<INodePermission[]>([]);
    const [accessLinks, setAccessLinks] = useState<IAccessLink[]>([]);
    const [myPermission, setMyPermission] = useState<INodePermission>();
    const [grantedPermissions, setGrantedPermissions] = useState<PermissionCollection>({ users: [], groups: [], guests: [], accessLinks: [] });
    const [otherPermissions, setOtherPermissions] = useState<PermissionCollection>({ users: [], groups: [], guests: [], accessLinks: [] });

    const [isLoading, setIsLoading] = useState(true);

    const createActions = (item: INodePermission | IAccessLink, isAccessLink = false): ContextMenuProps => {
        const contextActions: ContextMenuProps = {
            options: [
                {
                    text: 'Edit',
                    onClick: () => onEditPermissionClick(item, isAccessLink),
                    dataId: `permissions-menu-edit-${item?.id}`,
                },
                {
                    text: 'Remove',
                    onClick: () => onEditPermissionClick(item, isAccessLink),
                    dataId: `permissions-menu-remove-${item?.id}`,
                },
            ] as ContextMenuOptions[],
            dataId: `permissions-menu-${item?.id}`,
        };
        return contextActions;
    };

    const onViewAllClick = () => {
        openModal((closeModal) => <PermissionDetailsModal destroyModal={closeModal} permissionsState={[permissions, setPermissions]} />);
    };

    const onEditPermissionClick = (item: INodePermission | IAccessLink, isAccessLink: boolean) => {
        if (isAccessLink) {
            openModal((closeModal) => <EditAccessLinkModal destroyModal={closeModal} accessLink={item as IAccessLink} />);
        } else {
            console.log('Open Grant Access Modal');
            // openModal((closeModal) => <GrantPermissionsModal destroyModal={closeModal} permission={item as INodePermission} />);
        }
    };

    const loadNodePermissions = async (showSkeletonLoader = true) => {
        if (!!nodeId) {
            if (showSkeletonLoader && permissions.length == 0) {
                setIsLoading(true);
            }
            try {
                closeToastMessage('error-loading-permissions-toast');

                const permissionsResult = await getNodePermissionsAsync(nodeId);
                const accessLinksResult = await getNodeAccessLinksAsync(authUser?.accountID.toString() as string, nodeId);
                setPermissions(permissionsResult);
                setAccessLinks(accessLinksResult);

                const userRoleID = authUser?.userRoles.find((i) => i.accountID == authUser?.accountID && i.roleType != RoleEnum.Group)?.id.toString() as string;
                const userID = authUser?.userID.toString() as string;
                setMyPermission(filterIndividualPermission(permissionsResult, userRoleID));
                setGrantedPermissions(createCollection(filterNodePermissionsByCreatedByUserId(permissionsResult, userID), filterNodeAccessLinksByUserId(accessLinksResult, userID)));

                const otherPermissions = filterOtherNodePermissionsByCreatedByUserId(permissionsResult, userID).filter((permission) => permission.roleID?.toString() !== userRoleID);
                setOtherPermissions(createCollection(otherPermissions, filterOtherNodeAccessLinksByUserId(accessLinksResult, userID)));

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching Permission data:', error);
                showToastMessage({
                    color: ThemeEnum.Danger,
                    identifier: 'error-loading-permissions-toast',
                    message: t(PermissionsTKeys.ErrorLoadingPermissions),
                });
            }
        }
    };

    const createCollection = (permissions: INodePermission[], accessLinks: IAccessLink[]) => {
        const newCollection: PermissionCollection = {
            users: filterNodePermissionsByRoleType(permissions, RoleEnum.User),
            groups: filterNodePermissionsByRoleType(permissions, RoleEnum.Group),
            guests: filterNodePermissionsByRoleType(permissions, RoleEnum.Guest),
            accessLinks: accessLinks,
        };
        return newCollection;
    };

    const determineAccessLinkPermissions = (permissions: INodePermission[]) => {
        const nodeAccessLink = permissions.find((permission) => permission.nodeID?.toString() === nodeId);
        return determinePermissionsString(nodeAccessLink as INodePermission);
    };

    const determinePermissionCount = (collection: PermissionCollection) => {
        return collection.users.length + collection.groups.length + collection.guests.length + collection.accessLinks.length;
    };

    const determinePermissionsString = (permission: INodePermission): string => {
        let permissionString = '';

        if (permission?.admin) {
            permissionString += 'Admin';
            return permissionString;
        }

        if (permission?.view) {
            permissionString += 'View';
        }
        if (permission?.download) {
            permissionString += ', Download';
        }
        if (permission?.uploadFiles || permission?.createDirectories) {
            permissionString += ', Create';
        }
        if (permission?.write) {
            permissionString += ', Write';
        }
        if (permission?.delete) {
            permissionString += ', Delete';
        }

        return permissionString;
    };

    const determineIcon = (permission: INodePermission): IconProp => {
        if (permission?.roleData.roleType == RoleEnum.Group) {
            return 'users';
        }

        switch (permission?.roleData.license) {
            case UserLicenseEnum.Guest:
                return 'user-shield';
            case UserLicenseEnum.Full:
            case UserLicenseEnum.Essentials:
            case UserLicenseEnum.Compliance:
                return 'user-alt';
            default:
                return 'user-alt';
        }
    };

    useEffect(() => {
        if (!!nodeId) {
            loadNodePermissions();
        }
    }, [authUser, nodeId]);

    return (
        <>
            <SidesheetPage
                title={t(PermissionsTKeys.Title)}
                onClose={onClose}
                dataId='permissions-sideSheet-pane'
                secondaryButton={
                    <Button emphasis='low' color='primary' onClick={onViewAllClick}>
                        {t(PermissionsTKeys.ViewAllButton)}
                    </Button>
                }>
                {!!isLoading ? (
                    <>Loading</>
                ) : (
                    <>
                        <Button emphasis='med' color='primary' className='my-3 w-50 fs-6 fw-bold'>
                            {t(PermissionsTKeys.GrantAccessButton)}
                        </Button>
                        <Text size='lg' className='my-2'>
                            {t(PermissionsTKeys.PermissionsAndSharingHeader)}
                        </Text>
                        <NestedCollapse title={t(PermissionsTKeys.YourPermissionsHeader)} tooltipMessage={t(PermissionsTKeys.GrantedToolTip)} className='my-2' openByDefault={true}>
                            {!!myPermission && (
                                <>
                                    <SidesheetDisplayCard icon={{ icon: determineIcon(myPermission as INodePermission) }} title={myPermission.roleData.userName ?? 'Me'} subtitles={[determinePermissionsString(myPermission as INodePermission)]} actions={createActions(myPermission)} />
                                </>
                            )}
                        </NestedCollapse>

                        {determinePermissionCount(grantedPermissions as PermissionCollection) > 0 && (
                            <NestedCollapse title={t(PermissionsTKeys.PermissionsYouGrantedHeader)} subtitle={`(${determinePermissionCount(grantedPermissions as PermissionCollection)})`} openByDefault={true}>
                                <>
                                    {grantedPermissions.users.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.UsersHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.users.map((permission: INodePermission) => (
                                                <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.userName ?? 'Me'} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.groups.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.GroupsHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.groups.map((permission: INodePermission) => (
                                                <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.name ?? 'Me'} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.guests.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.GuestUsersHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.guests.map((permission: INodePermission) => (
                                                <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.userName ?? 'Me'} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.accessLinks.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.AccessLinksHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.accessLinks.map((accessLink: IAccessLink) => (
                                                <SidesheetDisplayCard key={accessLink.id} icon={{ icon: 'link' }} title={`Created on ${new Date(accessLink.createdOn).toLocaleDateString()}`} subtitles={[determineAccessLinkPermissions(accessLink.nodePermissions)]} actions={createActions(accessLink, true)} />
                                            ))}
                                        </>
                                    )}
                                </>
                            </NestedCollapse>
                        )}

                        {determinePermissionCount(otherPermissions as PermissionCollection) > 0 && (
                            <>
                                <Row className='my-3 ms-1 opacity-25 square border border-secondary' />

                                {otherPermissions.users.length > 0 && (
                                    <>
                                        <Text size='lg' className='my-2'>
                                            {t(PermissionsTKeys.AllOtherAccessHeader)}
                                        </Text>
                                        <NestedCollapse title={t(PermissionsTKeys.UsersHeader)} subtitle={`(${otherPermissions.users.length})`} className='my-2'>
                                            {otherPermissions.users.map((permission: INodePermission) => (
                                                <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.userName ?? 'Me'} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                            ))}
                                        </NestedCollapse>
                                    </>
                                )}

                                {otherPermissions.groups.length > 0 && (
                                    <NestedCollapse title={t(PermissionsTKeys.GroupsHeader)} subtitle={`(${otherPermissions.groups.length})`} className='my-2'>
                                        {otherPermissions.groups.map((permission: INodePermission) => (
                                            <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.userName ?? 'Me'} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.guests.length > 0 && (
                                    <NestedCollapse title={t(PermissionsTKeys.GuestUsersHeader)} subtitle={`(${otherPermissions?.guests.length})`} className='my-2'>
                                        {otherPermissions.guests.map((permission: INodePermission) => (
                                            <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.userName ?? 'Me'} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.accessLinks.length > 0 && (
                                    <>
                                        <NestedCollapse title={t(PermissionsTKeys.AccessLinksHeader)} subtitle={`(${otherPermissions.accessLinks.length})`} className='my-2'>
                                            <>
                                                {otherPermissions.accessLinks.map((accessLink: IAccessLink) => (
                                                    <SidesheetDisplayCard key={accessLink.id} icon={{ icon: 'link' }} title={`Created on ${new Date(accessLink.createdOn).toLocaleDateString()}`} subtitles={[determineAccessLinkPermissions(accessLink.nodePermissions)]} actions={createActions(accessLink, true)} />
                                                ))}
                                            </>
                                        </NestedCollapse>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </SidesheetPage>
        </>
    );
};
