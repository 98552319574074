import React from 'react';
import { Alert } from '@efilecabinet/efc-atlantis-components';
import { useLayerContext } from '../../../context/layer/LayerContext';
import { ThemeEnum } from '../../../hooks/useColors';

export const ToastMessages = () => {
    const { toastMessages, closeToastMessage } = useLayerContext();

    return (
        <>
            {!!toastMessages && (
                <div className='position-absolute px-4' style={{ top: '75px', right: '2px' }}>
                    {toastMessages.map(function (toast, index) {
                        return (
                            <div key={index} style={{ background: 'white', borderRadius: '.375rem', width: '450px' }}>
                                <Alert color={toast.color ?? ThemeEnum.Primary} toggle={() => closeToastMessage(toast.identifier)}>
                                    <span className={'fw-bold'}>{toast.message}</span>
                                </Alert>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
