import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Dropdown, Text, UncontrolledDropdown } from '@efilecabinet/efc-atlantis-components';
import { AccountsNavMenuItemTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useBootstrapBreakpoints } from '../../../../../hooks/useBootstrapBreakpoints';
import { useSettingsAndFeatures } from '../../../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { useAuthContext } from '../../../../../auth';
import { useRouting } from '../../../../../hooks/Routing/useRouting';
import { IAuthAccount } from '../../../../../auth/AuthenticationTypes';
import { ScreenSize } from '../../../../../types/BootstrapTypes';
import { FeatureFlags } from '../../../../../types/FeatureFlags';
import { AccountTile } from '../../../../AccountTile';
import { HelpCard } from '../../Help/HelpCard/HelpCard';
import { SeeMoreAccountsButton } from '../SeeMoreAccountsButton/SeeMoreAccountsButton';
import './AccountsCard.css';

interface IAccountsCardProps {
    toggleDropdown: () => void;
    toggleFeedback: () => void;
    openAccountsModal: () => void;
}

export const AccountsCard = (props: IAccountsCardProps) => {
    const { toggleDropdown, toggleFeedback, openAccountsModal } = props;

    const { t, i18n } = useSafeTranslation(TranslationFiles.AccountsNavMenuItem);
    const { authUser, handleLogout, userAccounts } = useAuthContext();
    const { hasFeatureFlags, checkFeatureFlag } = useSettingsAndFeatures();
    const { screenSize } = useBootstrapBreakpoints();
    const { getLinkToMySettings } = useRouting();

    const MAX_ACCOUNTS = screenSize < ScreenSize.l ? 1 : 3;

    const getOtherAccountIds = () => {
        if (!!userAccounts) {
            return Array.from(userAccounts, ([key]) => {
                return key;
            }).filter((id) => {
                return id != (authUser as any).accountID;
            });
        } else return [];
    };

    const showAdditionalAccounts = () => {
        return !!userAccounts && userAccounts.size > 1 && MAX_ACCOUNTS > 1;
    };

    const logout = () => {
        handleLogout();
    };

    return (
        <>
            <Card fluid borderless>
                <Card.Body className='accounts-navmenu-body'>
                    <Text muted>{t(AccountsNavMenuItemTKeys.ActiveAccount)}</Text>

                    {screenSize < ScreenSize.s && (
                        <div className='exit-button-mobile-acccount'>
                            <Button close onClick={toggleDropdown} />
                        </div>
                    )}

                    <AccountTile account={(userAccounts as any)?.get(authUser?.accountID)} />

                    {showAdditionalAccounts() && (
                        <>
                            <Text muted>{t(AccountsNavMenuItemTKeys.AdditionalAccounts, 'Additional accounts')}</Text>

                            {getOtherAccountIds()
                                .slice(0, MAX_ACCOUNTS - 1)
                                .map((acctId, index) => (
                                    <AccountTile key={index} account={userAccounts?.get(acctId) as IAuthAccount} switchTo />
                                ))}
                        </>
                    )}

                    {!!userAccounts &&
                        <SeeMoreAccountsButton userAccounts={userAccounts as Map<number, IAuthAccount>} onClick={openAccountsModal} maxAccounts={MAX_ACCOUNTS} />
                    }

                    <Dropdown.Item divider />

                    <Card.Link className='ms-2 my-3' tag={Link} to={getLinkToMySettings()} icon={{ icon: 'gear', size: 'lg' }}>
                        {t(AccountsNavMenuItemTKeys.MySettings)}
                    </Card.Link>

                    {!!hasFeatureFlags && checkFeatureFlag(FeatureFlags.LocalizationEnabled) && (
                        <UncontrolledDropdown direction='up'>
                            <Dropdown.Toggle className='nav-link d-flex align-items-center' tag='div'>
                                <Card.Link className='ms-2 my-3' icon={{ icon: ['far', 'globe'], size: 'lg' }}>
                                    {t(AccountsNavMenuItemTKeys.Language, 'Language')}
                                </Card.Link>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => i18n.changeLanguage('en')}>{t(AccountsNavMenuItemTKeys.English)}</Dropdown.Item>
                                <Dropdown.Item onClick={() => i18n.changeLanguage('es')}>{t(AccountsNavMenuItemTKeys.Spanish)}</Dropdown.Item>
                            </Dropdown.Menu>
                        </UncontrolledDropdown>
                    )}

                    {screenSize < ScreenSize.l && (
                        <>
                            <Dropdown.Item divider />

                            <HelpCard isSimplifiedCard toggleFeedback={toggleFeedback} />
                        </>
                    )}

                    <Dropdown.Item divider />

                    <Card.Link className='ms-2 mt-4' onClick={logout} icon={{ icon: ['far', 'arrow-left'], size: 'lg' }}>
                        {t(AccountsNavMenuItemTKeys.Logout)}
                    </Card.Link>
                </Card.Body>
            </Card>
        </>
    );
};
