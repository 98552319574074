import React from 'react';
import { Dropdown, useDropdown } from '@efilecabinet/efc-atlantis-components';
import { RecentsTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { Recents } from '../../../../pages/Home/Recents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './RecentsNavMenuItem.css';

export const RecentsNavMenuItem = () => {
    const RECENT_NODES_COUNT = 100;

    const { t } = useSafeTranslation(TranslationFiles.Recents);
    const { toggleDropdown, dropdownIsOpen } = useDropdown();

    return (
        <Dropdown toggle={toggleDropdown} isOpen={dropdownIsOpen}>
            <Dropdown.Toggle className='nav-link d-flex align-items-center' tag='a'>
                <FontAwesomeIcon icon='clock-rotate-left' className='mx-2' size='lg' title={t(RecentsTKeys.RecentsTitle)} />
            </Dropdown.Toggle>
            <Dropdown.Menu className='recents-navmenu'>
                <div className='recents-navmenu-div'>
                    <Recents maxRowsToDisplay={RECENT_NODES_COUNT} />
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
