import { useNavigate } from 'react-router';
import { RoutePath } from '../../types/RoutePaths';

export const useReportsRouting = () => {

    const navigate = useNavigate();

    const getLinkToReports = () => {
        return RoutePath.Reports;
    };

    const routeToReports = () => {
        navigate(getLinkToReports());
    };

    return {
        getLinkToReports,
        routeToReports,
    };
};