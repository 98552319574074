import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { EfcCallToActionInfo, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { ProfileRouteParams, ProfileRoutePath, RoutePath } from '../../../types/RoutePaths';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { TranslationFiles, useSafeTranslation, ProfilesAndProfileItemsTKeys } from '../../../hooks/useSafeTranslation';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useIFramePathChangeListener } from '../../../hooks/useIFramePathChangeListener';
import './ProfilesAndProfileItems.css';

const IFrameRoutes: Map<ProfileRoutePath, string> = new Map([
    [RoutePath.ProfilesIndex, '/admin/profile'],
    [RoutePath.Profiles, '/admin/profile'],
    [RoutePath.ProfileNew, '/admin/profile/add'],
    [RoutePath.ProfileEdit, `/admin/profile/edit/${ProfileRouteParams.ProfileId}`],
    [RoutePath.ProfileItems, '/admin/profileItem'],
    [RoutePath.ProfileItemNew, '/admin/profileItem/add'],
    [RoutePath.ProfileItemEdit, `/admin/profileItem/edit/${ProfileRouteParams.ProfileItemId}`],
]);

const ProfileTabRoutes = new Set<ProfileRoutePath>([
    RoutePath.ProfilesIndex,
    RoutePath.Profiles,
    RoutePath.ProfileNew,
    RoutePath.ProfileEdit,
]);

const ProfileItemTabRoutes = new Set<ProfileRoutePath>([
    RoutePath.ProfileItems,
    RoutePath.ProfileItemNew,
    RoutePath.ProfileItemEdit,
]);

export const ProfilesAndProfileItems = () => {

    const { profileId, profileItemId } = useParams();
    const { routeToProfileNew, routeToProfileItemNew, routeToProfiles, routeToProfileItems } = useRouting();

    const { t } = useSafeTranslation(TranslationFiles.ProfilesAndProfileItems);
    const { canGoToRoute } = useRouteValidator();

    const currentRoute =
        !!profileId ? RoutePath.ProfileEdit :
            !!profileItemId ? RoutePath.ProfileItemEdit :
                `${location.pathname}` as ProfileRoutePath;
    const iframe = useUtopiaIFrame(getIFrameUrl(currentRoute));

    function getIFrameUrl(route: ProfileRoutePath) {
        return IFrameRoutes.get(route)
            ?.replace(ProfileRouteParams.ProfileId, profileId ?? '')
            ?.replace(ProfileRouteParams.ProfileItemId, profileItemId ?? '') ?? '';
    }

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(ProfilesAndProfileItemsTKeys.CreateProfile),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            dataId: 'btnCreateProfile',
            onClick: () => routeToProfileNew(),
            show: () => ProfileTabRoutes.has(currentRoute),
        },
        {
            text: t(ProfilesAndProfileItemsTKeys.CreateProfileItem),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            dataId: 'btnCreateProfileItem',
            onClick: () => routeToProfileItemNew(),
            show: () => ProfileItemTabRoutes.has(currentRoute),
        },
    ];

    const onIFramePathChange = (newPath: string) => {
        if (currentRoute == RoutePath.ProfileItemNew && newPath == IFrameRoutes.get(RoutePath.ProfileItems)) {
            routeToProfileItems();
        } else if (currentRoute == RoutePath.ProfileNew && newPath == IFrameRoutes.get(RoutePath.Profiles)) {
            routeToProfiles();
        }
    };

    useIFramePathChangeListener({ onIFramePathChange: onIFramePathChange, currentRoute: currentRoute });

    useEffect(() => {
        if (currentRoute == RoutePath.ProfilesIndex) {
            routeToProfiles();
        } else {
            iframe.setUrl(getIFrameUrl(currentRoute));
        }
    }, [location.pathname]);

    return (
        <Page title={t(ProfilesAndProfileItemsTKeys.ProfilesTitle)} icon={{ icon: 'code' }} callsToAction={ctas}>
            {canGoToRoute(RoutePath.Profiles) &&
                <Tab active={ProfileTabRoutes.has(currentRoute)} onClick={() => routeToProfiles()}>
                    {t(ProfilesAndProfileItemsTKeys.ProfilesTitle)}
                </Tab>
            }
            {canGoToRoute(RoutePath.ProfileItems) &&
                <Tab active={ProfileItemTabRoutes.has(currentRoute)} onClick={() => routeToProfileItems()}>
                    {t(ProfilesAndProfileItemsTKeys.ProfileItemsTitle)}
                </Tab>
            }

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' src={iframe.url} />

            {/* Use the following Tab Panes once we make this page native */}

            {/*<Tab.Content activeTab={activeTab}>
                <Tab.Pane tabId={ProfileTab.Profiles}>
                    <ProfilesTable active={activeTab === ProfileTab.Profiles} />
                </Tab.Pane>
                <Tab.Pane tabId={ProfileTab.ProfileItems}>
                    <ProfileItemsTable active={activeTab === ProfileTab.ProfileItems} />
                </Tab.Pane>
            </Tab.Content>*/}
        </Page>
    );
};
