import React from 'react';
import { Col, Row } from 'reactstrap';
import { Text, EfcLink, Button } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, WelcomeModalTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { AccountOnboardingSteps } from '../AccountOnboardingTypes';
import { useSettingsAndFeatures } from '../../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { WelcomeExperienceLogo } from './WelcomeExperienceLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IAdminExistingFirstTimeUserWelcomeModalProps {
    currentStep: AccountOnboardingSteps;
    setCurrentStep: (step: AccountOnboardingSteps) => void;
}

export const AdminExistingFirstTimeUserWelcomeModal = (props: IAdminExistingFirstTimeUserWelcomeModalProps) => {
    const { currentStep, setCurrentStep } = props;

    const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { settings } = useSettingsAndFeatures();

    const next = () => {
        setCurrentStep(AccountOnboardingSteps.SetName);
    };

    return (
        <>
            {currentStep === AccountOnboardingSteps.ShowWelcome && (
                <>
                    <Row>
                        <Col xs='auto' className='position-relative empty-padding-left'>
                            <WelcomeExperienceLogo />
                        </Col>
                        <Col className='jump-in-col empty-padding-right'>
                            <Text size='xl'>{t(WelcomeModalTKeys.AccountOnboardingAdminIntroText)}</Text>

                            {!!settings?.guideToNewExperienceUrl && (
                                <div className='view-guide-divider'>
                                    <EfcLink href={settings?.guideToNewExperienceUrl} newWindow className='my-auto modal-pdf-link' color='primary'>
                                        {t(WelcomeModalTKeys.AccountOnboardingGuidPdfText)}
                                        <FontAwesomeIcon icon='external-link' className='my-auto ms-2' />
                                    </EfcLink>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Button color='primary' onClick={next} className='btn-lg float-end modal-button mb-4'>
                        Next
                    </Button>
                </>
            )}
        </>
    );
};
