import React from 'react';
import { EfcCallToActionInfo, Modal, Text } from '@efilecabinet/efc-atlantis-components';
import { IFileVersion } from '../../../../../types/CommonTypes';
import { useUtilities } from '../../../../../hooks/useUtilities';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';

interface FileVersionFullNoteModalProps {
    fileVersion: IFileVersion;
    isFullNoteModalOpen: boolean;
    closeFullNoteModal: () => void;
}

export const FileVersionFullNoteModal = (props: FileVersionFullNoteModalProps) => {
    const { fileVersion, isFullNoteModalOpen, closeFullNoteModal } = props;
    const { formatDateTime } = useUtilities();
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(FileVersionsTKeys.Close),
            emphasis: 'med',
            onClick: closeFullNoteModal,
        },
    ];

    return (
        <Modal isOpen={isFullNoteModalOpen} ctas={ctas}>
            <Modal.Header toggle={closeFullNoteModal} className='file-version-full-note-title'>
                {t(FileVersionsTKeys.VersionNoteFullPopupTitle)}
            </Modal.Header>
            <Modal.Body>
                <Text>
                    {fileVersion.createdByUser + ' | ' + formatDateTime(fileVersion.createdOn)}
                </Text>
                <div className='file-version-full-note-container'>{!!fileVersion.nodeComment && fileVersion.nodeComment.text}
                </div>
            </Modal.Body>
        </Modal>
    );
};
