import { Api } from './';
import { AxiosResponse } from 'axios';
import { IAtlantisTask, IUtopiaTaskCount } from '../types/ToDoTypes';
import {  NotificationOrderEnum, NotificationStatusEnum, IUtopiaTaskNotification } from '../types/UtopiaNotificationTypes';

export const useTaskApi = () => {
    const addAtlantisTask = async (userId: number, task: IAtlantisTask) => {
        const resp: AxiosResponse = await Api.post(`api/tasks/user/${userId}`, task);
        return resp.data as IAtlantisTask;
    };

    const completeAtlantisTask = async (userId: number, taskId: string) => {
        const resp: AxiosResponse = await Api.put(`api/tasks/user/${userId}/task/${taskId}/complete`);
        return resp.data as IAtlantisTask;
    };

    const getUserAtlantisTasks = async (userId: number, status: string, start: number, count: number) => {
        const resp: AxiosResponse = await Api.get(`api/tasks/user/${userId}/${status.toLowerCase()}?start=${start}&count=${count}`);
        return resp.data as IAtlantisTask[];
    };

    const getUserUtopiaTasks = async (taskStatuses: NotificationStatusEnum[], orderBy: NotificationOrderEnum, start: number, count: number): Promise<IUtopiaTaskNotification[]> => {
        const resp: AxiosResponse = await Api.get(`api/Tasks?${taskStatuses.map((taskStatus) => `taskStatuses=${taskStatus}&`).join('')}orderBy=${orderBy}&start=${start}&count=${count}`);
        return resp.data;
    };

    const getUserUtopiaTasksCount = async (taskStatuses: NotificationStatusEnum[]) => {
        const resp: AxiosResponse = await Api.get(`api/Tasks/Count?${taskStatuses.map((taskStatus) => `taskStatuses=${taskStatus}&`).join('')}`);
        return resp.data as IUtopiaTaskCount;
    };

    const dismissAtlantisTask = async (userId: number, taskId: string) => {
        const resp: AxiosResponse = await Api.put(`api/tasks/user/${userId}/task/${taskId}/dismiss`);
        return resp.data as IAtlantisTask;
    };

    const dismissUtopiaTask = async (taskId: string) => {
        const resp: AxiosResponse = await Api.post(`api/tasks/utopiaTask/${taskId}/dismiss`);
        return resp.data;
    };

    const restoreAtlantisTask = async (userId: number, taskId: string) => {
        const resp: AxiosResponse = await Api.put(`api/tasks/user/${userId}/task/${taskId}/restore`);
        return resp.data as IAtlantisTask;
    };

    const restoreUtopiaTask = async (taskId: string) => {
        const resp: AxiosResponse = await Api.post(`api/tasks/restore/${taskId}`);
        return resp.data;
    };

    return {
        addAtlantisTask,
        completeAtlantisTask,
        getUserAtlantisTasks,
        getUserUtopiaTasks,
        getUserUtopiaTasksCount,
        dismissAtlantisTask,
        dismissUtopiaTask,
        restoreAtlantisTask,
        restoreUtopiaTask,
    };
};
