import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Text, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNodePermissionsUtilities } from '../../../../../hooks/useNodePermissionsUtilities';
import { useColors } from '../../../../../hooks/useColors';
import { RoleEnum } from '../../../../../types/CommonTypes';
import { INodePermission, NodePermissionLevelEnum } from '../../../../../types/NodePermissionTypes';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { UserLicenseEnum } from '../../../../../auth/AuthenticationTypes';
import './PermissionDetailsTableRow.css';

interface PermissionDetailsTableRowProps {
    permission: INodePermission;
    isEditMode: boolean;
    onCheckboxClick: (permissionId: number, permissionLevel: NodePermissionLevelEnum) => void;
    onDeleteClick: (permissionId: number) => void;
    inheritedFrom?: string;
}

export const PermissionDetailsTableRow = ({ permission, isEditMode, onCheckboxClick, onDeleteClick, inheritedFrom }: PermissionDetailsTableRowProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { atlantisPrimary } = useColors();
    const { checkCreateBox } = useNodePermissionsUtilities();

    const [isCreateChecked, setIsCreateChecked] = useState<boolean>(false);

    const lowerPermissionCheckboxClass = 'lower-permission-checkbox';

    useEffect(() => {
        setIsCreateChecked(checkCreateBox(permission));
    }, [permission]);

    return (
        <tr className='permission-details-table-row'>
            <td>
                <div className='d-flex align-items-center'>
                    {permission.roleData.roleType == RoleEnum.Group &&
                        <FontAwesomeIcon icon='users' className='me-2' />
                    }
                    {permission.roleData.roleType == RoleEnum.User && (
                        permission.roleData.license != UserLicenseEnum.Guest
                            ? <FontAwesomeIcon icon='user' className='me-2' />
                            : <i className='icon-efc-guest-user permission-role-custom-icon m-1 me-2' />
                    )}
                    <Text>{`${permission.roleData.name}`}</Text>
                </div>
            </td>
            <td>
                {!!inheritedFrom &&
                    <TooltipItem id='permission-details-inheritance-icon' message={`${t(PermissionsTKeys.InheritedFromTooltip)} ${inheritedFrom}`} >
                        <FontAwesomeIcon icon='sitemap' color={atlantisPrimary} />
                    </TooltipItem>
                }
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={permission.view}
                        disabled={!isEditMode}
                        className={permission.download ? lowerPermissionCheckboxClass : ''}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.View)}/>
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={permission.download}
                        disabled={!isEditMode}
                        className={permission.uploadFiles || permission.createDirectories ? lowerPermissionCheckboxClass : ''}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.Download)}/>
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={isCreateChecked}
                        disabled={!isEditMode}
                        className={permission.write ? lowerPermissionCheckboxClass : ''}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.Create)}/>
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={permission.write}
                        disabled={!isEditMode}
                        className={permission.delete ? lowerPermissionCheckboxClass : ''}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.Write)}/>
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={permission.delete}
                        disabled={!isEditMode}
                        className={permission.admin ? lowerPermissionCheckboxClass : ''}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.Delete)}/>
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={permission.admin}
                        disabled={!isEditMode || permission.roleData.roleType == RoleEnum.Guest /* TODO: Check system permission if admin permission can be given */}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.Admin)}/>
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={permission.enforce}
                        disabled={!isEditMode || permission.roleData.roleType == RoleEnum.Group}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.Override)}/>
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input
                        type='checkbox'
                        checked={permission.restrict}
                        disabled={!isEditMode}
                        onClick={() => onCheckboxClick(permission.id, NodePermissionLevelEnum.RestrictAll)}/>
                </FormGroup>
            </td>
            <td className='pointer'>
                <FontAwesomeIcon
                    icon='trash-alt'
                    className={'delete-permission-icon'}
                    style={{ visibility: isEditMode ? undefined : 'hidden' }}
                    onClick={() => onDeleteClick(permission.id)} />
            </td>
        </tr>
    );
};
