import React, { useEffect, useState, useRef } from 'react';
import { Tooltip } from '@efilecabinet/efc-atlantis-components';
import { FileVersionLocked } from '../FileVersionLocked';
import { FileVersionPrimary } from '../FileVersionPrimary';
import { FileVersionActions } from '../FileVersionActions';
import { FileVersionDateLink } from '../FileVersionDateLink';
import { IFileVersion } from '../../../../../../types/CommonTypes';
import { useUtilities } from '../../../../../../hooks/useUtilities';
import '../FileVersion.css';

interface FileVersionTableRowProps {
    fileVersion: IFileVersion;
}

export const FileVersionTableRow = ({ fileVersion }: FileVersionTableRowProps) => {
    const { getSizeDisplay } = useUtilities();
    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const [tooltipTarget, setTooltipTarget] = useState<string>(`version-notes-${fileVersion.id}`);
    const toggleTooltip = () => setIsToolTipOpen((prevState) => !prevState);
    const textElementRef = useRef<HTMLTableDataCellElement>(null);
    const [isOverflown, setIsOverflown] = useState(false);

    const compareSize = () => {
        const element = textElementRef.current;

        const compare = element
            ? element.offsetWidth < element.scrollWidth ||
            element.offsetHeight < element.scrollHeight
            : false;

        setIsOverflown(compare);
    };

    useEffect(() => {
        compareSize();
    }, [fileVersion.nodeComment]);

    useEffect(() => {
        const randomId = Math.floor(1000 + Math.random() * 9000);
        setTooltipTarget(`version-notes-${randomId}`);
    }, []);

    return (
        <tr>
            {!!fileVersion.isActive ? (
                <td>
                    <FileVersionPrimary fileVersion={fileVersion} />
                </td>
            ) : !fileVersion.allowPreview ? (
                <td>
                    <FileVersionLocked fileVersion={fileVersion} />
                </td>
            ) : (
                <td></td>
            )}
            <td>
                <FileVersionDateLink fileVersion={fileVersion} />
            </td>
            <td className='d-none d-md-table-cell'>{getSizeDisplay(fileVersion.size)}</td>
            <td className='d-none d-sm-table-cell'>{fileVersion.createdByUser}</td>
            <td className='d-none d-sm-table-cell file-version-table-note' id={tooltipTarget} ref={textElementRef}>
                {fileVersion.nodeComment == null ? '-' : fileVersion.nodeComment.text}
            </td>
            {!!fileVersion.nodeComment && isOverflown && (
                <Tooltip target={tooltipTarget} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom' className='file-version-table-tooltip'>
                    {fileVersion.nodeComment.text}
                </Tooltip>)}
            <td>
                <FileVersionActions fileVersion={fileVersion} />
            </td>
        </tr>
    );
};
