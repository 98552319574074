import { EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface IToDo {
    id: string;
    icon: FontAwesomeIconProps;
    text: string;
    from: string;
    cta: EfcCallToActionInfo;
    createdDate?: string;
    completedDate?: string;
    dismissedDate?: string;
    sourceType: SourceTypeEnum;
}

export interface IAtlantisTask {
    taskId: string;
    text: string;
    status: AtlantisTaskStatus;
    createdDate?: string;
    completedDate?: string;
    dismissedDate?: string;
}

export enum AtlantisTaskStatus {
    Assigned = 0,
    Completed = 1,
    Dismissed = 2,
}

export enum SourceTypeEnum {
    Atlantis = 0,
    Utopia = 1,
}

export interface IUtopiaTaskCount {
    count: number;
}