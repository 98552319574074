import React, { useEffect } from 'react';
import { Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { ESignatureRoutePath, RoutePath } from '../../../types/RoutePaths';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { TranslationFiles, useSafeTranslation, ESignatureTKeys } from '../../../hooks/useSafeTranslation';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { AccountFeatureUsageHeader } from '../../../components/AccountFeatureUsageHeader/AccountFeatureUsageHeader';
import { AccountFeatureEnum } from '../../../auth/AuthenticationTypes';
import './ESignature.css';

const IFrameRoutes: Map<ESignatureRoutePath, string> = new Map([
    [RoutePath.ESignatureIndex, '/eSignatureTransaction/sent'],
    [RoutePath.ESignatureSent, '/eSignatureTransaction/sent'],
    [RoutePath.ESignatureAll, '/eSignatureTransaction/all'],
    [RoutePath.ESignatureTemplates, '/eSignatureTransaction/templates'],
]);

export const ESignature = () => {
    const { routeToSentESignatures, routeToAccountESignatures, routeToESignatureTemplates } = useRouting();

    const { t } = useSafeTranslation(TranslationFiles.ESignature);
    const { canGoToRoute } = useRouteValidator();

    const currentRoute = `${location.pathname}` as ESignatureRoutePath;
    const iframe = useUtopiaIFrame(IFrameRoutes.get(currentRoute));

    useEffect(() => {
        if (currentRoute == RoutePath.ESignatureIndex) {
            routeToSentESignatures();
        } else {
            iframe.setUrl(IFrameRoutes.get(currentRoute) ?? '');
        }
    }, [location.pathname]);

    return (
        <Page title={t(ESignatureTKeys.ESignatureTitle)} icon={{ icon: ['far', 'file-signature'] }}>
            <AccountFeatureUsageHeader accountFeatureTypes={[AccountFeatureEnum.ESignature, AccountFeatureEnum.ESignatureKBA, AccountFeatureEnum.ESignatureOTP]} />

            {canGoToRoute(RoutePath.ESignatureSent) &&
                <Tab active={currentRoute == RoutePath.ESignatureSent} onClick={() => routeToSentESignatures()} dataId='eSignatureSentTab'>
                    {t(ESignatureTKeys.SentTransactionsTab)}
                </Tab>
            }
            {canGoToRoute(RoutePath.ESignatureAll) &&
                <Tab active={currentRoute == RoutePath.ESignatureAll} onClick={() => routeToAccountESignatures()} dataId='eSignatureAccountTab'>
                    {t(ESignatureTKeys.AccountTransactionsTab)}
                </Tab>
            }
            {canGoToRoute(RoutePath.ESignatureTemplates) &&
                <Tab active={currentRoute == RoutePath.ESignatureTemplates} onClick={() => routeToESignatureTemplates()} dataId='eSignatureTemplatesTab'>
                    {t(ESignatureTKeys.TemplatesTab)}
                </Tab>
            }

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' src={iframe.url} dataId='eSignatureIframe' />

            {/* Use the following Tab Panes once we make this page native */}

            {/*<Tab.Content activeTab={activeTab}>*/}
            {/*    <Tab.Pane tabId={0}>*/}
            {/*        Placeholder for Sent Transactions*/}
            {/*    </Tab.Pane>*/}
            {/*    <Tab.Pane tabId={1}>*/}
            {/*        Placeholder for Account Transactions*/}
            {/*    </Tab.Pane>*/}
            {/*    <Tab.Pane tabId={2}>*/}
            {/*        Placeholder for Templates*/}
            {/*    </Tab.Pane>*/}
            {/*</Tab.Content>*/}
        </Page>
    );
};
