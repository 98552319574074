import { VertNavState } from './useVertNavState';
import { useAuthContext } from '../auth';
import { ExperienceTypeEnum } from '../auth/AuthenticationTypes';

export const useNavStateStyling = () => {
    const { experienceType } = useAuthContext();

    const getPagesSubClasses = (vertNavState: VertNavState) => {
        if (vertNavState == VertNavState.Hidden && experienceType != ExperienceTypeEnum.Anonymous) {
            return 'no-left-nav';
        } else if (vertNavState == VertNavState.Hidden && experienceType == ExperienceTypeEnum.Anonymous) {
            return 'no-bottom-nav-no-left-nav';
        } else if (vertNavState == VertNavState.Collapsed) {
            return 'left';
        } else {
            return '';
        }
    };
    
    const getPageContainerSubClasses = (vertNavState: VertNavState) => {
        return vertNavState != VertNavState.Hidden ? 'ps-3 pe-1' : 'extra-small-view-bottom-nav-padding';
    };

    return {
        getPagesSubClasses,
        getPageContainerSubClasses,
    };
};