import { Api } from '.';
import { AxiosResponse } from 'axios';
import { INodeProfile, IProfileItem } from '../types/ProfilesAndProfileItemsTypes';

export const useNodeProfileApi = () => {

    const getAllAsync = async (nodeId: number): Promise<INodeProfile[]> => {
        const resp: AxiosResponse = await Api.get(`api/nodeProfile/${nodeId}`);
        return resp.data as INodeProfile[];
    };

    const getMaskedProfileItemValueAsync = async (nodeId: string, profileItemId: number): Promise<IProfileItem> => {
        const resp: AxiosResponse = await Api.get(`api/NodeProfile/${nodeId}/Mask/${profileItemId}`);
        return resp.data as IProfileItem;
    };

    const getUnmaskedProfileItemValueAsync = async (nodeId: string, profileItemId: number): Promise<IProfileItem> => {
        const resp: AxiosResponse = await Api.get(`api/NodeProfile/${nodeId}/Unmask/${profileItemId}`);
        return resp.data as IProfileItem;
    };

    const updateNodeProfileAsync = async (nodeProfile: INodeProfile): Promise<INodeProfile> => {
        const resp: AxiosResponse = await Api.post('api/NodeProfile/Update', nodeProfile);
        return resp.data as INodeProfile;
    };

    return {
        getAllAsync,
        getMaskedProfileItemValueAsync,
        getUnmaskedProfileItemValueAsync,
        updateNodeProfileAsync,
    };
};