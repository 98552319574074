import { INode } from './CommonTypes';

export interface UtopiaUploadFileResponse {
    nodeID: string,
    uploadIdentifier: string,
    sizeInBytes: number,
    uploadedBytes: number,
    name: string
}

export interface UtopiaFileUploadProgressUpdate {
    identifier: string,
    percentage: number,
    accountID: number,
    updateType: UtopiaProgressNotificationUpdateType,
    status: ProgressNotificationUpdateStatus,
    record: UtopiaFileUploadProgressUpdateNode
}

export interface UtopiaFileUploadProgressUpdateNode extends INode {
    fileStatus: FileStatus
}

export interface FileStatus {
    bytesProcessed: number,
    name: string,
    nodeID: string,
    sizeInBytes: number,
    uploadedBytes: number,
    uploadIdentifier: string
}

export enum ProgressNotificationUpdateStatus {
    Processing = 0,
    Failed = 1,
    Completed = 2
}

export enum UtopiaProgressNotificationUpdateType {
    AuditLogCSV = 0,
    FileUpload = 1,
    FileAppend = 2,
    NodeCopy = 3,
    NodeMove = 4,
    TemplateApply = 5,
    TemplateSync = 6,
    RecycleBinOperation = 7,
    CsvImport = 8,
    AccountTemplateImport = 9,
    AccountTemplateExport = 10,
    NodeInfoExport = 11
}