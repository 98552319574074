import { Api } from './';

export const useOptInApi = () => {

    const hideWelcomeModal = async (userId: number) => {
        await Api.post(`api/OptIn/${userId}/HideWelcome`);
    };

    const optOut = async (userId: number) => {
        await Api.post(`api/OptIn/${userId}/OptOut`);
    };

    return {
        hideWelcomeModal,
        optOut
    };
};
