import React from 'react';
import dayjs from 'dayjs';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { INodeNote } from '../../../../../types/NodeTypes';
import { Note } from '../Note/Note';
import { NotesTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';

interface NoteListProps {
    nodeNotes: INodeNote[];
    onNoteDelete: (noteId: number) => void;
    onNoteEdit: (note: INodeNote) => void;
}

export const NotesList = ({ nodeNotes, onNoteDelete, onNoteEdit }: NoteListProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Notes);

    const today = dayjs().subtract(1, 'day').toDate().getTime();
    const todayNotes = nodeNotes.filter(note => new Date(note.createdOn as string).getTime() > today);

    const yesterday = dayjs().subtract(2, 'day').toDate().getTime();
    const yesterdayNotes = nodeNotes.filter(note => new Date(note.createdOn as string).getTime() > yesterday && new Date(note.createdOn as string).getTime() < today);

    const lastWeek = dayjs().subtract(1, 'week').toDate().getTime();
    const lastWeekNotes = nodeNotes.filter(note => new Date(note.createdOn as string).getTime() > lastWeek && new Date(note.createdOn as string).getTime() < yesterday);

    const lastMonth = dayjs().subtract(1, 'month').toDate().getTime();
    const lastMonthNotes = nodeNotes.filter(note => new Date(note.createdOn as string).getTime() > lastMonth && new Date(note.createdOn as string).getTime() < lastWeek);

    const overAMonthAgoNotes = nodeNotes.filter(note => new Date(note.createdOn as string).getTime() < lastMonth);

    const getNotesSegment = (notesSegment: INodeNote[], header: string) => {

        return (
            <>
                {!!notesSegment.length &&
                    <>
                        <div className='d-flex justify-content-center'>
                            <Text color="grey">{header}</Text>
                        </div>
                        {notesSegment.map(note => <Note key={note.id} note={note} onNoteDelete={onNoteDelete} onNoteEdit={onNoteEdit} />)}
                    </>
                }
            </>
        );
    };

    return (
        <>
            {getNotesSegment(overAMonthAgoNotes, t(NotesTKeys.OverAMonthAgo))}
            {getNotesSegment(lastMonthNotes, t(NotesTKeys.LastMonth))}
            {getNotesSegment(lastWeekNotes, t(NotesTKeys.LastWeek))}
            {getNotesSegment(yesterdayNotes, t(NotesTKeys.Yesterday))}
            {getNotesSegment(todayNotes, t(NotesTKeys.Today))}
        </>
    );
};