import React, { useEffect, useState } from 'react';
import { Tooltip } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';
import { IFileVersion } from '../../../../../types/CommonTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FileVersionPrimaryProps {
    fileVersion: IFileVersion;
}

export const FileVersionPrimary = ({ fileVersion }: FileVersionPrimaryProps) => {
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);

    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const [tooltipTarget, setTooltipTarget] = useState<string>(`is-primary-${fileVersion.id}`);

    const toggleTooltip = () => setIsToolTipOpen((prevState) => !prevState);

    useEffect(() => {
        const randomId = Math.floor(1000 + Math.random() * 9000);
        setTooltipTarget(`is-primary-${randomId}`);
    }, []);

    return (
        <>
            <span id={tooltipTarget} className='fa-layers fa-fw' data-id={`primary-file-version-icon-${fileVersion.id}`} >
                <FontAwesomeIcon icon='star' color='#FFCA00' />
                <FontAwesomeIcon icon={['far', 'star']} color='#BC890C' />
            </span>
            <Tooltip target={tooltipTarget} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                {t(FileVersionsTKeys.PrimaryTooltip)}
            </Tooltip>
        </>
    );
};
