import { useNavigate } from 'react-router';
import { RoutePath, ProfileRouteParams } from '../../types/RoutePaths';

export const useProfilesRouting = () => {

    const navigate = useNavigate();

    // Profiles Index

    const getLinkToProfilesIndex = () => {
        return RoutePath.ProfilesIndex;
    };

    const routeToProfilesIndex = () => {
        navigate(getLinkToProfilesIndex());
    };
    
    // Profiles Tab

    const getLinkToProfiles = () => {
        return RoutePath.Profiles;
    };

    const routeToProfiles = () => {
        navigate(getLinkToProfiles());
    };

    const getLinkToProfile = (profileId: string) => {
        return RoutePath.ProfileEdit.replace(ProfileRouteParams.ProfileId, profileId);
    };

    const routeToProfile = (profileId: string) => {
        navigate(getLinkToProfile(profileId));
    };

    const getLinkToProfileNew = () => {
        return RoutePath.ProfileNew;
    };

    const routeToProfileNew = () => {
        navigate(getLinkToProfileNew());
    };

    // Profile Items Tab

    const getLinkToProfileItems = () => {
        return RoutePath.ProfileItems;
    };

    const routeToProfileItems = () => {
        navigate(getLinkToProfileItems());
    };

    const getLinkToProfileItem = (profileItemId: string) => {
        return RoutePath.ProfileItemEdit.replace(ProfileRouteParams.ProfileItemId, profileItemId);
    };

    const routeToProfileItem = (profileItemId: string) => {
        navigate(getLinkToProfileItem(profileItemId));
    };

    const getLinkToProfileItemNew = () => {
        return RoutePath.ProfileItemNew;
    };

    const routeToProfileItemNew = () => {
        navigate(getLinkToProfileItemNew());
    };

    return {
        getLinkToProfilesIndex,
        getLinkToProfiles,
        getLinkToProfile,
        getLinkToProfileNew,
        getLinkToProfileItems,
        getLinkToProfileItem,
        getLinkToProfileItemNew,
        routeToProfilesIndex,
        routeToProfiles,
        routeToProfile,
        routeToProfileNew,
        routeToProfileItems,
        routeToProfileItem,
        routeToProfileItemNew,
    };
};
