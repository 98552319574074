import React from 'react';
import { useRouteValidator } from '../hooks/useRouteValidator';
import { RoutePath } from '../types/RoutePaths';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';

export const AuthRoute = (props: any) => {
    const { path, children } = props;

    const { canGoToRoute } = useRouteValidator();

    return canGoToRoute(path as RoutePath) ? children : <PageNotFound />;
};