import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { Card, RevverIcon, Spinner, Text } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, LoginLoadingStatesTKeys } from '../../hooks/useSafeTranslation';
import { useSettingsAndFeatures } from '../../context/settingsAndFeatures/SettingsFeaturesContext';
import { useAuthContext } from '../AuthContext';
import { useSearchParams } from 'react-router-dom';
import { useUrlUtilities } from '../../hooks/useUrlUtilities';
import { useBrandingApi } from '../../api/useBrandingApi';
import './Login.css';

const SendToLogin = () => {
    const { t } = useSafeTranslation(TranslationFiles.LoginLoadingStates);
    const [queryParameters] = useSearchParams();
    const location = useLocation();
    const { getQueryParamDelimiter, removeOrigin } = useUrlUtilities();
    const { getCnameExists } = useBrandingApi();

    let authTimer: NodeJS.Timer | null = null;

    const { hasSettings, settings, hasUtopiaConfig, utopiaConfig } = useSettingsAndFeatures();
    const [showLoadingText, setShowLoadingText] = useState(false);

    const getLoginUrl = async (loginUrl: string) => {
        const cname = window.location.host.split('.')[0];
        const url = new URL(loginUrl);

        if (await getCnameExists(cname)) {
            url.hostname = `${cname}.${url.hostname}`;
        }

        return url.href;
    };

    const redirectToLogin = async () => {
        if (!!settings && !!utopiaConfig) {
            
            const goTo = (location?.state as any)?.from?.pathname ?? '' + (location?.state as any)?.from?.search ?? '';
            const redirect = queryParameters.get('redirect');
            const loginUrl = await getLoginUrl(settings.loginUrl);
            const region = utopiaConfig.Region;

            let url = `${loginUrl}${getQueryParamDelimiter(loginUrl)}region=${region}`;

            if (!!redirect) {
                url = `${url}${getQueryParamDelimiter(url)}redirect=${redirect}`;
            }
            if (!!goTo) {
                url = `${url}${getQueryParamDelimiter(url)}goTo=${goTo}`; 
            }

            window.location.href = url;
        }
    };

    useEffect(() => {
        if (hasSettings && hasUtopiaConfig) {
            redirectToLogin();
        }
    }, [hasSettings, hasUtopiaConfig]);

    useEffect(() => {
        authTimer = setTimeout(() => {
            setShowLoadingText(true);
        }, 3000);

        return () => {
            if (!!authTimer) {
                clearInterval(authTimer);
                authTimer = null;
            }
        };
    }, []);

    return (
        <div className='mt-4 mb-5'>
            {showLoadingText &&
                <Text className='d-block fs-5 fw-bold'>{t(LoginLoadingStatesTKeys.GettingEverythingReady)}</Text>
            }
        </div>
    );
};

const OptingOut = () => {
    const { t } = useSafeTranslation(TranslationFiles.LoginLoadingStates);

    return (
        <div className='mt-4 mb-5'>
            <Text className='d-block fs-5 fw-bold'>{t(LoginLoadingStatesTKeys.LeavingTheNewExperience)}</Text>
            <Text className='d-block fw-bold mt-2'>{t(LoginLoadingStatesTKeys.ComeBackAnytime)}</Text>
        </div>
    );
};

const WaitForLogin = () => {
    const { t } = useSafeTranslation(TranslationFiles.LoginLoadingStates);

    return (
        <div className='mt-4 mb-5'>
            <Text className='d-block fs-5 fw-bold'>{t(LoginLoadingStatesTKeys.SigningIn)}</Text>
            <Text className='d-block fw-bold mt-2'>{t(LoginLoadingStatesTKeys.PuttingDocumentsToWork)}</Text>
        </div>
    );
};

export const Login = () => {
    const { token } = useParams();
    const [queryParameters] = useSearchParams();
    const { t } = useSafeTranslation(TranslationFiles.LoginLoadingStates);
    const { handleLoginWithToken } = useAuthContext();

    const optingOut = queryParameters.get('optingout');

    useEffect(() => {
        document.body.style.backgroundColor = '#f6f4f2';

        if (!!token) {
            handleLoginWithToken(token);
        }

        return () => {
            document.body.style.backgroundColor = '#fff';
        };
    }, []);

    return (
        <>
            <div className='page-wrapper d-flex flex-column justify-content-between vh-100'>
                <div className='content-wrapper d-flex flex-column justify-content-center align-items-center flex-grow-1'>
                    <div>
                        <Card body fluid className='login-card mx-4'>
                            <RevverIcon color='#7514BD' className='d-flex mx-auto mt-4 revver-icon' />
                            <div className='text-center'>
                                {token ?
                                    <WaitForLogin />
                                    :
                                    <>
                                        {!!optingOut ?
                                            <OptingOut />
                                            :
                                            <SendToLogin />
                                        }
                                    </>
                                }
                                <div>
                                    <Spinner className='login-spinner' size='large' color='primary'>
                                        {t(LoginLoadingStatesTKeys.Loading)}
                                    </Spinner>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};
