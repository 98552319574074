import { Api } from './';
import { AxiosResponse } from 'axios';
import { IDocumentRequest, IDocumentRequestQueryParams } from '../types/DocumentRequestTypes';

export const useDocumentRequestsApi = () => {
    const getUserDocumentRequests = async (query: IDocumentRequestQueryParams): Promise<IDocumentRequest[]> => {
        const resp: AxiosResponse = await Api.get(`api/documentRequest?start=${query.start}&count=${query.count}&orderBy=${query.orderBy}`);
        return resp.data as IDocumentRequest[];
    };

    const queryTemplates = async (query: IDocumentRequestQueryParams): Promise<IDocumentRequest[]> => {
        const resp: AxiosResponse = await Api.get(`api/documentRequest/Template?start=${query.start}&count=${query.count}&orderBy=${query.orderBy}`);
        return resp.data as IDocumentRequest[];
    };

    const deleteDocumentRequest = async (id: string) => {
        await Api.delete(`api/documentRequest/${id}`);
    };

    return {
        getUserDocumentRequests,
        queryTemplates,
        deleteDocumentRequest,
    };
};
