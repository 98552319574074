import { To } from 'react-router';
import { SystemActionEnum, SystemTypeEnum } from './CommonTypes';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface IUtopiaNotification {
    id: number;
    recipientRoleID: number;
    accountID: number;
    notificationType: UtopiaNotificationTypeEnum;
    actionType: SystemActionEnum;
    systemType: SystemTypeEnum;
    dateQueued: string;
    content: string;
    itemUrl?: string;
    requiresEmail: boolean;
    completedDate?: string;
    dismissedDate?: string;
    routeTo?: string;
}

export enum UtopiaNotificationTypeEnum {
    Alert = 0,
    Task = 1,
}

export interface IUtopiaNotificationDisplayInfo {
    id: string;
    icon: FontAwesomeIconProps;
    title: string;
    subText?: string;
    blockText?: string;
    ctaText: string;
    from?: string;
    to?: To;
    onClick?: () => void;
    createdDate: string;
    completedDate?: string | undefined;
    dismissedDate?: string | undefined;
}

export enum NotificationStatusEnum {
    Active = 0,
    Dismissed = 1,
    Completed = 2,
}

export enum NotificationOrderEnum {
    QueuedOnAsc = 0,
    QueuedOnDesc = 1,
    DismissedOnAsc = 2,
    DismissedOnDesc = 3,
    CompletedOnAsc = 4,
    CompletedOnDesc = 5,
}

export interface IUtopiaAlert extends IUtopiaNotification {
    supportText?: string;
}

export type IUtopiaTaskNotification = IUtopiaDocRequestTask | IUtopiaESignatureTask | IUtopiaWorkflowTask; 

export interface IUtopiaWorkflowTask extends IUtopiaNotification {
    workflowInstanceId: number;
    workflowStageInstanceId: number;
    workflowInstanceName: string;
}

export function isWorkflowTask(utopiaTaskNotification: IUtopiaTaskNotification): utopiaTaskNotification is IUtopiaWorkflowTask {
    return (utopiaTaskNotification as IUtopiaWorkflowTask).workflowInstanceId != undefined
        && (utopiaTaskNotification as IUtopiaWorkflowTask).workflowStageInstanceId != undefined
        && (utopiaTaskNotification as IUtopiaWorkflowTask).workflowInstanceName != undefined;
}

export interface IUtopiaDocRequestTask extends IUtopiaNotification {
    documentRequestId: string;
    senderDisplayName: string;
    fileUploadCount: number;
    formFillCount: number;
}

export function isDocRequestTask(utopiaTaskNotification: IUtopiaTaskNotification): utopiaTaskNotification is IUtopiaDocRequestTask {
    return (utopiaTaskNotification as IUtopiaDocRequestTask).documentRequestId != undefined
        && (utopiaTaskNotification as IUtopiaDocRequestTask).senderDisplayName != undefined
        && (utopiaTaskNotification as IUtopiaDocRequestTask).fileUploadCount != undefined
        && (utopiaTaskNotification as IUtopiaDocRequestTask).formFillCount != undefined;
}

export interface IUtopiaESignatureTask extends IUtopiaNotification {
    senderDisplayName: string;
    externalType: ESignatureExternalTypeEnum;
    transactionId: number;
}

export function isEsignatureTask(utopiaTaskNotification: IUtopiaTaskNotification): utopiaTaskNotification is IUtopiaESignatureTask {
    return (utopiaTaskNotification as IUtopiaESignatureTask).senderDisplayName != undefined
        && (utopiaTaskNotification as IUtopiaESignatureTask).externalType != undefined;
}

export enum ESignatureExternalTypeEnum {
    None = 0,
    Signix = 1,
    Docusign = 3,
}

export interface IUtopiaNotificationsQueryParams {
    notificationStatuses: NotificationStatusEnum[],
    orderBy: NotificationOrderEnum,
    start: number,
    count: number,
}
