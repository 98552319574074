export enum ProductUpdateTemplateTypes {
    General = 1,
    PreviewerX = 2,
    Branding = 3,
}

export interface ProductUpdateCardButtonInfo {
    buttonText: string | null;
    buttonNavigateArea: string | null;
}

export interface ProductUpdateExternalButtonInfo {
    buttonText: string | null;
    buttonUrl: string | null;
}

export interface IProductUpdateCard {
    bulletPointText: string[] | null;
    buttonInfo: ProductUpdateCardButtonInfo;
    externalButton?: ProductUpdateExternalButtonInfo;
    image: string;
    linkText: string;
    linkUrl: string;
    templateType: ProductUpdateTemplateTypes;
    text: string;
    textHeader: string;
}
