import React from 'react';
import { AllowedCharacterTypes, DateFormatTypes, DropdownListFormatTypes, IMDField, SensitiveDataFormat, TextFormatTypes } from '../types/MDFieldTypes';
import { MDSideSheetInformationTKeys, TranslationFiles, useSafeTranslation } from './useSafeTranslation';

export const useMDSideSheet = () => {

    const { t } = useSafeTranslation(TranslationFiles.MDSideSheetInformation);

    function getSensitiveDataFormatSection(sensitiveDataFormat: SensitiveDataFormat) {
        const advancedFormatting: string[] = [];
        if (sensitiveDataFormat) {
            if (!sensitiveDataFormat?.sensitiveDataShown) {
                advancedFormatting.push(t(MDSideSheetInformationTKeys.NoSensitiveData));
            } else {
                // TODO: i think show and hide isn't clear in the interfaces. so hide/show 
                advancedFormatting.push(`${t(MDSideSheetInformationTKeys.ShowFirst)} ${sensitiveDataFormat.firstChars} ${t(MDSideSheetInformationTKeys.Characters)}`);
                advancedFormatting.push(`${t(MDSideSheetInformationTKeys.ShowLast)} ${sensitiveDataFormat.lastChars} ${t(MDSideSheetInformationTKeys.Characters)}`);
            }
        }
        return advancedFormatting;
    }

    function getMinMaxPropSectionForText(mdField: IMDField) {
        const advancedFormatting: string[] = [];

        if (!mdField.textProps?.minChars && !mdField.textProps?.maxChars) {
            advancedFormatting.push(t(MDSideSheetInformationTKeys.NoMinMax));
        } else if (!!mdField.textProps?.minChars) {
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MinChars)}: ${mdField.textProps?.minChars}`);
        } else if (!!mdField.textProps?.maxChars) {
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MaxChars)}: ${mdField.textProps?.maxChars}`);
        } else {
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MaxChars)}: ${mdField.textProps?.maxChars}`);
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MinChars)}: ${mdField.textProps?.minChars}`);
        }

        return advancedFormatting;
    }

    function getMinMaxPropSectionForNumber(mdField: IMDField) {
        const advancedFormatting: string[] = [];

        if (!mdField.numberProps?.minValue && !mdField.numberProps?.maxValue) {
            advancedFormatting.push(t(MDSideSheetInformationTKeys.NoMinMax));
        } else if (!!mdField.numberProps?.minValue) {
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MinChars)}: ${mdField.numberProps?.minValue}`);
        } else if (!!mdField.numberProps?.maxValue) {
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MaxChars)}: ${mdField.numberProps?.maxValue}`);
        } else {
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MaxChars)}: ${mdField.numberProps?.maxValue}`);
            advancedFormatting.push(`${t(MDSideSheetInformationTKeys.MinChars)}: ${mdField.numberProps?.minValue}`);
        }

        return advancedFormatting;
    }

    function getAdvancedFormattingForNumberProps(mdField: IMDField) {
        const advancedFormatting: string[] = [];
        if (mdField.numberProps?.sensitiveDataFormat) {
            advancedFormatting.push(...getSensitiveDataFormatSection(mdField.numberProps.sensitiveDataFormat));
        }
        if (mdField.numberProps) {
            advancedFormatting.push(...getMinMaxPropSectionForNumber(mdField));
        }
        return advancedFormatting;
    }

    function getAdvancedFormattingForTextProps(mdField: IMDField) {
        const advancedFormatting: string[] = [];
        if (!!mdField.textProps) {
            if (!!mdField.textProps.prefix) {
                advancedFormatting.push(`${MDSideSheetInformationTKeys.HasPrefix}: ${mdField.textProps.prefix}`);
            }

            advancedFormatting.push(AllowedCharacterTypes[mdField.textProps.allowedCharacters]);

            if (!!mdField.textProps) {
                advancedFormatting.push(...getMinMaxPropSectionForText(mdField));
            }
            if (mdField.textProps.sensitiveDataFormat) {
                advancedFormatting.push(...getSensitiveDataFormatSection(mdField.textProps.sensitiveDataFormat));
            }
        }
        return advancedFormatting;
    }

    function getAdvancedFormattingForCheckbox(mdField: IMDField): string {
        if (mdField.checkboxProps?.checkboxChecked) {
            return t(MDSideSheetInformationTKeys.CheckboxChecked);
        }
        else return t(MDSideSheetInformationTKeys.CheckboxUnchecked);
    }

    function styleTextFormat(mdField: IMDField) {
        if (mdField?.textProps?.textFormat === TextFormatTypes.SingleLine) {
            return t(MDSideSheetInformationTKeys.TextFormatSingleLine);
        } else {
            return t(MDSideSheetInformationTKeys.TextFormatMultiLine);
        }
    }
    function styleDropdownListFormat(mdField: IMDField) {
        if (mdField?.dropdownListProps?.dropdownListFormat === DropdownListFormatTypes.SingleSelect) {
            return t(MDSideSheetInformationTKeys.DropdownListFormatSingleSelect);
        } else {
            return t(MDSideSheetInformationTKeys.DropdownListFormatMultiSelect);
        }
    }

    function styleDateFormat(mdField: IMDField) {
        if (mdField?.dateProps?.dateFormat) {
            switch (mdField.dateProps.dateFormat) {
                case DateFormatTypes.ddmmyyyy:
                    return t(MDSideSheetInformationTKeys.ddmmyyyy);
                case DateFormatTypes.yyyymmdd:
                    return t(MDSideSheetInformationTKeys.yyyymmdd);
                case DateFormatTypes.monthddyyyy:
                    return t(MDSideSheetInformationTKeys.monthddyyyy);
                case DateFormatTypes.ddmonthyyyy:
                    return t(MDSideSheetInformationTKeys.ddmonthyyyy);
                default:
                    return t(MDSideSheetInformationTKeys.mmddyyyy);
            }
        }
        else return '';
    }

    function styleAddressFormat(mdField: IMDField) {
        const addressProps = mdField?.addressProps;
        const addressFormat: string[] = [];
        if (addressProps?.addressOne) {
            addressFormat.push(t(MDSideSheetInformationTKeys.AddressLine1));
        }
        if (addressProps?.addressTwo) {
            addressFormat.push(t(MDSideSheetInformationTKeys.AddressLine2));
        }
        if (addressProps?.city) {
            addressFormat.push(t(MDSideSheetInformationTKeys.City));
        }
        if (addressProps?.state) {
            addressFormat.push(t(MDSideSheetInformationTKeys.State));
        }
        if (addressProps?.postal) {
            addressFormat.push(t(MDSideSheetInformationTKeys.PostalCode));
        }
        if (addressProps?.country) {
            addressFormat.push(t(MDSideSheetInformationTKeys.Country));
        }
        return addressFormat;
    }

    return {
        getSensitiveDataFormatSection,
        getMinMaxPropSectionForText,
        getMinMaxPropSectionForNumber,
        getAdvancedFormattingForNumberProps,
        getAdvancedFormattingForTextProps,
        getAdvancedFormattingForCheckbox,
        styleTextFormat,
        styleDropdownListFormat,
        styleDateFormat,
        styleAddressFormat
    };
};