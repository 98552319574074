import { Api } from './';
import { AxiosResponse } from 'axios';
import { IActiveSession, ActiveSessionQueryRequest } from '../types/SessionTypes';

export const useUserSessionsApi = () => {

    const getUserSessions = async (accountID: number, queryParams: ActiveSessionQueryRequest): Promise<IActiveSession[]> => {
        const resp: AxiosResponse = await Api.post(`api/userSessions/${accountID}`, queryParams);
        return resp.data as IActiveSession[];
    };

    const endUserSessions = async (roleIDs: number[]) => {
        await Api.post('api/userSessions/remove', roleIDs);
    };

    return {
        getUserSessions,
        endUserSessions
    };
};
