import React from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { IProfileItem } from '../../../../../../types/ProfilesAndProfileItemsTypes';
import { useProfileUtilities } from '../../../../../../hooks/useProfileUtilities';
import { ProfilesAndProfileItemsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../hooks/useSafeTranslation';

export interface NodeProfileItemFieldProps {
    children: React.ReactNode;
    nodeProfileItem: IProfileItem;
}

export const NodeProfileItemField = ({ children, nodeProfileItem }: NodeProfileItemFieldProps) => {

    const { t } = useSafeTranslation(TranslationFiles.ProfilesAndProfileItems);
    const { truncateString } = useProfileUtilities();

    return (<>
        <Text size='md' className='d-flex align-items-center pb-2 pe-4' muted color='#8E8E8E'>
            {truncateString(nodeProfileItem.name)}
            {!!nodeProfileItem.required &&
                <Text color='#8E8E8E' size='md' muted>
                    &nbsp; ({t(ProfilesAndProfileItemsTKeys.RequiredText)})
                </Text>
            }
        </Text>
        {children}
    </>);
};
