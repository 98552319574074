import React, { useState } from 'react';
import { Dropdown, Text, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import './ContextMenu.css';

export interface ContextMenuProps extends React.HTMLAttributes<HTMLElement> {
    options: ContextMenuOptions[];
    dataId?: string;
    dropdownIcon?: FontAwesomeIconProps;
    emphasis?: 'low' | 'high' | 'med' | undefined;
}

export interface ContextMenuOptions extends Omit<EfcCallToActionInfo, 'text'> {
    type?: ContextMenuOptionType;
    text?: string;
}

export enum ContextMenuOptionType {
    Header = 'header',
    Divider = 'divider',
}

export const ContextMenu = ({ options, dataId, dropdownIcon = { icon: 'ellipsis' }, emphasis = 'low', ...otherProps }: ContextMenuProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const toggleDropdown = () => setIsMenuOpen((prevState) => !prevState);

    const determineBottomMargin = (i: number) => {
        if (options.length === 2) {
            return i === 0 && 'mb-2';
        } else if (i != 0 && i != options.length - 1) {
            return 'my-2';
        }
    }

    return (
        <Dropdown isOpen={isMenuOpen} toggle={toggleDropdown} dataId={dataId} {...otherProps}>
            <Dropdown.Toggle emphasis={emphasis} icon={dropdownIcon} size='sm' dataId={`${dataId}-toggle`} />
            <Dropdown.Menu dataId={`${dataId}-menu`} className='context-menu'>
                {options.map((o, i) => (
                    <Dropdown.Item onClick={o.onClick} className={`d-flex align-items-center option ${determineBottomMargin(i)}`} disabled={o.disabled} dataId={o.dataId} key={i} header={o?.type == ContextMenuOptionType.Header} divider={o?.type === ContextMenuOptionType.Divider}>
                        {o.icon && <FontAwesomeIcon {...o.icon} />}
                        {o.text && (
                            <Text className='ms-1' muted={o.disabled}>
                                {o.text}
                            </Text>
                        )}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
