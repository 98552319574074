import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { RevverIcon, SideNav, SideNavContainer } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, VerticalNavTKeys, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useUploadLinkApi } from '../../../api/useUploadLinkApi';
import { useAuthContext } from '../../../auth';
import { RoutePath } from '../../../types/RoutePaths';
import { AllFeaturesSlideout } from './AllFeaturesSlideout/AllFeaturesSlideout';
import { ExperienceTypeEnum } from '../../../auth/AuthenticationTypes';
import { DocumentRequestType } from '../../../types/UploadLinkTypes';
import './VerticalNav.css';

export interface VerticalNavProps {
    className?: string;
    isCollapsed: boolean;
    toggleAndSaveVertNavState: () => void;
    closeAndSaveVertNavState: () => void;
}

export const VerticalNav = (props: VerticalNavProps) => {
    const { toggleAndSaveVertNavState, isCollapsed, closeAndSaveVertNavState } = props;

    const location = useLocation();

    const { experienceType } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.VerticalNav);
    const { canGoToRoute } = useRouteValidator();
    const { getLinkToDocuments, getLinkToDocRequestIndex, getLinkToDocRequestInboxRequest, getLinkToESignatureIndex, getLinkToWorkflowIndex, getLinkToReports, getLinkToMetadataIndex } = useRouting();

    const { getDocumentRequestType } = useUploadLinkApi();
    const [showNavIcons, setShowNavIcons] = useState<boolean>(false);

    const checkIsUploadLink = async () => {
        try {
            const DOCUMENT_REQUEST_URL_PATH = getLinkToDocRequestInboxRequest('');
            if (location.pathname.includes(DOCUMENT_REQUEST_URL_PATH)) {
                const docRequestID = location.pathname.substring(location.pathname.indexOf(DOCUMENT_REQUEST_URL_PATH) + DOCUMENT_REQUEST_URL_PATH.length);
                const response = await getDocumentRequestType(docRequestID);
                if (!!response.documentRequestType) {
                    return response.documentRequestType === DocumentRequestType.UploadLink;
                } else {
                    console.error('Error getting document request type: ', response.errorMessage);
                }
            }
        } catch (error) {
            console.error('Error getting document request type: ', error);
        }
        return false;
    };

    useEffect(() => {
        const updatePageType = async () => {
            const isUploadLink = await checkIsUploadLink();
            setShowNavIcons(!isUploadLink);
            if (isUploadLink) {
                closeAndSaveVertNavState();
            }
        };

        updatePageType();
    }, [location.pathname]);

    return (
        <SideNavContainer {...props}>
            {/* prettier-ignore */}
            {showNavIcons && (<SideNav link={Link} location={location} style={{ overflow: 'hidden' }}>
                <Row className='sideNav-header flex-nowrap'>
                    <Col xs='auto' className='pe-0'>
                        <SideNav.Toggler className='sideNav-toggler' toggle={toggleAndSaveVertNavState} isOpen={!isCollapsed} dataId="btnToggleOpen" />
                    </Col>
                    <Col className='align-middle ps-0'>
                        <RevverIcon to={`${experienceType != ExperienceTypeEnum.Anonymous ? RoutePath.Home : ''}`} tag={Link} className={`sideNav-icon-new ${!!isCollapsed ? 'collapsed' : ''}`} />
                    </Col>
                </Row>
                <SideNav.Link show={canGoToRoute(RoutePath.Home)} tooltipId='home' dataId='sideNavHome' useTooltip={!!isCollapsed} exact to='/' icon={{ icon: 'house-chimney' }} text={t(VerticalNavTKeys.Home)} />
                <SideNav.Link show={canGoToRoute(RoutePath.Documents)} tooltipId='docs' dataId='sideNavDocuments' useTooltip={!!isCollapsed} to={getLinkToDocuments()} icon={{ icon: 'folder' }} text={t(VerticalNavTKeys.Documents)} />
                <SideNav.Link show={canGoToRoute(RoutePath.DocumentRequestIndex)} tooltipId='docReqs' useTooltip={!!isCollapsed} to={getLinkToDocRequestIndex()} icon={{ icon: 'inbox' }} text={t(VerticalNavTKeys.DocRequests)} />
                <SideNav.Link show={canGoToRoute(RoutePath.ESignatureIndex)} tooltipId='esig' dataId='sideNavESignature' useTooltip={!!isCollapsed} to={getLinkToESignatureIndex()} icon={{ icon: ['far', 'file-signature'] }} text={t(VerticalNavTKeys.ESignature)} />
                <SideNav.Link show={canGoToRoute(RoutePath.WorkflowIndex)} tooltipId='wf' useTooltip={!!isCollapsed} to={getLinkToWorkflowIndex()} icon={{ icon: 'wand-magic-sparkles' }} text={t(VerticalNavTKeys.Workflow)} />
                <SideNav.Link show={canGoToRoute(RoutePath.Reports)} tooltipId='reps' useTooltip={!!isCollapsed} to={getLinkToReports()} icon={{ icon: 'chart-line-up' }} text={t(VerticalNavTKeys.Reports)} />
                <SideNav.Link show={experienceType == ExperienceTypeEnum.Standard} icon={{ icon: 'grid' }} tooltipId='all' dataId='sideNavAllFeatures' useTooltip={!!isCollapsed} text={t(VerticalNavTKeys.AllFeatures)}>
                    <AllFeaturesSlideout closeTray />
                </SideNav.Link>
            </SideNav>)}
        </SideNavContainer>
    );
};