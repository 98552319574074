import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ShareableLink from '../../../../ShareableLink/ShareableLink';
import { IAccessLink } from '../../../../../types/CommonTypes';

export interface IEditAccessLinkModalProps {
  destroyModal: () => void;
  accessLink: IAccessLink;
}

export function EditAccessLinkModal ({ destroyModal }: IEditAccessLinkModalProps) {

  return (
    <>
      <Modal isOpen={true}>
        <div className='modal-header'>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={destroyModal}></button>
        </div>
        <ModalBody>
            <ShareableLink label='Test label' link='https://www.google.com/reallyLongUrlTestSoICanMakeAdjustmentsIfNeeded' />
        </ModalBody>
      </Modal>
    </>
  );
}
