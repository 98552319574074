import { useNavigate } from 'react-router';
import { RoutePath } from '../../types/RoutePaths';

export const useCheckedOutRouting = () => {

    const navigate = useNavigate();

    // Checked Out Index

    const getLinkToCheckedOutIndex = () => {
        return RoutePath.CheckedOutIndex;
    };

    const routeToCheckedOutIndex = () => {
        navigate(getLinkToCheckedOutIndex());
    };

    // My Checked Out Tab

    const getLinkToMyCheckedOut = () => {
        return RoutePath.CheckedOutPersonal;
    };

    const routeToMyCheckedOut = () => {
        navigate(getLinkToMyCheckedOut());
    };
    
    // Account Checked Out Tab

    const getLinkToAccountCheckedOut = () => {
        return RoutePath.CheckedOutAccount;
    };

    const routeToAccountCheckedOut = () => {
        navigate(getLinkToAccountCheckedOut());
    };

    return {
        getLinkToCheckedOutIndex,
        getLinkToMyCheckedOut,
        getLinkToAccountCheckedOut,
        routeToCheckedOutIndex,
        routeToMyCheckedOut,
        routeToAccountCheckedOut,
    };
};
