import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { EfcCallToActionInfo, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { RoutePath, UserSettingsRoute, UserSettingsRouteParams } from '../../types/RoutePaths';
import { IFrame } from '../../components/IFrame/IFrame';
import { useRouteValidator } from '../../hooks/useRouteValidator';
import { TranslationFiles, useSafeTranslation, UserSettingsTKeys } from '../../hooks/useSafeTranslation';
import { useUtopiaIFrame } from '../../hooks/useUtopiaIFrame';
import { useRouting } from '../../hooks/Routing/useRouting';
import { FeatureToggles } from '../../components/Nav/NavMenu/FeatureToggles/FeatureToggles';
import { useSettingsAndFeatures } from '../../context/settingsAndFeatures/SettingsFeaturesContext';
import { useAuthContext } from '../../auth';
import { FeatureFlags } from '../../types/FeatureFlags';
import { useIFramePathChangeListener } from '../../hooks/useIFramePathChangeListener';
import './UserSettings.css';

const IFrameRoutes: Map<UserSettingsRoute, string> = new Map([
    [RoutePath.UserSettingsIndex, '/userSettings/settings'],
    [RoutePath.UserSettings, '/userSettings/settings'],
    [RoutePath.MyAccounts, '/userSettings/accounts'],
    [RoutePath.IntegratedProducts, '/userSettings/externalIntegrations'],
    [RoutePath.UserEmailImports, '/userSettings/emailImportManagement'],
    [RoutePath.UserEmailImportNew, '/userSettings/emailImportManagement/edit/'],
    [RoutePath.UserEmailImportEdit, `/userSettings/emailImportManagement/edit/${UserSettingsRouteParams.MappingId}`],
]);

const EmailImportsTabStates = new Set<UserSettingsRoute>([
    RoutePath.UserEmailImports,
    RoutePath.UserEmailImportNew,
    RoutePath.UserEmailImportEdit,
]);

export const UserSettings = () => {
    const { routeToUserEmailImportNew, routeToMySettings, routeToMyAccounts, routeToIntegratedProducts, routeToUserEmailImports } = useRouting();
    const { mappingId } = useParams();

    const { t } = useSafeTranslation(TranslationFiles.UserSettings);
    const { canGoToRoute } = useRouteValidator();
    const { hasSettings, settings, hasFeatureFlags, checkFeatureFlag } = useSettingsAndFeatures();
    const { hasUser, user, authUser } = useAuthContext();

    const [showSideSheetToggle, setShowSideSheetToggle] = useState(false);
    const [showMetadataToggle, setShowMetadataToggle] = useState(false);

    const currentRoute =
        !!mappingId ? RoutePath.UserEmailImportEdit :
            `${location.pathname}` as UserSettingsRoute;
    const iframe = useUtopiaIFrame(IFrameRoutes.get(currentRoute));

    function getIFrameUrl(route: UserSettingsRoute) {
        return IFrameRoutes.get(route)
            ?.replace(UserSettingsRouteParams.MappingId, mappingId ?? '') ?? '';
    }

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(UserSettingsTKeys.AddNewMappingButton),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            onClick: () => routeToUserEmailImportNew(),
            show: () => currentRoute == RoutePath.UserEmailImports || currentRoute == RoutePath.UserEmailImportNew,
        }
    ];

    const onIFramePathChange = (newPath: string) => {
        if (currentRoute == RoutePath.UserEmailImportNew && newPath == IFrameRoutes.get(RoutePath.UserEmailImports)) {
            routeToUserEmailImports();
        }
    };

    useIFramePathChangeListener({ onIFramePathChange: onIFramePathChange, currentRoute: currentRoute });

    useEffect(() => {
        if (currentRoute == RoutePath.UserSettingsIndex) {
            routeToMySettings();
        } else {
            iframe.setUrl(getIFrameUrl(currentRoute));
        }
    }, [location.pathname]);

    useEffect(() => {
        if (hasSettings && !!settings && hasUser && !!user) {
            if ((settings.userIdsWithSideSheetToggle.includes(user.id) || checkFeatureFlag(FeatureFlags.SideSheetToggleEnabled)) && !checkFeatureFlag(FeatureFlags.SideSheetEnabled)) {
                setShowSideSheetToggle(true);
            } else {
                setShowSideSheetToggle(false);
            }

            if (!!authUser && (settings.userOrAccountIdsWithMetadataToggle?.userIds.includes(user.id) || settings.userOrAccountIdsWithMetadataToggle?.accountIds.includes(authUser.accountID) || checkFeatureFlag(FeatureFlags.MetadataToggleEnabled)) && !checkFeatureFlag(FeatureFlags.SideSheetEnabled)) {
                setShowMetadataToggle(true);
            } else {
                setShowMetadataToggle(false);
            }
        }
    }, [hasSettings, settings, hasUser, user, hasFeatureFlags]);

    return (
        <>
            {(showSideSheetToggle || showMetadataToggle) && <FeatureToggles showSideSheetToggle={showSideSheetToggle} showMetadataToggle={showMetadataToggle} />}
            <Page title={t(UserSettingsTKeys.UserSettingsTitle)} icon={{ icon: 'gear' }} callsToAction={ctas}>
                {canGoToRoute(RoutePath.UserSettings) && (
                    <Tab active={currentRoute == RoutePath.UserSettings} onClick={() => routeToMySettings()}>
                        {t(UserSettingsTKeys.UserSettingsTab)}
                    </Tab>
                )}
                {canGoToRoute(RoutePath.MyAccounts) && (
                    <Tab active={currentRoute == RoutePath.MyAccounts} onClick={() => routeToMyAccounts()}>
                        {t(UserSettingsTKeys.MyAccountsTab)}
                    </Tab>
                )}
                {canGoToRoute(RoutePath.IntegratedProducts) && (
                    <Tab active={currentRoute == RoutePath.IntegratedProducts} onClick={() => routeToIntegratedProducts()}>
                        {t(UserSettingsTKeys.IntegratedProductsTab)}
                    </Tab>
                )}
                {canGoToRoute(RoutePath.UserEmailImports) && (
                    <Tab active={EmailImportsTabStates.has(currentRoute)} onClick={() => routeToUserEmailImports()}>
                        {t(UserSettingsTKeys.EmailImportsTab)}
                    </Tab>
                )}

                {/* Use the iframe until we make this page native */}

                <IFrame className='iframe' src={iframe.url} />

                {/* Use the following Tab Panes once we make this page native */}

                {/*<Tab.Content activeTab={activeTab}>*/}
                {/*    <Tab.Pane tabId={0}>*/}
                {/*        Placeholder for Users*/}
                {/*    </Tab.Pane>*/}
                {/*    <Tab.Pane tabId={1}>*/}
                {/*        Placeholder for Other*/}
                {/*    </Tab.Pane>*/}
                {/*</Tab.Content>*/}
            </Page>
        </>
    );
};
