import { useEffect } from 'react';
import { useAuthContext } from '../auth';
import { useDocumentsUtilities } from './useDocumentsUtilities';
import { useRouting } from './Routing/useRouting';
import { useLayerContext } from '../context/layer/LayerContext';
import { useUtopiaRedirect } from './useUtopiaRedirect';

export const useSyncUtopiaDocumentsIFrameToAtlantisRoute = () => {
    const { user } = useAuthContext();
    const { getDocumentsIFrameState } = useDocumentsUtilities();
    const { routeToNodeContainer, routeToCollection, routeToDocuments } = useRouting();
    const { updatePreviewerXDocViewCount } = useLayerContext();
    const { getSelectedDetailEnum } = useUtopiaRedirect();
    const lastUtopiaHomePathLocalStorageKey = 'lastUtopiaHomeUrl:' + user?.id;

    const navigateToMatchingAtlantisUrl = (iframeUrlPath: string): void => {
        try {
            const state = getDocumentsIFrameState(iframeUrlPath);
            const selectedDetail = !!state.showFullPreview && state.showFullPreview == 'true' ? getSelectedDetailEnum('fullPreview') : getSelectedDetailEnum(state.selectedDetail ?? '');
            
            if (!!state.nodeIds) {
                routeToNodeContainer(state.parentId ?? '', state.nodeIds.split(','), selectedDetail, true);
            } else if (!!state.collectionId) {
                routeToCollection(state.collectionId, true);
            } else {
                routeToDocuments(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // when a new item or "Go To" is selected in the old ui, it posts a message to it's parent (atlantis) and we save it in local storage
    // and update the atlantis url to match (this makes items bookmarkable)
    const handleMessage = (message: any) => {
        if (message.data.key == 'utopiaHomeUrlNotification') {
            try {
                const newUrlPath = new URL(message.data.value).hash.substring(1);
                localStorage.setItem(lastUtopiaHomePathLocalStorageKey, newUrlPath);
                navigateToMatchingAtlantisUrl(newUrlPath);
            } catch (error) {
                console.error(error);
            }
        }

        if (message.data.key == 'previewerXDocLoadNotification' && user != null) {
            updatePreviewerXDocViewCount(user.id);
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
};
