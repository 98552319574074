import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { EfcCallToActionInfo, EfcTableColumnInfo, FullTable } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, MDGroupsTKeys, MetadataTKeys } from '../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../auth';
import { useMDGroupsApi } from '../../../api/useMDGroupsApi';
import { useLayerContext } from '../../../context/layer/LayerContext';
import { IMDGroup } from '../../../types/MDFieldTypes';
import { MDZeroState } from './MDZeroState';
import { MDGroupModal } from './MDGroupModal';

interface IMDGroupDisplay {
    id: string;
    name: string;
    appliedInstanceCount: number;
    createdBy: string;
}

interface MDGroupsProps {
    active: boolean;
    refreshMDGroupsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    selectedMDGroupState: [IMDGroup, Dispatch<SetStateAction<IMDGroup>>];
}

export const MDGroups: FC<MDGroupsProps> = ({ active, refreshMDGroupsSentinalKeyState, selectedMDGroupState }) => {

    const { authUser, hasAuthUser } = useAuthContext();
    const { openModal } = useLayerContext();
    const { getAccountMDGroups, deleteMDGroup, getMDGroup } = useMDGroupsApi();
    const { t } = useSafeTranslation(TranslationFiles.MDGroups);

    const [allGroupsLoaded, setAllGroupsLoaded] = useState(false);
    const [mdGroups, setMDGroups] = useState<IMDGroup[]>([]);
    const [refreshMDGroupsSentinalKey, setRefreshMDGroupsSentinalKey] = refreshMDGroupsSentinalKeyState;
    const [selectedMDGroup, setSelectedMDGroup] = selectedMDGroupState;

    const BATCH_SIZE = 100;

    const mdGroupDisplays = mdGroups.map((mdGroup: IMDGroup) => convertGroupToDisplay(mdGroup));

    function openMDGroupModal(mdGroupToEdit?: IMDGroup) {
        openModal(() =>
            <MDGroupModal mdGroupToEdit={mdGroupToEdit} onSubmitGroup={() => setRefreshMDGroupsSentinalKey(prevState => prevState + 1)} />
        );
    }

    function convertGroupToDisplay(mdGroup: IMDGroup): IMDGroupDisplay {
        const mdGroupItem: IMDGroupDisplay = {
            id: mdGroup.id as string,
            name: mdGroup.name,
            appliedInstanceCount: mdGroup.appliedInstanceCount ?? 0,
            createdBy: mdGroup.createdByDisplayName ?? '-',
        };

        return mdGroupItem;
    }

    async function deleteMetadataGroupAsync(mdGroup: IMDGroupDisplay) {
        if (!!authUser && !!mdGroup) {
            setMDGroups(prevState => prevState
                .filter(group => group.id != mdGroup.id)
                .map(group => ({ ...group })));
            await deleteMDGroup(authUser?.accountID, mdGroup.id);
        }
    }

    function onSelectGroup(selectedList: IMDGroupDisplay[]) {
        //seems to be double calling, but that appears to be a FullTable issue (imo)
        const selected = selectedList[0];
        if (!!selected && selected.id !== selectedMDGroup?.id) {
            const group = mdGroups.find((g) => g.id === selected.id);
            setSelectedMDGroup(group as IMDGroup);
        }
    }

    async function editMDGroup(id: string) {
        const mdGroupToEdit = await getMDGroup(authUser?.accountID as number, id);
        if (!!mdGroupToEdit) {
            openMDGroupModal(mdGroupToEdit);
        }
    }

    const getColumns = (): EfcTableColumnInfo[] => {
        return [
            { name: 'name', displayName: t(MDGroupsTKeys.GroupNameColumn), widthPct: 40 },
            { name: 'instances', displayName: t(MDGroupsTKeys.InstanceColumn), widthPct: 15 },
            { name: 'createdBy', displayName: t(MDGroupsTKeys.CreatedByColumn), widthPct: 40 },
        ];
    };

    const getContextActions = (): EfcCallToActionInfo[] => {
        return [
            { text: t(MDGroupsTKeys.EditContextButton), onClick: (mdGroupDisplay) => { editMDGroup(mdGroupDisplay.id); } },
            { text: t(MDGroupsTKeys.DeleteContextButton), onClick: deleteMetadataGroupAsync },
        ];
    };

    useEffect(() => {
        let componentUnmounted = false;

        async function loadAccountMDGroupsAsync() {
            await getAccountMDGroups(authUser?.accountID as number, 0, BATCH_SIZE).then((groups) => {
                if (!componentUnmounted) {
                    if (groups.length < BATCH_SIZE) {
                        setAllGroupsLoaded(true);
                    }
                    setMDGroups(groups);
                }
            });
        }

        if (!!active && !!hasAuthUser) {
            loadAccountMDGroupsAsync();
        }

        return () => {
            componentUnmounted = true;
        };
    }, [active, hasAuthUser, refreshMDGroupsSentinalKey]);

    return (
        <>
            {!allGroupsLoaded
                ? (<>Loading</>)
                : (
                    (mdGroupDisplays.length == 0)
                        ? <MDZeroState buttonText={t(MetadataTKeys.CreateGroup)} onButtonClick={() => openMDGroupModal()} />
                        : <FullTable filterable={true} multiselect={false} data={mdGroupDisplays} columns={getColumns()} contextActions={getContextActions()} onSelect={onSelectGroup} />
                )}
        </>
    );
};