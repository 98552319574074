import { AxiosResponse } from 'axios';
import { Api } from './';
import { IProductUpdateCard } from '../types/ProductUpdateTypes';

export const useProductUpdatesApi = () => {

    const getProductUpdateCards = async (): Promise<IProductUpdateCard[]> => {
        const resp: AxiosResponse = await Api.get('ProductUpdateCards.json');
        return resp.data;
    };
    
    const getToken = async () => {
        const resp: AxiosResponse = await Api.get('api/ProductUpdates/GetToken');
        return resp.data;
    };

    return {
        getProductUpdateCards,
        getToken,
    };
};
