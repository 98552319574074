import React from 'react';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { Text, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../../../../hooks/useColors';

export const PermissionDetailsTableHeader = () => {

    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { atlantisPrimary } = useColors();

    const ICON_COL_WIDTH = '4%';
    const PERMISSION_COL_WIDTH = '9%';

    return (
        <tr>
            <th scope='col'>
                <Text>{t(PermissionsTKeys.RoleColumnHeader)}</Text>
            </th>
            <th scope='col' style={{ width: ICON_COL_WIDTH }}></th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <Text>{t(PermissionsTKeys.ViewColumnHeader)}</Text>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <Text>{t(PermissionsTKeys.DownloadColumnHeader)}</Text>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.CreateColumnHeader)}</Text>
                    <TooltipItem id='permission-details-create-column-header' message={t(PermissionsTKeys.CreateInfoText)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.WriteUploadColumnHeader)}</Text>
                    <TooltipItem id='permission-details-writeupload-column-header' message={t(PermissionsTKeys.WriteUploadInfoText)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.DeleteColumnHeader)}</Text>
                    <TooltipItem id='permission-details-delete-column-header' message={t(PermissionsTKeys.DeleteInfoText)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.AdminColumnHeader)}</Text>
                    <TooltipItem id='permission-details-admin-column-header' message={t(PermissionsTKeys.AdminInfoText)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.OverrideColumnHeader)}</Text>
                    <TooltipItem id='permission-details-override-column-header' message={t(PermissionsTKeys.OverrideInfoText)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.RestrictAllColumnHeader)}</Text>
                    <TooltipItem id='permission-details-restrictall-column-header' message={t(PermissionsTKeys.RestrictAllInfoText)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: ICON_COL_WIDTH }}></th>
        </tr>
    );
};