import { useNavigate } from 'react-router';
import { DocumentRequestRouteParams, RoutePath } from '../../types/RoutePaths';

export const useDocRequestsRouting = () => {

    const navigate = useNavigate();

    // Doc Request Index

    const getLinkToDocRequestIndex = () => {
        return RoutePath.DocumentRequestIndex;
    };

    const routeToDocRequestIndex = () => {
        navigate(getLinkToDocRequestIndex());
    };

    const getLinkToDocRequestNew = () => {
        return RoutePath.DocumentRequestNew;
    };

    const routeToDocRequestNew = () => {
        navigate(getLinkToDocRequestNew());
    };

    // Inbox Tab

    const getLinkToDocRequestInbox = () => {
        return RoutePath.DocumentRequestInbox;
    };

    const routeToDocRequestInbox = () => {
        navigate(getLinkToDocRequestInbox());
    };

    const getLinkToDocRequestInboxRequest = (requestId: string) => {
        return RoutePath.DocumentRequestInboxEdit.replace(DocumentRequestRouteParams.InboxRequestId, requestId);
    };

    const routeToDocRequestInboxRequest = (requestId: string) => {
        navigate(getLinkToDocRequestInboxRequest(requestId));
    };

    // Sent Tab

    const getLinkToDocRequestSent = () => {
        return RoutePath.DocumentRequestSent;
    };

    const routeToDocRequestSent = () => {
        navigate(getLinkToDocRequestSent());
    };

    const getLinkToDocRequestSentRequest = (requestId: string) => {
        return RoutePath.DocumentRequestSentEdit
            .replace(DocumentRequestRouteParams.SentRequestId, requestId);
    };

    const routeToDocRequestSentRequest = (requestId: string) => {
        navigate(getLinkToDocRequestSentRequest(requestId));
    };

    // Account Tab

    const getLinkToDocRequestAccountRequests = () => {
        return RoutePath.DocumentRequestAccountRequests;
    };

    const routeToDocRequestAccountRequests = () => {
        navigate(getLinkToDocRequestAccountRequests());
    };

    const getLinkToDocRequestAccountRequest = (requestId: string) => {
        return RoutePath.DocumentRequestAccountRequestEdit
            .replace(DocumentRequestRouteParams.AccountRequestId, requestId);
    };

    const routeToDocRequestAccountRequest = (requestId: string) => {
        navigate(getLinkToDocRequestAccountRequest(requestId));
    };

    // Templates Tab

    const getLinkToDocRequestTemplates = () => {
        return RoutePath.DocumentRequestTemplates;
    };

    const routeToDocRequestTemplates = () => {
        navigate(getLinkToDocRequestTemplates());
    };

    const getLinkToDocRequestTemplate = (requestId: string) => {
        return RoutePath.DocumentRequestTemplateEdit
            .replace(DocumentRequestRouteParams.TemplateRequestId, requestId);
    };
    
    const routeToDocRequestTemplate = (requestId: string) => {
        navigate(getLinkToDocRequestTemplate(requestId));
    };

    const getLinkToDocRequestTemplateNew = () => {
        return RoutePath.DocumentRequestTemplateNew;
    };

    const routeToDocRequestTemplateNew = () => {
        navigate(getLinkToDocRequestTemplateNew());
    };

    return {
        getLinkToDocRequestIndex,
        getLinkToDocRequestNew,
        getLinkToDocRequestInbox,
        getLinkToDocRequestInboxRequest,
        getLinkToDocRequestSent,
        getLinkToDocRequestSentRequest,
        getLinkToDocRequestAccountRequests,
        getLinkToDocRequestAccountRequest,
        getLinkToDocRequestTemplates,
        getLinkToDocRequestTemplate,
        getLinkToDocRequestTemplateNew,
        routeToDocRequestIndex,
        routeToDocRequestNew,
        routeToDocRequestInbox,
        routeToDocRequestInboxRequest,
        routeToDocRequestSent,
        routeToDocRequestSentRequest,
        routeToDocRequestAccountRequests,
        routeToDocRequestAccountRequest,
        routeToDocRequestTemplates,
        routeToDocRequestTemplate,
        routeToDocRequestTemplateNew,
    };
};
