import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Card, EfcFeedItemInfo, Feed, Text } from '@efilecabinet/efc-atlantis-components';
import { INodeRecent, IRecentNodeQueryParams, RecentNodeTypeEnum } from '../../../types/RecentsTypes';
import { useIcons } from '../../../hooks/useIcons';
import { useRecentsApi } from '../../../api';
import { useSafeTranslation, RecentsTKeys, TranslationFiles } from '../../../hooks/useSafeTranslation';
import { useCardSizes } from '../../../hooks/useCardSizes';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useBootstrapBreakpoints } from '../../../hooks/useBootstrapBreakpoints';
import { ZeroState } from '../../../components/ZeroState';
import { ScreenSize } from '../../../types/BootstrapTypes';
import zeroStateImg from './RecentsZeroState.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import './Recents.css';

export interface IRecentsProps {
    className?: string;
    maxRowsToDisplay: number;
    initialPullCount?: number;
}

export const Recents = (props: IRecentsProps) => {
    const { className, maxRowsToDisplay, initialPullCount = 15 } = props;

    const SKELETON_COUNT = 10;

    const [recentFeedItems, setRecentFeedItems] = useState<EfcFeedItemInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const { getNodeIconProps } = useIcons();
    const { t } = useSafeTranslation(TranslationFiles.Recents);
    const { getRecentNodes } = useRecentsApi();
    const { getCardTitleSize, getCardIconSize } = useCardSizes();
    const { getLinkToRecents, getLinkToNode } = useRouting();
    const { screenSize } = useBootstrapBreakpoints();

    const recentNodesParams: IRecentNodeQueryParams = {
        recentType: RecentNodeTypeEnum.All,
        start: 0,
        count: initialPullCount,
    };

    const convertNodeRecentToFeedItem = (node: INodeRecent): EfcFeedItemInfo => {
        const feedItem: EfcFeedItemInfo = {
            id: node.id,
            title: node.name,
            feedDate: new Date(node.auditLogDate),
            icon: { ...getNodeIconProps(node.systemType, node.fileInfo?.fileExtension), size: 'lg' },
        };

        feedItem.to = getLinkToNode([node.id]);

        return feedItem;
    };

    const addRecentNodes = (moreNodes: INodeRecent[]) => {
        const moreFeedItems = moreNodes.map((node: INodeRecent) => convertNodeRecentToFeedItem(node));
        setRecentFeedItems((prevState) => [...prevState, ...moreFeedItems]);
    };

    const getRecentNodeItems = async () => {
        getRecentNodes(recentNodesParams)
            .then((nodes: INodeRecent[]) => {
                setRecentFeedItems(nodes.map((node: INodeRecent) => convertNodeRecentToFeedItem(node)));

                const rowsLeft = maxRowsToDisplay - initialPullCount;
                if (rowsLeft > 0) {
                    getRecentNodes({ ...recentNodesParams, start: initialPullCount, count: rowsLeft }).then((moreNodes) => {
                        addRecentNodes(moreNodes);
                    });
                }
            })
            .catch(() => {
                setErrorLoading(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getRecentNodeItems();
    }, []);

    return (
        <>
            <Card fluid borderless className={className}>
                <Card.Body>
                    <Card.Title tag={Link} to={getLinkToRecents()} className='ms-1 no-decoration' size={getCardTitleSize()} bold icon={{ icon: 'clock-rotate-left', pull: 'left', size: getCardIconSize() }}>
                        {t(RecentsTKeys.RecentsTitle)}
                    </Card.Title>

                    {!!isLoading || !!errorLoading ? (
                        <>
                            {!!isLoading && (
                                <SkeletonTheme enableAnimation height='24px' width='100%'>
                                    <Skeleton count={SKELETON_COUNT} className='mb-3 mx-auto' />
                                </SkeletonTheme>
                            )}
                            {!!errorLoading &&
                                <Card.Text>{t(RecentsTKeys.ErrorLoading)}</Card.Text>
                            }
                        </>
                    ) : (
                        <>
                            {recentFeedItems.length == 0 ? (
                                <ZeroState
                                    imageColContent={<img src={zeroStateImg} alt={t(RecentsTKeys.ZeroStateImgAltText)} />}
                                    textColContent={
                                        <>
                                            <Text bold>{t(RecentsTKeys.NoItemsMainText)}</Text>
                                            <br />
                                            <Text>{t(RecentsTKeys.NoItemsSubText)}</Text>
                                        </>
                                    }
                                    stackColumns={screenSize < ScreenSize.xxl} />
                            ) : (
                                <Feed condensed feedItems={recentFeedItems} maxRowsToDisplay={maxRowsToDisplay} tag={Link} seeMoreTo={getLinkToRecents()} />
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};
