import React, { Dispatch, FC, SetStateAction } from 'react';
import { Text, Button } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, MDZeroStateTKeys } from '../../../hooks/useSafeTranslation';
import { ZeroState } from '../../../components/ZeroState';
import { useBootstrapBreakpoints } from '../../../hooks/useBootstrapBreakpoints';
import { ScreenSize } from '../../../types/BootstrapTypes';
import zeroStateImg from './MetadataFieldsZeroState.svg';

export interface IMDZeroStateProps {
    buttonText: string;
    onButtonClick: () => void;
}

export const MDZeroState: FC<IMDZeroStateProps> = ({ buttonText, onButtonClick }) => {

    const { screenSize } = useBootstrapBreakpoints();

    const { t } = useSafeTranslation(TranslationFiles.MDZeroState);

    return (
        <>
            <ZeroState maxImgWidth={450} style={{ padding: '5em' }} maxTextWidth={450}
                imageColContent={<img src={zeroStateImg} alt={t(MDZeroStateTKeys.ZeroStateImgAltText)} />}
                textColContent={
                    <>
                        <div>
                            <Text semibold tag='h3'>{t(MDZeroStateTKeys.ZeroStateHeader)} </Text>
                            <Text>{t(MDZeroStateTKeys.ZeroStateBody)}</Text>
                            <div style={{ marginTop: '1em' }}>
                                <Button dataId='addFromLibraryBtn' type='submit' style={{ marginRight: '1em' }}>
                                    <span className='d-flex align-items-center'>
                                        {t(MDZeroStateTKeys.AddFromLibrary)}
                                    </span>
                                </Button>
                                <Button dataId={buttonText + 'Btn'} emphasis='med' className='me-3' type='button' onClick={onButtonClick}>
                                    {buttonText}
                                </Button>
                            </div>
                        </div>
                    </>
                }
                stackColumns={screenSize < ScreenSize.xl} />
        </>
    );
};