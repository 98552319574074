import axios, { AxiosRequestConfig } from 'axios';
import { useSessionStorage } from '../hooks/useSessionStorage';

const instance = axios.create();
const sessionStorage = useSessionStorage();

//do other things to axios, like intercept requests and add valid bearer token, etc.
instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = sessionStorage.getAccessToken();

    if (config.headers === undefined) {
        config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

export default instance;