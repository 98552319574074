import { Node_RecycleBin } from '../types/RecycleBinTypes';
import { Role, IUserFeedbackInfo } from '../types/CommonTypes';
import { AccountBranding } from '../types/BrandingTypes';

export enum AccountFeatureEnum {
    FullUserLicense = 1,
    GuestUserLicense = 2,
    Governance = 3,
    FileStorageSize = 4,
    FileVersioning = 5,
    Templates = 6,
    CheckInOut = 7,
    AccusoftPreview = 8,
    ZonalOCRLicense = 11,
    AccountTemplateFeature = 12,
    FullTextSearch = 13,
    PersonalProviders = 14,
    DocumentRequests = 15,
    GovernanceLock = 16,
    AccusoftOCR = 17,
    Workflow = 19,
    Salesforce = 20,
    ItemStatus = 21,
    EnterpriseSecurity = 22,
    GovernanceUnlock = 23,
    Branding = 24,
    GuestUserPreview = 25,
    FilePassword = 26,
    GuestUserSearch = 27,
    ConcurrentLicensing = 28,
    EmailFiles = 29,
    BetaAccount = 30,
    Records = 31,
    SSO_SAML = 32,
    EmailImport = 33,
    PreviewerImageStamps = 34,
    ESignature = 35,
    DualScreenPreview = 36,
    ESignatureKBA = 37,
    ESignatureOTP = 38,
    AnonymousAccessLinks = 39,
    SearchReports = 40,
    Watermark = 41,
    Reporting = 42,
    FormFill = 43,
    PriorityOCR = 44,
    O365 = 45,
    WorkflowStepsPerWorkflow = 46,
    AbbyFineReaderOCR = 47,
    EssentialsUserLicense = 49,
    PublicShareSearch = 50,
    ComplianceUserLicense = 51,
}

export enum SystemPermissionEnum {
    ItemPermissionsControl = 0,
    ItemPermissionsOverride = 1,
    SystemSettings = 2,
    SalesforceManagement = 3,
    AuditLogManagement = 4,
    ViewAuditLogs = 5,
    PersonalProviders = 6,
    DocumentRequests = 7,
    DocumentRequestManagement = 8,
    FormFill = 9,
    ESignature = 10,
    ESignatureManagement = 11,
    Profiles = 12,
    ProfileManagement = 13,
    FileManagement = 14,
    TemplateManagement = 15,
    FileVersioning = 16,
    FilePassword = 17,
    Search = 18,
    CheckInOut = 19,
    PreviewerTools = 20,
    CreateModifyStructure = 21,
    Governance = 22,
    GovernanceAdmin = 23,
    Sharing = 24,
    AccessLinks = 25,
    AccessLinkManagement = 26,
    UserManagement = 27,
    SessionManagement = 28,
    GroupManagement = 29,
    Workflow = 30,
    WorkflowApproval = 31,
    WorkflowManagement = 32,
    Automation = 33,
    RubexReports = 34,
    EnterpriseSecurityManagement = 35,
}

export interface SystemPermission {
    name: string;
    description: string;
    systemPermissionType: SystemPermissionEnum;
}

export interface SystemPermissions {
    permissionsList: SystemPermission[];
    accountID: number;
}

export enum UserLicenseEnum {
    Full = AccountFeatureEnum.FullUserLicense,
    Guest = AccountFeatureEnum.GuestUserLicense,
    Essentials = AccountFeatureEnum.EssentialsUserLicense,
    Compliance = AccountFeatureEnum.ComplianceUserLicense,
    Unassigned = -1
}

export interface AccountFeature {
    id: number;
    utopiaAccountId: number;
    utopiaAccountIdentifier: string;
    featureType: AccountFeatureEnum;
    featureValue: number | null;
    isAssignable: boolean;
}

export interface IAuthUser {
    accountID: number;
    userID: number;
    accessToken: string;
    refreshToken?: string;
    expiresIn?: number;
    mfaToken?: string;
    userType: number;
    displayName: string;
    multipleAccounts: boolean;
    samlConfigId?: number;
    eSigProduct?: number;
    systemPermissionsToAccounts: SystemPermissions[];
    userRoles: Role[];
    sessionExpirationMismatch: boolean;
}

export interface IAuthContext {
    accountFeatures: AccountFeature[];
    authUser: IAuthUser | null;
    hasAuthUser: boolean;
    user: IUser | null;
    hasUser: boolean;
    handleLoginWithToken: (token: string) => Promise<void>;
    handleLogout: () => void;
    handleSwitchAccounts: (roleId: number, onAccountSwitch?: () => void) => void;
    getIsAuthorized: () => Promise<boolean>;
    hasCheckedAuthUser: boolean;
    userAccounts: Map<number, IAuthAccount> | null;
    hasUserAccounts: boolean;
    openAccountSwitchRequiredModal: (accountId: number, onAccountSwitch: () => void) => void;
    optInInfo: IOptInInfo | null;
    groupManagerAccounts: number[];
    loginCount: number;
    previewerXSessionCount: number;
    apiOperationCache: Set<any>;
    setApiOperationCache: React.Dispatch<React.SetStateAction<Set<any>>>;
    experienceType: ExperienceTypeEnum;
    userCanViewDocumentRequests: boolean;
    userCanViewDocuments: boolean;
    showAdminAccountOnboarding: boolean;
    isNewUser: boolean;
    updateCurrentAccountBranding: (newBranding: AccountBranding) => void;
    loginIframeComplete: boolean;
    userFeedbackInfo: IUserFeedbackInfo | null;
    handleNewSideSheetToggle: (optIn: boolean) => Promise<void>;
    atlantisSideSheetEnabled: boolean;
    handleMetadataToggle: (optIn: boolean) => void;
    getMetadataToggleStatus: () => boolean;
}

export interface IUser {
    id: number;
    securitySettings: IUserSecuritySettings;
    preferences: IUserPreferences;
    emailSettings: IEmailSetting[];
    CustomPreviewerStamps: Node_RecycleBin;
    userName: string;
    sessionExpire: number;
}

export enum ExperienceTypeEnum {
    Standard,
    Guest,
    Anonymous,
}

export enum MfaTypeEnum {
    App = 0,
    Email = 1,
    Either = 2,
}

export interface IUserSecuritySettings {
    oldPassword: string;
    newPassword: string;
    mfaIsRequired: boolean;
    mfaType: MfaTypeEnum;
    mfaRequiredDays?: number;
    mobilePhoneNumber: string;
    mobilePhoneCountryCode: string;
}

export interface IUserPreferences {
    activeEmailSettingID?: number;
    checkOutOnDoubleClick?: boolean;
    alwaysConfirmAppend: boolean;
    deleteAppendedNode: boolean;
    defaultCollectionID?: number;
    timeZoneId: string;
    useStoreArea: boolean;
    searchDefaultByName: boolean;
    searchDefaultByProfileValue: boolean;
    searchDefaultByCreatedByName: boolean;
    searchDefaultByComment: boolean;
    searchDefaultByFullText: boolean;
    searchDefaultByPath: boolean;
    searchDefaultUseContainsWordsIn: boolean;
    skipSSOFullUserPrompt: boolean;
}

export enum EmailSettingTypeEnum {
    SMTP = 0,
    IMAP = 1,
}

export enum EmailAuthTypeEnum {
    UsernameAndPassword = 0,
    OAuth = 1,
}

export enum ExternalAuthenticationProviderEnum {
    GoogleDrive = 1,
    OneDrive = 2,
    Docusign = 3,
    Gmail = 5,
    Outlook = 6,
}

export interface IEmailSetting {
    id: number;
    accountID?: number;
    emailAddress: string;
    serverAddress: string;
    serverPort: number;
    userName: string;
    password: string;
    enableSSL: boolean;
    settingType: EmailSettingTypeEnum;
    isAccountSetting: boolean;
    failureCount: number;
    lastFailureMessage: string;
    inFailure: boolean;
    authType: EmailAuthTypeEnum;
    externalAuthorizationID?: number;
    externalAuthProviderType: ExternalAuthenticationProviderEnum;
    expiration: number;
    authToken: string;
}

export interface IAuthAccount {
    userID: number;
    roleID: number;
    accountID: number;
    accountName: string;
    userType: string;
    licenseType: UserLicenseEnum;
    accessToken: string;
    systemPermissions: SystemPermission[];
    identifier: string;
    branding?: AccountBranding;
}

export interface ISelectAuthAccounts {
    authAccounts: IAuthAccount[];
}

export interface IOptInInfo {
    optInAtlantis: boolean;
    optInAtlantisSideSheet: boolean;
    showWelcomeModal: boolean;
    canOptInAtlantis: boolean;
    canOptOutAtlantis: boolean;
    optInAtlantisDateTime?: Date;
    optOutAtlantisDateTime?: Date;
    optInAtlantisRevverLogo: boolean;
}

export interface IsGroupManagerResponse {
    isManager: boolean;
    accountsWhereManager: number[];
}
