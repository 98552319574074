import React, { useState } from 'react';
import { Offcanvas, SearchInput } from '@efilecabinet/efc-atlantis-components';
import { useRouteValidator } from '../../../../hooks/useRouteValidator';
import { useRouting } from '../../../../hooks/Routing/useRouting';
import { useZIndexManager } from '../../../../hooks/useZIndexManager';
import { RoutePath } from '../../../../types/RoutePaths';
import { AllFeaturesPanel } from '../../components/AllFeatureGroups/AllFeaturesPanel';
import './AllFeaturesSlideout.css';

export interface AllFeaturesSlideoutProps {
    isOpen: boolean;
    collapseAllFeatures: () => void;
}

export const AllFeaturesSlideout = (props: AllFeaturesSlideoutProps) => {
    const { isOpen, collapseAllFeatures } = props;
    const { canGoToRoute } = useRouteValidator();
    const { getOffCanvasZIndex } = useZIndexManager();

    const [searchInput, setSearchInput] = useState<string>('');
    const { routeToSearchInputResults } = useRouting();

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            searchIconClick();
        }
    };

    const searchIconClick = () => {
        routeToSearchInputResults(searchInput);
        setSearchInput('');
    };

    return (
        <Offcanvas zIndex={getOffCanvasZIndex()} isOpen={isOpen} direction='bottom' backdrop={false} fade={true} className='all-features-slideout-spacing'>
            <Offcanvas.Header className='all-features-slideout-header'>All Features</Offcanvas.Header>
            <Offcanvas.Body>
                <div className='px-1'>
                    {canGoToRoute(RoutePath.Search) &&
                        <SearchInput className='all-features-slideout-search-input' value={searchInput} onChange={(event) => setSearchInput(event.target.value)} onKeyDown={handleKeyEvent} iconOnClick={searchIconClick} />
                    }
                    <AllFeaturesPanel collapseAllFeatures={collapseAllFeatures} color='white' />
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};
