import React, { useEffect, useState } from 'react';
import { Tooltip } from '@efilecabinet/efc-atlantis-components';
import { IFileVersion } from '../../../../../types/CommonTypes';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FileVersionLockedProps {
    fileVersion: IFileVersion;
}

export const FileVersionLocked = ({ fileVersion }: FileVersionLockedProps) => {
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);

    const [isLockToolTipOpen, setIsLockToolTipOpen] = useState<boolean>(false);
    const [tooltipTarget, setTooltipTarget] = useState<string>(`is-locked-${fileVersion.id}`);

    const toggleLockTooltip = () => setIsLockToolTipOpen((prevState) => !prevState);

    useEffect(() => {
        const randomId = Math.floor(1000 + Math.random() * 9000);
        setTooltipTarget(`is-locked-${randomId}`);
    }, []);

    return (
        <>
            <span id={tooltipTarget}>
                <FontAwesomeIcon icon='lock' className='ms-1 me-2' data-id={`file-version-lock-icon-${fileVersion.id}`}/>
            </span>
            <Tooltip target={tooltipTarget} isOpen={isLockToolTipOpen} toggle={toggleLockTooltip} placement='bottom'>
                {t(FileVersionsTKeys.LockedTooltip)}
            </Tooltip>
        </>
    );
};
