import React from 'react';
import { Col, Row, List } from 'reactstrap';
import { EfcLink, Text, Button } from '@efilecabinet/efc-atlantis-components';
import { IProductUpdateCard } from '../../../../types/ProductUpdateTypes';
import { useProductUpdatesApi } from '../../../../api/useProductUpdatesApi';
import { useSettingsAndFeatures } from '../../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { useNavigate } from 'react-router';
import { useBootstrapBreakpoints } from '../../../../hooks/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../types/BootstrapTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../../../hooks/useColors';

export const ProductUpdateItem = (props: IProductUpdateCard) => {
    const { image, textHeader, text, linkUrl, linkText, bulletPointText, buttonInfo, externalButton } = props;

    const { getToken } = useProductUpdatesApi();
    const { settings } = useSettingsAndFeatures();
    const { screenSize } = useBootstrapBreakpoints();
    const navigate = useNavigate();
    const { atlantisPrimary } = useColors();

    const handleButtonClick = () => {
        if (!!buttonInfo.buttonNavigateArea) {
            navigate(buttonInfo.buttonNavigateArea);
        }
    };

    const handleExternalButtonClick = () => {
        if (!!externalButton?.buttonUrl) {
            window.open(externalButton.buttonUrl);
        }
    };

    const goToCardLink = () => {
        if (linkUrl.includes('[[PRODUCT_BOARD_TOKEN]]')) {
            getToken()
                .then((token: string) => {
                    window.open(linkUrl.replace('[[PRODUCT_BOARD_TOKEN]]', token), '_blank');
                })
                .catch(() => {
                    window.open(settings?.productUpdatesUrl, '_blank');
                });
        } else if (linkUrl.includes('[[BASE_URL]]')) {
            navigate(linkUrl.replace('[[BASE_URL]]', ''));
        } else {
            window.open(linkUrl);
        }
    };

    return (
        <Row className='align-items-center'>
            {screenSize > ScreenSize.m && (
                <Col xs='auto'>
                    <img style={{ maxWidth: '200px' }} src={image} className='img-fluid w-auto user-select-none ms-2' alt='Product Board' />
                </Col>
            )}

            <Col className='pb-5 ms-4'>
                <Text size='lg' bold>
                    {textHeader}
                </Text>
                <br />
                <br />
                <Text>{text}</Text>
                <br />
                {!!bulletPointText && bulletPointText.length > 0 && (
                    <List className='mt-2 mb-0'>
                        {bulletPointText.map((textStr, index) => (
                            <li key={index}>{textStr}</li>
                        ))}
                    </List>
                )}
                <br />
                {!!buttonInfo && (
                    <Button color='primary' emphasis='med' className='me-3' onClick={handleButtonClick}>
                        {buttonInfo.buttonText}
                    </Button>
                )}
                {!!externalButton && (
                    <Button color='primary' emphasis='high' className='me-3' onClick={handleExternalButtonClick}>
                        {externalButton.buttonText}
                    </Button>
                )}
                {!!linkText && (
                    <EfcLink color='primary' onClick={goToCardLink}>
                        {linkText}
                        <FontAwesomeIcon icon='external-link' className='my-auto' color={atlantisPrimary} />
                    </EfcLink>
                )}
            </Col>
        </Row>
    );
};
