import { VerticalNavTKeys } from './useSafeTranslation';
import { RoutePath } from '../types/RoutePaths';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { useAuthContext } from '../auth';

interface FeatureGroup {
    TitleTranslationKey: VerticalNavTKeys;
    Features: Feature[];
}

interface Feature {
    icon: FontAwesomeIconProps;
    routePath: RoutePath;
    translationKey: VerticalNavTKeys;
}

export const useAllFeaturesFlyoutHook = () => {
    const { getMetadataToggleStatus } = useAuthContext();

    const Documents: FeatureGroup = {
        TitleTranslationKey: VerticalNavTKeys.Documents,
        Features: [
            { routePath: RoutePath.Documents, icon: { icon: 'folder' }, translationKey: VerticalNavTKeys.Documents },
            { routePath: RoutePath.Collections, icon: { icon: 'heart' }, translationKey: VerticalNavTKeys.MyFavorites },
            { routePath: RoutePath.CheckedOutPersonal, icon: { icon: 'lock' }, translationKey: VerticalNavTKeys.CheckedOut },
            { routePath: RoutePath.RecycleBin, icon: { icon: 'trash-can' }, translationKey: VerticalNavTKeys.RecycleBin },
            { routePath: RoutePath.FileProcessingQueue, icon: { icon: ['far', 'repeat'] }, translationKey: VerticalNavTKeys.FileProcessingQueue },
        ],
    };

    const People: FeatureGroup = {
        TitleTranslationKey: VerticalNavTKeys.People,
        Features: [
            { routePath: RoutePath.UserIndex, icon: { icon: 'user' }, translationKey: VerticalNavTKeys.Users },
            { routePath: RoutePath.Groups, icon: { icon: 'users' }, translationKey: VerticalNavTKeys.Groups },
            { routePath: RoutePath.AccessLinks, icon: { icon: 'link' }, translationKey: VerticalNavTKeys.PublicAccessLinks },
        ],
    };

    const Account: FeatureGroup = {
        TitleTranslationKey: VerticalNavTKeys.Account,
        Features: [
            { routePath: RoutePath.AuditLogs, icon: { icon: 'clock-rotate-left' }, translationKey: VerticalNavTKeys.AuditLogs },
            { routePath: RoutePath.AccountSettingsIndex, icon: { icon: 'gear' }, translationKey: VerticalNavTKeys.AccountSettings },
            { routePath: RoutePath.UserSettings, icon: { icon: 'gear' }, translationKey: VerticalNavTKeys.MySettings },
            { routePath: RoutePath.SecurityPolicies, icon: { icon: 'shield' }, translationKey: VerticalNavTKeys.SecurityPolicies },
            { routePath: RoutePath.Salesforce, icon: { icon: ['far', 'cloud'] }, translationKey: VerticalNavTKeys.SalesforceIntegration },
            { routePath: RoutePath.UtilitiesIndex, icon: { icon: 'wrench' }, translationKey: VerticalNavTKeys.Utilities },
            { routePath: RoutePath.Search, icon: { icon: 'search' }, translationKey: VerticalNavTKeys.Search },
        ],
    };

    const Work: FeatureGroup = {
        TitleTranslationKey: VerticalNavTKeys.Work,
        Features: [
            { routePath: RoutePath.FolderTemplatesIndex, icon: { icon: 'sitemap' }, translationKey: VerticalNavTKeys.FolderTemplates },
            { routePath: RoutePath.DocumentRequestIndex, icon: { icon: 'inbox' }, translationKey: VerticalNavTKeys.DocRequests },
            { routePath: RoutePath.UserEmailImports, icon: { icon: ['far', 'envelope'] }, translationKey: VerticalNavTKeys.EmailImports },
            //TODO: change out Profile to Metadata
            (!!getMetadataToggleStatus()
                ? { routePath: RoutePath.MetadataIndex, icon: { icon: 'code' }, translationKey: VerticalNavTKeys.Metadata }
                : { routePath: RoutePath.ProfilesIndex, icon: { icon: 'clipboard-list' }, translationKey: VerticalNavTKeys.Profiles }),
            { routePath: RoutePath.ESignatureIndex, icon: { icon: ['far', 'file-signature'] }, translationKey: VerticalNavTKeys.ESignature },
            { routePath: RoutePath.Forms, icon: { icon: ['far', 'clipboard-list'] }, translationKey: VerticalNavTKeys.Forms },
            { routePath: RoutePath.WorkflowIndex, icon: { icon: 'wand-magic-sparkles' }, translationKey: VerticalNavTKeys.Workflow },
            { routePath: RoutePath.Governance, icon: { icon: 'shield' }, translationKey: VerticalNavTKeys.Governance },
            { routePath: RoutePath.Reports, icon: { icon: 'chart-line-up' }, translationKey: VerticalNavTKeys.Reports },
        ],
    };

    const AllFeatureGroups: FeatureGroup[] = [Documents, Work, People, Account];

    return {
        AllFeatureGroups,
    };
};
