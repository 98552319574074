import { Api } from './';
import { AxiosResponse } from 'axios';
import { IRecentNodeQueryParams, RecentNodeTypeEnum } from '../types/RecentsTypes';

export const useRecentsApi = () => {
    const getRecentNodes = async (recentNodeQueryParams: IRecentNodeQueryParams) => {
        const recentNodeType: string = RecentNodeTypeEnum[recentNodeQueryParams.recentType];
        const resp: AxiosResponse = await Api.get(`api/recentNodes/RecentNodes/${recentNodeType}/${recentNodeQueryParams.start}/${recentNodeQueryParams.count}`);
        return resp.data;
    };

    return {
        getRecentNodes,
    };
};
