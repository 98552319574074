import React, { ReactNode, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Text, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { AnimatedCollapseIcon } from '../AnimatedCollapseIcon/AnimatedCollapseIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../hooks/useColors';
import './NestedCollapse.css';

interface NestedCollapseProps {
    children: ReactNode;
    title: string;
    subtitle?: string;
    titleSize?: 'micro' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    tooltipMessage?: string;
    className?: string;
    innerClassName?: string;
    openByDefault?: boolean;
    color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'muted' | 'default';
}

export const NestedCollapse = (props: NestedCollapseProps) => {
    const { title, subtitle, titleSize = 'md', tooltipMessage, children, className, innerClassName, openByDefault = false, color = 'primary' } = props;

    const { atlantisPrimary, atlantisDefault, atlantisMuted } = useColors();

    const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
    const [isIconOpen, setIsIconOpen] = useState<boolean>(false);
    const [animationClass, setAnimationClass] = useState<string>('');
    const [tooltipId] = useState<string>(crypto.randomUUID());

    const ANIMATION_DELAY_IN_MS = 380;

    const grow = () => {
        setIsIconOpen(true);
        setIsCollapseOpen(true);
        setAnimationClass('grow-animation');
    };

    const onClick = () => {
        if (isCollapseOpen) {
            setAnimationClass('shrink-animation');
            setIsIconOpen(false);
            setTimeout(() => {
                setIsCollapseOpen(false);
            }, ANIMATION_DELAY_IN_MS);
        } else {
            grow();
        }
    };

    let styleColor = '';
    if (color === 'default') {
        styleColor = atlantisDefault;
    } else if (color === 'muted') {
        styleColor = atlantisMuted;
    } else {
        styleColor = color;
    }

    useEffect(() => {
        if (openByDefault) {
            grow()
        }
    }, [openByDefault]);

    return (
        <>
            <Row className={`${className || ''}`}>
                <Col xs='auto' role='button' className='me-auto d-flex align-items-center' onClick={onClick}>
                    <AnimatedCollapseIcon isOpen={isIconOpen} size='lg' />
                    <Text size={titleSize} color={styleColor}>{title}</Text>
                    <Text className='mx-1' size={titleSize} color={styleColor} >{subtitle}</Text>
                </Col>
                <Col xs='auto' className='d-flex align-items-center'>
                    {!!tooltipMessage &&
                        <TooltipItem id={tooltipId} message={tooltipMessage} innerClassName='d-flex align-items-center' >
                            <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                        </TooltipItem>
                    }
                </Col>
            </Row>
            {!!isCollapseOpen &&
                <Row className='h-auto'>
                    <Col xs='11' className={`collapse-content ${animationClass} ${innerClassName || ''}`}>
                        {children}
                    </Col>
                </Row>
            }
        </>
    );
};