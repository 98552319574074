import { useNavigate } from 'react-router';
import { RoutePath } from '../../types/RoutePaths';

export const useHomeRouting = () => {

    const navigate = useNavigate();

    const getLinkToHome = () => {
        return RoutePath.Home;
    };
    
    const routeToHome = () => {
        navigate(getLinkToHome());
    };

    return {
        getLinkToHome,
        routeToHome,
    };
};
