import { AxiosResponse } from 'axios';
import { Api } from './';
import { DocumentRequestTypeCheckResponse, UploadLinkResponse } from '../types/UploadLinkTypes';

export const useUploadLinkApi = () => {
    const createUploadLink = async (nodeId: string, subject: string, accountId: number) => {
        const resp: AxiosResponse = await Api.post('api/UploadLink/Create', { nodeId, subject, accountId });
        return resp.data as UploadLinkResponse;
    };

    const getDocumentRequestType = async (documentRequestID: string) => {
        const resp: AxiosResponse = await Api.get(`api/UploadLink/CheckDocumentRequestType/${documentRequestID}`);
        return resp.data as DocumentRequestTypeCheckResponse;
    };

    return {
        createUploadLink,
        getDocumentRequestType,
    };
};