import React, { useEffect, useState, ChangeEvent } from 'react';
import { UploadLinkProps } from './UploadLinkModal';
import { TranslationFiles, UploadLinkTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { Alert, FormFeedback, FormGroup, Input, Label, Text } from '@efilecabinet/efc-atlantis-components';
import { ThemeEnum } from '../../../../hooks/useColors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './UploadLink.css';

interface CreateUploadLinkProps extends UploadLinkProps {
    error: boolean;
    subject: string;
    handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
    toggleDisable: (value: boolean) => void;
}

const MAXIMUM_CHARACTERS = 90;

export const CreateUploadLink = ({ node, subject, error, toggleDisable, handleInput }: CreateUploadLinkProps) => {
    const { t } = useSafeTranslation(TranslationFiles.UploadLinkModal);

    const [invalid, setInvalid] = useState<boolean>(false);
    const [validationErr, setValidationErr] = useState<string>('');

    const validate = (value: string) => {
        if (!value || value.trim() === '') {
            setInvalid(true);
            setValidationErr(`${t(UploadLinkTKeys.Required)} ${t(UploadLinkTKeys.MinimumCharacterError)}`);
            toggleDisable(true);
        } else if (value.length > MAXIMUM_CHARACTERS) {
            setInvalid(true);
            setValidationErr(`${t(UploadLinkTKeys.Required)} ${t(UploadLinkTKeys.MaximumCharacterError, { max: MAXIMUM_CHARACTERS })}`);
            toggleDisable(true);
        } else {
            setInvalid(false);
            setValidationErr('');
            toggleDisable(false);
        }
    };

    useEffect(() => {
        validate(subject);
    }, [subject]);

    return (
        <>
            {!!error && (
                <div>
                    <Alert color={ThemeEnum.Danger} dataId='upload-link-error'>
                        {t(UploadLinkTKeys.ErrorCreatingLink)}
                    </Alert>
                </div>
            )}
            {t(UploadLinkTKeys.CreateDescription, { nodeName: node.name })}
            <ul className='mb-4'>
                <li>{t(UploadLinkTKeys.CreateListItemOne)}</li>
                <li>{t(UploadLinkTKeys.CreateListItemTwo)}</li>
            </ul>
            <FormGroup>
                <Label className='my-0'>{t(UploadLinkTKeys.CreatePageTitle)}</Label>
                <Text className='my-1 d-block' muted>
                    {t(UploadLinkTKeys.CreateHelpText)}
                </Text>
                <Input dataId='upload-page-title' value={subject} onChange={handleInput} invalid={invalid} />
                <FormFeedback className='invalid'>
                    <span hidden={!invalid}>
                        <FontAwesomeIcon icon='exclamation-triangle' className='mr-1' />
                    </span>
                    {validationErr}
                </FormFeedback>
            </FormGroup>
        </>
    );
};
