import React from 'react';
import { Button } from '@efilecabinet/efc-atlantis-components';
import { AccountsNavMenuItemTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { IAuthAccount } from '../../../../../auth/AuthenticationTypes';

interface SeeMoreAccountsButtonProps {
    userAccounts: Map<number, IAuthAccount>;
    onClick: any;
    maxAccounts: number;
}

export const SeeMoreAccountsButton = (props: SeeMoreAccountsButtonProps) => {
    const { userAccounts, onClick, maxAccounts } = props;

    const { t } = useSafeTranslation(TranslationFiles.AccountsNavMenuItem);

    return (
        <>
            {!!userAccounts && userAccounts.size > maxAccounts && (
                <>
                    <div className='d-flex justify-content-center'>
                        <Button color='primary' emphasis='low' onClick={onClick}>
                            {t(AccountsNavMenuItemTKeys.Accounts, { count: userAccounts.size - maxAccounts })}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};