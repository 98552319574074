import React from 'react';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { TourType } from '../../../../types/TourTypes';
import { TranslationFiles, WelcomeTourTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { RoutePath } from '../../../../types/RoutePaths';
import { AtlantisTour, AtlantisTourStep } from '../../AtlantisTour/AtlantisTour';
import { useBootstrapBreakpoints } from '../../../../hooks/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../types/BootstrapTypes';

export const WelcomeTour = () => {

    const { t } = useSafeTranslation(TranslationFiles.WelcomeTour);

    const { isTourOpen } = useLayerContext();
    const { screenSize } = useBootstrapBreakpoints();

    const tourType = TourType.Welcome;

    const steps: AtlantisTourStep[] = [
        {
            selector: '[data-id="sideNavHome"] > li > span',
            content: t(WelcomeTourTKeys.NavigateHome),
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.Home,
        },
        {
            selector: '[data-id="sideNavDocuments"] > li > span',
            content: t(WelcomeTourTKeys.Step1Text),
        },
        {
            selector: '[data-id="sideNavAllFeatures"] > li > span',
            content: t(WelcomeTourTKeys.Step2Text),
        },
        {
            selector: '[data-id="workYouCareAboutBody"]',
            content: t(WelcomeTourTKeys.Step3Text),
            resizeObservables: ['[data-id="workYouCareAboutBody"]'],
        },
        {
            selector: '[data-id="toDoListBody"]',
            content: t(WelcomeTourTKeys.Step4Text),
            resizeObservables: ['[data-id="toDoListBody"]'],
        },
        {
            selector: '[data-id="navMenuHelpIcon"]',
            content: t(WelcomeTourTKeys.Step5Text),
        },
    ];

    return (
        <AtlantisTour steps={steps} isOpen={isTourOpen(tourType) && screenSize > ScreenSize.s} tourType={tourType} />
    );
};
