import React, { ReactNode, useEffect, useState } from 'react';
import { useSystemApi } from '../../api';
import { DesktopApplicationInfo, ISettings, ISettingsFeaturesContext, UtopiaConfiguration } from '../../types/SettingsFeaturesTypes';
import { SettingsFeaturesContext } from './SettingsFeaturesContext';

interface SettingsFeaturesProviderProps {
    children: ReactNode;
}

export const SettingsFeaturesProvider = (props: SettingsFeaturesProviderProps) => {

    const { children } = props;

    const [settings, setSettings] = useState<ISettings | undefined>();
    const [hasSettings, setHasSettings] = useState<boolean>(false);

    const [utopiaConfig, setUtopiaConfig] = useState<UtopiaConfiguration | undefined>();
    const [hasUtopiaConfig, setHasUtopiaConfig] = useState<boolean>(false);

    const [featureFlags, setFeatureFlags] = useState<string[] | undefined>();
    const [hasFeatureFlags, setHasFeatureFlags] = useState<boolean>(false);

    const [desktopApplicationInfo, setDesktopApplicationInfo] = useState<DesktopApplicationInfo | undefined>();
    const [hasDesktopApplicationInfo, setHasDesktopApplicationInfo] = useState(false);

    const system = useSystemApi();

    const getSettingsAndFeatures = async () => {

        system.getFeatureFlags()
            .then((flags) => {
                setFeatureFlags(flags);
                setHasFeatureFlags(true);
            });
        system.getSettings()
            .then((settings) => {
                setSettings(settings);
                setHasSettings(true);
            });
        system.getRubexConfiguration()
            .then((config) => {
                setUtopiaConfig(config);
                setHasUtopiaConfig(true);
            });

        getApplicationInfo();
    };

    const getApplicationInfo = () => {
        const applicationInfoString = localStorage.getItem('applicationInfo');
        if (!!applicationInfoString) {
            const applicationInfo = JSON.parse(applicationInfoString);
            setDesktopApplicationInfo(applicationInfo as DesktopApplicationInfo);
            setHasDesktopApplicationInfo(true);
        }
        else {
            setHasDesktopApplicationInfo(false);
        }
    };

    const checkFeatureFlag = (flag: string): boolean => {
        return !!featureFlags && featureFlags.indexOf(flag) >= 0;
    };

    useEffect(() => {

        getSettingsAndFeatures();

        const updateTimer: NodeJS.Timer = setInterval(getSettingsAndFeatures, 30000);

        return () => {
            if (!!updateTimer) clearInterval(updateTimer);
        };

    }, []);

    const contextObject: ISettingsFeaturesContext = {
        hasSettings,
        settings,
        hasUtopiaConfig,
        utopiaConfig,
        hasFeatureFlags,
        featureFlags,
        checkFeatureFlag,
        hasDesktopApplicationInfo,
        desktopApplicationInfo
    };

    return (
        <SettingsFeaturesContext.Provider value={contextObject}>
            {children}
        </SettingsFeaturesContext.Provider>
    );
};