import { Api } from './';
import { AxiosResponse } from 'axios';
import { INode } from '../types/CommonTypes';

export const useFormsApi = () => {
    const getFormFillTemplates = async (accountId: number): Promise<INode[]> => {
        const resp: AxiosResponse = await Api.get(`api/FormFill/Templates/${accountId}`);
        return resp.data as INode[];
    };

    return {
        getFormFillTemplates,
    };
};
