import { Api } from './';
import { AxiosResponse } from 'axios';
import { Role, AccountRoleCounts, IBatchItem } from '../types/CommonTypes';
import { RoleQuery } from '../types/SearchTypes';

export const useRoleApi = () => {
    const updateRole = async (role: Role): Promise<Role> => {
        const roleBatchItem: IBatchItem = { batchObject: role, clientIdentifier: '' };
        return await batchUpdateRole([roleBatchItem]);
    };

    const batchUpdateRole = async (batchItems: IBatchItem[]): Promise<Role> => {
        const resp: AxiosResponse = await Api.post('api/Role/RoleBatch/Update', batchItems);
        return resp.data;
    };

    const queryRolesByAccountId = async (roleQuery: RoleQuery): Promise<Role[]> => {
        const queryString = `?roleTypes=${roleQuery.roleTypes.join(',')}&userLicenseTypes=${roleQuery.userLicenseTypes.join(',')}&orderBy=${roleQuery.order ?? ''}&searchCriteria=${roleQuery.searchCriteria ?? ''}&start=${roleQuery.start ?? ''}&count=${roleQuery.count ?? ''}`;
        const resp: AxiosResponse = await Api.get(`api/Role/Account/${roleQuery.accountId}` + queryString);
        return resp.data;
    };

    const getAccountRoleCounts = async (accountId: number): Promise<AccountRoleCounts> => {
        const resp: AxiosResponse = await Api.get(`api/Role/GetAccountRoleCounts/${accountId}`);
        return resp.data;
    };

    return {
        updateRole,
        batchUpdateRole,
        queryRolesByAccountId,
        getAccountRoleCounts,
    };
};
