import { useState } from 'react';
import _ from 'lodash';
import { useNodeApi } from '../api/useNodeApi';
import { NodeType } from '../types/CommonTypes';

export interface INodePath {
    nodeId: string;
    name: string;
    type: NodeType;
    path: string;
}

export const useAncestorPathUtility = () => {

    const { getAncestorsOnlyAsync } = useNodeApi();

    const [nodePathLookup, setNodePathLookup] = useState<Map<string, INodePath>>(new Map());

    const loadAncestorsAsync = (nodeId: string): void => {
        getAncestorsOnlyAsync(nodeId)
            .then(ancestorNodes => {
                setNodePathLookup(new Map(
                    ancestorNodes.map(node => {
                        const parentNode = _.find(ancestorNodes, (ancestorNode) => ancestorNode.id == node.parentID);
                        const grandParentNode = _.find(ancestorNodes, (ancestorNode) => ancestorNode.id == parentNode?.parentID);
                        const greatGrandParentNode = _.find(ancestorNodes, (ancestorNode) => ancestorNode.id == grandParentNode?.parentID);
                        return {
                            nodeId: node.id,
                            name: node.name,
                            type: node.systemType,
                            path: (!!greatGrandParentNode ? '... / ' : '')
                                + (!!grandParentNode ? grandParentNode.name + ' / ' : '')
                                + (!!parentNode ? parentNode.name + ' / ' : '')
                                + node.name,
                        };
                    })
                        .map(nodePath => [nodePath.nodeId, nodePath])));
            });
    };

    const getAncestorPath = (ancestorNodeId: string): INodePath => nodePathLookup.get(ancestorNodeId) ?? { nodeId: ancestorNodeId, name: '', type: NodeType.Folder, path: '' };

    return {
        getAncestorPath,
        loadAncestorsAsync,
    };
};