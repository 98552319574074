import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, EfcCallToActionInfo, EfcTableColumnInfo, FullTable, Page } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { useAuthContext } from '../../../auth';
import { useNodeApi } from '../../../api';
import { FolderTemplatesTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { IFrame } from '../../../components/IFrame/IFrame';
import { INode } from '../../../types/CommonTypes';
import { AddTemplateModal } from './AddTemplateModal';
import { AccountFeatureUsageHeader } from '../../../components/AccountFeatureUsageHeader/AccountFeatureUsageHeader';
import { AccountFeatureEnum } from '../../../auth/AuthenticationTypes';
import './FolderTemplates.css';

export const FolderTemplates = () => {
    const { t } = useSafeTranslation(TranslationFiles.FolderTemplates);

    const { libraryId, templateId } = useParams();
    const { routeToFolderTemplateLibrary, routeToFolderTemplate, getLinkToFolderTemplateLibrary, getLinkToFolderTemplate, getLinkToFolderTemplatesIndex } = useRouting();

    const { hasAuthUser, authUser } = useAuthContext();

    const [templateItems, setTemplateItems] = useState<INode[]>([]);
    const [library, setLibrary] = useState<INode | null>();
    const [template, setTemplate] = useState<INode | null>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const { getTemplateLibraries, getNodeChildrenAsync, getNodeByIdAsync, createTemplateLibrary, createTemplate, deleteNodeById } = useNodeApi();

    const defaultUrl = '/admin/template/libraries/';
    const iframe = useUtopiaIFrame(defaultUrl);

    const setEditTemplateUrl = (libraryId: string, templateId: string) => {
        iframe.setUrl(`${defaultUrl}${libraryId}/editor/${templateId}`);
    };

    const showLibraries = () => !libraryId && !templateId;
    const showTemplates = () => !!libraryId && !templateId;

    const linkToTemplate = (node: INode) => {
        if (!libraryId) {
            return getLinkToFolderTemplateLibrary(node.id);
        }
        else if (!!libraryId && !templateId) {
            return getLinkToFolderTemplate(libraryId, node.id);
        }
        else return getLinkToFolderTemplatesIndex();
    };

    const closeAddTemplateModal = () => setIsModalOpen(false);
    const handleNewTemplateClick = () => setIsModalOpen(true);

    const handleSubmitNewTemplate = (name: string) => {
        closeAddTemplateModal();

        if (!libraryId) {
            createTemplateLibrary(name)
                .then((node: INode) => {
                    routeToFolderTemplateLibrary(node.id);
                });
        }
        else if (!!libraryId && !templateId){
            createTemplate(libraryId, name)
                .then((node: INode) => {
                    routeToFolderTemplate(node.parentID, node.id);
                });
        }
    };

    const removeTemplate = (template: INode) => {
        setTemplateItems((prevState) =>
            prevState.filter((temp) => {
                return temp.id != template.id;
            })
        );
    };

    const deleteTemplate = (node: INode) => {
        if (!!node && !!authUser) {
            deleteNodeById(node.id)
                .then(() => {
                    removeTemplate(node);
                });
        }
    };

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(FolderTemplatesTKeys.NewTemplateLibrary),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            onClick: handleNewTemplateClick,
            show: () => showLibraries(),
            dataId: 'btnNewTemplateLibrary',
        },
        {
            text: t(FolderTemplatesTKeys.NewTemplate),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            onClick: handleNewTemplateClick,
            show: () => showTemplates(),
            dataId: 'btnNewFolderTemplate',
        },
    ];

    const libColumns: (string | EfcTableColumnInfo)[] = [
        { name: 'name', displayName: t(FolderTemplatesTKeys.NameColumn), useIcon: () => !libraryId ? { icon: ['far', 'building-columns'] } : { icon: 'sitemap' }, linkTo: linkToTemplate },
        { name: 'createdBy', displayName: t(FolderTemplatesTKeys.CreatedByColumn) },
        { name: 'createdOn', displayName: t(FolderTemplatesTKeys.CreatedOnColumn), isDate: true },
    ];

    const templateColumns: (string | EfcTableColumnInfo)[] = [
        { name: 'name', displayName: t(FolderTemplatesTKeys.NameColumn), useIcon: () => !libraryId ? { icon: ['far', 'building-columns'] } : { icon: 'sitemap' }, linkTo: linkToTemplate },
        { name: 'instances', displayName: t(FolderTemplatesTKeys.InstancesColumn), },
        { name: 'createdBy', displayName: t(FolderTemplatesTKeys.CreatedByColumn) },
        { name: 'createdOn', displayName: t(FolderTemplatesTKeys.CreatedOnColumn), isDate: true },
    ];

    const contextActions: EfcCallToActionInfo[] = [
        { text: t(FolderTemplatesTKeys.Delete), icon: { icon: 'trash-can' }, onClick: deleteTemplate },
    ];

    useEffect(() => {
        if (!!authUser) {
            if (!!libraryId && !!templateId) {
                setEditTemplateUrl(libraryId, templateId);
            }

            if (!!libraryId) {
                getNodeByIdAsync(libraryId).then((library: INode) => {
                    setLibrary(library);
                });
            } else setLibrary(null);

            if (!!templateId) {
                getNodeByIdAsync(templateId).then((template: INode) => {
                    setTemplate(template);
                });
            } else setTemplate(null);

            setTemplateItems([]);

            if (showLibraries()) {
                getTemplateLibraries(authUser.accountID).then((templateLibraries: INode[]) => {
                    setTemplateItems(templateLibraries);
                });
            }
            else if (showTemplates()) {
                getNodeChildrenAsync(Number(libraryId)).then((templates: INode[]) => {
                    setTemplateItems(templates);
                });
            }
        }

    }, [hasAuthUser, libraryId, templateId]);

    return (
        <>
            <Page title={t(FolderTemplatesTKeys.PageTitle)} icon={{ icon: 'sitemap' }} callsToAction={ctas} className='h-100 folder-template-page'>
                <AccountFeatureUsageHeader accountFeatureTypes={[AccountFeatureEnum.Templates]} />

                <Breadcrumb>
                    <Breadcrumb.Item tag={Link} to={getLinkToFolderTemplatesIndex()}>Template Libraries</Breadcrumb.Item>
                    {!!libraryId &&
                        <Breadcrumb.Item tag={Link} to={getLinkToFolderTemplateLibrary(library?.id ?? '')}>{library?.name}</Breadcrumb.Item>
                    }
                    {!!templateId &&
                        <Breadcrumb.Item>{template?.name}</Breadcrumb.Item>
                    }
                </Breadcrumb>

                {(showLibraries() || showTemplates()) &&
                    <FullTable tag={Link} data={templateItems} columns={showTemplates() ? templateColumns : libColumns} selectable={false} contextActions={contextActions} />
                }

                {/* Use the iframe until we make this page native */}

                <IFrame className='iframe' src={iframe.url} hidden={showLibraries() || showTemplates()} />

                <AddTemplateModal isOpen={isModalOpen} closeModal={closeAddTemplateModal} library={!libraryId} submit={handleSubmitNewTemplate} />
            </Page>

        </>
    );
};
