import { Api } from './';
import { AxiosResponse } from 'axios';
import { FeedbackFormProps } from '../components/Nav/NavMenu/Help/Feedback/FeedbackModal';
import { IUserFeedbackInfo } from '../types/CommonTypes';

export const useFeedbackApi = () => {
    const getUserFeedbackInfo = async (userId: number) => {
        const resp: AxiosResponse = await Api.get(`api/Feedback/GetUserFeedbackInfo/${userId}`);
        return resp.data as IUserFeedbackInfo;
    };

    const acknowledgePreviewerFeedback = async (userId: number) => {
        const resp: AxiosResponse = await Api.post(`api/Feedback/AcknowledgePreviewerFeedback/${userId}`);
        return resp.data as IUserFeedbackInfo;
    };

    const submitFeedback = async (form: FeedbackFormProps) => {
        await Api.post('api/Feedback', form);
    };

    const updateSurveyModal = async (userId: number) => {
        await Api.post(`api/Feedback/UpdateSurveyModal/${userId}`);
    };

    const updateSurveyReprompt = async (userId: number) => {
        await Api.post(`api/Feedback/UpdateSurveyReprompt/${userId}`);
    };

    return {
        getUserFeedbackInfo,
        acknowledgePreviewerFeedback,
        submitFeedback,
        updateSurveyModal,
        updateSurveyReprompt,
    };
};
