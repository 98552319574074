import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { EfcCallToActionInfo, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { AccountSettingsRouteParams, AccountSettingsRoutePath, RoutePath } from '../../../types/RoutePaths';
import { FeatureFlags } from '../../../types/FeatureFlags';
import { useSettingsAndFeatures } from '../../../context/settingsAndFeatures/SettingsFeaturesContext';
import { SettingsTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../../hooks/Routing/useRouting';
import { Branding } from './Branding';
import { useIFramePathChangeListener } from '../../../hooks/useIFramePathChangeListener';
import './Settings.css';

const IFrameRoutes: Map<AccountSettingsRoutePath, string> = new Map([
    [RoutePath.AccountSettingsIndex, '/admin/settings'],
    [RoutePath.AccountSettings, '/admin/settings'],
    [RoutePath.SingleSignOn, '/admin/samlsettings'],
    [RoutePath.SamlValidate, '/admin/samlsettings/validate'],
    [RoutePath.SamlNew, '/admin/samlsettings/add'],
    [RoutePath.SamlEdit, `/admin/samlsettings/edit/${AccountSettingsRouteParams.ConfigurationId}`],
    [RoutePath.Branding, '/admin/branding'],
    [RoutePath.AccountEmailImports, '/admin/emailImportManagement'],
    [RoutePath.AccountEmailImportNew, '/admin/emailImportManagement/edit/'],
    [RoutePath.AccountEmailImportNew, `/admin/emailImportManagement/edit/${AccountSettingsRouteParams.MappingId}`],
]);

const SSOTabRoutes = new Set<AccountSettingsRoutePath>([
    RoutePath.SingleSignOn,
    RoutePath.SamlValidate,
    RoutePath.SamlNew,
    RoutePath.SamlEdit,
]);

const EmailImportsTabStates = new Set<AccountSettingsRoutePath>([
    RoutePath.AccountEmailImports,
    RoutePath.AccountEmailImportNew,
    RoutePath.AccountEmailImportEdit,
]);

export const Settings = () => {

    const { routeToAccountSettings, routeToSingleSignOn, routeToSamlValidate, routeToSamlNew, routeToBranding, routeToAccountEmailImports, routeToAccountEmailImportNew } = useRouting();
    const { mappingId, configurationId } = useParams();

    const { t } = useSafeTranslation(TranslationFiles.Settings);
    const { hasFeatureFlags, checkFeatureFlag } = useSettingsAndFeatures();

    const [hideIframeRoutes, setHideIframeRoutes] = useState(new Set<AccountSettingsRoutePath>([]));
    const [canAccessAtlantisBranding, setCanAccessAtlantisBranding] = useState(false);

    const { canGoToRoute } = useRouteValidator();
    const currentRoute =
        !!mappingId ? RoutePath.AccountEmailImportEdit :
            !!configurationId ? RoutePath.SamlEdit :
                (`${location.pathname}` as AccountSettingsRoutePath);
    const iframe = useUtopiaIFrame(getIFrameUrl(currentRoute));

    function getIFrameUrl(route: AccountSettingsRoutePath) {
        return IFrameRoutes.get(route)
            ?.replace(AccountSettingsRouteParams.MappingId, mappingId ?? '')
            .replace(AccountSettingsRouteParams.ConfigurationId, configurationId ?? '') ?? '';
    }

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(SettingsTKeys.GoToSAMLAssertionValidatorCta),
            emphasis: 'med',
            color: 'primary',
            icon: { icon: 'check' },
            onClick: () => routeToSamlValidate(),
            show: () => SSOTabRoutes.has(currentRoute),
        },
        {
            text: t(SettingsTKeys.CreateSAMLConfigurationCta),
            emphasis: 'med',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            onClick: () => routeToSamlNew(),
            show: () => SSOTabRoutes.has(currentRoute),
        },
        {
            text: t(SettingsTKeys.AddNewMappingCta),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            onClick: () => routeToAccountEmailImportNew(),
            show: () => EmailImportsTabStates.has(currentRoute),
        }
    ];

    const addRouteToHide = (newRoute: AccountSettingsRoutePath) => {
        const updatedRoutes = new Set(hideIframeRoutes);
        updatedRoutes.add(newRoute);
        setHideIframeRoutes(updatedRoutes);
    };

    const removeRouteFromHide = (routeToRemove: AccountSettingsRoutePath) => {
        const updatedRoutes = new Set(hideIframeRoutes);
        updatedRoutes.delete(routeToRemove);
        setHideIframeRoutes(updatedRoutes);
    };

    const onIFramePathChange = (newPath: string) => {
        if (currentRoute == RoutePath.AccountEmailImportNew && newPath == IFrameRoutes.get(RoutePath.AccountEmailImports)) {
            routeToAccountEmailImports();
        } else if (currentRoute == RoutePath.SamlNew && newPath == IFrameRoutes.get(RoutePath.SingleSignOn)) {
            routeToSingleSignOn();
        }
    };

    useIFramePathChangeListener({ onIFramePathChange: onIFramePathChange, currentRoute: currentRoute });

    useEffect(() => {
        if (!!hasFeatureFlags && !!checkFeatureFlag(FeatureFlags.BrandingEnabled)) {
            addRouteToHide(RoutePath.Branding);
            setCanAccessAtlantisBranding(true);
        } else {
            removeRouteFromHide(RoutePath.Branding);
            setCanAccessAtlantisBranding(false);
        }
    }, [hasFeatureFlags]);

    useEffect(() => {
        if (currentRoute == RoutePath.AccountSettingsIndex) {
            if (canGoToRoute(RoutePath.AccountSettings)) {
                routeToAccountSettings();
            } else if (canGoToRoute(RoutePath.SingleSignOn)) {
                routeToSingleSignOn();
            } else if (canGoToRoute(RoutePath.Branding)) {
                routeToBranding();
            } else if (canGoToRoute(RoutePath.AccountEmailImports)) {
                routeToAccountEmailImports();
            }
        } else {
            iframe.setUrl(IFrameRoutes.get(currentRoute) ?? '');
        }
    }, [location.pathname]);

    return (
        <Page title={t(SettingsTKeys.AccountSettingsTitle)} icon={{ icon: 'gear' }} callsToAction={ctas}>
            {canGoToRoute(RoutePath.AccountSettings) &&
                <Tab active={currentRoute == RoutePath.AccountSettings} onClick={() => routeToAccountSettings()}>
                    {t(SettingsTKeys.SettingsTitle)}
                </Tab>
            }
            {canGoToRoute(RoutePath.SingleSignOn) &&
                <Tab active={SSOTabRoutes.has(currentRoute)} onClick={() => routeToSingleSignOn()}>
                    {t(SettingsTKeys.SingleSignOnTitle)}
                </Tab>
            }
            {canGoToRoute(RoutePath.Branding) &&
                <Tab active={currentRoute == RoutePath.Branding} onClick={() => routeToBranding()}>
                    {t(SettingsTKeys.BrandingTitle)}
                </Tab>
            }
            {canGoToRoute(RoutePath.AccountEmailImports) &&
                <Tab active={EmailImportsTabStates.has(currentRoute)} onClick={() => routeToAccountEmailImports()}>
                    {t(SettingsTKeys.EmailImportsTitle)}
                </Tab>
            }

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' src={iframe.url} hidden={hideIframeRoutes.has(currentRoute)} />

            {/* Use the following Tab Panes once we make this page native */}

            <Tab.Content hidden={!hideIframeRoutes.has(currentRoute)}>
                <Tab.Pane>
                    {currentRoute === RoutePath.Branding &&
                     canAccessAtlantisBranding &&
                     <Branding />}
                </Tab.Pane>
            </Tab.Content>
        </Page>
    );
};
