import React, { useEffect, useState } from 'react';
import { Modal, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { IUseModalProps } from '../../../../hooks/useModal';
import { useLayerContext } from '../../../../context/layer/LayerContext';
import { PreviewTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { ThemeEnum } from '../../../../hooks/useColors';
import { INode } from '../../../../types/CommonTypes';
import { PreviewerFormFillSaveModal } from '../PreviewerFormFillSaveModal/PreviewerFormFillSaveModal';
import './PreviewerFormFillModal.css';

interface PreviewerFormFillModalProps extends IUseModalProps {
    previewerNode: INode | undefined;
    fullAccusoftPreviewUrl: string;
    onClose: (isConfirmed: boolean) => void;
}

export const PreviewerFormFillModal = (props: PreviewerFormFillModalProps) => {
    const {  previewerNode, fullAccusoftPreviewUrl, onClose, ...otherProps } = props;

    const ACCUSOFT_MESSAGE_DATA = 'FormDesignerSaved';

    const { openModal, showToastMessage } = useLayerContext();
    const { t } = useSafeTranslation(TranslationFiles.Preview);

    const [isOpen, setIsOpen] = useState(true);
    const [closeAccusoftPreviewerModals, setCloseAccusoftPreviewerModals] = useState<boolean>(false);

    const handleClose = () => {
        setIsOpen(false);
        onClose(false);
    };

    const onPreviewerFormFillSaveClicked = () => {
        openModal((closeModalFn) => 
            <PreviewerFormFillSaveModal accusoftPreviewerIframeId={`accusoftPreviewerIframId-${previewerNode?.id}`} onClose={closeModalFn} onPreviewerFormFillModalClose={handleClose} closeAccusoftPreviewerModals={closeAccusoftPreviewerModals} />
    )};

    const handleFormToolsClick = () => {
        const documentIframe = document.querySelector('[name="utopiaIframe"]') as HTMLIFrameElement;
        documentIframe?.contentWindow?.postMessage('openFormTools', '*');
    };

    const handleCloseBothModals = () => {
        setCloseAccusoftPreviewerModals(true);
    };

    const handleAccusoftAlerts = (data: any) => {
        if (!!previewerNode) {
            showToastMessage({
                color: ThemeEnum.Success,
                timeout: 5000,
                identifier: 'accusoft-save-success',
                message: (
                    <>
                        {t(PreviewTKeys.AccusoftSaveSuccessAlertBeforeBtn, { fileName: previewerNode.name })}
                        <button className='form-tools-btn' onClick={handleFormToolsClick}>
                            {t(PreviewTKeys.AccusoftSaveSuccessAlertBtn)}
                        </button>{' '}
                        {t(PreviewTKeys.AccusoftSaveSuccessAlertAfterBtn)}
                    </>
                ),
            });
        }

        handleCloseBothModals();
    };

    const showFormFillCtas: EfcCallToActionInfo[] = [
        { text: t(PreviewTKeys.Close), emphasis: 'med', color: 'primary', onClick: onPreviewerFormFillSaveClicked },
    ];

    useEffect(() => {
        const accusoftAlertListener = async function (event: any) {
            if (event.data.key === ACCUSOFT_MESSAGE_DATA) {
                handleAccusoftAlerts(event.data);
            }
        };

        window.addEventListener('message', accusoftAlertListener);

        return () => window.removeEventListener('message', accusoftAlertListener);
    }, []);

    return (
        <Modal  toggle={onPreviewerFormFillSaveClicked} isOpen={isOpen} backdrop='static' fullscreen size='xl' ctas={showFormFillCtas} className="forms-modal" {...otherProps}>
            <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onPreviewerFormFillSaveClicked}></button>
            </div>
            <Modal.Body className='forms-modal-body' >
                {!!fullAccusoftPreviewUrl && <iframe id={`accusoftPreviewerIframId-${previewerNode?.id}`} name={`accusoftPreviewerName-${previewerNode?.id}`} src={fullAccusoftPreviewUrl} height='100%' width='100%' allowFullScreen />}
            </Modal.Body>
        </Modal>
    );
};