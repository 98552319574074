export enum MDFieldTypes {
    Text = 0,
    Email,
    Address,
    Date,
    PhoneNumber,
    Checkbox, //True/False
    Number,
    DropdownList,
}

export type DateFormatTypeKeyStrings = keyof typeof DateFormatTypes;
export enum DateFormatTypes {
    mmddyyyy = 0,
    ddmmyyyy,
    yyyymmdd,
    monthddyyyy,
    ddmonthyyyy,
}

export type NumberFormatTypeKeyStrings = keyof typeof NumberFormatTypes;
export enum NumberFormatTypes {
    Number = 0,
    Percentage,
    Decimal,
    Currency,
}

export type TextFormatTypeKeyStrings = keyof typeof TextFormatTypes;
export enum TextFormatTypes {
    SingleLine = 0,
    MultiLine,
}

export type DropdownListFormatTypeKeyStrings = keyof typeof DropdownListFormatTypes;
export enum DropdownListFormatTypes {
    SingleSelect = 0,
    MultiSelect
}

export enum AllowedCharacterTypes {
    Alphanumeric = 0,
    Alpha,
}

export enum MDModalSteps {
    NewField = 0,
    DropdownListOptions = 3,
}

export enum MDModalAddLink {
    AddInBulk = 0,
    AddIndividually = 1,
}

export enum MDModalSorting {
    Ascending = 0,
    Descending = 1,
}

export interface IMDOptionItem {
    id: string;
    optionName: string;
    isDefault?: boolean;
}

export interface IMDGetFieldsRequest {
    accountId: string;
    mdFieldIds?: string[];
}

export interface IMDCheckFieldNameRequest {
    accountId: string;
    name: string;
}

export interface IMDField {
    id: string;
    accountId?: string;
    name: string;
    type: MDFieldTypes;
    appliedInstanceCount?: number;
    createdByUserId?: string;
    createdByDisplayName?: string;
    system?: boolean;

    textProps?: ITextMDField;
    numberProps?: INumberMDField;
    addressProps?: IAddressMDField;
    dateProps?: IDateMDField;
    checkboxProps?: ICheckboxMDField;
    dropdownListProps?: IDropdownListMDField;
}

export interface IMDGetGroupsRequest {
    accountId: string;
    mdGroupIds?: string[];
}

export interface IMDGroup {
    id: string;
    accountId?: string;
    name: string;
    appliedInstanceCount?: number;
    createdByUserId?: string;
    createdByDisplayName?: string;
    metadataFields: IMDGroupField[];
}

export interface IMDGroupField extends IMDField {
    required: boolean;
}

export interface IAddressMDField {
    addressOne: boolean;
    addressTwo: boolean;
    city: boolean;
    state: boolean;
    postal: boolean;
    country: boolean;
}

export interface INumberMDField {
    numberFormat: NumberFormatTypes;
    minValue?: number;
    maxValue?: number;
    sensitiveDataFormat?: SensitiveDataFormat;
}

export interface IDateMDField {
    dateFormat: DateFormatTypes;
}

export interface ITextMDField {
    textFormat: TextFormatTypes;
    allowedCharacters: AllowedCharacterTypes;
    minChars?: number;
    maxChars?: number;
    prefix?: string;
    sensitiveDataFormat?: SensitiveDataFormat;
}

export interface ICheckboxMDField {
    checkboxChecked: boolean;
}

export interface IDropdownListMDField {
    dropdownListFormat: DropdownListFormatTypes;
    allowNewOptions: boolean;
    listValue?: IMDOptionItem[];
}

export interface SensitiveDataFormat {
    sensitiveDataShown: boolean;
    firstChars?: number;
    lastChars?: number;
}

export interface AdvancedNumberFormat {
    minValue?: number;
    maxValue?: number;
    sensitiveDataFormat: SensitiveDataFormat;
}

export interface AdvancedTextFormat {
    allowedCharacters: AllowedCharacterTypes;
    minChars?: number;
    maxChars?: number;
    prefix?: string;
    sensitiveDataFormat: SensitiveDataFormat;
}

// When we are ready to declare interfaces for Applied Metadata, we decided on this naming convention:
// IMetadataAppliedItem
// IAddressMetadataAppliedItem