import React from 'react';
import { EfcCallToActionInfo, Form, FormGroup, Input, Label, Modal } from '@efilecabinet/efc-atlantis-components';
import { useForm } from '../../../hooks/useForm';
import { AddTemplateModalTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';

interface AddTemplateModalProps {
    isOpen: boolean;
    closeModal: () => void;
    library?: boolean;
    submit: (name: string) => void;
}

interface ITemplateForm {
    name: string;
}

export const AddTemplateModal = (props: AddTemplateModalProps) => {
    const { isOpen, closeModal, submit, library = false } = props;

    const { t } = useSafeTranslation(TranslationFiles.AddTemplateModal);

    const initModel: ITemplateForm = {
        name: '',
    };

    const newTemplateForm = useForm<ITemplateForm>(initModel);

    const clearForm = () => {
        newTemplateForm.setObject(initModel);
    };

    const handleSubmit = () => {
        submit(newTemplateForm.model.name);
        clearForm();
    };

    const handleCancel = () => {
        closeModal();
        clearForm();
    };

    const MAX_INPUT_CHARS = 250;

    const getCtas = () => {
        const ctas: EfcCallToActionInfo[] = [{ text: t(AddTemplateModalTKeys.CancelCta), emphasis: 'med', color: 'primary', onClick: handleCancel }];
        let text, dataId;
        if (!!library) {
            text = t(AddTemplateModalTKeys.AddLibraryCta);
            dataId = 'btnAddTemplateLibrary';
        } else {
            text = t(AddTemplateModalTKeys.AddCta);
            dataId = 'btnAddFolderTemplate';
        }
        ctas.push({ text: text, emphasis: 'high', color: 'primary', disabled: !newTemplateForm.model.name, onClick: handleSubmit, dataId: dataId });
        return ctas;
    };

    return (
        <Modal title={!!library ? t(AddTemplateModalTKeys.TitleLibrary) : t(AddTemplateModalTKeys.Title)} toggle={closeModal} isOpen={isOpen} ctas={getCtas()} unmountOnClose={true}>
            <Modal.Body>
                <Form>
                    <FormGroup>
                        <Label for='name'>{t(AddTemplateModalTKeys.NameLabel)}</Label>
                        <Input
                            id='name'
                            name='name'
                            dataId={!!library ? 'txtTemplateLibraryName' : 'txtTemplateName'}
                            placeholder={!!library ? t(AddTemplateModalTKeys.PlaceHolderLibrary) : t(AddTemplateModalTKeys.PlaceHolder)}
                            value={newTemplateForm.model.name}
                            maxLength={MAX_INPUT_CHARS}
                            onChange={newTemplateForm.onPropChanged}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit();
                                    e.preventDefault();
                                }
                            }}
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>
        </Modal>
    );
};